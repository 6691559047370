import _compact from 'lodash/compact';
import resolve from '../modules/api/resolve';
import {
  apiGet, apiPost, apiStore, generateFormData,
} from '../modules/apiHelper';
import { isStaleData, now, toBoolean } from '../modules/dataHelper';

async function addParticipant(data) {
  const formData = generateFormData(data);

  const response = await apiPost('add_prt', formData, 62).catch((err) => {
    console.error('error adding participants::', err);
    return false;
  }).then((res) => {
    if (!res) return false;
    if (toBoolean(res.data.error)) return false;

    return true;
  });

  return response;
}

async function removeParticipant(data) {
  const formData = generateFormData(data);

  const response = await apiPost('del_prt', formData, 64).catch((err) => {
    console.error('error removing participant', err);
    return false;
  }).then((res) => {
    if (!res) return false;
    if (toBoolean(res.data.error)) return false;

    return true;
  });

  return response;
}

async function updateMeetingTitle(data) {
  const formData = generateFormData(data);

  const response = await apiPost('change_meet_name', formData, 64).catch((err) => {
    console.error('error updating meeting title', err);
    return false;
  }).then((res) => {
    if (!res) return false;
    if (toBoolean(res.data.error)) return false;

    return true;
  });

  return response;
}

async function rescheduleMeeting(data) {
  const formData = generateFormData(data);

  const response = await apiPost('rsc_meet', formData, 62).catch((err) => {
    console.error('error rescheduling meeting', err);
    return false;
  }).then((res) => {
    if (!res) return false;
    if (toBoolean(res.data.error)) return false;

    return true;
  });

  return response;
}

async function addExpertParticipant(data) {
  const formData = generateFormData(data);

  const response = await apiPost('add_exprt_prt', formData, 77).catch((err) => {
    console.error('error adding expert participants::', err);
    return false;
  }).then((res) => {
    if (!res) return false;
    if (toBoolean(res.data.error)) return false;

    return true;
  });

  return response;
}

async function removeExpertParticipant(data) {
  const formData = generateFormData(data);

  const response = await apiPost('del_prt', formData, 77).catch((err) => {
    console.error('error removing expert participant', err);
    return false;
  }).then((res) => {
    if (!res) return false;
    if (toBoolean(res.data.error)) return false;

    return true;
  });

  return response;
}

export default {
  state: {},
  actions: {
    async createMeeting(context, meeting) {
      // eslint-disable-next-line no-param-reassign
      meeting.userid = context.rootState.User.id;
      // eslint-disable-next-line no-param-reassign
      meeting.dash = 1;
      // eslint-disable-next-line no-param-reassign
      meeting.description = meeting.title; // ? do we need description?

      const formData = generateFormData(meeting);

      const response = await apiPost('create_meet', formData, 62).catch((err) => {
        console.error('error creating meeting::', err);
        return false;
      }).then((res) => {
        if (!res) return false;
        if (toBoolean(res.data.error)) return false;

        return res.data.linkid;
      });

      if ((response || '').length) {
        // get the new meeting
        const newMeeting = await context.dispatch('getMeetingByLinkId', [response]);

        return newMeeting;
      }

      return response;
    },
    async updateMeeting(context, [meetingId, data]) {
      const currentMeetingData = await context.dispatch('getMeetingById', [meetingId, true]);

      // compare values and run update on updated values
      const promises = [];

      if (data.title !== currentMeetingData.title) {
        const res = updateMeetingTitle({
          meetingid: meetingId,
          title: data.title,
        });

        promises.push(res);
      }

      if (
        (new Date(data.start).getTime() !== new Date(currentMeetingData.start).getTime())
        || data.duration !== currentMeetingData.duration
        || data.time !== currentMeetingData.time
      ) {
        const res = rescheduleMeeting({
          meetingid: meetingId,
          ...data,
          reason: '[convinience]',
        });

        promises.push(res);
      }

      const result = await Promise.all(promises);

      // if success on all
      if (result.every((res) => !!res)) {
        // get meeting data afresh
        await context.dispatch('getMeetingById', [meetingId, true]);

        return true;
      }

      return false;
    },
    async cancelMeeting(context, data) {
      const formData = generateFormData(data);

      const response = await apiPost('dev_meet', formData, 62).catch((err) => {
        console.error('error cancelling meeting::', err);
        return false;
      }).then(async (res) => {
        if (!res) return false;
        if (toBoolean(res.data.error)) return false;

        await apiStore.removeItem(`meeting/${data.linkId}`);

        return true;
      });

      return response;
    },
    async getMeetingByLinkId(context, [linkId, refresh = false]) {
      const meeting = await apiStore.getItem(`meeting/${linkId}`) || [0, {}];

      if (!refresh && !isStaleData(meeting[0]) && (meeting[1] || {})?.id) return meeting[1];

      const formData = generateFormData({
        linkid: linkId,
      });

      const result = await resolve(apiPost('meetings_linkid', formData, 63)).then((data) => {
        if (!data) return false;

        const meetingData = data.meetings_linkid[0] || false;

        if (meetingData) {
          apiStore.setItem(`meeting/${linkId}`, [now(), meetingData]);
        }

        return meetingData;
      });

      return result;
    },
    async getMeetingById(context, [meetingId, refresh = false]) {
      const meeting = await apiStore.getItem(`meeting/${meetingId}`) || [0, {}];

      if (!refresh && !isStaleData(meeting[0]) && (meeting[1] || {})?.id) return meeting[1];

      const formData = generateFormData({
        meetingid: meetingId,
      });

      const result = await resolve(apiPost('meetings', formData, 63)).then((data) => {
        if (!data) return false;

        const meetingData = data.meetings[0] || false;

        if (meetingData) {
          apiStore.setItem(`meeting/${meetingId}`, [now(), meetingData]);
        }

        return meetingData;
      });

      return result;
    },
    async getCompanyMeetings(context, companyid) {
      const formData = generateFormData({ companyid });

      const result = await resolve(apiPost('company_meetings', formData, 62)).then(async (data) => {
        if (!data) return false;

        const activeMeetings = data.company_meetings.filter((m) => Number(m.dash) === 1);
        const meetings = [];
        const promises = [];

        activeMeetings.forEach((meeting) => {
          // console.log(meeting);
          // get meeting and meeting participants
          const prom2 = context.dispatch('getParticipants', {
            id: meeting.id,
            companyId: meeting.companyid,
          });

          Promise.all([
            prom2,
          ]).then(([p]) => {
            if (p) {
              meetings.push({
                ...meeting,
                participants: p,
              });
            }
          });

          promises.push(prom2);
        });

        await Promise.all(promises);

        return meetings;
      });

      return result;
    },
    async getParticipants(context, data) {
      const formData = generateFormData({
        meetingid: data.id,
        companyid: data.companyId,
      });

      const result = await resolve(apiPost('company_participants', formData, 63)).then(async (res) => {
        if (!res) return false;

        /** @type {Array} */
        const participants = res.company_participants || [];

        // get users' data
        const promises = [];

        // cache data first
        await Promise.allSettled([
          apiGet('gender', 5),
          apiGet('countries', 5),
          apiGet('county', 5),
          apiGet('profession', 5),
          apiGet('experience', 5),
          apiGet('availability', 5),
          apiGet('group_goals', 5),
          context.dispatch('companyRegions', data.companyId),
        ]);

        participants.forEach((participant) => {
          if (toBoolean(participant.expt)) return;
          const prom = context.dispatch('getUserData', participant.userid);

          promises.push(prom);
        });

        const users = await Promise.all(promises);
        // console.log('participants::', users);

        return users;
      });

      return result;
    },
    async saveParticipants(context, { meetingId, companyId, participants }) {
      // get current participants
      /** @type {Array} */
      const currentParticipants = await context.dispatch('getParticipants', {
        id: meetingId,
        companyId,
      });

      // console.log(currentParticipants, participants);

      participants.forEach((participant) => {
        const foundInCurrent = currentParticipants.find((part) => Number(part.id) === Number(participant));

        // if already exist, skip
        if (foundInCurrent) return;

        // new, so add
        addParticipant({
          meetingid: meetingId,
          userid: participant,
        });
      });

      currentParticipants.forEach((currentParticipant) => {
        const foundInNew = participants.find((part) => Number(part) === Number(currentParticipant.id));

        // if found in new list, skip
        if (foundInNew) return;

        // if not exist in new list, remove
        removeParticipant({
          meetingid: meetingId,
          userid: currentParticipant.id,
        });
      });
    },
    async saveParticipantsInMentorshipGroup(context, {
      meetingId, companyId, mentorshipGroupId, userType,
    }) {
      const usersInMentorshipGroup = await context.dispatch('getMentorshipGroupParticipants', {
        companyId,
        groupId: mentorshipGroupId,
      });
      //
      const filteredUsers = userType === 0
        ? usersInMentorshipGroup
        : usersInMentorshipGroup.filter((user) => Number(user.type) === Number(userType));
      //
      const usersIds = filteredUsers.map((user) => user.id);

      console.log(_compact(usersIds));

      await context.dispatch('saveParticipants', {
        meetingId,
        companyId,
        participants: _compact(usersIds),
      });
    },
    // eslint-disable-next-line no-unused-vars
    async startMeeting(context, meetingId) {
      // todo
    },
    async endMeeting(context, meetingId) {
      const formData = generateFormData({
        meetingid: meetingId,
        userid: context.rootState.User.id,
      });

      const result = await apiPost('end_meet', formData, 65).catch((err) => {
        console.warn('error ending meeting', err);
        return false;
      }).then((res) => {
        if (!res) return false;
        if (toBoolean(res.data.error)) return false;

        return true;
      });

      return result;
    },
    async assertMeetingOwnerByLinkId(context, meetingId) {
      const meeting = await context.dispatch('getMeetingByLinkId', [meetingId]);

      if (!meeting) return false;

      if (Number(meeting.admin) !== Number(context.rootState.User.id)) return false;

      return meeting;
    },
    async assertMeetingOwnerById(context, meetingId) {
      const meeting = await context.dispatch('getMeetingById', [meetingId]);

      if (!meeting) return false;

      if (Number(meeting.admin) !== Number(context.rootState.User.id)) return false;

      return meeting;
    },
    async saveExpertsParticipants(context, { meetingId, companyId, participants }) {
      // get current participants
      /** @type {Array} */
      const currentParticipants = await context.dispatch('getExpertsParticipants', {
        id: meetingId,
        companyId,
      });

      // console.log(currentParticipants, participants);

      participants.forEach((participant) => {
        const foundInCurrent = currentParticipants.find((part) => Number(part.id) === Number(participant));

        // if already exist, skip
        if (foundInCurrent) return;

        // new, so add
        addExpertParticipant({
          meetingid: meetingId,
          expertid: participant,
        });
      });

      currentParticipants.forEach((currentParticipant) => {
        const foundInNew = participants.find((part) => Number(part) === Number(currentParticipant.id));

        // if found in new list, skip
        if (foundInNew) return;

        // if not exist in new list, remove
        removeExpertParticipant({
          meetingid: meetingId,
          expertid: currentParticipant.id,
        });
      });
    },
    async getExpertsParticipants(context, data) {
      const formData = generateFormData({
        meetingid: data.id,
        companyid: data.companyId,
      });

      const result = await resolve(apiPost('expert_participants', formData, 77)).then(async (res) => {
        if (!res) return false;

        /** @type {Array} */
        const participants = res.expert_participants || [];

        // get users' data
        const promises = [];

        participants.forEach((participant) => {
          const prom = context.dispatch('getExpert', participant.userid);

          promises.push(prom);
        });

        const users = await Promise.all(promises);
        console.log('expert participants::', users);

        return users;
      });

      return result;
    },
  },
};
