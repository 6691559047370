import actions from './actions';

export default {
  namespaced: false,
  state: () => ({
    surveyTypes: [
      {
        id: 0,
        type: 'Not set',
      },
      {
        id: 1,
        type: 'When a mentorship session ends',
      },
    ],
  }),
  actions,
};
