<template>
  <AppView
    padd-bottom
  >
    <template v-slot:top-app-view>
      <transition name="fade" mode="out-in" appear>
        <CompanyHeader :company="company" :loading="loadingCompany" />
      </transition>
    </template>

    <transition name="fade" mode="out-in" appear>

      <div v-if="errorCompany || errorJourney" class="tw-mt-12" key="errmsg">
        <p class="tw-py-12 tw-text-base tw-text-center" v-html="errorCompany || errorJourney"></p>
      </div>

      <div v-else-if="loadingCompany || loadingJourney" class="tw-mt-12" key="lodng">
        <p class="tw-py-12 tw-text-base tw-text-center">Loading...</p>
      </div>

      <div v-else key="bdy" class="tw-h-full">

        <div class="px-body">
          <h2 class="tw-text-2xl font-title tw-mb-4 tw-pb-4 tw-border-b">{{companyJourney.title}}</h2>
        </div>

        <transition name="fade" mode="out-in" appear>

          <div class="app-view-grid px-body tw-h-full">
            <div class="app-view-grid__col-74-- tw-w-7/12 tw-h-full">

              <div class="tw-rounded-10 tw-bg-app-black-11 tw-p-4 tw-w-full tw-h-full tw-relative tw-overflow-auto tw-flex tw-justify-around">
                <!-- JOURNEY Hierarchy -->
                <CompanyMainJourney
                  :company="company"
                  :journey-id="journeyId"
                  :active-modal="activeModal"
                  @open-modal="activeModal = 'EditJourney'"
                />

                <div>
                  <!-- Mentorship Groups Journey Hierarchy -->
                  <CompanyMentorshipGroupsJourney
                    :company="company"
                    :journey-id="journeyId"
                    :active-modal="activeModal"
                    @open-modal="activeModal = 'EditMentorshipGroupJourney'"
                  />

                  <div></div>

                  <!-- Announcements -->
                  <CompanyAnnouncements
                    :company="company"
                    :journey-id="journeyId"
                    :active-modal="activeModal"
                    @open-modal="activeModal = 'EditAnnouncement'"
                  />
                </div>

              </div>
            </div>

            <div class="tw-w-5/12 tw-h-full tw-px-5">
              <CompanyJourneyBadges :company="company" :journey="companyJourney" />
            </div>
          </div>

        </transition>
      </div>

    </transition>
  </AppView>
</template>

<script>
import CompanyHeader from '@/views/Companies/Company/_partials/CompanyHeader.vue';
import CompanyMainJourney from '@/views/Companies/Company/_partials/CompanyMainJourney.vue';
import CompanyMentorshipGroupsJourney from '@/views/Companies/Company/_partials/CompanyMentorshipGroupsJourney.vue';
import CompanyAnnouncements from '@/views/Companies/Company/_partials/CompanyAnnouncements.vue';
import CompanyJourneyBadges from '@/views/Companies/Company/_partials/CompanyJourneyBadges.vue';

import companyMixin from '@/mixins/company';

export default {
  name: 'CustomizeCompanyJourneyView',
  components: {
    CompanyHeader,
    CompanyMainJourney,
    CompanyMentorshipGroupsJourney,
    CompanyAnnouncements,
    CompanyJourneyBadges,
  },
  mixins: [companyMixin],
  props: ['id', 'journeyId'],
  data() {
    return {
      errorJourney: undefined,
      loadingJourney: true,
      companyJourney: {},
      activeModal: '',
    };
  },
  methods: {
    async getCompanyJourney() {
      this.loadingJourney = true;
      await this.$nextTick();

      const journey = await this.$store.dispatch('getJourney', [this.journeyId, this.company.id, true]);

      if (journey) {
        this.companyJourney = journey;
      }

      await this.$nextTick();
      this.loadingJourney = false;
    },
  },
  async created() {
    // get company
    await this.getCompany();
    await this.$nextTick();

    // assert user company permissions
    if (!this.hasPermission) {
      this.$router.push({
        name: 'app',
      });

      return false;
    }

    await this.getCompanyJourney();
    await this.$nextTick();

    this.loadingCompany = false;
    return true;
  },
};
</script>

<style lang="postcss">
.journey-kanban {
  /* max-height: 70vh; */
}

.journey-kanban .journey-kanban__actions-list {
  width: 140px;
}

.journey-kanban .journey-kanban__hierarchy-line {
  top: 30px;
  height: calc(100% - 90px);
}

.journey-kanban .journey-kanban__hierarchy-list {
  width: 205px;
}

.journey-kanban .journey-kanban__hierarchy-list__item {
  background-color: rgba(195, 195, 195, 1);
  border-color: rgba(112, 112, 112, .31);
  min-height: 64px;
}

.journey-kanban .journey-kanban__pill {
  border-color: #C2C2C2;
  max-width: 145px;
}

@keyframes hover-empty-list-item {
  0% {
    transform: scale(1) rotate(0deg);
  }

  20% {
    transform: scale(1.12) rotate(2deg);
  }

  40% {
    transform: scale(1.12) rotate(-2deg);
  }

  60% {
    transform: scale(1.12) rotate(2deg);
  }

  80% {
    transform: scale(1.12) rotate(-2deg);
  }

  90% {
    transform: scale(1) rotate(0deg);
  }

  100% {
    transform: scale(1) rotate(0deg);
  }
}

.journey-kanban .journey-kanban__hierarchy-list__item.journey-kanban__hierarchy-list__item--dropzone:hover {
  animation-name: hover-empty-list-item;
  animation-duration: 2s;
  animation-iteration-count: infinite;
}

</style>
