<template>
  <form
    id="sub-goal-form"
    ref="sub-goal-form"
    class="tw-max-w-full tw-flex-shrink-0 tw-mx-auto tw-mb-20 auth-card tw-flex tw-flex-wrap"
    method="post"
    action="/sub-goal/create"
    @submit.prevent="onSubmit"
  >

    <div class="tw-w-full tw-mb-4">
      <h1 class="tw-text-4xl font-title">{{ goalBadgeId ? 'Edit Goal Badge' : 'Create a Goal Badge' }}</h1>
      <!-- <h6 class="tw-text-base opacity-78 tw-text-app-deep-blue">Please fill all inputs</h6> -->
    </div>

    <div class="tw-w-full tw-mb-4 tw-flex-shrink-0 tw-px-1">
      <!-- SELECT GOAL IF GOALID IS NOT SET -->
      <SelectGroup
        :class="{ '--loading-data': loadingForm }"
        label="Goal"
        name="goalid"
        nameKey="goal"
        placeholder="goal"
        input-classes="my-select--huge tw-bg-gray-200 tw-text-left"
        v-model="form.goalId"
        :options="goals"
        :reduce="goal => goal.id"
        :selected="goalId"
        :clearable="false"
        :error="errors.goalId"
      />
    </div>
    <div class="tw-w-full tw-mb-4 tw-flex-shrink-0 tw-px-1">
      <InputGroup
        :class="{ '--loading-data': loadingForm }"
        label="Goal Badge"
        name="goalbadge"
        type="file"
        placeholder="goal badge"
        input-classes="tw-p-3"
        v-model="form.badge"
        :error="errors.badge"
      />
    </div>
    <div class="tw-w-full tw-mb-4 tw-flex-shrink-0 tw-px-1">
      <InputGroup
        :class="{ '--loading-data': loadingForm }"
        label="Name"
        name="name"
        type="text"
        placeholder="name"
        input-classes="tw-p-3"
        v-model="form.name"
        :error="errors.name"
      />
    </div>
    <div class="tw-w-full tw-mb-4 tw-flex-shrink-0 tw-px-1">
      <QuillTextareaGroup
        :class="{ '--loading-data': loadingForm }"
        label="Goal Badge Description"
        instruct="Give a small description about the goal badge"
        name="description"
        type="text"
        placeholder="name"
        input-classes="tw-p-3 focus:tw-bg-gray-100"
        v-model="form.description"
        :rows="4"
        :error="errors.description"
      />
    </div>

    <div class="tw-w-full tw-flex-shrink-0 tw-px-1 tw-mb-4 tw-pt-10">
      <BaseButton
        type="submit"
        :class="[
          'tw-block tw-w-full md:tw-inline-block md:tw-w-auto tw-mb-4 tw-py-3 tw-px-8',
          `${btnClass}`
        ]"
        :text="btnText"
        :disabled="!canSubmit || btnDisabled"
      />
    </div>

  </form>
</template>

<script>
import _debounce from 'lodash/debounce';

import QuillTextareaGroup from '@/components/QuillTextareaGroup.vue';
import InputGroup from '@/components/InputGroup.vue';
import SelectGroup from '@/components/SelectGroup.vue';

import form from '@/modules/formHelper';

export default {
  name: 'EditGoalBadge',
  components: {
    QuillTextareaGroup,
    InputGroup,
    SelectGroup,
  },
  props: {
    company: {
      type: Object,
      required: true,
    },
    goalId: {
      type: [String, Number],
      default: undefined,
    },
    goalBadgeId: {
      type: [String, Number],
      default: undefined,
    },
    btnClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loadingForm: true,
      errorForm: undefined,
      btnText: 'Save',
      btnDisabled: false,
      errors: {},
      goals: [],
      form: {
        goalId: undefined,
        name: undefined,
        description: undefined,
        badge: undefined,
      },
    };
  },
  watch: {
    // eslint-disable-next-line func-names
    'company.id': function () {
      this.resetForm();
      this.loadForm();
    },
    goalId() {
      this.resetForm();
      this.loadForm();
    },
    goalBadgeId() {
      this.resetForm();
      this.loadForm();
    },
  },
  computed: {
    canSubmit() {
      return form.isValidTextInput(this.form.name)
        && !this.loadingForm
        && form.isValidTextInput(this.form.description)
        && this.form.badge !== undefined
        && this.form.goalId !== undefined;
    },
  },
  methods: {
    resetForm() {
      this.form = {
        goalId: undefined,
        name: undefined,
        description: undefined,
        badge: undefined,
      };
    },
    // eslint-disable-next-line func-names
    loadForm: _debounce(async function () {
      this.loadingForm = true;
      this.errorForm = undefined;

      // * get list of company goals
      this.goals = (await this.$store.dispatch('getGoals', [this.company.id, false]) || []);

      // * get goal badge's details if id is defined
      // if (this.goalBadgeId !== undefined) {
      //   const subGoals = (await this.$store.dispatch('getSubGoals', [this.company.id, false]) || []);
      //   const curr = subGoals.find((g) => Number(g.id) === Number(this.goalBadgeId)) || {};

      //   this.form.name = curr.goal || '';
      //   this.form.description = curr.description || '';
      // }

      await this.$nextTick();
      await this.$nextTick();

      this.form.goalId = this.goalId;
      await this.$nextTick();

      this.loadingForm = false;
    }, 300),
    async onSubmit() {
      if (!this.canSubmit) {
        this.$toasted.global.appError({
          errorMessage: 'You can\'t submit the form. Check for empty inputs',
        });

        console.warn('Cannot submit form... \n check for empty inputs');
        return false;
      }

      // signup!!
      this.btnDisabled = true;
      this.btnText = 'working...';

      const data = {
        ...(this.form),
        companyId: this.company.id,
        goalBadgeId: this.goalBadgeId,
        badge: this.form.badge[0],
      };

      const result = await this.$store.dispatch('createGoalBadge', data);

      if (result) {
        this.$toasted.success('Saved!', { duration: 1000 });
        this.$emit('created-success');
      } else {
        this.$toasted.global.appError({
          errorMessage: 'Saving sub-goal failed. Please try again later',
        });
      }

      this.btnDisabled = false;
      this.btnText = 'save';

      return result;
    },
  },
  async created() {
    this.errors = { ...this.form };

    await this.$nextTick();
    await this.loadForm();
    await this.$nextTick();

    this.loadingForm = false;
  },
};
</script>

<style>

</style>
