import _debounce from 'lodash/debounce';

let onResizeFns = [];

export default {
  name: 'resizeUiMixin',
  methods: {
    // eslint-disable-next-line func-names
    onResize: _debounce(async function () {
      // console.log('resize');
      this.$store.state.Ui.viewWidth = window.innerWidth;
      this.viewWidth = window.innerWidth;
      onResizeFns.forEach((fn) => {
        fn();
      });
    }, 1000),
    addOnResizeFn(fn) {
      if (typeof fn === 'function') {
        onResizeFns.push(fn);
      }
    },
    clearOnResizeFns() {
      onResizeFns = [];
    },
  },
  mounted() {
    window.onresize = null;
    window.onresize = this.onResize;
  },
};
