<template>
  <form
    id="goal-form"
    ref="goal-form"
    class="tw-max-w-full tw-flex-shrink-0 tw-mx-auto tw-mb-20 auth-card tw-flex tw-flex-wrap"
    method="post"
    action="/expert/create"
    @submit.prevent="onSubmit"
  >
    <div class="tw-w-full tw-mb-4">
      <h1 class="tw-text-4xl font-title">{{ expertId ? 'Edit Expert' : 'Add an Expert' }}</h1>
      <!-- <h6 class="tw-text-base opacity-78 tw-text-app-deep-blue">Please fill all inputs</h6> -->
    </div>

    <div class="tw-w-full tw-mb-4 tw-flex-shrink-0 tw-px-1">
      <PhotoInput :photo="form.photoURL" @change="setPhoto" />
    </div>
    <div class="tw-w-full tw-mb-4 tw-flex-shrink-0 tw-px-1">
      <InputGroup
        :class="{ '--loading-data': loadingForm }"
        label="First Name"
        name="first_name"
        type="text"
        placeholder="name"
        input-classes="tw-p-3"
        v-model="form.firstName"
        :error="errors.firstName"
      />
    </div>
    <div class="tw-w-full tw-mb-4 tw-flex-shrink-0 tw-px-1">
      <InputGroup
        :class="{ '--loading-data': loadingForm }"
        label="Last Name"
        name="last_name"
        type="text"
        placeholder="name"
        input-classes="tw-p-3"
        v-model="form.lastName"
        :error="errors.lastName"
      />
    </div>
    <div class="tw-w-full tw-mb-4 tw-flex-shrink-0 tw-px-1">
      <SelectGroup
          label="Gender"
          name="gender"
          nameKey="gender"
          placeholder="Gender"
          input-classes="my-select--huge tw-font-sans tw-bg-gray-200 tw-text-left"
          v-model="form.gender"
          :options="gendersList"
          :reduce="gender => gender.id"
          :selected="form.gender"
          :clearable="false"
          :error="errors.gender"
        />
    </div>
    <div class="tw-w-full tw-mb-4 tw-flex-shrink-0 tw-px-1">
      <InputGroup
        :class="{ '--loading-data': loadingForm }"
        label="Email"
        name="email"
        type="email"
        placeholder="name"
        input-classes="tw-p-3"
        v-model="form.email"
        :error="errors.email"
      />
    </div>
    <div class="tw-w-full tw-mb-4 tw-flex-shrink-0 tw-px-1">
      <PhoneNumberInput
        label="Phone number"
        name="phone"
        placeholder="7********"
        instruct="select your country code"
        dial-codes
        input-classes="tw-p-3 tw-w-full"
        v-model="form.phone"
        :error="form.phone"
      />
    </div>
    <div class="tw-w-full tw-mb-4 tw-flex-shrink-0 tw-px-1">
      <QuillTextareaGroup
        :class="{ '--loading-data': loadingForm }"
        label="Expert Bio"
        name="bio"
        type="text"
        placeholder="Bio"
        input-classes="tw-p-3 focus:tw-bg-gray-100"
        v-model="form.bio"
        :rows="4"
        :error="errors.bio"
      />
    </div>

    <div class="tw-w-full tw-flex-shrink-0 tw-px-1 tw-mb-4 tw-pt-10">
      <BaseButton
        type="submit"
        :class="[
          'tw-block tw-w-full md:tw-inline-block md:tw-w-auto tw-mb-4 tw-py-3 tw-px-8',
        ]"
        :text="btnText"
        :disabled="!canSubmit || btnDisabled"
      />
    </div>

  </form>
</template>

<script>
import _debounce from 'lodash/debounce';

import QuillTextareaGroup from '@/components/QuillTextareaGroup.vue';
import InputGroup from '@/components/InputGroup.vue';
import SelectGroup from '@/components/SelectGroup.vue';
import PhoneNumberInput from '@/components/PhoneNumberInput.vue';
import PhotoInput from '@/components/PhotoInput.vue';
import form from '@/modules/formHelper';
import { apiGet, generateFormData } from '@/modules/apiHelper';
import apiResolve from '@/modules/api/resolve';

export default {
  name: 'EditExpert',
  components: {
    QuillTextareaGroup,
    PhoneNumberInput,
    InputGroup,
    SelectGroup,
    PhotoInput,
  },
  props: {
    company: {
      type: Object,
      required: true,
    },
    expertId: {
      type: [String, Number],
      default: undefined,
    },
  },
  data() {
    return {
      loadingForm: true,
      errorForm: undefined,
      btnText: 'Save',
      btnDisabled: false,
      gendersList: [],
      form: {
        email: undefined,
        phone: undefined,
        firstName: undefined,
        lastName: undefined,
        bio: undefined,
        gender: undefined,
        photo: undefined,
        photoURL: undefined,
      },
      errors: {},
    };
  },
  watch: {
    // eslint-disable-next-line func-names
    'company.id': function () {
      this.resetForm();
      this.loadForm();
    },
  },
  computed: {
    canSubmit() {
      return form.isValidTextInput(this.form.firstName)
        && form.isValidTextInput(this.form.lastName)
        && form.isValidTextInput(this.form.bio)
        && form.isValidSelectInput(this.form.gender)
        && form.isValidEmail(this.form.email)
        && (this.expertId ? true : !!this.form.photo?.name)
        && !this.loadingForm
        && this.company.id !== undefined;
    },
  },
  methods: {
    resetForm() {
      this.form = {
        name: undefined,
        description: undefined,
      };
    },
    setPhoto(photoFile) {
      this.form.photo = photoFile;
    },
    // eslint-disable-next-line func-names
    loadForm: _debounce(async function () {
      this.loadingForm = true;
      this.errorForm = undefined;

      await this.getGendersList();

      if (this.expertId) {
        const experts = await this.$store.dispatch('getCompanyExperts', this.company.id);
        const curr = experts.find((p) => Number(p.id) === Number(this.expertId)) || {};

        this.form.firstName = curr.name || '';
        this.form.lastName = curr.last_name || '';
        this.form.email = curr.email || '';
        this.form.phone = curr.phone || '';
        this.form.bio = curr.bio || '';
        this.form.gender = curr.gender || '';
        this.form.photoURL = curr.pic || '';
        this.form.phone = curr.phone || '';
      }

      await this.$nextTick();
      await this.$nextTick();

      this.loadingForm = false;
    }, 300),

    async getGendersList() {
      const result = await apiResolve(apiGet('gender', 5));

      if (result) {
        this.gendersList = result.gender;
      }
    },

    async onSubmit() {
      if (!this.canSubmit) {
        this.$toasted.global.appError({
          errorMessage: 'You can\'t submit the form. Check for empty inputs',
        });

        console.warn('Cannot submit form... \n check for empty inputs');
        return false;
      }

      this.btnDisabled = true;
      this.btnText = 'working...';

      const formData = generateFormData({
        companyid: this.company.id,
        email: this.form.email,
        first_name: this.form.firstName,
        last_name: this.form.lastName,
        bio: this.form.bio,
        gender: this.form.gender,
        phone: this.form.phone,
        name: this.form.firstName,
      });

      formData.append('file1', this.form.photo);

      const result = await this.$store.dispatch('createExpert', formData);

      if (result) {
        this.$toasted.success('Saved!', { duration: 1000 });
        this.$emit('created-success');

        if (this.expertId) {
          this.$store.dispatch('logAction', this.$store.state.Logger.actionsIndexes.createExpert);
        } else {
          this.$store.dispatch('logAction', this.$store.state.Logger.actionsIndexes.createExpert);
        }
      } else {
        this.$toasted.global.appError({
          errorMessage: 'Saving expert failed. Please try again later',
        });
      }

      this.btnDisabled = false;
      this.btnText = 'save';

      return result;
    },
  },
  async created() {
    this.errors = { ...this.form };

    await this.$nextTick();
    await this.loadForm();
    await this.$nextTick();

    this.loadingForm = false;
  },
};
</script>

<style>

</style>
