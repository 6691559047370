export default {
  name: 'companyMixin',
  data() {
    return {
      loadingCompany: true,
      errorCompany: undefined,
      loadingKengen: true,
      isKengen: false,
      company: {},
    };
  },
  computed: {
    hasPermission() {
      if (this.isSuperAdmin) return true;

      return Number(this.$store.getters.userCompanyId) === Number(this.company.id);
    },
  },
  methods: {
    /**
     * Gets a non-cached company information
     * then runs a function after getting the company
     */
    async getCompany() {
      this.loadingCompany = true;
      this.errorCompany = undefined;
      let company;

      if (this.isSuperAdmin) {
        company = await this.$store.dispatch('getCompany', [this.id, true]);
      } else {
        company = await this.$store.dispatch('myCompany', true);
      }

      if (company?.id) {
        this.company = { ...company };

        await this.$nextTick();
      } else {
        this.errorCompany = 'Error in finding company.';
      }

      this.loadingCompany = false;
    },

    async kengenIsLoggedIn() {
      if (!this.isSuperAdmin) {
        this.loadingKengen = true;
        this.isKengen = await this.$store.dispatch('myCompanyIsKengen') || false;
        this.loadingKengen = false;

        if (this.isKengen) {
          // change document title
          document.title = 'KenGen mentorship dashboard';
        }
      }
    },
  },
};
