var render = function render(){var _vm=this,_c=_vm._self._c;return _c('TopNav',{attrs:{"type":"back","prev-route":{ name: (_vm.isSuperAdmin ? 'viewCompany' : 'myLicense'), params: { id: _vm.$route.params.id } },"title":_vm.company.company || 'fetching company...',"title-class":[
    'tw-ml-8',
    { 'opacity-78 font-title tw-text-xl tw-tracking-wider tw-leading-6': !_vm.loading },
    { 'opacity-31 tw-italic tw-lowercase': _vm.loading },
  ]},scopedSlots:_vm._u([(!_vm.loading)?{key:"title-left",fn:function(){return [_c('UserPhoto',{class:[
        'tw-mr-6 tw-my-1 tw-rounded-10--force --force-children tw-bg-app-white-87',
        { 'tw-shadow-app-sm': !(_vm.company.logo || '').length }
      ],attrs:{"photo":_vm.company.logo,"user-name":{
        name: _vm.company.company,
      },"logo":"","photo-size":"tw-h-12 tw-w-12"}})]},proxy:true}:null],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }