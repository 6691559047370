import Vue from 'vue';
import Vuex from 'vuex';

import Ui from './Ui';
import User from './User';
import CompanySettings from './CompanySettings';
import Surveys from './Surveys';
import MentorshipGroups from './MentorshipGroups';
import Logger from './Logger';
import VideoMeetings from './video-meetings';
import Experts from './Experts';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    Ui,
    User,
    CompanySettings,
    Surveys,
    MentorshipGroups,
    Logger,
    VideoMeetings,
    Experts,
  },
});


export default store;
