<template>
  <AppView
    padd-bottom
  >

    <template v-slot:top-app-view>
      <transition name="fade" mode="out-in" appear>
        <CompanyHeader :company="company" :loading="loadingCompany" />
      </transition>
    </template>

    <transition name="fade" mode="out-in" appear>

      <div v-if="errorCompany" class="tw-mt-12" key="errmsg">
        <p class="tw-py-12 tw-text-base tw-text-center" v-html="errorCompany"></p>
      </div>

      <div v-else-if="loadingCompany" class="tw-mt-12" key="lodng">
        <p class="tw-py-12 tw-text-base tw-text-center">Loading...</p>
      </div>

      <div v-else key="bdy">
        <transition name="fade" mode="out-in" appear>

          <div class="app-view-grid px-body">
            <div class="app-view-grid__col-74">
              <portal to="modal-body" key="mdl">
                <!-- // TODO -->
                <EditGoalBadge
                  :company="company"
                  @created-success="closeModal(); getCompanyGoalsBadges();"
                />
              </portal>

              <template>
                <div class="tw-flex tw-items-center tw-pt-10 tw-pb-4">
                  <img
                    data-src="/img/edit-goals.png"
                    src="/img/edit-goals.png"
                    style="width: 80px; height: 80px;"
                    class="tw-object-cover tw-block tw-mr-8"
                    async
                  >
                  <h3 class="font-title tw-text-xl tw-tracking-wider">
                    Goals Badges
                  </h3>

                  <BaseButton
                    text="Create Goal Badge"
                    class="tw-ml-auto"
                    @click="promptCreateGoalBadge"
                  />
                </div>
                <div class="tw-border-t tw-border-app-black-11 tw-mb-16"></div>
                <div></div>
              </template>

              <!-- GOALS BADGES -->
              <ViewMore
                class="tw-flex tw-flex-wrap stacked-grid stacked-grid--stack-three lg:stacked-grid--stack-three"
                :list="goalsBadges"
                :limit="6"
                v-slot="{ item: goalBadge }"
              >
                <div class="tw-border tw-rounded-10 tw-p-4 tw-bg-gray-200">
                  <UserPhoto
                    :photo="goalBadge.data"
                    class="tw-mb-2 tw-inline-block"
                    photo-size="tw-h-20 tw-w-20 tw-rounded-10"
                  />
                  <p class="tw-mb-2">{{goalBadge.name}}</p>
                  <div>
                    <p class="tw-text-sm tw-bg-gray-300 tw-rounded-10 tw-p-2 tw-inline-block tw-leading-none">
                      <span class="opacity-54">Goal</span>
                      <br />
                      {{goalBadge.goal?.goal || '---'}}
                    </p>
                  </div>
                </div>
              </ViewMore>

            </div>
          </div>

        </transition>
      </div>

    </transition>
  </AppView>
</template>

<script>
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';

import CompanyHeader from '@/views/Companies/Company/_partials/CompanyHeader.vue';
import EditGoalBadge from '@/views/Companies/Company/_partials/EditGoalBadge.vue';

import UserPhoto from '@/components/UserPhoto.vue';

import companyMixin from '@/mixins/company';

export default {
  name: 'EditCompanyGoalsBadgesView',
  components: {
    CompanyHeader,
    UserPhoto,
    EditGoalBadge,
  },
  mixins: [companyMixin],
  props: ['id'],
  data() {
    return {
      goalsBadges: [],
    };
  },
  methods: {
    async getCompanyGoalsBadges() {
      const goalsBadges = await this.$store.dispatch('getCompanyGoalsBadges', this.company.id);

      if (Array.isArray(goalsBadges)) {
        this.goalsBadges = _reverse(_sortBy(goalsBadges, 'dateOfPost'));
      }
    },
    async promptCreateGoalBadge() {
      await this.$nextTick();

      // open modal
      this.$store.commit('openModal', [true, 'modal']);
    },
  },
  async created() {
    // get company
    await Promise.all([
      this.getCompany(),
    ]);

    await this.$nextTick();

    // assert user company permissions
    if (!this.hasPermission) {
      this.$router.push({
        name: 'app',
      });

      return false;
    }

    await this.getCompanyGoalsBadges();

    await this.$nextTick();
    await this.$nextTick();

    return true;
  },
};
</script>

<style>

</style>
