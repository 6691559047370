import store from '../../store';

import EditCompanyGoalsView from '../../views/Companies/Company/EditCompanyGoalsView.vue';
import EditCompanyGoalsBadgesView from '../../views/Companies/Company/EditCompanyGoalsBadgesView.vue';
import CustomizeCompanyView from '../../views/Companies/Company/CustomizeCompanyView.vue';
import CustomizeCompanyJourneyView from '../../views/Companies/Company/CustomizeCompanyJourneyView.vue';
import CompanySurveysView from '../../views/Companies/Company/CompanySurveysView.vue';
import CompanySurveyResponses from '../../views/Companies/Company/CompanySurveyResponses.vue';

import MentorshipGroupsView from '../../views/Companies/Company/MentorshipGroups.vue';
import MentorshipGroupView from '../../views/Companies/Company/MentorshipGroup/MentorshipGroup.vue';

import VideoMeetingsView from '../../views/Companies/Company/VideoMeetings.vue';
import VideoMeetingView from '../../views/Companies/Company/VideoMeeting.vue';

import ExpertsView from '../../views/Companies/Company/Experts.vue';
import ExpertView from '../../views/Companies/Company/Expert.vue';

const companiesView = import(/* webpackChunkName: "sadminviews" */ '../../views/Companies/CompaniesView.vue');
const companyView = import(/* webpackChunkName: "staffviews" */ '../../views/Companies/Company/CompanyView.vue');

const metaSuperAdmin = {
  requiresLogin: true,
  requiresUserType: store.state.User.accountTypes.superAdmin,
};

const metaStaff = {
  requiresLogin: true,
  requiresUserType: store.state.User.accountTypes.staff,
};

export default {
  superAdmin: [
    {
      path: 'companies',
      name: 'companies',
      component() {
        return companiesView;
      },
      meta: metaSuperAdmin,
    },
    {
      path: 'companies/create',
      name: 'createCompany',
      component() {
        return companiesView;
      },
      meta: metaSuperAdmin,
      props: {
        create: true,
      },
    },
    {
      path: 'companies/edit/:id',
      name: 'viewCompany',
      component() {
        return companyView;
      },
      meta: metaSuperAdmin,
      props: true,
    },
    {
      path: 'companies/edit/:id/goals',
      name: 'editCompanyGoals',
      component: EditCompanyGoalsView,
      meta: metaSuperAdmin,
      props: true,
    },
    {
      path: 'companies/edit/:id/goals-badges',
      name: 'editCompanyGoalsBadges',
      component: EditCompanyGoalsBadgesView,
      meta: metaSuperAdmin,
      props: true,
    },
    {
      path: 'companies/edit/:id/customize',
      name: 'customizeCompany',
      component: CustomizeCompanyView,
      meta: metaSuperAdmin,
      props: true,
    },
    {
      path: 'companies/edit/:id/customize/journey/:journeyId',
      name: 'customizeCompanyJourney',
      component: CustomizeCompanyJourneyView,
      meta: metaSuperAdmin,
      props: true,
    },
    {
      path: 'companies/edit/:id/surveys',
      name: 'companySurveys',
      component: CompanySurveysView,
      meta: metaSuperAdmin,
      props: true,
    },
    {
      path: 'companies/edit/:id/surveys/:surveyId/responses',
      name: 'companySurveyResponses',
      component: CompanySurveyResponses,
      meta: metaSuperAdmin,
      props: true,
    },
    {
      path: 'companies/edit/:id/video-meetings',
      name: 'videoMeetings',
      component: VideoMeetingsView,
      meta: metaSuperAdmin,
      props: true,
    }, {
      path: 'companies/edit/:id/video-meeting/:linkid',
      name: 'videoMeeting',
      component: VideoMeetingView,
      meta: metaSuperAdmin,
      props: true,
    },
    {
      path: 'companies/edit/:id/mentorship-groups',
      name: 'companyMentorshipGroups',
      component: MentorshipGroupsView,
      meta: metaSuperAdmin,
      props: true,
    },
    {
      path: 'companies/edit/:id/mentorship-groups/:groupId',
      name: 'companyMentorshipGroup',
      component: MentorshipGroupView,
      meta: metaSuperAdmin,
      props: true,
      children: [
        {
          path: 'chat',
          name: 'companyMentorshipGroupChat',
          component() {
            return import(/* webpackChunkName: "mentorshipgroup" */ '../../views/Companies/Company/MentorshipGroup/MentorshipGroupChat.vue');
          },
          meta: metaStaff,
          props: true,
        },
        {
          path: 'tasks',
          name: 'companyMentorshipGroupTasks',
          component() {
            return import(/* webpackChunkName: "mentorshipgroup" */ '../../views/Companies/Company/MentorshipGroup/MentorshipGroupTasks.vue');
          },
          meta: metaStaff,
          props: true,
        },
        {
          path: 'tasks/:taskId/review',
          name: 'reviewGroupTask',
          component() {
            return import(/* webpackChunkName: "mentorshipgroup" */ '../../views/Companies/Company/MentorshipGroup/MentorshipGroupReviewTask.vue');
          },
          meta: metaStaff,
          props: true,
        },
        {
          path: 'tasks/:taskId/revert',
          name: 'revertGroupTask',
          component() {
            return import(/* webpackChunkName: "mentorshipgroup" */ '../../views/Companies/Company/MentorshipGroup/MentorshipGroupRevertTask.vue');
          },
          meta: metaStaff,
          props: true,
        },
        {
          path: 'settings',
          name: 'companyMentorshipGroupSettings',
          component() {
            return import(/* webpackChunkName: "mentorshipgroup" */ '../../views/Companies/Company/MentorshipGroup/MentorshipGroupSettings.vue');
          },
          meta: metaStaff,
          props: true,
        },
      ],
    },
    {
      path: 'companies/edit/:id/experts',
      name: 'experts',
      component: ExpertsView,
      meta: metaSuperAdmin,
      props: true,
    },
    {
      path: 'companies/edit/:id/experts/:expertId',
      name: 'expert',
      component: ExpertView,
      meta: metaSuperAdmin,
      props: true,
    },
  ],
  staff: [
    {
      path: 'my-license',
      name: 'myLicense',
      component() {
        return companyView;
      },
      meta: metaStaff,
    },
    {
      path: 'my-license/mentorship/goals',
      name: 'editCompanyGoals',
      component: EditCompanyGoalsView,
      meta: metaStaff,
      props: true,
    },
    {
      path: 'my-license/mentorship/goals-badges',
      name: 'editCompanyGoalsBadges',
      component: EditCompanyGoalsBadgesView,
      meta: metaStaff,
      props: true,
    },
    {
      path: 'my-license/mentorship/customize-experience',
      name: 'customizeCompany',
      component: CustomizeCompanyView,
      meta: metaStaff,
      props: true,
    },
    {
      path: 'my-license/mentorship/customize-experience/journey/:journeyId',
      name: 'customizeCompanyJourney',
      component: CustomizeCompanyJourneyView,
      meta: metaStaff,
      props: true,
    },
    {
      path: 'my-license/mentorship/surveys',
      name: 'companySurveys',
      component: CompanySurveysView,
      meta: metaStaff,
      props: true,
    },
    {
      path: 'my-license/mentorship/surveys/:surveyId/responses',
      name: 'companySurveyResponses',
      component: CompanySurveyResponses,
      meta: metaStaff,
      props: true,
    },
    {
      path: 'my-license/mentorship/video-meetings',
      name: 'videoMeetings',
      component: VideoMeetingsView,
      meta: metaStaff,
      props: true,
    },
    {
      path: 'my-license/mentorship/video-meeting/:linkid',
      name: 'videoMeeting',
      component: VideoMeetingView,
      meta: metaStaff,
      props: true,
    },
    {
      path: 'my-license/mentorship/mentorship-groups',
      name: 'companyMentorshipGroups',
      component: MentorshipGroupsView,
      meta: metaStaff,
      props: true,
    },
    {
      path: 'my-license/mentorship/mentorship-groups/:groupId',
      name: 'companyMentorshipGroup',
      component: MentorshipGroupView,
      meta: metaStaff,
      props: true,
      children: [
        {
          path: 'chat',
          name: 'companyMentorshipGroupChat',
          component() {
            return import(/* webpackChunkName: "mentorshipgroup" */ '../../views/Companies/Company/MentorshipGroup/MentorshipGroupChat.vue');
          },
          meta: metaStaff,
          props: true,
        },
        {
          path: 'tasks',
          name: 'companyMentorshipGroupTasks',
          component() {
            return import(/* webpackChunkName: "mentorshipgroup" */ '../../views/Companies/Company/MentorshipGroup/MentorshipGroupTasks.vue');
          },
          meta: metaStaff,
          props: true,
        },
        {
          path: 'tasks/:taskId/review',
          name: 'reviewGroupTask',
          component() {
            return import(/* webpackChunkName: "mentorshipgroup" */ '../../views/Companies/Company/MentorshipGroup/MentorshipGroupReviewTask.vue');
          },
          meta: metaStaff,
          props: true,
        },
        {
          path: 'tasks/:taskId/revert',
          name: 'revertGroupTask',
          component() {
            return import(/* webpackChunkName: "mentorshipgroup" */ '../../views/Companies/Company/MentorshipGroup/MentorshipGroupRevertTask.vue');
          },
          meta: metaStaff,
          props: true,
        },
        {
          path: 'settings',
          name: 'companyMentorshipGroupSettings',
          component() {
            return import(/* webpackChunkName: "mentorshipgroup" */ '../../views/Companies/Company/MentorshipGroup/MentorshipGroupSettings.vue');
          },
          meta: metaStaff,
          props: true,
        },
      ],
    },
    {
      path: 'my-license/mentorship/experts',
      name: 'experts',
      component: ExpertsView,
      meta: metaStaff,
      props: true,
    },
    {
      path: 'my-license/mentorship/experts/:expertId',
      name: 'expert',
      component: ExpertView,
      meta: metaStaff,
      props: true,
    },
  ],
};
