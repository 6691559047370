import resolve from '../../../modules/api/resolve';
import { apiPost, apiStore, generateFormData } from '../../../modules/apiHelper';
import { invalideDate, isStaleData, now } from '../../../modules/dataHelper';

export default {
  async saveJourney(context, data) {
    const request = await resolve(apiPost('create_journey', generateFormData({
      title: data.title,
      desc: data.description,
      companyid: data.companyId,
      admin: context.rootState.User.id,
    }), 71));

    return request;
  },

  async getJourneys(context, [CompanyId, refresh = false]) {
    const companyid = CompanyId || context.rootState.User.companyid;
    if (!companyid) return false;

    const journeys = await apiStore.getItem(`journeys/${companyid}`) || [invalideDate, []];

    if (!refresh && !isStaleData(journeys[0]) && journeys[1].length) return journeys[1];

    const result = await resolve(apiPost('company_jan', generateFormData({ companyid }), 71)).then((data) => {
      if (!data) return false;

      const companyJourneys = data.company_jan || [];

      if (companyJourneys.length > 0) {
        apiStore.setItem(`journeys/${companyid}`, [now(), companyJourneys]);
      }

      return companyJourneys;
    });

    return result;
  },
  async getJourney(context, [journeyId, companyId, refresh = false]) {
    const journeys = await context.dispatch('getJourneys', [companyId, refresh]);

    if (Array.isArray(journeys)) {
      return journeys.find((journey) => Number(journey.id) === Number(journeyId)) || false;
    }

    return false;
  },
  async getJourneyActions(context, [journeyId, companyId, refresh = false]) {
    const companyid = companyId || context.rootState.User.companyid;
    if (!companyid) return false;

    const apiStoreKey = `journeyActions/${journeyId}/${companyid}`;
    const journeyActions = await apiStore.getItem(apiStoreKey) || [invalideDate, []];

    if (!refresh && !isStaleData(journeyActions[0]) && journeyActions[1].length) return journeyActions[1];

    const result = await resolve(apiPost('action_list', generateFormData({ companyid }), 71)).then((data) => {
      if (!data) return false;

      const companyJourneyActions = (data.action_list || []).filter((action) => Number(action.janid) === Number(journeyId));

      if (companyJourneyActions.length > 0) {
        apiStore.setItem(apiStoreKey, [now(), companyJourneyActions]);
      }

      return companyJourneyActions;
    });

    return result;
  },
  async getJourneyAction(context, [actionId, journeyId, companyId, refresh = false]) {
    const actions = await context.dispatch('getJourneyActions', [journeyId, companyId, refresh]);

    if (Array.isArray(actions)) {
      return actions.find((action) => Number(action.id) === Number(actionId));
    }

    return actions;
  },
  async saveJourneyAction(context, data) {
    let badgeType;

    if (data?.isAnnouncement) {
      badgeType = 4; // announcement
    } else if (data.tip?.length && data.badgeId) {
      badgeType = 3; // both
    } else if (data.tip?.length && !data.badgeId) {
      badgeType = 1; // tip only
    } else {
      badgeType = 2; // badge only
    }

    if (data.id) {
      const request = await resolve(apiPost('edit_jan_act', generateFormData({
        actid: data.id,
        action: data.tip || '',
      }), 73));

      return request;
    }

    const request = await resolve(apiPost('add_jan_act', generateFormData({
      title: data.title,
      action: data.tip || '',
      companyid: data.companyId,
      janid: data.journeyId,
      post: data.position,
      type: data.type, // user types
      badgeid: data.badgeId,
      badge_type: badgeType,
    }), 71));

    return request;
  },
  async deleteJourneyAction(context, journeyActionId) {
    const result = await resolve(apiPost('del_action', generateFormData({ id: journeyActionId }), 71));

    return result;
  },
  async createBadge(context, data) {
    const formData = generateFormData({
      badge_name: data.badge_name,
      userid: context.rootState.User.id,
      companyid: data.companyid,
    });

    formData.append('file1', data.badge);

    const result = await resolve(apiPost('create_badges', formData, 71));

    return result;
  },
  async deleteBadge(context, badgeId) {
    const result = await resolve(apiPost('del_badge', generateFormData({ id: badgeId }), 71));

    return result;
  },
  async getBadges(context, [companyId]) {
    const badges = await resolve(apiPost('badge_list', generateFormData({ companyid: companyId }), 71))
      .then((res) => {
        if (!res) {
          console.warn('getBadges::journeys.js Error in getting badges');
          return false;
        }

        return res.badge_list;
      });
    //

    return badges;
  },
};
