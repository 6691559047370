<template>
  <form
    id="share-resource"
    ref="share-resource-form"
    class="tw-max-w-full md:tw-w-2/3 tw-mb-12 auth-card tw-overflow-hidden-- tw-relative"
    method="post"
    action="/share-resource"
    @submit.prevent="onSubmit"
  >

  <transition-group
    name="slide-right"
    tag="div"
    class="tw-w-full"
    mode="out-in"
    appear
  >
    <template v-if="step !== 2">
      <!-- SHARE TO -->
      <div key="step-1-body">
        <div class="tw-pt-6 tw-border-b tw-pb-4 tw-mb-8">
          <p class="tw-text-center opacity-54 tw-text-xs">
            Users
          </p>
        </div>

        <div class="tw-my-4">
          <div
            v-if="isSuperAdmin"
            class="tw-m-4 tw-border-b tw-bg-gray-300 tw-rounded-3 tw-p-4"
          >
            <v-select
              placeholder="Company"
              :class="[
                { '--loading-data': loadingForm },
                'my-select tw-bg-app-deep-blue-11 tw-border-b tw-border-black tw-text-black tw-rounded-t-3 tw-rounded-b-none tw-text-sm tw-flex-shrink-0'
              ]"
              label="company"
              v-model="form.sendToCompanyId"
              :value="(companies[1] || {}).id"
              :options="companies"
              :reduce="option => option.id"
              :clearable="false"
              searchable
              filterable
            />
          </div>
          <!-- <div class="tw-m-4 tw-mb-0">
            <v-select
              placeholder="Specific person"
              :class="[
                { '--loading-data': loadingForm },
                'my-select tw-bg-app-deep-blue-11 tw-border-b tw-border-black tw-text-black tw-rounded-t-3 tw-rounded-b-none tw-text-sm tw-flex-shrink-0',
              ]"
              v-model="form.sendToUserId"
              :value="(users[0] || {}).id"
              :options="users"
              :reduce="option => option.id"
              searchable
              filterable
              clearable
            />
            <div class="tw-py-4">
              <p class="tw-text-center opacity-54 tw-text-xs">
                or
              </p>
            </div>
          </div> -->

          <div
            :class="{ '--loading-data': loadingForm }"
          >
            <div class="tw-m-4">
              <BaseCheckBox
                @change="selectUserGroup(arguments)"
                :checked="form.sendTo === 'all'"
                id="every"
                :label="`Everyone ${selectedCompany.label ? `on ${selectedCompany.label}` : ''}`"
                value="all"
                class="tw-text-sm"
              />
            </div>
            <div class="tw-m-4">
              <BaseCheckBox
                @change="selectUserGroup(arguments, accountTypes.mentor)"
                :checked="form.sendTo === 'mentors'"
                id="mentors"
                :label="`Mentors ${selectedCompany.label ? `on ${selectedCompany.label}` : ''}`"
                value="mentors"
                class="tw-text-sm"
              />
            </div>
            <div class="tw-m-4">
              <BaseCheckBox
                @change="selectUserGroup(arguments, accountTypes.mentee)"
                :checked="form.sendTo === 'mentees'"
                id="mentees"
                :label="`Mentees ${selectedCompany.label ? `on ${selectedCompany.label}` : ''}`"
                value="mentees"
                class="tw-text-sm"
              />
            </div>
          </div>
        </div>

        <div class="tw-border-t tw-mx-4 tw-py-4 tw-text-right" key="step-1-footer">
          <BaseButton
            class="tw-py-3 tw-bg-black tw-text-white tw-border-0 tw-shadow-xl tw-uppercase"
            text="next"
            type="button"
            :disabled="!canGoToNext"
            @click="step = 2"
          />
        </div>
      </div>

    </template>

    <template v-else>
      <div class="tw-flex tw-flex-no-wrap tw-justify-between tw-border-b tw-mb-4 tw-pb-4 tw-pt-6 tw-items-center" key="step-2-header">
        <div class="tw-w-1/2">
          <BaseButton
            text="back"
            @click="step = 1"
          />
        </div>
        <p class="tw-text-right tw-text-app-yellow opacity-87 tw-text-sm">
          {{ sendToName }}
        </p>
      </div>

      <div class="tw-w-full tw-py-4 tw-mb-4" key="step-2-body">
        <div class="tw-flex tw-mt-4 tw-mb-2">
          <button
            :class="[
              'tw-w-1/2 tw-text-center tw-text-black tw-leading-6 tw-text-sm tw-block tw-rounded-t-10 tw-border tw-py-4',
              { 'tw-bg-white tw-border-gray-500 tw-border-b-white tw-font-bold': activeTab == 'file' },
              { 'tw-bg-gray-200 tw-border-gray-100 opacity-78': activeTab != 'file' },
            ]"
            @click="activeTab = 'file'"
          >
            Upload Media
          </button>
          <button
            :class="[
              'tw-w-1/2 tw-text-center tw-text-black tw-leading-6 tw-text-sm tw-block tw-rounded-t-10 tw-border tw-py-4',
              { 'tw-bg-white tw-border-gray-500 tw-border-b-white tw-font-bold': activeTab == 'link' },
              { 'tw-bg-gray-200 tw-border-gray-100 opacity-78': activeTab != 'link' },
            ]"
            @click="activeTab = 'link'"
          >
            Upload Link
          </button>
          <button></button>
        </div>

        <div v-show="activeTab == 'file'">
          <div class="tw-mb-1">
            <SelectGroup
              :class="{ '--loading-data': pillars.length == 0 }"
              label="Pillar"
              name="pillar"
              name-key="label"
              instruct="Please select the category of the resource you want to upload"
              placeholder="Resource category"
              input-classes="my-select--huge tw-font-sans tw-text-base tw-bg-gray-200 tw-pt-1"
              v-model="form.upload.pillarid"
              :options="pillars"
              :reduce="pillar => pillar.id"
              :error="form.errors.pillarid"
              :clearable="false"
              :multiple="false"
            />
          </div>
          <div class="tw-mb-1">
            <InputGroup
              label="Resource file"
              name="resource-file"
              ref="resource-file-input"
              instruct="File must be less than 8MBS"
              type="file"
              accepts="*"
              placeholder="Upload a file"
              @input="fileChange"
              :error="form.errors.file"
            />
          </div>

          <div class="tw-mb-1">
            <InputGroup
              label="Resource Name"
              name="resource-name"
              ref="resource-name"
              type="text"
              placeholder="Resource name"
              v-model.lazy="form.upload.name"
              :value="form.upload.name"
              :error="form.errors.name"
            />
          </div>
        </div>
        <div v-show="activeTab == 'link'">
          <div class="tw-mb-1">
            <SelectGroup
              :class="{ '--loading-data': pillars.length == 0 }"
              label="Pillar"
              name="pillar"
              name-key="label"
              instruct="Please select the category of the resource you want to upload"
              placeholder="Resource category"
              input-classes="my-select--huge tw-font-sans tw-text-base tw-bg-gray-200 tw-pt-1"
              v-model="form.upload.pillarid"
              :options="pillars"
              :reduce="pillar => pillar.id"
              :error="form.errors.pillarid"
              :clearable="false"
              :multiple="false"
            />
          </div>
          <div class="tw-mb-1">
            <InputGroup
              label="Link Name"
              name="link-name"
              ref="link-name"
              type="text"
              placeholder="Link name"
              v-model="form.upload.name"
              :value="form.upload.name"
              :error="form.errors.name"
            />
          </div>
          <div class="tw-mb-1">
            <InputGroup
              label="Link URL"
              name="link-url"
              ref="link-url"
              type="text"
              placeholder="URL"
              v-model="form.upload.link"
              :value="form.upload.link"
              :error="form.errors.link"
            />
          </div>
        </div>
      </div>

      <div class="tw-w-full tw-border-t tw-p-4 tw-py-8" key="step-2-footer">
        <BaseButton
          class="tw-py-3 tw-bg-black tw-text-white tw-block tw-border-0 tw-w-full tw-shadow-xl tw-uppercase"
          :text="uploadButtonText"
          :disabled="!canUploadResource || !uploadResource"
          type="submit"
        />
      </div>
    </template>
  </transition-group>

  </form>
</template>

<script>
import { mapState } from 'vuex';
import form from '@/modules/formHelper';
import BaseCheckBox from '@/components/BaseCheckBox.vue';
import SelectGroup from '@/components/SelectGroup.vue';
import InputGroup from '@/components/InputGroup.vue';

import { maxFileSize } from '@/modules/misc';
import { toBoolean } from '@/modules/dataHelper';

export default {
  name: 'ShareResources',
  components: {
    BaseCheckBox,
    InputGroup,
    SelectGroup,
  },
  props: {
    prompUpload: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeTab: 'file', // file, link
      loadingForm: true,
      step: 1, // this is a two step form
      uploadResource: true,
      uploadButtonText: 'upload',
      uploadModalOpen: (this.prompUpload || toBoolean(this.$route.query['prompt-upload'])), // * should be false if query is not found
      companies: [],
      users: [],
      pillars: [],
      form: {
        sendTo: undefined, // all, specific, mentors, mentees
        sendToUserId: undefined,
        sendToCompanyId: undefined,
        upload: {
          file: undefined,
          name: '',
          link: undefined,
          progress: 0,
          pillarid: undefined,
        },
        errors: {
          sendTo: undefined,
          file: undefined,
          name: undefined,
          link: undefined,
          pillarid: undefined,
        },
      },
    };
  },
  watch: {
    // eslint-disable-next-line func-names
    'form.upload.file': function (val) {
      if (!val) {
        this.form.upload.file = undefined;
        this.form.upload.name = undefined;
        this.form.errors.file = 'File required';
        this.form.errors.name = 'File name required';
        return false;
      }
      this.form.errors.file = undefined;
      this.form.errors.name = undefined;

      if (val.size > maxFileSize) {
        this.form.upload.file = undefined;
        this.form.upload.name = undefined;

        this.$toasted.info('Please upload a file 8MB maximum', {
          duration: 4000,
          position: 'top-right',
        });

        if (this.$refs['resource-file-input']) {
          console.log(this.$refs['resource-file-input']);
          const uploadFileInput = this.$refs['resource-file-input'].$el.querySelector('input');
          uploadFileInput.value = null;
        }
        this.clearFileNameInput();

        return false;
      }

      if (!form.isValidFile(val) && val) {
        this.form.errors.file = 'Invalid file type';
        this.form.upload.file = undefined;
        this.form.upload.name = undefined;
        this.clearFileNameInput();
        return false;
      }
      this.form.upload.name = val.name.split('.').slice(0, -1).join('');

      this.clearFileNameInput(this.form.upload.name || '');
      return true;
    },
    // eslint-disable-next-line func-names
    'form.upload.name': function (val) {
      if (!form.isValidTextInput(val)) {
        this.form.errors.name = 'File name required';
        return;
      }

      this.form.errors.name = undefined;
    },
    // eslint-disable-next-line func-names
    'form.sendToCompanyId': async function (val) {
      if (val) {
        // get company users
        this.form.sendTo = undefined;
        this.form.sendToUserId = undefined;

        const [users, pillars] = await Promise.all([
          this.getCompanyUsers([val]),
          this.getCompanyPillars([val]),
        ]);

        this.users = users.map((c) => ({
          id: c.id,
          label: `${c.name} ${c.middle || c.last_name} (${c.email})`,
        }));

        this.pillars = pillars.map((p) => ({
          id: p.id,
          label: p.goal_group,
        }));
      }
    },
    // eslint-disable-next-line func-names
    'form.sendTo': function (val) {
      if (val) {
        this.form.sendToUserId = undefined;
      }
    },
    // eslint-disable-next-line func-names
    'form.sendToUserId': function (val) {
      if (val) {
        this.form.sendTo = undefined;
      }
    },
  },
  computed: {
    ...mapState({
      accountTypes: (state) => state.User.accountTypes,
    }),
    sendToName() {
      if (this.form.sendToUserId) {
        // selected userId
        const user = this.users.find((u) => Number(u.id) === Number(this.form.sendToUserId));
        // console.log(user);
        if (user) {
          return user.label;
        }
      }

      return `${this.form.sendTo} ${this.selectedCompany.label ? `on ${this.selectedCompany.label}` : ''}`;
    },
    selectedCompany() {
      if (!this.form.sendToCompanyId) return {};

      return this.companies.find((c) => Number(c.id) === Number(this.form.sendToCompanyId));
    },
    canUploadResource() {
      if (this.activeTab === 'file') {
        return form.isValidFile(this.form.upload.file)
          && form.isValidTextInput(this.form.upload.name)
          && this.form.upload.file.size < maxFileSize
          && !!this.form.sendTo
          && this.loadingForm === false
          && form.isValidSelectInput([
            this.form.upload.pillarid,
          ]);
      }

      return form.isValidTextInput(this.form.upload.name)
        && form.isValidUrl(this.form.upload.link)
        && !!this.form.sendTo
        && this.loadingForm === false
        && form.isValidSelectInput([
          this.form.upload.pillarid,
        ]);
    },
    canGoToNext() {
      return !!this.form.sendTo
        && this.loadingForm === false
        && (this.form.sendTo === 'specific' ? !!this.form.sendToUserId : true);
    },
  },
  methods: {
    /**
    * set sendToUserId
    */
    selectUserGroup([group, checked]) {
      this.form.sendTo = checked ? group : '';
    },
    clearFileInput() {
      if (this.$refs['resource-file-input']) {
        const uploadFileInput = this.$refs['resource-file-input'].$el.querySelector('input');
        uploadFileInput.value = null;
      }

      this.form.upload.file = undefined;
    },
    clearFileNameInput(val = '') {
      if (this.$refs['resource-name']) {
        const uploadNameInput = this.$refs['resource-name'].$el.querySelector('input');
        uploadNameInput.value = val;
        uploadNameInput.dispatchEvent(new Event('input'));
      }

      this.form.upload.name = undefined;
    },
    clearLinkNameInput() {
      if (this.$refs['link-name']) {
        const uploadNameInput = this.$refs['link-name'].$el.querySelector('input');
        uploadNameInput.value = '';
        uploadNameInput.dispatchEvent(new Event('input'));
      }
      this.form.upload.name = undefined;
    },
    clearLinkInput() {
      if (this.$refs['link-url']) {
        const uploadNameInput = this.$refs['link-url'].$el.querySelector('input');
        uploadNameInput.value = '';
        uploadNameInput.dispatchEvent(new Event('input'));
      }
      this.form.upload.link = undefined;
    },
    fileChange(val) {
      [this.form.upload.file] = val;
    },
    async onSubmit() {
      await this.$nextTick();

      if (!this.canUploadResource || !this.uploadResource) return;

      this.uploadButtonText = 'uploading...';
      this.uploadResource = false;

      if (this.activeTab === 'link') {
        const storeAction = this.isRegionalAdmin ? 'shareLinkResourceInRegion' : 'shareLinkResource';
        // action to share link resource
        const response = await this.$store.dispatch(storeAction, {
          link: this.form.upload.link,
          name: this.form.upload.name,
          to: this.form.sendTo,
          companyId: this.form.sendToCompanyId,
          pillarid: this.form.upload.pillarid,
        });
        //

        this.uploadButtonText = 'upload';
        this.uploadResource = true;

        if (!response) {
          this.$toasted.global.appError();
          return;
        }

        this.$toasted.success(`Resource ${this.form.upload.name} shared`, {
          duration: 2000,
          position: 'top-right',
        });

        await this.$nextTick();

        this.$emit('resource-shared');

        await this.$nextTick();

        this.clearLinkNameInput();
        this.clearLinkInput();

        // all, specific, mentors, mentees
        switch (this.form.sendTo) {
          case 'all':
            this.$store.dispatch('logAction', this.$store.state.Logger.actionsIndexes.shareLinkResourcesToAll);
            break;
          case 'mentors':
            this.$store.dispatch('logAction', this.$store.state.Logger.actionsIndexes.shareLinkResourcesToMentors);
            break;
          case 'mentees':
            this.$store.dispatch('logAction', this.$store.state.Logger.actionsIndexes.shareLinkResourcesToMentees);
            break;
          default:
            console.warn('unknown ::sendTo to log');
            break;
        }

        return;
      }

      const storeAction = this.isRegionalAdmin ? 'shareResourceInRegion' : 'shareResource';
      const [res, progress] = await this.$store.dispatch(storeAction, {
        resource: this.form.upload.file,
        name: this.form.upload.name,
        to: this.form.sendTo,
        companyId: this.form.sendToCompanyId, // staff admin's company will be selected at the store action
        userId: this.form.sendToUserId,
        pillarid: this.form.upload.pillarid,
      });

      // wait for upload to complete to show success message
      // eslint-disable-next-line no-unused-vars, no-async-promise-executor
      const r = await new Promise(async (resolve, reject) => {
        const $this = this;

        res.then((_r) => {
          resolve(_r);
        });

        setTimeout(async function again() {
          const p = progress[0];
          // console.log(progress[0]);
          $this.uploadButtonText = `working... (${p}%)`;
          if (p < 100) {
            setTimeout(again, 100);
          } else {
            await resolve((await res));
          }
        }, 500);
      }).then(async (result) => {
        await this.$nextTick();

        this.uploadButtonText = 'upload';
        this.uploadResource = true;

        if (!result) {
          this.$toasted.global.appError();
          return;
        }

        this.$toasted.success(`Resource ${this.form.upload.name} shared`, {
          duration: 2000,
          position: 'top-right',
        });

        await this.$nextTick();

        this.$emit('resource-shared');

        await this.$nextTick();

        this.clearFileInput();
        this.clearFileNameInput();

        // all, specific, mentors, mentees
        switch (this.form.sendTo) {
          case 'all':
            this.$store.dispatch('logAction', this.$store.state.Logger.actionsIndexes.shareMediaResourcesToAll);
            break;
          case 'mentors':
            this.$store.dispatch('logAction', this.$store.state.Logger.actionsIndexes.shareMediaResourcesToMentors);
            break;
          case 'mentees':
            this.$store.dispatch('logAction', this.$store.state.Logger.actionsIndexes.shareMediaResourcesToMentees);
            break;
          default:
            console.warn('unknown ::sendTo to log');
            break;
        }
      });
    },
    async getCompanyUsers(companyIds = []) {
      this.loadingForm = true;

      const requests = [];

      companyIds.forEach((companyId) => {
        const mentorAccounts = this.$store.dispatch(
          'getAccounts',
          ['mentor', 'verified', companyId],
        );
        const menteeAccounts = this.$store.dispatch(
          'getAccounts',
          ['mentee', 'verified', companyId],
        );

        requests.push(mentorAccounts, menteeAccounts);
      });

      const results = await Promise.all(requests).then((res) => {
        // eslint-disable-next-line no-underscore-dangle
        const _res = [];
        res.forEach((__res) => {
          _res.push(...__res);
        });
        return _res;
      });

      this.loadingForm = false;

      return results;
    },
    async getCompanyPillars(companyIds = []) {
      this.loadingForm = true;

      const requests = [];

      companyIds.forEach((companyId) => {
        requests.push(this.$store.dispatch('getPillarGroups', [companyId, true]));
      });

      const results = await Promise.all(requests).then((res) => {
        // eslint-disable-next-line no-underscore-dangle
        const _res = [];
        res.forEach((__res) => {
          _res.push(...__res);
        });
        return _res;
      });

      this.loadingForm = false;

      return results;
    },
  },
  async created() {
    this.loadingForm = true;


    if (this.isSuperAdmin) {
      const companies = await this.$store.dispatch('getCompanies');
      const myCompany = await this.$store.dispatch('myCompany');

      if (Array.isArray(companies)) {
        this.companies = companies;

        this.form.sendToCompanyId = myCompany.id;
      } else {
        this.$toasted.global.appError({
          errorMessage: 'Could not fetch companies',
        });
      }
    } else {
      const myCompany = await this.$store.dispatch('myCompany');
      this.companies = [myCompany];
      this.form.sendToCompanyId = myCompany.id;
    }

    const users = await this.getCompanyUsers([this.form.sendToCompanyId]);
    const pillars = await this.getCompanyPillars([this.form.sendToCompanyId]);

    this.pillars = pillars.map((p) => ({
      id: p.id,
      label: p.goal_group,
    }));

    this.users = users.map((c) => ({
      id: c.id,
      label: `${c.name} ${c.middle || c.last_name} (${c.email})`,
    }));

    this.loadingForm = false;
  },
};
</script>

<style>

</style>
