import actionsIndexes from './actions-indexes.json';
import actions from './actions';

export default {
  namespaced: false,
  state: () => ({
    actionsIndexes,
  }),
  actions,
};
