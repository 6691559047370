<template>
  <transition name="fade" mode="out-in" appear>
    <div>
      <portal to="modal-body" key="mdl">
        <!--  -->
        <EditAnnouncement
          :position="journeyActionPosition"
          :action="journeyActionAction"
          v-if="activeModal === 'EditAnnouncement'"
          :company="company"
          :journey-id="journeyId"
          :journey-action-id="journeyActionId"
          @created-success="closeModal(); getAnnouncements(); getCompanyAnnouncementsStats();"
        />
      </portal>

      <!-- JOURNEY Hierarchy -->
      <div class="journey-kanban tw-flex tw-flex-col tw-relative">
        <div class="tw-p-2">
          <p class="tw-text-center tw-text-xs tw-uppercase">Announcements</p>
        </div>

        <div class="tw-z-0 tw-mx-auto tw-absolute tw-left-1/2 journey-kanban__hierarchy-line">
          <div class="tw-border-l tw-border-gray-700 tw-h-full"></div>
        </div>

        <div class="tw-z-10 journey-kanban__hierarchy-list tw-mx-auto tw-pt-5">
          <!--  -->
          <template v-for="({ announcements, title, action }, index) in formattedAnnouncements">
            <div class="tw-mb-4" :key="index">
              <div>
                <div :class="[
                  'tw-rounded-10 tw-border journey-kanban__hierarchy-list__item tw-p-3 tw-mb-4 tw-flex tw-flex-col tw-justify-center',
                  ]"
                >
                  <div class="tw-h-full tw-flex tw-items-center">
                    <div class="tw-flex-grow tw-text-center">
                      <p class="tw-font-sm tw-mb-1">{{title}}</p>

                      <div v-for="announcement in announcements" :key="announcement.id">
                        <AnnouncementPill
                          :class="[{ 'tw-mb-2' : announcements.length > 1 }]"
                          :announcement="announcement"
                          :stats="announcementsStats[action]"
                          @edit-announcement="editAnnouncement"
                        />
                      </div>
                    </div>
                  </div>

                  <BaseButton
                    text="Add Announcement"
                    class="tw-bg-black tw-border-0 tw-text-white tw-mt-3"
                    @click="openAnnouncementForm(action)"
                    v-if="!announcements.length"
                  />
                </div>

              </div>

            </div>
          </template>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import EditAnnouncement from '@/views/Companies/Company/_partials/EditAnnouncement.vue';
import AnnouncementPill from '@/components/AnnouncementPill.vue';

export default {
  name: 'CompanyAnnouncements',
  components: {
    EditAnnouncement,
    AnnouncementPill,
  },
  props: {
    company: {
      type: Object,
      required: true,
    },
    journeyId: {
      type: [String, Number],
      required: true,
    },
    activeModal: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      announcements: [],
      announcementsStats: {},
      journeyActionPosition: 13,
      journeyActionAction: undefined,
      journeyActionId: undefined,
    };
  },
  computed: {
    accountTypes() {
      return this.$store.state.User.accountTypes;
    },
    actionsList() {
      return this.$store.state.CompanySettings.announcementsActionsList;
    },
    formattedAnnouncements() {
      this.actionsList.forEach((actionList, index) => {
        // eslint-disable-next-line no-param-reassign
        this.actionsList[index].announcements = this.announcements.filter((action) => action?.action?.title === actionList.action);
      });

      return this.actionsList;
    },
  },
  methods: {
    async getAnnouncements() {
      const prom = [];
      const announcements = await this.$store.dispatch('getCompanyAnnouncements', this.company.id);

      if (Array.isArray(announcements)) {
        announcements.forEach((announcement, index) => {
          const req = this.$store.dispatch('getJourneyAction', [
            announcement.actid,
            this.journeyId,
            this.company.id,
            true,
          ]).then((action) => {
            announcements[index].action = action;
          });

          prom.push(req);
        });

        await Promise.all(prom);
        await this.$nextTick();

        this.announcements = announcements;
      }
    },
    async getCompanyAnnouncementsStats() {
      this.announcementsStats = await this.$store.dispatch('getCompanyAnnouncementsStats', this.company.id);
    },
    async openAnnouncementForm(action) {
      this.journeyActionId = undefined;

      this.$emit('open-modal');
      await this.$nextTick();

      this.journeyActionAction = action;
      await this.$nextTick();

      this.$store.commit('openModal', [true, 'modal']);
    },
    async editAnnouncement(actionId, action) {
      this.journeyActionId = actionId;

      this.$emit('open-modal');
      await this.$nextTick();

      this.journeyActionAction = action;
      await this.$nextTick();

      this.$store.commit('openModal', [true, 'modal']);
    },
    // canAddAnnouncementInThisPosition(action) {
    //   const { announcements } = (this.formattedAnnouncements.find((announcement) => announcement.action === action) || {});

    //   if (announcements.length) return false;

    //   return true;
    // },
  },
  async created() {
    await Promise.all([
      this.getAnnouncements(),
      this.getCompanyAnnouncementsStats(),
    ]);
  },
};
</script>
