import { generateFormData, apiPost, apiStore } from '../../../modules/apiHelper';
import resolve from '../../../modules/api/resolve';
import {
  invalideDate, isStaleData, objectIsValid, now,
} from '../../../modules/dataHelper';

export default {
  async companyMessages(context, [CompanyId, refresh = false]) {
    const companyid = CompanyId || context.rootState.User.companyid;
    if (!companyid) return false;

    const messages = await apiStore.getItem(`companymessages/${companyid}`) || [invalideDate, []];

    if (!refresh && !isStaleData(messages[0]) && objectIsValid(messages[1])) return messages[1];

    const request = resolve(apiPost('active_messages', generateFormData({ companyid }), 8))
      .then((res) => {
        if (!res) return false;
        const mssgs = res.company_messages || [];

        if (mssgs.length) {
          // store to store
          apiStore.setItem(`companymessages/${companyid}`, [now(), mssgs]);
        }

        return mssgs;
      });

    const result = await request;
    return result;
  },

  async updateCompanyMessage(context, [CompanyId, message = '', level = 0]) {
    const companyid = CompanyId || context.rootState.User.companyid;
    if (!message.length || !companyid) return false;

    const formData = generateFormData({
      userid: context.rootState.User.id,
      message,
      level,
      companyid,
    });

    const result = await resolve(apiPost('add_company_message', formData, 8));

    if (result && (result || {}).messageid) {
      // activate the message
      await apiStore.removeItem(`companymessages/${companyid}`);
      await context.dispatch('activateLevelMessage', [companyid, result.messageid, level]);
    }

    return !!result;
  },

  async activateLevelMessage(context, [CompanyId, messageid, level]) {
    const companyid = CompanyId || context.rootState.User.companyid;
    if (!companyid || !messageid || Number.isNaN(level)) return false;

    // get the active company messages of the same level type
    const companyMessages = await context.dispatch('companyMessages', [companyid, true]);
    if (!companyMessages) return false;

    const activeCompanyLevelMessagesIds = (companyMessages || [])
      .filter((message) => (
        (Number(message.id) !== Number(messageid))
        && (Number(message.level) === Number(level))
        && !!(Number(message.status))
      ));

    // console.log(activeCompanyLevelMessagesIds);
    // deactivate the active messages
    const res = [];

    activeCompanyLevelMessagesIds.forEach(({ id }) => {
      const r = resolve(apiPost('deactivate_company_message', generateFormData({ messageid: id }), 8));
      res.push(r);
    });

    await Promise.all(res);

    await apiPost('activate_company_message', generateFormData({ messageid, companyid, level }), 8);

    return true;
  },
};
