<template>
  <AppView
    padd-bottom
  >
    <template v-slot:top-app-view>
      <transition name="fade" mode="out-in" appear>
        <TopNav
          title="Resources"
          title-class="font-title pl-body tw-text-xl tw-tracking-wider tw-leading-6 opacity-54"
        >
          <template slot="right-actions">
            <BaseButton
              text="View User's resources"
              class="tw-py-3 tw-px-6 transition-fast tw-mr-4"
              :to="{ name: 'usersUploadedResources' }"
            />
            <BaseButton
              text="Share Resource"
              class="tw-bg-app-deep-blue tw-border-none tw-py-3 tw-px-6 tw-text-white tw-shadow-app hover:tw-shadow-app-lg transition-fast mr-body"
              @click="openUploadModal()"
            />
          </template>
        </TopNav>
      </transition>
    </template>

    <!-- Upload form modal -->
    <portal to="modal-body">
      <div class="tw-w-full tw-p-4 tw-pt-6 tw-overflow-auto">
        <ShareResources
          class="tw-mx-auto"
          @resource-shared="resourceShared()"
        />
      </div>
    </portal>

    <div class="app-view-grid px-body tw-py-4">
      <div class="app-view-grid__col-74">
        <BaseCard v-if="isSuperAdmin" class="tw-flex tw-flex-no-wrap tw-py-2 tw-px-4 tw-items-center tw-border-t-2 tw-border-app-white-87 tw-transition-slow tw-mb-4">
          <label class="tw-text-sm opacity-78">Select Company</label>
          <div class="tw-flex-grow tw-ml-2">
            <v-select
              placeholder="Select Company"
              :class="[
                { '--loading-data': loadingCompanies },
                'my-select tw-bg-app-deep-blue-11 tw-border-b tw-border-black tw-text-black tw-rounded-t-3 tw-rounded-b-none tw-text-sm tw-flex-shrink-0'
              ]"
              label="company"
              v-model="selectedCompany"
              :options="companies"
              :reduce="option => option.id"
              :clearable="false"
              searchable
              filterable
            />
          </div>
        </BaseCard>
        <div>
          <div class="tw-mb-12 tw-border-b">
            <LinksResources
              :prop-to-trigger-get-resources="dataToRefreshResourcesInChildComponents"
            />
          </div>
          <div class="">
            <DocumentsResources
              :prop-to-trigger-get-resources="dataToRefreshResourcesInChildComponents"
            />
          </div>
        </div>
        <div>
          <!-- select company -->
        </div>
      </div>
      <div></div>
    </div>
  </AppView>
</template>

<script>
import ShareResources from '@/views/Resources/_partials/ShareResources.vue';
// import UserPhoto from '@/components/UserPhoto.vue';
import DocumentsResources from '@/views/Resources/_partials/DocumentsResources.vue';
import LinksResources from '@/views/Resources/_partials/LinksResources.vue';


export default {
  name: 'ResourcesView',
  components: {
    ShareResources,
    DocumentsResources,
    LinksResources,
    // UserPhoto,
  },
  data() {
    return {
      dataToRefreshResourcesInChildComponents: true, // will just toggle boolean
      companies: [],
      selectedCompany: undefined,
      loadingCompanies: true,
    };
  },
  watch: {
    selectedCompany(val) {
      if (val) {
        this.$store.commit('setResourceContext', val);

        this.dataToRefreshResourcesInChildComponents = !this.dataToRefreshResourcesInChildComponents;
      }
    },
  },
  methods: {
    async resourceShared() {
      // close modal then get resources
      this.closeModal();

      this.dataToRefreshResourcesInChildComponents = !this.dataToRefreshResourcesInChildComponents;
    },

    openUploadModal() {
      this.$store.commit('openModal', [true]);
    },
  },
  async created() {
    if (this.isSuperAdmin) {
      this.loadingCompanies = true;
      const companies = await this.$store.dispatch('getCompanies');

      if (Array.isArray(companies)) {
        this.companies = companies;
        // this.$store.commit('setResourceContext', companies[0].id);
      }

      await this.$nextTick();

      this.loadingCompanies = false;
    }
  },
};
</script>

<style>

</style>
