<template>
  <AppView
    padd-bottom
  >

    <template v-slot:top-app-view>
      <transition name="fade" mode="out-in" appear>
        <CompanyHeader :company="company" :loading="loadingCompany" />
      </transition>
    </template>

    <transition name="fade" mode="out-in" appear>

      <div v-if="errorCompany || error" class="tw-mt-12" key="errmsg">
        <p class="tw-py-12 tw-text-base tw-text-center" v-html="errorCompany"></p>
      </div>

      <div v-else-if="loadingCompany || loading" class="tw-mt-12" key="lodng">
        <p class="tw-py-12 tw-text-base tw-text-center">Loading...</p>
      </div>

      <div v-else key="bdy">
        <transition name="fade" mode="out-in" appear>
          <div class="app-view-grid px-body">
            <div class="app-view-grid__col-74">
              <portal to="modal-body" key="mdl">
                <transition
                  name="fade"
                  mode="out-in"
                  appear
                  :key="activeModalComponent"
                >
                  <component
                    :is="activeModalComponent"
                    v-bind="activeModalComponentOptions.props"
                    :class="['tw-p-6']"
                    @created-success="closeModal(); getSurveys();"
                  />
                </transition>
              </portal>

              <template>
                <div class="tw-flex tw-items-center tw-pt-10 tw-pb-3">
                  <img
                    data-src="/img/company-surveys.png"
                    src="/img/company-surveys.png"
                    style="width: 80px; height: 80px;"
                    class="tw-object-cover tw-block tw-mr-8"
                    async
                  >
                  <h3 class="font-title tw-text-xl tw-tracking-wider">
                    Surveys
                  </h3>

                  <div class="tw-flex-grow"></div>

                  <BaseButton
                    type="button"
                    text="Create a Survey"
                    @click="promptEdit('survey')"
                    v-if="isSuperAdmin"
                  />
                </div>
                <div class="tw-border-t tw-border-app-black-11 tw-mb-6"></div>
                <div>
                  <div class="tw-flex tw-items-start tw-flex-wrap">
                    <!-- list of surveys -->
                    <template v-for="(survey, index) in surveys">
                      <div
                        :key="index"
                        class="tw-p-4 tw-bg-gray-200 tw-mx-4 tw-mb-4 tw-w-1/2 tw-rounded-10"
                        :style="{ width: 'calc(50% - 32px)'}"
                      >
                        <div class="tw-flex">
                          <!-- <div class="tw-w-2/3 tw-pr-3"> -->
                          <div class=" tw-pr-3">
                            <h3 class="tw-text-xl tw-mb-2 tw-font-bold">{{survey.pillar}}</h3>
                            <h5 class="tw-text-sm tw-mb-0">
                              Appears when -> <span class="tw-underline">{{getSurveyTypeName(survey.type)}}</span>
                            </h5>

                            <div class="tw-flex tw-items-center">
                              <BaseButton v-if="isSuperAdmin" text="Edit" @click="promptEdit('survey', {surveyId: survey.id})" class="tw-mb-4 tw-py-0 tw-px-3 tw-mr-3" />
                              <BaseButton :to="{name: 'companySurveyResponses', params: {id, surveyId: survey.id}}" text="View Responses" class="tw-mb-4 tw-py-0 tw-px-3 tw-mr-3 tw-bg-app-primary-blue tw-text-white tw-border-0" />

                            </div>
                          </div>
                          <!-- <div class="tw-w-1/3 tw-pl-3 tw-text-right"> -->
                            <!-- switch input for activating/deactivating survey -->
                            <!-- [update] no need for the input -->
                            <!-- <SwitchInput :checked="Boolean(Number(survey.status))" @change="toggleActivateSurvey(survey.id, ...arguments)" /> -->
                          <!-- </div> -->
                        </div>

                        <template v-if="isSuperAdmin">
                          <p class="tw-text-sm opacity-54">Survey Questions</p>
                          <div class="tw-border-t tw-border-app-black-11 tw-mt-1 tw-mb-3"></div>

                          <template v-if="(survey.questions || []).length">

                            <div class="tw-flex tw-mb-1 opacity-54">
                              <p class="tw-w-1/2">Questions</p>
                              <p class="tw-w-1/2">Ratings</p>
                            </div>

                            <!-- list of survey questions -->
                            <template v-for="(question, questionIndex) in (survey.questions || [])">
                              <div :key="questionIndex" class="tw-bg-gray-300 tw-mb-3 tw-rounded-3 tw-p-2">
                                <div class="tw-flex">
                                  <div class="tw-w-1/2 tw-pr-4 tw-border-r tw-border-black tw-mr-4">
                                    <p class="tw-text-base tw-mb-1">{{question.criteria}}</p>
                                    <!-- switch input for activating/deactivating questions -->
                                    <div class="tw-flex tw-items-center tw-mb-1">
                                      <SwitchInput :checked="Boolean(Number(question.status))" @change="toggleActivateSurveyQuestion(question.id, ...arguments)" size="sm" />
                                      <span v-if="Boolean(Number(question.status))" class="tw-pl-2 tw-text-sm opacity-54">Activated</span>
                                      <span v-if="!Boolean(Number(question.status))" class="tw-pl-2 tw-text-sm opacity-54">Deactivated</span>
                                    </div>
                                    <BaseButton text="Edit" @click="promptEdit('surveyQuestion', {surveyId: survey.id, questionId: question.id})" class="tw-mb-4 tw-py-0 tw-px-3" />
                                  </div>
                                  <div>
                                      <!-- list of ratings -->
                                      <p v-for="(rating, ratingIndex) in (question.ratings || [])" :key="ratingIndex" class="tw-text-sm tw-mb-2">
                                        - {{rating.rates}}
                                        <BaseButton text="Edit" @click="promptEdit('questionRatings', {questionId: question.id, ratingId: rating.id})" class="tw-py-0 tw-px-3 tw-mr-1" />
                                      </p>

                                      <p class="tw-text-sm tw-mb-1 tw-italic" v-if="(question.ratings || []).length == 0">
                                        A question without ratings is a text response question type
                                      </p>

                                      <BaseButton
                                        text="Create a rating"
                                        @click="promptEdit('questionRatings', {questionId: question.id})"
                                        :class="[
                                          'tw-mt-4 transition-fast',
                                          {'tw-opacity-25 hover:tw-opacity-100': (question.ratings || []).length == 0}
                                          ]"
                                      />
                                  </div>
                                </div>
                              </div>
                            </template>

                            <BaseButton text="Create a question" @click="promptEdit('surveyQuestion', {surveyId: survey.id})" />
                          </template>
                          <template v-else>
                            <BaseButton text="Create a question" @click="promptEdit('surveyQuestion', {surveyId: survey.id})" />
                          </template>
                        </template>

                      </div>
                    </template>
                  </div>
                </div>
              </template>

            </div>
          </div>
        </transition>
      </div>

    </transition>

  </AppView>
</template>

<script>
import CompanyHeader from '@/views/Companies/Company/_partials/CompanyHeader.vue';
import EditSurvey from '@/views/Companies/Company/_partials/EditSurvey.vue';
import EditSurveyQuestion from '@/views/Companies/Company/_partials/EditSurveyQuestion.vue';
import EditQuestionRatings from '@/views/Companies/Company/_partials/EditQuestionRatings.vue';
import SwitchInput from '@/components/SwitchInput.vue';

import companyMixin from '@/mixins/company';
import _flatten from 'lodash/flatten';
import _orderBy from 'lodash/orderBy';
import _reverse from 'lodash/reverse';

export default {
  name: 'CompanySurveys',
  components: {
    CompanyHeader,
    EditSurvey,
    EditSurveyQuestion,
    EditQuestionRatings,
    SwitchInput,
  },
  mixins: [companyMixin],
  props: ['id'],
  data() {
    return {
      error: undefined,
      loading: true,
      surveys: [],
      // surveysQuestions: [],
      // surveysQuestionsRatings: [],
      modalComponents: {
        survey: {
          component: 'EditSurvey',
        },
        surveyQuestion: {
          component: 'EditSurveyQuestion',
        },
        questionRatings: {
          component: 'EditQuestionRatings',
        },
      },
      // active modal components (forms)
      activeModalComponent: false,
      activeModalComponentOptions: {
        props: {},
      },
    };
  },
  computed: {
    surveyTypes() {
      return this.$store.state.Surveys.surveyTypes;
    },
  },
  methods: {
    getSurveyTypeName(id) {
      return (this.surveyTypes.find((type) => Number(type.id) === Number(id)) || {}).type || 'any';
    },
    // gets the survey questions and ratings of all the questions
    async getSurveys() {
      this.loading = true;
      this.error = undefined;

      const surveys = await this.$store.dispatch('getCompanySurveys', [this.id, true]);

      if (!surveys || !Array.isArray(surveys)) {
        // error in getting surveys
        this.error = 'Could not get surveys. Please try again later';

        return false;
      }

      const surveyQuestionsPromises = [];
      const surveyQuestionsRatingsPromises = [];

      // get surveys questions
      surveys.forEach((survey) => {
        surveyQuestionsPromises.push(new Promise((resolve, reject) => {
          this.$store.dispatch('getSurveyQuestions', [this.id, survey.id, true])
            .catch((error) => {
              reject(error);
              // maybe toast
            })
            .then((questions) => {
              resolve(questions);
            });
        }));
      });

      const surveysQuestions = _flatten(await Promise.all(surveyQuestionsPromises));

      // get questions' ratings
      surveysQuestions.forEach((surveyQuestion) => {
        surveyQuestionsRatingsPromises.push(new Promise((resolve, reject) => {
          this.$store.dispatch('getSurveyQuestionRatings', [this.id, surveyQuestion.id, true])
            .catch((error) => {
              reject(error);
              // maybe toast
            })
            .then((ratings) => {
              resolve(ratings);
            });
        }));
      });

      const surveysQuestionsRatings = _flatten(await Promise.all(surveyQuestionsRatingsPromises));

      this.surveys = _reverse(_orderBy(surveys.map((survey) => {
        const surveyQuestions = surveysQuestions.filter((question) => Number(question.pillarid) === Number(survey.id));

        const surveyQuestionsWithRatings = surveyQuestions.map((question) => {
          const ratings = surveysQuestionsRatings.filter((rating) => Number(rating.suvid) === Number(question.id));

          return {
            ...question,
            ratings,
          };
        });

        // console.log(surveyQuestionsWithRatings);

        return {
          ...survey,
          questions: _orderBy(surveyQuestionsWithRatings, (q) => q.dateOfPost),
        };
      }), (i) => i.dateOfPost));

      // console.log(this.surveys);

      await this.$nextTick();

      this.loading = false;

      return true;
    },
    // opens the right modal component
    async promptEdit(componentName, props = {}) {
      const allProps = {
        company: this.company,
        ...props,
      };

      this.activeModalComponentOptions.props = { ...allProps };
      this.activeModalComponent = this.modalComponents[componentName].component;

      await this.$nextTick();

      // openModal
      this.$store.commit('openModal', [true, 'modal']);
    },
    async toggleActivateSurvey(surveyId, activate) {
      let result;
      if (activate) {
        result = await this.$store.dispatch('activateSurvey', surveyId);
      } else {
        result = await this.$store.dispatch('deactivateSurvey', surveyId);
      }

      if (result) {
        this.$toasted.success(
          activate ? 'Activated successfully!' : 'Deactivated successfully',
          { duration: 1500 },
        );

        this.getSurveys();
      } else {
        this.$toasted.global.appError();
      }
    },
    async toggleActivateSurveyQuestion(questionId, activate) {
      let result;
      if (activate) {
        result = await this.$store.dispatch('activateSurveyQuestion', questionId);
      } else {
        result = await this.$store.dispatch('deactivateSurveyQuestion', questionId);
      }

      if (result) {
        this.$toasted.success(
          activate ? 'Activated successfully!' : 'Deactivated successfully',
          { duration: 1500 },
        );

        this.getSurveys();

        if (activate) {
          this.$store.dispatch('logAction', this.$store.state.Logger.actionsIndexes.activateSurveyQuestion);
        } else {
          this.$store.dispatch('logAction', this.$store.state.Logger.actionsIndexes.deactivateSurveyQuestion);
        }
      } else {
        this.$toasted.global.appError();
      }
    },
  },
  async created() {
    // get company
    await this.getCompany();
    await this.$nextTick();

    // assert user company permissions
    if (!this.hasPermission) {
      this.$router.push({
        name: 'app',
      });

      return false;
    }

    await this.getSurveys();

    await this.$nextTick();
    await this.$nextTick();

    return true;
  },
};
</script>

<style>

</style>
