<template>
  <div>
    <p class="tw-uppercase tw-font-bold opacity-78 tw-mb-4">Badges</p>

    <Accordion class="tw-mb-8">
      <template v-slot:header>
        <p class="tw-text-sm">Add A Badge</p>
      </template>
      <template v-slot:body>
        <div class="tw-bg-app-black-11-- tw-bg-gray-300 tw-p-5 tw-rounded-10 tw-border">
          <form
            method="post"
            action="/journey-action/create"
            @submit.prevent="onSubmit"
          >
            <div class="tw-w-full tw-mb-4 tw-flex-shrink-0 tw-px-1">
              <InputGroup
                label="Name"
                name="name"
                type="text"
                placeholder="name"
                input-type=""
                v-model="form.title"
                :error="errors.title"
              />
            </div>
            <div class="tw-w-full tw-mb-4 tw-flex-shrink-0 tw-px-1">
              <InputGroup
                label="Badge"
                name="badge"
                type="file"
                placeholder="Badge"
                input-type=""
                v-model="form.badge"
                :error="errors.badge"
              />
            </div>

            <div class="tw-text-right">
              <BaseButton
                type="submit"
                class="tw-ml-auto tw-border-0 tw-bg-black tw-text-white tw-text-right"
                :text="btnText"
                :disabled="!canSubmit || btnDisabled"
              />
            </div>
          </form>
        </div>
      </template>
    </Accordion>

    <div class="tw-relative tw-flex tw-flex-wrap">
      <!-- list of badges -->
      <div class="tw-pb-2 tw-pr-2 tw-w-1/3" v-for="(badge, index) in badges" :key="index">
        <JourneyBadge :badge="badge" @delete-badge="deleteBadge(badge)" />
      </div>
    </div>

  </div>
</template>

<script>
import InputGroup from '@/components/InputGroup.vue';
import Accordion from '@/components/Accordion.vue';
import JourneyBadge from '@/components/JourneyBadge.vue';

import form from '@/modules/formHelper';

export default {
  name: 'CompanyJourneyBadges',
  components: {
    InputGroup,
    Accordion,
    JourneyBadge,
  },
  props: {
    company: {
      type: Object,
      required: true,
    },
    journey: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      error: undefined,
      btnText: 'Create Badge',
      btnDisabled: false,
      badges: [],
      form: {
        title: undefined,
        badge: undefined,
      },
      errors: {},
    };
  },
  computed: {
    canSubmit() {
      return form.isValidTextInput(this.form.title)
        && !!this.form.badge;
    },
  },
  methods: {
    async getCompanyBadges() {
      this.loading = true;
      this.error = undefined;
      await this.$nextTick();

      const badges = await this.$store.dispatch('getBadges', [this.company.id]);

      if (Array.isArray(badges)) {
        this.badges = badges;
      } else {
        this.error = 'Error fetching journey badges';
      }

      await this.$nextTick();
      this.loading = false;
    },
    async onSubmit() {
      if (!this.canSubmit) return;

      this.btnDisabled = true;
      this.btnText = 'Working...';

      const data = {
        badge_name: this.form.title,
        badge: this.form.badge[0],
        companyid: this.company.id,
      };

      const result = await this.$store.dispatch('createBadge', data);

      if (result) {
        this.$toasted.success('Saved!', { duration: 1000 });
        this.getCompanyBadges();

        this.form = {
          title: undefined,
          badge: undefined,
        };
      } else {
        this.$toasted.global.appError({
          errorMessage: 'Saving badge failed. Please try again later',
        });
      }

      this.btnDisabled = false;
      this.btnText = 'Save';
    },
    async deleteBadge(badge) {
      const result = await this.$store.dispatch('deleteBadge', badge.id);

      if (result) {
        this.$toasted.success('Badge Deleted!', { duration: 1000 });
        this.getCompanyBadges();
      }
    },
  },

  async created() {
    this.errors = { ...this.form };
    await this.getCompanyBadges();
  },
};
</script>

<style>

</style>
