export default {
  state: {
    userViewsStates: {},
    userViewsData: {},
    viewWidth: window.innerWidth,
  },
  getters: {
    findViewState: (state) => (stateId) => state.userViewsStates[stateId],
    findViewData: (state) => (dataId) => state.userViewsData[dataId],
    isMobileView(state) {
      return state.viewWidth < 1024;
    },
    isDesktopView(state) {
      return state.viewWidth > 1024;
    },
    viewWidthIsLargerThan: (state) => (width) => state.viewWidth > width,
  },
  mutations: {
    updateUserViewStates(state, data) {
      state.userViewsStates = {
        ...state.userViewsStates,
        ...data,
      };
    },
    updateUserViewData(state, data) {
      state.userViewsData = {
        ...state.userViewsData,
        ...data,
      };
    },
    deleteViewData(state, refId) {
      const data = state.userViewsData[refId];

      if (data) {
        const viewsData = {
          ...state.userViewsData,
        };
        delete viewsData[refId];

        state.userViewsData = {
          ...viewsData,
        };
      }
    },
    screenSizeChange(state, newSize) {
      state.viewWidth = newSize;
    },
  },
  actions: {},
};
