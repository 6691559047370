<template>
  <AppView
    padd-bottom
  >
    <template v-slot:top-app-view>
      <transition name="fade" mode="out-in" appear>
        <CompanyHeader :company="company" :loading="loadingCompany" />
      </transition>
    </template>

    <transition name="fade" mode="out-in" appear>

      <div v-if="errorCompany" class="tw-mt-12" key="errmsg">
        <p class="tw-py-12 tw-text-base tw-text-center" v-html="errorCompany"></p>
      </div>

      <div v-else-if="loadingCompany" class="tw-mt-12" key="lodng">
        <p class="tw-py-12 tw-text-base tw-text-center">Loading...</p>
      </div>

      <div v-else key="bdy">
        <transition name="fade" mode="out-in" appear>

          <div class="app-view-grid px-body">
            <div class="app-view-grid__col-74">

              <portal to="modal-header">
                <div class="px-body tw-mx-auto tw-max-w-4xl tw-relative tw-p-4" ref="top-page-position">
                  <BaseButton
                    class="tw-uppercase tw-rounded-md tw-z-20 hover:tw-bg-gray-300 tw-flex tw-items-center"
                    text="Close"
                    icon
                    @click="closeForm"
                  >
                    <span
                      class="my-btn__icon tw-h-auto"
                      style="height: auto;"
                      slot="icon"
                      v-html="leftNav"
                    ></span>
                  </BaseButton>
                </div>
              </portal>
              <portal to="modal-body" key="mdl">
                <div class="px-body tw-mx-auto tw-max-w-4xl tw-flex tw-flex-wrap md:tw-flex-nowrap" v-if="form.meeting?.id || newForm">
                  <div class="tw-w-full md:tw-w-3/5">
                    <h4 class="font-title tw-mb-4">Meeting Details</h4>
                    <EditVideoMeeting
                      :company-id="company.id"
                      :meeting="form.meeting"
                      @on-success="onSuccess"
                    />
                    <div v-if="form.meeting?.id" class="tw-mb-4 tw-border tw-border-red-500 tw-bg-red-50 tw-p-4 tw-rounded-10 tw-text-right md:tw-max-w-md">
                      <BaseButton
                        type="button"
                        :disabled="deleteButtonDisabled"
                        class="tw-bg-red-500 tw-border-0 tw-text-white tw-py-2 tw-px-3"
                        :text="deleteButtonText"
                        @click="deleteMeeting"
                      />
                    </div>
                  </div>
                  <div class="tw-w-full md:tw-w-2/5 md:tw-pr-6" ref="meeting-participants">
                    <h4 class="font-title tw-mb-4">Participants</h4>
                    <AddMeetingParticipants :meeting="form.meeting" @on-success="onSuccess" />
                  </div>
                </div>
              </portal>

              <template>
                <div class="tw-flex tw-items-center tw-pt-10 tw-pb-4">
                  <img
                    data-src="/img/mentorship-groups.png"
                    src="/img/video-meetings.png"
                    style="width: 80px; height: 80px;"
                    class="tw-object-cover tw-block tw-mr-8"
                    async
                  >
                  <h3 class="font-title tw-text-xl tw-tracking-wider">
                    Video Meetings
                  </h3>

                  <BaseButton
                    class="tw-ml-auto"
                    text="Create a video meeting"
                    title="Create a video meeting"
                    @click="createVideoMeeting"
                  />
                </div>
                <div class="tw-border-t tw-border-app-black-11 tw-mb-8"></div>
                <div></div>
              </template>

              <!-- VIDEO MEETINGS -->
              <template>
                <ViewMore
                  :list="activeVideoMeetings"
                  :limit="8"
                  class="tw-flex tw-flex-wrap tw-h-auto"
                  v-slot="{ item: { participants, ...meeting} }"
                >
                  <div class="tw-pr-4 tw-pb-4">
                    <VideoMeetingCard
                      :meeting="meeting"
                      :participants="participants"
                      @edit-meeting="editVideoMeeting"
                      class="tw-h-full"
                    />
                  </div>
                </ViewMore>
              </template>

              <!-- ENDED VIDEO MEETINGS -->
              <div class="tw-flex tw-items-center tw-justify-between tw-mt-8 tw-mb-3">
                <h5 class="tw-text-sm tw-leading-tight tw-capitalize">Ended Meetings</h5>
              </div>

              <template>
                <ViewMore
                  :list="endedVideoMeetings"
                  :limit="8"
                  class="tw-flex tw-flex-wrap tw-h-auto"
                  v-slot="{ item: { participants, ...meeting} }"
                >
                  <div class="tw-pr-4 tw-pb-4">
                    <VideoMeetingCard
                      :meeting="meeting"
                      :participants="participants"
                      :can-edit="false"
                      class="tw-h-full"
                    />
                  </div>
                </ViewMore>
              </template>

            </div>
          </div>
        </transition>
      </div>
    </transition>
  </AppView>
</template>

<script>
import CompanyHeader from '@/views/Companies/Company/_partials/CompanyHeader.vue';
import EditVideoMeeting from '@/views/Companies/Company/_partials/EditVideoMeeting.vue';
import AddMeetingParticipants from '@/views/Companies/Company/_partials/AddMeetingParticipants.vue';
import VideoMeetingCard from '@/components/VideoMeetingCard.vue';

import companyMixin from '@/mixins/company';

const defaultDeleteButtonText = 'Cancel Meeting';

export default {
  name: 'VideoMeetings',
  mixins: [companyMixin],
  components: {
    CompanyHeader,
    EditVideoMeeting,
    AddMeetingParticipants,
    VideoMeetingCard,
  },
  props: ['id'],
  data() {
    return {
      error: undefined,
      loading: true,
      loadingParticipants: true,
      maxVisibleParticipants: 10,
      videoMeetings: [],
      newForm: false,
      form: {
        meeting: {},
      },
      deleteButtonDisabled: false,
      deleteButtonText: defaultDeleteButtonText,
    };
  },
  computed: {
    activeVideoMeetings() {
      return this.videoMeetings.filter((meeting) => Number(meeting.status) === 0);
    },
    endedVideoMeetings() {
      return this.videoMeetings.filter((meeting) => Number(meeting.status) === 1);
    },
  },
  methods: {
    async getCompanyVideoMeetings() {
      this.loading = true;

      await this.$nextTick();

      this.videoMeetings = await this.$store.dispatch('getCompanyMeetings', this.company.id);

      this.loading = false;
    },
    async createVideoMeeting() {
      this.newForm = true;

      await this.$nextTick();
      // open modal
      this.$store.commit('openModal', [true, 'full-screen', false]);
    },
    async editVideoMeeting(meeting) {
      this.form.meeting = meeting;

      await this.$nextTick();
      // open modal
      this.$store.commit('openModal', [true, 'full-screen', false]);
    },
    async closeForm() {
      // close modal
      this.closeModal();

      await this.$nextTick();

      this.form.meeting = {};
      this.newForm = false;
    },
    async onSuccess(meetingId) {
      this.getCompanyVideoMeetings();

      this.form.meeting = await this.$store.dispatch('getMeetingByLinkId', [meetingId, true]);

      // if meeting is not found... then there was an error
      if (!this.form.meeting?.id) {
        this.$toasted.global.appError({
          errorMessage: 'Meeting does not exist',
        });

        this.closeForm();
      }

      // scroll to top if desktop
      if (this.$refs['top-page-position']) {
        this.$refs['top-page-position'].scrollIntoView({
          behavior: 'smooth',
        });
      }
    },
    async deleteMeeting() {
      if (!this.form.meeting?.id) return;

      this.deleteButtonDisabled = true;
      this.deleteButtonText = 'Cancelling...';

      const result = await this.$store.dispatch('cancelMeeting', {
        meetingid: this.form.meeting.id,
        linkId: this.form.meeting.linkid,
        reason: '[not needed any more]',
      });

      if (result) {
        this.$toasted.success('Meeting Cancelled!', { duration: 4000 });

        await this.getCompanyVideoMeetings();

        this.closeModal();
      }

      this.deleteButtonDisabled = false;
      this.deleteButtonText = defaultDeleteButtonText;
    },
  },
  async created() {
    // get company
    await Promise.all([
      this.getCompany(),
    ]);

    await this.$nextTick();

    // assert user company permissions
    if (!this.hasPermission) {
      this.$router.push({
        name: 'app',
      });

      return;
    }

    await this.getCompanyVideoMeetings();

    await this.$nextTick();
    await this.$nextTick();

    this.loading = false;
  },
};
</script>

<style>

</style>
