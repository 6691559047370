<template>
  <label :class="`switch-input-wrapper ${size == 'sm' ? 'switch-input-wrapper--small' : ''}`">
    <input type="checkbox" @change="toggleSwitch" ref="switch-input">
    <span class="switch-input-wrapper__slider round"></span>
  </label>
</template>

<script>
export default {
  name: 'SwitchInput',
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'normal',
    },
  },
  data() {
    return {
      checkedValue: this.checked,
    };
  },
  methods: {
    toggleSwitch($ev) {
      const isChecked = $ev.target.checked;

      if (Boolean(this.checkedValue) === Boolean(isChecked)) return;

      this.checkedValue = isChecked;
      this.$emit('change', isChecked);
      this.$emit('input', isChecked);
    },
  },
  mounted() {
    this.checkedValue = this.checked;

    if (this.$refs['switch-input'] && this.checkedValue) {
      this.$refs['switch-input'].setAttribute('checked', 'checked');
    }
  },
};
</script>

<style>

</style>
