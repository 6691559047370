const rightNavigationArrow = `
  <svg version="1.1" class="tw-h-4 tw-w-4" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
    <path d="M16,67.8c0-1.2,0.4-2,1.2-2.9l29-29l-29-29c-1.6-1.6-1.6-4.1,0-5.7c1.6-1.6,4.1-1.6,5.7,0l31.9,31.9c1.6,1.6,1.6,4.1,0,5.7
      L22.9,70.8c-1.6,1.6-4.1,1.6-5.7,0C16.4,69.9,16,69,16,67.8z"/>
  </svg>
`;

const leftNavigationArrow = `
  <svg version="1.1" class="tw-h-4 tw-w-4" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
    <path d="M56,4.2c0,1.2-0.4,2-1.2,2.9l-29,29l29,29c1.6,1.6,1.6,4.1,0,5.7s-4.1,1.6-5.7,0L17.2,39c-1.6-1.6-1.6-4.1,0-5.7l31.9-32
      c1.6-1.6,4.1-1.6,5.7,0C55.6,2.1,56,3,56,4.2z"/>
  </svg>
`;


export default {
  name: 'navUiMixin',
  data() {
    return {
      leftNav: leftNavigationArrow,
      rightNav: rightNavigationArrow,
    };
  },
  methods: {
    async minimizeMenu() {
      this.$emit('minimize-menu-nav');
      this.$store.commit('minimizeMenu', true);
    },
    async maximizeMenu() {
      this.$emit('maximize-menu-nav');
      this.$store.commit('minimizeMenu', false);
    },
  },
};
