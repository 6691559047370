<template>
  <form
    id="pillar-group-form"
    ref="pillar-group-form"
    class="tw-max-w-full tw-mx-auto tw-flex-shrink-0 tw-mb-20 auth-card tw-flex tw-flex-wrap"
    method="post"
    action="/survey-question-rating/create"
    @submit.prevent="onSubmit"
  >

    <div class="tw-w-full tw-mb-4">
      <h1 class="tw-text-4xl font-title">{{ questionId ? 'Edit Survey Question' : 'Add a Survey Question' }}</h1>
      <!-- <h6 class="tw-text-base opacity-78 tw-text-app-deep-blue">Please fill all inputs</h6> -->
    </div>

    <div class="tw-w-full tw-mb-4 tw-flex-shrink-0 tw-px-1">
      <InputGroup
        :class="{ '--loading-data': loadingForm }"
        label="Survey Question"
        name="survey-question"
        type="text"
        placeholder="Survey question"
        input-classes="tw-p-3"
        v-model="form.question"
        :error="errors.question"
      />
    </div>

    <div class="tw-w-full tw-flex-shrink-0 tw-px-1 tw-mb-4 tw-pt-10">
      <BaseButton
        type="submit"
        :class="[
          'tw-block tw-w-full md:tw-inline-block md:tw-w-auto tw-mb-4 tw-py-3 tw-px-8',
          `${btnClass}`
        ]"
        :text="btnText"
        :disabled="!canSubmit || btnDisabled"
      />
    </div>

  </form>
</template>

<script>
import _debounce from 'lodash/debounce';

// import QuillTextareaGroup from '@/components/QuillTextareaGroup.vue';
import InputGroup from '@/components/InputGroup.vue';

import form from '@/modules/formHelper';

export default {
  name: 'EditSurveyQuestion',
  components: {
    // QuillTextareaGroup,
    InputGroup,
  },
  props: {
    company: {
      type: Object,
      required: true,
    },
    questionId: {
      type: [String, Number],
      default: undefined,
    },
    surveyId: {
      type: [String, Number],
      required: true,
    },
    btnClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loadingForm: true,
      errorForm: undefined,
      btnText: 'Save',
      btnDisabled: false,
      form: {
        question: '',
      },
      errors: {},
    };
  },
  watch: {
    // eslint-disable-next-line func-names
    'company.id': function () {
      this.resetForm();
      this.loadForm();
    },
    surveyId() {
      this.resetForm();
      this.loadForm();
    },
    questionId() {
      this.resetForm();
      this.loadForm();
    },
  },
  computed: {
    canSubmit() {
      return form.isValidTextInput(this.form.question)
        && !this.loadingForm
        && this.company.id !== undefined;
    },
  },
  methods: {
    resetForm() {
      this.form = {
        question: '',
      };
    },
    // eslint-disable-next-line func-names
    loadForm: _debounce(async function () {
      this.loadingForm = true;
      this.errorForm = undefined;

      // * get question details if questionId is defined
      if (this.questionId) {
        const surveyQuestions = await this.$store.dispatch('getSurveyQuestions', [this.company.id, this.surveyId, true]);
        const curr = surveyQuestions.find((r) => Number(r.id) === Number(this.questionId)) || {};

        this.form.question = curr.criteria;
      }

      await this.$nextTick();
      await this.$nextTick();

      this.loadingForm = false;
    }, 300),
    async onSubmit() {
      if (!this.canSubmit) {
        this.$toasted.global.appError({
          errorMessage: 'You can\'t submit the form. Check for empty inputs',
        });

        console.warn('Cannot submit form... \n check for empty inputs');
        return false;
      }

      // signup!!
      this.btnDisabled = true;
      this.btnText = 'working...';

      const data = {
        ...(this.form),
        companyId: this.company.id,
        surveyId: this.surveyId,
        id: this.questionId,
      };

      const result = await this.$store.dispatch('saveSurveyQuestion', data);

      if (result) {
        this.$toasted.success('Saved!', { duration: 1000 });
        this.$emit('created-success');

        if (this.questionId) {
          this.$store.dispatch('logAction', this.$store.state.Logger.actionsIndexes.editSurveyQuestion);
        } else {
          this.$store.dispatch('logAction', this.$store.state.Logger.actionsIndexes.addSurveyQuestion);
        }
      } else {
        this.$toasted.global.appError({
          errorMessage: 'Saving survey question failed. Please try again later',
        });
      }

      this.btnDisabled = false;
      this.btnText = 'save';

      return true;
    },
  },
  async created() {
    this.errors = { ...this.form };

    await this.$nextTick();
    await this.loadForm();
    await this.$nextTick();

    this.loadingForm = false;
  },
};
</script>

<style>

</style>
