import { apiStore } from '../../../modules/apiHelper';

export default {
  state: {
    // userViewsData: {},
  },
  getters: {
    // findViewData: (state) => (dataId) => state.userViewsData[dataId],
  },
  mutations: {
    // updateUserViewData(state, data) {
    //   state.userViewsData = { ...state.userViewsData, ...data };
    // },
  },
  actions: {
    async saveGroupTaskRef(context, task) {
      const taskData = {
        refTask: task,
      };

      context.commit('updateUserViewData', taskData);
      await apiStore.setItem('refTask', task);
      return true;
    },
    async getGroupTaskChatReferencedItem(context) {
      const data = context.rootGetters.findViewData('refTask') || false;

      if (data) return data;

      const dbData = await apiStore.getItem('refTask') || false;
      return dbData;
    },
    async clearGroupChatReferencedItem(context, referencedItem) {
      context.commit('deleteViewData', referencedItem);
      await apiStore.removeItem(referencedItem);

      return true;
    },
  },
};
