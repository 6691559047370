import { apiGet, apiStore } from '../../../../modules/apiHelper';
import resolve from '../../../../modules/api/resolve';
import {
  invalideDate, isStaleData, objectIsValid, now,
} from '../../../../modules/dataHelper';

export default {
  // gets the company User belongs to
  async myCompany(context, refresh = false) {
    // ! do not cache all the companies' data!
    const companyId = context.rootState.User.companyid;
    if (!companyId) return false;

    // eslint-disable-next-line no-underscore-dangle
    const _company = await apiStore.getItem(`company/${companyId}`) || [invalideDate, {}];

    if (!refresh && !isStaleData(_company[0]) && objectIsValid(_company[1])) return _company[1];

    const request = await resolve(apiGet('company', 0, null, refresh));
    const companies = request ? request.company : false;

    if (!companies) return false;

    const company = Array.isArray(companies)
      ? companies.find(({ id }) => Number(companyId) === Number(id)) || false
      : false;

    //
    if (!company) return false;

    // ? set states
    apiStore.setItem(`company/${companyId}`, [now(), company]);

    return company;
  },
  // Kengen has specific admin tweaks
  async myCompanyIsKengen(context) {
    const myCompany = await context.dispatch('myCompany', true);

    if (!myCompany) return false;

    // verify using company website

    const isKenGen = /(kengen\.ementored\.com)/.test(myCompany.website);

    // console.log('isKenGen::', isKenGen, myCompany);

    return isKenGen;
  },
  // gets regional staff's region
  async myRegion(context) {
    const me = await context.dispatch('getUserData', context.rootState.User.id);

    return me.region || {};
  },
};
