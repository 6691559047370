export default {
  state: {
    authError: undefined,
    xhrError: undefined,
  },
  getters: {},
  mutations: {
    xhrError(state, message = undefined) {
      state.xhrError = message;
    },
  },
  actions: {},
};
