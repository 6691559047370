<template>
  <div :class="[
    'popover tw-fixed tw-z-50 transition-fast',
    { 'popover--open': openMenu && isMounted },
    { 'popover--bottombar': isMobileView },
  ]"
  ref="pop-over-menu"
  :style="{
    top: mouseY,
    left: mouseX,
  }"
  @click.stop="closePopOver()"
  >
    <portal-target :is-mounted="isMounted"  name="popover-menu"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'PopOver',
  data() {
    return {
      isMounted: false,
      bodyMounted: false,
      updatedCounts: 0,
    };
  },
  computed: {
    ...mapState({
      popOverIsOpen: (state) => state.Ui.popOver.openPopOver[0],
      popOverXPos: (state) => state.Ui.popOver.openPopOver[1],
      popOverYPos: (state) => state.Ui.popOver.openPopOver[2],
    }),
    // does the math to see
    mouseX() {
      // eslint-disable-next-line no-unused-expressions
      this.updatedCounts;
      if (this.isMobileView) {
        return '0';
      }
      let positionX = (window.innerWidth - this.popOverXPos);
      if (this.$refs['pop-over-menu'] !== undefined) {
        // console.log(this.isAtBottom);
        if (this.isAtBottom) {
          // center it
          return `${(window.innerWidth - this.$refs['pop-over-menu'].clientWidth) / 2}px`;
        }
        positionX = ((positionX - this.$refs['pop-over-menu'].clientWidth) > 0) ? this.popOverXPos : this.popOverXPos - this.$refs['pop-over-menu'].clientWidth;
        return `${positionX}px`;
      }
      return `${this.popOverXPos}px`;
    },
    mouseY() {
      // eslint-disable-next-line no-unused-expressions
      this.updatedCounts;
      if (this.$refs['pop-over-menu'] !== undefined) {
        if (this.isMobileView) {
          return `${window.innerHeight - this.$refs['pop-over-menu'].clientHeight}px`;
        }
        const positionY = (!this.isAtBottom) ? this.popOverYPos : this.popOverYPos - this.$refs['pop-over-menu'].clientHeight - 48;
        return `${positionY}px`;
      }
      return `${this.popOverYPos}px`;
    },
    openMenu() {
      return !!this.popOverIsOpen;
    },
    isAtBottom() {
      if (this.$refs['pop-over-menu'] !== undefined) {
        return ((window.innerHeight - this.popOverYPos) - this.$refs['pop-over-menu'].clientHeight) < 0;
      }

      return false;
    },
  },
  methods: {
    closePopOver(event) {
      if (event) {
        this.$store.commit('openPopOver', [false]);
        this.$emit('popover-close');
        this.isMounted = false;
        this.$emit('input');
      }
    },
  },
  async mounted() {
    await this.$nextTick();
    this.isMounted = true;
    await this.$nextTick();

    document.addEventListener('click', this.closePopOver);
    // recalculate position on resize
    this.addOnResizeFn(() => {
      console.log('on resize fn');
      this.updatedCounts += 1;
      setTimeout(() => {
        this.updatedCounts += 1;
      }, 500);
    });
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closePopOver);
  },
  async updated() {
    await this.$nextTick();
    this.bodyMounted = true;
    await this.$nextTick();

    if (this.updatedCounts < 15) {
      await this.$nextTick();
      this.updatedCounts += 1;
      await this.$nextTick();
    }
  },
};
</script>

<style>

</style>
