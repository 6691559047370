import modal from './modules/modal';
import nav from './modules/nav';
import popOver from './modules/popOver';
import views from './modules/views';
import errors from './modules/errors';
import settings from './modules/settings';
import groupChat from './modules/group-chat';

export default {
  namespaced: false,
  modules: {
    nav,
    modal,
    popOver,
    errors,
    views,
    settings,
    groupChat,
  },
};
