<template>
  <div class="journey-kanban__pill tw-rounded-10 tw-bg-white tw-inline-flex tw-items-center tw-mx-auto tw-pl-3 tw-pr-2">
    <div class="tw-pr-2 tw-py-1">
      <p class="tw-text-xs tw-text-left tw-break-words" v-html="announcement.notice">
      </p>
      <p class="tw-text-xs tw-mt-1 opacity-54 tw-text-left">
        <span>{{stats.seen}}</span> seen, <span>{{stats.unseen}}</span> unseen
      </p>
    </div>
    <div class="tw-relative">
      <div @click.prevent="hideMenu = false" class="tw-inline-block tw-w-5 tw-h-5 hover:tw-bg-gray-300 tw-cursor-pointer">
        <svg version="1.1" id="icon-more" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
          <path d="M66.3,41.7c-3,0-5.7-2.7-5.7-5.7s2.7-5.7,5.7-5.7S72,33,72,36S69.3,41.7,66.3,41.7z M41.7,36c0-3-2.7-5.7-5.7-5.7
            S30.3,33,30.3,36s2.7,5.7,5.7,5.7S41.7,39,41.7,36z M11.4,36c0-3-2.7-5.7-5.7-5.7S0,33,0,36s2.7,5.7,5.7,5.7S11.4,39,11.4,36z"/>
        </svg>
      </div>

      <ul :class="['tw-absolute tw-right-0 tw-mr-2 tw-top-2 tw-z-30 tw-bg-white tw-shadow tw-rounded-10 tw-w-[200px]', { 'tw-hidden': hideMenu}]">
        <li class="tw-text-right tw-p-2 tw-pb-0">
          <a @click.prevent="hideMenu = true" class="tw-w-4 tw-h-4 tw-rounded-full hover:tw-bg-gray-300 tw-p-0.5 tw-inline-block">
            <svg version="1.1" id="icon-close" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
              <path d="M41.2,36L70.9,6.4c1.5-1.5,1.5-3.8,0-5.2c-1.5-1.5-3.8-1.5-5.2,0L36,30.8L6.4,1.1c-1.5-1.5-3.8-1.5-5.2,0s-1.5,3.8,0,5.2
                L30.7,36L1.1,65.6c-1.5,1.5-1.5,3.8,0,5.3C1.9,71.6,3,72,3.7,72s1.9-0.4,2.6-1.1L36,41.3l29.6,29.6c0.7,0.8,1.9,1.1,2.6,1.1
                s1.9-0.4,2.6-1.1c1.5-1.5,1.5-3.8,0-5.3L41.2,36z"/>
            </svg>
          </a>
        </li>
        <li @click="editAnnouncement" class="tw-p-3 hover:tw-bg-gray-100 tw-cursor-pointer tw-text-xs">
          Edit
        </li>
        <!-- <li @click="deleteJourneyAction" class="tw-p-4 hover:tw-bg-gray-100 tw-cursor-pointer tw-text-xs">
          Delete
        </li> -->
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AnnouncementPill',
  props: {
    announcement: {
      type: Object,
      required: true,
    },
    stats: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      hideMenu: true,
    };
  },
  computed: {
    // actionUserType() {
    //   switch (Number(this.announcement.type)) {
    //     case this.$store.state.User.accountTypes.mentor:
    //       return 'Mentors';
    //     case this.$store.state.User.accountTypes.mentee:
    //       return 'Mentees';
    //     default:
    //       return 'Both';
    //   }
    // },
    actionType() {
      return 'Announcement';
    },
    actionsList() {
      return this.$store.state.CompanySettings.announcementsActionsList;
    },
    journeyActionTitle() {
      return (this.actionsList.find((action) => action.action === this.announcement.title) || {}).title;
    },
    badgeTypes() {
      return this.$store.state.CompanySettings.badgeTypes;
    },
  },
  methods: {
    editAnnouncement() {
      this.hideMenu = true;
      this.$emit('edit-announcement', this.announcement.actid, this.announcement?.action?.title);
    },
    // deleteAnnouncement() {
    //   this.hideMenu = true;
    //   this.$emit('delete-announcement', this.announcement.actid);
    // },
  },
};
</script>

<style>

</style>
