export default {
  state: {
    openPopOver: [false, 0, 0],
  },
  getters: {
    popOverIsOpen(state) {
      return !!state.openPopOver[0];
    },

  },
  mutations: {
    openPopOver(state, [open = true, mouseX = 0, mouseY = 0]) {
      state.openPopOver = [open, mouseX, mouseY];
    },
  },
  actions: {},
};
