<template>
  <AppView
    padd-bottom
  >
    <template v-slot:top-app-view>
      <transition name="fade" mode="out-in" appear>
        <CompanyHeader :company="company" :loading="loadingCompany" />
      </transition>
    </template>

    <transition name="fade" mode="out-in" appear>

      <div v-if="errorCompany" class="tw-mt-12" key="errmsg">
        <p class="tw-py-12 tw-text-base tw-text-center" v-html="errorCompany"></p>
      </div>

      <div v-else-if="loadingCompany" class="tw-mt-12" key="lodng">
        <p class="tw-py-12 tw-text-base tw-text-center">Loading...</p>
      </div>

      <div v-else key="bdy">
        <transition name="fade" mode="out-in" appear>
          <div v-if="error" class="tw-bg-red-500 tw-p-4 tw-rounded-10">
            <p class="tw-text-white" v-html="error"></p>
          </div>
          <div v-else-if="loading" class="tw-p-4 tw-rounded-10">
            <p class="tw-italic tw-text-sm tw-text-center tw-text-white">Loading...</p>
          </div>
          <div v-else-if="hasPast || (meetingIsToday && !isNow) || (!isNow && !meetingIsToday && !hasPast)">
            <div class="tw-max-w-md tw-mx-auto tw-p-8 tw-shadow-sm tw-bg-red-100 tw-rounded-b-32 tw-border-b tw-border-l tw-border-r">
              <p v-if="hasPast" class="tw-text-center tw-font-sans tw-mb-4">
                This meeting has passed
                <BaseButton v-if="userIsAdmin" text="End Meeting" @click="endMeeting" class="tw-bg-black tw-text-white tw-py-3 tw-px-4 tw-ml-1" />
              </p>
              <p v-if="!isNow && !meetingIsToday && !hasPast" class="tw-text-center tw-font-sans tw-mb-4">Meeting has not started yet</p>
              <div v-if="meetingIsToday">
                <p class="tw-text-center tw-font-sans tw-mb-4">
                  Meeting start{{ hasPast ? 'ed' : 's' }} <span class="tw-font-bold">{{ countDownTime }}</span>
                  <!-- <br /> -->
                  <!-- <span class="tw-text-sm tw-italic">{{ countDownTime }}</span> -->
                </p>
              </div>

              <BaseButton
                text="Exit Meeting"
                class="tw-block tw-w-full tw-mb-4"
                v-if="!isNow && !userIsAdmin"
                :to="{ name: isMentor ? 'mentorMenteesView' : 'menteeMentorsView' }"
              />

              <!-- <BaseButton v-if="userIsAdmin" text="End Meeting" @click="endMeeting" class="tw-bg-black tw-block tw-w-full tw-text-white tw-py-3 tw-px-4" /> -->
            </div>
          </div>
          <div v-else class="tw-p-5">
            <VideoSdk :meeting="meeting" />

            <div v-if="userIsAdmin" class="tw-max-w-md tw-p-8 tw-shadow-sm tw-bg-app-light-blue tw-rounded-t-32 tw-fixed tw-bottom-0">
              <BaseButton text="End Meeting" @click="endMeeting" class="tw-bg-black tw-text-white tw-py-3 tw-px-4" />
            </div>
          </div>
        </transition>
      </div>
    </transition>

  </AppView>
</template>

<script>
import VideoSdk from '@/components/VideoSdk.vue';
import CompanyHeader from '@/views/Companies/Company/_partials/CompanyHeader.vue';
import companyMixin from '@/mixins/company';
import { now, toJsDate } from '../../../modules/dataHelper';

const windowPeriod = 10; // minutes

export default {
  name: 'VideoMeeting',
  components: {
    CompanyHeader,
    VideoSdk,
  },
  mixins: [companyMixin],
  props: ['id', 'linkid'],
  data() {
    return {
      loading: true,
      error: undefined,
      meeting: {},
      countDownTime: '',
      isNow: false,
      hasPast: false,
    };
  },
  computed: {
    meetingDate() {
      const time = this.meeting.time.split(':');
      const d = new Date(this.meeting.start).setHours(time[0], time[1]);

      return this.$moment(toJsDate(d, true)).format('YYYY-MM-DD HH:mm:ss');
    },
    meetingStartTime() {
      return this.$moment(this.meetingDate).subtract(windowPeriod, 'minutes').format('YYYY-MM-DD HH:mm:ss');
    },
    meetingIsToday() {
      return this.$moment(this.meetingDate).isSame(now(), 'day');
    },
    userIsAdmin() {
      return Number(this.meeting.dash) === 1;
    },
  },
  methods: {
    async getMeeting() {
      this.meeting = await this.$store.dispatch('getMeetingByLinkId', [this.linkid, true]);
    },
    meetingIsNow() {
      const duration = Number(this.meeting.duration) + Number(windowPeriod);
      const meetingEndDate = this.$moment(this.meetingDate).add(duration, 'minutes').format('YYYY-MM-DD HH:mm:ss');

      // moment is failing to calculate
      const startTime = new Date(this.meetingStartTime).getTime();
      const endTime = new Date(meetingEndDate).getTime();
      const nowTime = now();

      // console.log(startTime - nowTime, endTime, nowTime);

      this.isNow = startTime - nowTime < 0 && endTime - nowTime > 0;
    },
    meetingHasPast() {
      const duration = Number(this.meeting.duration) + Number(windowPeriod);
      const meetingEndDate = this.$moment(this.meetingDate).add(duration, 'minutes').format('YYYY-MM-DD HH:mm:ss');

      const meetingEndTime = new Date(meetingEndDate).getTime();
      const nowTime = now();

      this.hasPast = meetingEndTime - nowTime < 0;
    },
    countdown() {
      if (this.meetingIsToday && !this.isNow && !this.hasPast) {
        setTimeout(() => {
          this.countDownTime = this.$moment(this.meetingStartTime).fromNow();
          this.meetingIsNow();
          this.meetingHasPast();
          this.countdown();
        }, 3000);
      }
    },
    async endMeeting() {
      this.$store.dispatch('endMeeting', this.meeting.id);

      this.$toasted.info('Meeting ended', { duration: 4000 });

      this.$router.push({
        name: 'videoMeetings',
        id: this.meeting.companyid,
      });
    },
  },
  async created() {
    // get company
    await Promise.all([
      this.getCompany(),
    ]);

    await this.$nextTick();
    // get and assert meeting
    await this.getMeeting();

    // todo: assert if meeting has ended

    if (this.meetingIsToday && !this.isNow && !this.hasPast) {
      this.countdown();
    }

    this.meetingHasPast();

    this.loading = false;
  },
};
</script>

<style>

</style>
