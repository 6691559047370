<template>
  <div
    :class="[
      'video-meeting-card tw-rounded-10 tw-px-5 tw-pt-4 tw-pb-5',
    ]"
  >
    <div class="tw-flex tw-relative">
      <h6 class="tw-font-bold tw-mb-2.5 tw-grow tw-pr-3 tw-mr-auto">{{meetingTime}}</h6>

      <div :class="{ 'tw-hidden': !canEdit }">
        <a @click.prevent="hideMenu = false" class="tw-w-6 tw-h-6 tw-rounded-full hover:tw-bg-gray-300 tw-p-0.5 tw-inline-block tw-text-app-primary-blue">
          <svg version="1.1" id="icon-more" class="tw-fill-current" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
            <path d="M66.3,41.7c-3,0-5.7-2.7-5.7-5.7s2.7-5.7,5.7-5.7S72,33,72,36S69.3,41.7,66.3,41.7z M41.7,36c0-3-2.7-5.7-5.7-5.7
              S30.3,33,30.3,36s2.7,5.7,5.7,5.7S41.7,39,41.7,36z M11.4,36c0-3-2.7-5.7-5.7-5.7S0,33,0,36s2.7,5.7,5.7,5.7S11.4,39,11.4,36z"/>
          </svg>
        </a>

        <ul :class="['tw-absolute tw-right-0 tw-mr-2 tw-top-2 tw-z-30 tw-bg-white tw-shadow tw-rounded-10 tw-w-[200px]', { 'tw-hidden': hideMenu}]">
          <li class="tw-text-right tw-p-2 tw-pb-0">
            <a @click.prevent="hideMenu = true" class="tw-w-4 tw-h-4 tw-rounded-full hover:tw-bg-gray-300 tw-p-0.5 tw-inline-block">
              <svg version="1.1" id="icon-close" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                <path d="M41.2,36L70.9,6.4c1.5-1.5,1.5-3.8,0-5.2c-1.5-1.5-3.8-1.5-5.2,0L36,30.8L6.4,1.1c-1.5-1.5-3.8-1.5-5.2,0s-1.5,3.8,0,5.2
                  L30.7,36L1.1,65.6c-1.5,1.5-1.5,3.8,0,5.3C1.9,71.6,3,72,3.7,72s1.9-0.4,2.6-1.1L36,41.3l29.6,29.6c0.7,0.8,1.9,1.1,2.6,1.1
                  s1.9-0.4,2.6-1.1c1.5-1.5,1.5-3.8,0-5.3L41.2,36z"/>
              </svg>
            </a>
          </li>
          <li @click="goToMeeting" class="tw-p-3 hover:tw-bg-gray-100 tw-cursor-pointer tw-text-xs">
            Go to meeting
          </li>
          <li @click="editMeeting" class="tw-p-4 hover:tw-bg-gray-100 tw-cursor-pointer tw-text-xs">
            Edit meeting
          </li>
          <li @click="copyPublicLink" class="tw-p-4 hover:tw-bg-gray-100 tw-cursor-pointer tw-text-xs">
            Copy public link
          </li>
        </ul>
      </div>
    </div>
    <h4 class="tw-mb-2.5 opacity-54">{{meeting.title}}</h4>
    <div class="tw-inline-flex">
      <template v-for="(participant, index) in participants.slice(0, maxVisibleParticipants)">
        <div :key="index" :class="['tw-h-6 tw-w-6 tw-rounded-full tw-overflow-hidden', {'tw--ml-3': index !== 0}]">
          <UserPhoto :photo="participant.photo || participant.pic" :user-name="participant" photo-size="tw-h-6 tw-w-6" class="tw-w-full tw-h-full tw-object-cover tw-bg-gray-400"/>
        </div>
      </template>
      <span v-if="participants?.length > maxVisibleParticipants" class="opacity-54 tw-ml-1">...</span>
      <template v-if="participants.length === 0">
        <p class="tw-text-xs opacity-54">0 Participants</p>
      </template>
    </div>
  </div>
</template>

<script>
import { toJsDate } from '@/modules/dataHelper';
import UserPhoto from '@/components/UserPhoto.vue';

export default {
  name: 'VideoMeetingCard',
  components: { UserPhoto },
  props: {
    meeting: {
      type: Object,
      required: true,
    },
    participants: {
      type: Array,
      default() {
        return [];
      },
    },
    canEdit: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      hideMenu: true,
      maxVisibleParticipants: 10,
    };
  },
  computed: {
    meetingTime() {
      if (!this.meetingTimeIsValid) return '';

      return `${this.$moment(toJsDate(this.meeting.start)).format('Do MMM')}, ${this.meeting.time} (${this.meeting.duration} minutes)`;
    },
    meetingTimeIsValid() {
      try {
        toJsDate(this.meeting.start);

        return true;
      } catch (error) {
        return false;
      }
    },
    // isOwner() {
    //   return Number(this.meeting.admin) === Number(this.$store.state.User.id);
    // },
  },
  methods: {
    goToMeeting() {
      this.$router.push({
        name: 'videoMeeting',
        params: {
          id: this.meeting.companyid,
          linkid: this.meeting.linkid,
        },
      });
    },
    editMeeting() {
      this.hideMenu = true;
      this.$emit('edit-meeting', this.meeting);
    },
    copyPublicLink() {
      if (!navigator?.clipboard) return;

      if (!this.meeting.link2) {
        console.warn('deprecated meeting object. missing link2 property');
        return;
      }

      navigator.clipboard.writeText(this.meeting.link2);

      this.$toasted.success('Link copied!', {
        duration: 3500,
        position: 'top-right',
      });
    },
  },
};
</script>

<style>

</style>
