import store from '../../store';

const meta = {
  requiresLogin: true,
  requiresUserType: store.state.User.accountTypes.superAdmin,
};

export default [
  {
    path: 'packages',
    name: 'packages',
    component() {
      return import(/* webpackChunkName: "sadminviews" */ '../../views/Packages/PackagesView.vue');
    },
    meta,
  },
];
