<template>
  <div>
    <p class="tw-text-xs leading-14 tw-mb-2 tw-uppercase">
      {{ userTypeName }}
    </p>

    <transition name="fade" mode="out-in" appear>
      <div v-if="!edit && message.length">
        <div>
          <h5 class="tw-text-app-deep-blue tw-text-sm leading-17 opacity-78 tw-overflow-hidden tw-break-words" v-html="message">
          </h5>
        </div>
        <BaseButton
          text="Edit"
          class="tw-text-black bg-black-11 tw-mt-4"
          icon
          @click="promptUpdateMessage"
        >
          <svg version="1.1" class="my-btn__icon" slot="icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
            <path d="M5.6,72c-1.5,0-2.9-0.5-3.9-1.7C0.5,69.1,0,67.3,0,65.6l1.2-11.3c0.2-1.2,0.7-2.5,1.7-3.4L52,1.7c2.2-2.2,5.9-2.2,8.1,0
              l10,10c2.2,2.2,2.2,5.9,0,8.1L20.8,69.1c-1,1-2.2,1.5-3.4,1.7l0,0L6.1,72C5.9,72,5.6,72,5.6,72z M17.2,68.1L17.2,68.1L17.2,68.1z
              M56.1,4.8c0,0-0.2,0-0.5,0.2L6.1,54.4c-0.2,0.2-0.2,0.2-0.2,0.5L4.7,66.1c0,0.2,0.2,0.5,0.2,0.7s0.2,0.2,0.7,0.2l11.3-1.2
              c0.2,0,0.2,0,0.5-0.2l49.3-49.3c0.2-0.2,0.2-0.7,0-1l-10-10C56.4,5.1,56.1,4.8,56.1,4.8z"/>
          </svg>
        </BaseButton>
      </div>

      <div v-else>
        <p class="tw-text-sm leading-17 opacity-54 tw-mb-4" v-if="hint.length">
          {{hint}}
        </p>
        <label class="tw-text-xs tw-text-black opacity-78">{{ messageLength }} / {{ maxMessageLength }}</label>
        <QuillTextareaGroup
          :rows="3"
          class="tw-bg-white"
          v-model="form.message"
          max-height="400px"
          :value="message"
        />
        <div class="tw-flex tw-justify-end tw-py-2">
          <div>
            <BaseButton
              :text="saveMessageBtnText"
              class="tw-text-white tw-bg-black"
              :disabled="!canSaveMessage || saveMessageBtnDisabled"
              @click="saveMessage"
            />
          </div>
          <div class="tw-pl-4" v-if="message.length">
            <BaseButton
              text="Cancel"
              class="tw-text-black bg-black-11"
              @click="cancelEditingMessage"
            />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import QuillTextareaGroup from '@/components/QuillTextareaGroup.vue';

export default {
  name: 'CompanyCustomUserWelcomeMessage',
  components: {
    QuillTextareaGroup,

  },
  props: {
    userTypeId: {
      type: [String, Number],
      required: true,
    },
    companyId: {
      type: [String, Number],
      required: true,
    },
    hint: {
      type: String,
      default: '',
    },
    messageId: {
      type: [Number, String],
      default: undefined,
    },
    companyMessage: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      minMessageLength: 50,
      maxMessageLength: 500,
      saveMessageBtnText: 'Save',
      saveMessageBtnDisabled: false,
      edit: false,
      message: this.companyMessage,
      form: {
        message: '',
      },
    };
  },
  computed: {
    messageLength() {
      const match = this.form.message.match(/[a-z]/gi);
      return match ? match.length : 0;
    },
    userTypeName() {
      return this.$store.getters.userTypeName(this.userTypeId);
    },
    isValidMessage() {
      return this.message.length || this.form.message.length;
    },
    canSaveMessage() {
      return this.messageLength >= this.minMessageLength && this.messageLength <= this.maxMessageLength;
    },
  },
  methods: {
    async promptUpdateMessage() {
      this.form.message = this.message;
      this.edit = true;
      await this.$nextTick();
      await this.$nextTick();
    },
    cancelEditingMessage() {
      this.edit = false;
      this.form.message = '';
    },
    async saveMessage() {
      if (!this.isValidMessage) return;

      await this.$nextTick();

      this.saveMessageBtnDisabled = true;
      this.saveMessageBtnText = 'working...';

      const result = await this.$store.dispatch(
        'updateCompanyMessage',
        [
          this.companyId,
          this.form.message,
          this.userTypeId,
        ],
      );

      // console.log(result);

      if (!result) {
        this.$toasted.global.appError({ errorMessage: 'Message could not be saved, please try again.' });
      } else {
        this.$toasted.success(`Message ${this.messageId ? 'saved' : 'created!'}!`, {
          duration: 3000,
          position: 'top-right',
        });

        this.$emit('save', this.form.message);

        switch (this.userTypeName) {
          case 'mentee':
            this.$store.dispatch('logAction', this.$store.state.Logger.actionsIndexes.changeCompanyMenteesWelcomeMessage);
            break;
          case 'mentor':
            this.$store.dispatch('logAction', this.$store.state.Logger.actionsIndexes.changeCompanyMentorsWelcomeMessage);
            break;
          default:
            console.warn('unknown ::userTypeName to log');
            break;
        }
      }

      this.cancelEditingMessage();

      this.saveMessageBtnDisabled = false;
      this.saveMessageBtnText = 'save';

      await this.$nextTick();
    },
  },
  created() {
    this.message = this.companyMessage;
  },
};
</script>

<style>

</style>
