var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.type === 'submit')?_c('button',{class:[
    'my-btn tw-fill-current',
    { 'tw-px-0': _vm.icon },
    { 'tw-pl-4': _vm.iconRight },
  ],attrs:{"type":_vm.type}},[(_vm.icon)?_vm._t("icon"):_vm._e(),(_vm.to)?_c('router-link',{attrs:{"to":_vm.to}},[_vm._v(_vm._s(_vm.text))]):_c('span',{staticClass:"my-btn__text tw-align-middle"},[_vm._v(_vm._s(_vm.text))])],2):(_vm.type === 'link')?_c('a',{class:[
    'my-btn tw-fill-current',
    { 'tw-px-0': _vm.icon },
    { 'tw-pl-4': _vm.iconRight },
  ]},[(_vm.icon)?_vm._t("icon"):_vm._e(),_c('span',{staticClass:"my-btn__text tw-align-middle"},[_vm._v(_vm._s(_vm.text))])],2):(_vm.to)?_c('router-link',{class:[
    'my-btn tw-fill-current',
    { 'tw-px-0': _vm.icon },
    { 'tw-pl-4': _vm.iconRight },
  ],attrs:{"tag":"button","type":_vm.type,"to":_vm.to}},[(_vm.icon && !_vm.iconRight)?_vm._t("icon"):_vm._e(),_c('span',{staticClass:"my-btn__text tw-align-middle"},[_vm._v(_vm._s(_vm.text))]),(_vm.icon && _vm.iconRight)?_vm._t("icon"):_vm._e()],2):_c('button',{class:[
    'my-btn tw-fill-current',
    { 'tw-px-0': _vm.icon },
    { 'tw-pl-4': _vm.iconRight },
  ],attrs:{"type":_vm.type},on:{"click":function($event){return _vm.btnClick()}}},[(_vm.icon && !_vm.iconRight)?_vm._t("icon"):_vm._e(),(_vm.text.length)?_c('span',{staticClass:"my-btn__text tw-align-middle"},[_vm._v(_vm._s(_vm.text))]):_vm._e(),(_vm.icon && _vm.iconRight)?_vm._t("icon"):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }