<template>
  <div
    :class="[
      'tw-relative transition-slow',
      { 'tw-overflow-y-hidden': listHideOverflow }
    ]"
    :style="{maxHeight: `${listMaxHeight}px` }"
    ref="list"
  >

    <template v-for="(listItem, index) in list">
        <slot :item="listItem" :index="index" v-if="index < howManyCanView" />
    </template>

    <div
      class="tw-text-center tw-py-4 tw-w-full"
      style="width: 100% !important; margin: 0;"
      v-if="list.length > limit"
    >
      <BaseButton
        class="tw-bg-gray-200"
        :text="moreBtnText"
        @click="maximizeList()"
        v-if="howManyCanView < list.length"
      />
      <BaseButton
        class="tw-bg-gray-200"
        text="Less"
        @click="minimizeList()"
        v-else
      />
    </div>
    <div v-else class="tw-mt-4"></div>
  </div>
</template>

<script>
export default {
  name: 'ViewMore',
  props: {
    limit: {
      type: Number,
      default: 4,
    },
    list: {
      type: Array,
      required: true,
    },
    moreBtnText: {
      type: String,
      default: 'More',
    },
  },
  data() {
    return {
      isOpen: false,
      howManyCanView: this.limit,
      listMaxHeight: 70000,
      listInitialHeight: 700,
      listHideOverflow: false,
      updateCounts: 0,
    };
  },
  watch: {
    updateCounts() {
      this.calculateHeights();
    },
    async list() {
      await this.$nextTick();
      this.updateCounts += 1;
    },
    // calculates the height of the list
    async howManyCanView() {
      await this.calculateHeights();
    },
  },
  methods: {
    maximizeList() {
      this.howManyCanView += 8;
    },
    minimizeList() {
      this.listHideOverflow = true;
      this.listMaxHeight = this.listInitialHeight;
      this.howManyCanView = this.limit;
    },
    async calculateHeights() {
      this.listHideOverflow = true;
      await this.$nextTick();
      this.$emit('list-change');
      // console.log(this.$refs);
      if (this.$refs.list) {
        this.listMaxHeight = this.$refs.list.scrollHeight;
      }
      setTimeout(() => {
        this.listHideOverflow = false;
      }, 400);
    },
  },
  async mounted() {
    this.howManyCanView = this.limit;

    if (this.$refs.list) {
      this.listMaxHeight = this.$refs.list.clientHeight;
      this.listInitialHeight = this.$refs.list.clientHeight;

      setTimeout(() => {
        this.listMaxHeight = this.$refs.list.clientHeight;
      }, 500);
    }

    await this.$nextTick();
    await this.$nextTick();

    this.calculateHeights();
    this.addOnResizeFn(this.calculateHeights);
  },
};
</script>

<style>

</style>
