<template>
  <div>
    <h4 class="tw-text-xl tw-p-6 tw-text-center opacity-78" v-html="message"></h4>
    <div class="tw-flex tw-mt-3">

      <div
        @click.stop="decline()"
        class="tw-w-1/2 tw-rounded-3 tw-text-center tw-cursor-pointer hover:tw-bg-red-100 transition-fast"
      >
        <p class="tw-p-3 tw-text-red-500 tw-uppercase tw-font-bold tw-text-sm">Cancel</p>
      </div>
      <div
        @click.stop="accept()"
        class="tw-w-1/2 tw-rounded-3 tw-text-center tw-cursor-pointer hover:tw-bg-green-100 transition-fast"
      >
        <p class="tw-p-3 opacity-78 tw-uppercase tw-font-bold tw-text-sm">Continue</p>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'ConfirmCard',
  props: {
    onAccept: {
      type: Function,
      default: () => {},
    },
    onDecline: {
      type: Function,
      default: () => {},
    },
    message: {
      type: String,
      default: 'Are you sure?',
    },
  },
  computed: {
    confirmMessage() {
      return this.message;
    },
  },
  methods: {
    async decline() {
      await this.$nextTick();
      await this.onDecline();
      await this.$nextTick();
      await this.$nextTick();
      this.$emit('confirmed:false');
    },
    async accept() {
      await this.$nextTick();
      await this.onAccept();
      await this.$nextTick();
      await this.$nextTick();
      this.$emit('confirmed:true');
    },
  },
};
</script>

<style>

</style>
