<template>
  <transition name="fade" mode="out-in" appear>
    <div>
      <portal to="modal-body" key="mdl">
        <!--  -->
        <EditMentorshipGroupJourneyAction
          :position="journeyActionPosition"
          :action="journeyActionAction"
          v-if="activeModal === 'EditMentorshipGroupJourney'"
          :company="company"
          :journey-id="journeyId"
          @created-success="closeModal(); getJourneyActions();"
        />
      </portal>

      <!-- JOURNEY Hierarchy -->
      <div class="journey-kanban tw-flex tw-flex-col tw-relative">
        <div class="tw-p-2">
          <p class="tw-text-center tw-text-xs tw-uppercase">MENTORSHIP GROUPS JOURNEY HIERARCHY</p>
        </div>

        <div class="tw-z-0 tw-mx-auto tw-absolute tw-left-1/2 journey-kanban__hierarchy-line">
          <div class="tw-border-l tw-border-gray-700 tw-h-full"></div>
        </div>

        <div class="tw-z-10 journey-kanban__hierarchy-list tw-mx-auto tw-pt-5">
          <!--  -->
          <div class="tw-mb-4">
            <div>
              <div :class="[
                'tw-rounded-10 tw-border journey-kanban__hierarchy-list__item tw-p-3 tw-mb-4 tw-flex tw-flex-col tw-justify-center',
                ]"
              >
                <div class="tw-h-full tw-flex tw-items-center">
                  <div class="tw-flex-grow tw-text-center">
                    <p class="tw-font-sm tw-mb-1">New Group</p>

                    <div v-for="journeyAction in journeyActions" :key="journeyAction.id">
                      <JourneyKanbanActionPill
                        :class="[{ 'tw-mb-2' : journeyActions.length > 1 }]"
                        :journey-action="journeyAction"
                        @delete-journey-action="deleteJourneyAction"
                      />
                    </div>
                  </div>
                </div>

                <BaseButton
                  text="Add Action"
                  class="tw-bg-black tw-border-0 tw-text-white tw-mt-3"
                  @click="openJourneyActionForm()"
                  v-if="canAddAction"
                />
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import EditMentorshipGroupJourneyAction from '@/views/Companies/Company/_partials/EditMentorshipGroupJourneyAction.vue';
import JourneyKanbanActionPill from '@/components/JourneyKanbanActionPill.vue';

const newGroupAction = 'new_mentorship_group';

export default {
  name: 'CompanyMentorshipGroupsJourney',
  components: {
    EditMentorshipGroupJourneyAction,
    JourneyKanbanActionPill,
  },
  props: {
    company: {
      type: Object,
      required: true,
    },
    journeyId: {
      type: [String, Number],
      required: true,
    },
    activeModal: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      journeyActions: [],
      journeyActionPosition: 1,
      journeyActionAction: undefined,
    };
  },
  computed: {
    accountTypes() {
      return this.$store.state.User.accountTypes;
    },
    canAddAction() {
      const hasActionForBoth = this.journeyActions.find((action) => Number(action.type) === 0);

      if (hasActionForBoth) return false;

      const hasActionsForMentor = this.journeyActions.find((action) => Number(action.type) === this.$store.state.User.accountTypes.mentor);
      const hasActionsForMentee = this.journeyActions.find((action) => Number(action.type) === this.$store.state.User.accountTypes.mentee);

      if (hasActionsForMentor && hasActionsForMentee) return false;

      return true;
    },
  },
  methods: {
    async getJourneyActions() {
      const actions = await this.$store.dispatch('getJourneyActions', [this.journeyId, this.company.id, true]);

      if (Array.isArray(actions)) {
        this.journeyActions = actions.filter((action) => action.title === newGroupAction);
      }
    },
    async openJourneyActionForm() {
      this.$emit('open-modal');
      await this.$nextTick();

      this.journeyActionPosition = 15;
      this.journeyActionAction = newGroupAction;
      await this.$nextTick();

      this.$store.commit('openModal', [true, 'modal']);
    },
    async deleteJourneyAction(journeyActionId) {
      const result = await this.$store.dispatch('deleteJourneyAction', journeyActionId);

      if (result) {
        this.$toasted.success('Action Deleted!', { duration: 1000 });
        this.getJourneyActions();
      }
    },
  },
  async created() {
    await Promise.all([
      this.getJourneyActions(),
    ]);
  },
};
</script>
