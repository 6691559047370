export function toBoolean(data) {
  const d = (typeof data === 'string') ? data.toLowerCase() : data;

  switch (d) {
    case '':
    case 'false':
    case false:
    case '0':
    case 0:
    case 'null':
    case null:
    case 'undefined':
    case undefined:
      return false;

    default:
      return true;
  }
}

/**
 *
 * @param {String} fileType can be a file name or a mime type
 */
export function getReadableFileType(fileType, isFileName = false) {
  if (!fileType) return false;

  const type = (fileType.split('.').length > 1 && isFileName) ? fileType.split('.').pop() : fileType;
  switch (type.toLowerCase()) {
    // svg
    case 'image/svg+xml':
    // images
      return 'svg';
    case 'image/jpeg':
    case 'image/jpg':
    case 'image/webp':
    case 'image/*':
    case 'image':
    case 'jpg':
      return 'jpg';
    case 'image/png':
    case 'png':
      return 'png';

    // audios
    case 'audio/mp3':
    case 'audio/mpeg':
    case 'audio/wav':
    case 'audio/webm':
    case 'audio/ogg':
    case 'audio/aac':
    case 'audio/3gpp':
    case 'audio/3gpp2':
    case 'audio/*':
    case 'audio':
    case 'wav':
    case 'aac':
    case 'mp3':
    case 'mpa':
    case 'm4a':
      return 'audio';

    // videos
    case 'video/mp4':
    case 'video/webm':
    case 'video/ogg':
    case 'video/mpeg':
    case 'video/3gpp':
    case 'video/3gpp2':
    case 'video/x-msvideo':
    case 'video/*':
    case 'video':
    case 'mp4':
    case 'avi':
    case '3gp':
    case 'mv4':
    case 'mov':
      return 'video';

    // files
    case 'application/vnd.oasis.opendocument.text':
    case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    case 'application/vnd.openxmlformats':
    case 'application/msword':
    case 'doc':
    case 'docx':
    case 'word':
      return 'word';

    case 'application/vnd.ms-powerpoint':
    case 'application/vnd.oasis.opendocument.presentation':
    case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    case 'ppt':
    case 'pptx':
      return 'ppt';

    case 'application/pdf':
    case 'pdf':
      return 'pdf';

    case 'application/vnd.ms-excel':
    case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
    case 'xls':
    case 'xlsx':
    case 'excel':
      return 'excel';

    // else
    default:
      if (isFileName) return type;
      return false;
  }
}

export function calculatePercentage(amount = 0, totalAmount = 0) {
  if (amount === 0 || totalAmount === 0 || !amount || !totalAmount) {
    return '0%';
  }
  const value = (amount * 100) / totalAmount;
  return `${(Math.round(value))}%`;
}

export function capitalizeText(str) {
  return str.charAt(0).toUpperCase() + str.substring(1);
}

export function toJsDate(d = Date(), makeJsObject = false) {
  let date = d;
  if (typeof d === 'string') {
    // add the T for compatibility
    date = d.replace(/(.*)+(\s)+(.*)/, '$1T$3');
  }

  if (makeJsObject === true) {
    return new Date(date);
  }
  // const jsDate = new Date(date).toISOString().slice(0, 19).replace('T', ' ');
  const jsDate = new Date(date);
  const timeOffset = jsDate.getTimezoneOffset();

  jsDate.setUTCHours(jsDate.getUTCHours() + (timeOffset > 0
    ? (Math.abs(timeOffset / 60) * -1)
    : (Math.abs(timeOffset / 60))));
  // jsDate.setUTCMinutes(jsDate.getUTCHours() + (timeOffset > 0
  //   ? (Math.abs(timeOffset % 60) * -1)
  //   : (Math.abs(timeOffset % 60))));
  return jsDate.toISOString().slice(0, 19).replace('T', ' ');
}

export function pluralize(str, value = 1, pluralizedStr) {
  if (Number(value) === 1) {
    return str;
  }
  return pluralizedStr || `${str}s`;
}

/**
 *
 * @param {String} str Number|StringedNumber
 * @param {Number} grammarType 0 = 'male', 1 = 'he', 2 = 'him', 3 = 'his
 */
export function genderize(str = '', grammarType = 0) {
  switch (grammarType) {
    case 0:
      return Number(str) === 1 ? 'male' : 'female';
    case 1:
      return Number(str) === 1 ? 'he' : 'she';
    case 2:
      return Number(str) === 1 ? 'him' : 'her';
    case 3:
      return Number(str) === 1 ? 'his' : 'her';
    default:
      return Number(str) === 1 ? 'male' : 'female';
  }
}

export function getRandomArrayItem(arr) {
  const key = Math.floor(Math.random() * arr.length);
  return arr[key];
}

export function objectSize(obj) {
  return Object.entries(obj).length;
}

/**
 * If it is a valid string array, it willl JSON.parse
 * else split by commas
 * [TODO] Improve, the spliting of the string
 * @param {String} arr
 */
export function toArray(arr, splitUsing = ',') {
  if (/(?:^\[)[\s\S]+\]/g.test(arr)) {
    return JSON.parse(arr);
  }
  return arr.split(splitUsing);
}

export function now() {
  return new Date().getTime();
}

export const invalideDate = new Date('2000-01-01').getTime();

/**
 * Checks if expiry is past 40 minutes
 * @param {Number} expiry date the data was created [default: 0]
 * @param {Number} expiryDate date to compare the expiry to [default: now()]
 * @param {Number} expiryLifeTime length of time the expiry is considered valid [default: 40 minutes]
 */
export function isStaleData(expiry = 0, expiryDate = now(), expiryLifeTime = 1200000) {
  return !!(expiryDate - expiry >= expiryLifeTime);
}

export function objectIsValid(obj = {}) {
  const objArray = Object.entries(obj);

  if (objArray.length === 0) return false;

  return objArray.every((curr) => !!curr[0]);
}

export function firstLetter(str = '') {
  return str.slice(0, 1);
}

export async function getPhoto(file, callback = () => {}) {
  const fileReader = new FileReader();

  if (file) {
    fileReader.addEventListener('load', callback);
    fileReader.addEventListener('loadend', () => {
      fileReader.removeEventListener('load', callback);
      fileReader.removeEventListener('loadend', callback, false);
      return fileReader.result;
    });

    fileReader.readAsDataURL(file);
  }

  return fileReader;
}

export function getBase64Image(img) {
  return new Promise((resolve) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    canvas.width = img.width || 80;
    canvas.height = img.height || 80;
    ctx.drawImage(img, 0, 0);

    const dataURL = canvas.toDataURL();

    resolve(dataURL);
    // return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
  });
}

/**
 * converts a string to be url-friendly
 * @param {String} str string to be used in a url
 * @param {Boolean} decode decodeUriComponent??
 * @returns {String} string without characters not recognized by browser in the url
 */
export function urlFriendly(str = '', decode = true) {
  return decode ? decodeURIComponent(str) : encodeURIComponent(str);
}

export function totalSumOfArray(arr = []) {
  let totalSum = 0;

  arr.forEach((item = 0) => {
    // console.log(item, totalSum);
    totalSum += item;
  });

  return totalSum || 0;
}

export function notificationActionText(notificationType = 'to_read', actionNumber = 1) {
  const notificationTexts = {
    to_read: ['follow link', 'chat'], // there's no consistency here
    to_read_task: ['view task'],
    to_decide_match: ['view profile', 'accept', 'decline'],
    to_decide_submitted_task: ['review task'],
  };

  return (notificationTexts[notificationType] || notificationTexts.to_read)[actionNumber - 1] || 'follow link';
}

/**
 * Removes double slashes from url
 * @param {String} url
 */
export function cleanUrl(url = window.origin) {
  return url.replace(/([^:])(\/{2,})/g, '$1/');
}

/**
 * Triggers a download
 * @param {String} blob
 */
export function download(blob, fileName, mimeType = 'application/pdf', modeAlreadyDecided = false) {
  const winMode = window.URL;
  const defaultMime = 'application/octet-stream'; // this default mime also triggers iframe downloads

  // decide mode first;
  if (!modeAlreadyDecided) {
    if (winMode) { // simple fast and modern way using Blob and URL:
      download(blob, fileName, mimeType, true);
    } else {
      // handle non-Blob()+non-URL browsers:
      if (typeof blob === 'string' || blob.constructor === toString) {
        try {
          return download(`data:${mimeType};base64,${window.btoa(blob)}`, fileName, mimeType, true);
        } catch (y) {
          return download(`data:${mimeType},${encodeURIComponent(blob)}`, fileName, mimeType, true);
        }
      }

      // Blob but not URL support:
      const reader = new FileReader();
      // eslint-disable-next-line func-names
      reader.onload = function () {
        download(this.result, fileName, mimeType, true);
      };
      reader.readAsDataURL(blob);
    }

    return true;
  }

  let url = window.URL.createObjectURL(blob);
  const anchor = document.createElement('a');

  if ('download' in anchor) { // html5 A[download]
    anchor.href = url;
    anchor.setAttribute('download', fileName);
    anchor.className = 'download-js-link';
    anchor.innerHTML = 'downloading...';
    anchor.style.display = 'none';

    anchor.addEventListener('click', function evClick(e) {
      e.stopPropagation();
      // eslint-disable-next-line no-restricted-properties
      this.removeEventListener('click', evClick);
    });

    document.body.appendChild(anchor);

    setTimeout(() => {
      anchor.click();
      document.body.removeChild(anchor);

      if (winMode === true) {
        setTimeout(() => {
          window.URL.revokeObjectURL(anchor.href);
        }, 250);
      }
    }, 66);

    return true;
  }

  // handle non-a[download] safari as best we can:
  if (/(Version)\/(\d+)\.(\d+)(?:\.(\d+))?.*Safari\//.test(navigator.userAgent)) {
    // eslint-disable-next-line no-useless-escape
    if (/^data:/.test(url)) url = `data:${url.replace(/^data:([\w\/\-\+]+)/, defaultMime)}`;

    if (!window.open(url)) { // popup blocked, offer direct download:
      // eslint-disable-next-line no-alert
      if (window.confirm('Displaying New Document\n\nUse Save As... to download, then click back to return to this page.')) {
        window.location.href = url;
      }
    }
    return true;
  }

  // do iframe dataURL download (old ch+FF):
  const f = document.createElement('iframe');
  document.body.appendChild(f);

  if (!winMode && /^data:/.test(url)) { // force a mime that will download:
    // eslint-disable-next-line no-useless-escape
    url = `data:${url.replace(/^data:([\w\/\-\+]+)/, defaultMime)}`;
  }

  f.src = url;

  setTimeout(() => {
    document.body.removeChild(f);
  }, 333);

  return true;
}

export function htmlizeLinksInString(text) {
  if (typeof text !== 'string') return text;

  // Put the URL to variable $1 after visiting the URL
  const Rexp = /((http|https|ftp):\/\/[\w?=&.\/-;#~%-]+(?![\w\s?&.\/;#~%"=-]*>))/g;

  // Replace the RegExp content by HTML element
  return text.replace(Rexp, "<a href='$1' target='_blank' style='text-decoration: underline;'><i>$1</i></a>");
}
