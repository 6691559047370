import { apiGet, apiPost, generateFormData } from '../../../modules/apiHelper';
import { toBoolean } from '../../../modules/dataHelper';

async function addParticipantToMentorshipGroup(data) {
  const request = await apiPost('add_prt', generateFormData(data), 59)
    .catch((err) => {
      console.warn('addParticipantToMentorshipGroup::add_prt err', err);
      return false;
    })
    .then(async (res) => {
      if (!res) return false;
      if (toBoolean(res.data.error)) return false;

      return true;
    });
  //

  return request;
}

async function removeParticipantfromMentorshipGroup(data) {
  const request = await apiPost('del_prt', generateFormData(data), 60)
    .catch((err) => {
      console.warn('removeParticipantfromMentorshipGroup::del_prt err', err);
      return false;
    })
    .then(async (res) => {
      if (!res) return false;
      if (toBoolean(res.data.error)) return false;

      return true;
    });
  //

  return request;
}

async function updateMentorshipGroupTitle(data) {
  const request = await apiPost('change_grp_name', generateFormData(data), 60)
    .catch((err) => {
      console.warn('updateMentorshipGroupTitle::change_grp_name err', err);
      return false;
    })
    .then(async (res) => {
      if (!res) return false;
      if (toBoolean(res.data.error)) return false;

      return true;
    });
  //

  return request;
}

async function updateMentorshipGroupDescription(data) {
  const request = await apiPost('change_grp_desc', generateFormData(data), 60)
    .catch((err) => {
      console.warn('updateMentorshipGroupDescription::change_grp_desc err', err);
      return false;
    })
    .then(async (res) => {
      if (!res) return false;
      if (toBoolean(res.data.error)) return false;

      return true;
    });
  //

  return request;
}

export default {
  async createCompanyMentorshipGroup(context, data) {
    // eslint-disable-next-line no-param-reassign
    data.admin = context.rootState.User.id;

    const request = await apiPost('create_grp', generateFormData(data), 59)
      .catch((err) => {
        console.warn('createCompanyMentorshipGroup::create_grp err', err);
        return false;
      })
      .then(async (res) => {
        if (!res) return false;
        if (toBoolean(res.data.error)) return false;

        return res.data.groupid || true;
      });
    //

    return request;
  },
  async updateCompanyMentorshipGroup(context, data) {
    // eslint-disable-next-line no-param-reassign
    data.admin = context.rootState.User.id;

    const currentData = await context.dispatch('getCompanyMentorshipGroup', data.id);

    // compare values and run update on updated values
    const promises = [];

    if (data.title !== currentData.group_name) {
      const res = updateMentorshipGroupTitle({
        groupid: data.id,
        title: data.title,
      });

      promises.push(res);
    }

    if (data.description !== currentData.description) {
      const res = updateMentorshipGroupDescription({
        groupid: data.id,
        description: data.description,
      });

      promises.push(res);
    }

    const result = await Promise.all(promises);

    // if success on all
    if (result.every((res) => !!res)) {
      // get meeting data afresh // no need because group is not cached atm.
      // await context.dispatch('getCompanyMentorshipGroup', data.id);

      return true;
    }

    return false;
  },
  async getCompanyMentorshipGroups(context, companyId) {
    const companyid = companyId || context.rootState.User.companyid;
    if (!companyid) return false;

    const request = await apiPost('company_group', generateFormData({ companyid }), 61)
      .catch((err) => {
        console.warn('getCompanyMentorshipGroups::company_group err', err);
        return false;
      })
      .then(async (res) => {
        if (!res) return false;
        if (toBoolean(res.data.error)) return false;

        const mentorshipGroups = res.data.company_group || [];

        const goals = await context.dispatch('getGoals', [companyid]);

        mentorshipGroups.map((group) => {
          // eslint-disable-next-line no-param-reassign
          group.goal = goals.find((goal) => Number(goal.id) === Number(group.goalid));

          return group;
        });

        return mentorshipGroups;
      });
    //

    return request;
  },
  async getCompanyMentorshipGroup(context, mentorshipGroupId) {
    const request = await apiPost('groups_links', generateFormData({ groupid: mentorshipGroupId }), 59)
      .catch((err) => {
        console.warn('getCompanyMentorshipGroup::group_links err', err);
        return false;
      })
      .then(async (res) => {
        if (!res) return false;
        if (toBoolean(res.data.error)) return false;

        const mentorshipGroup = (res.data.group_links || [])[0] || {};

        if (mentorshipGroup.goalid) {
          const goals = await context.dispatch('getGoals', [mentorshipGroup.companyid]);
          mentorshipGroup.goal = goals.find((goal) => Number(goal.id) === Number(mentorshipGroup.goalid));
        }

        return mentorshipGroup;
      });
    //

    return request;
  },
  // ! [DEPR] NOT USED
  async saveMentorshipGroupParticipants(context, { companyId, groupId, participantsIds }) {
    // get current participants
    /** @type {Array} */
    const currentParticipants = await context.dispatch('getMentorshipGroupParticipants', {
      groupId,
      companyId,
    });

    participantsIds.forEach((participantId) => {
      const foundInCurrent = currentParticipants.find((participant) => Number(participant.id) === Number(participantId));

      // if already exist, skip
      if (foundInCurrent) return;

      // new, so add
      addParticipantToMentorshipGroup({
        groupid: groupId,
        userid: participantId,
      });
    });

    currentParticipants.forEach((currentParticipant) => {
      const foundInNew = participantsIds.find((participantId) => Number(participantId) === Number(currentParticipant.id));

      // if already exist, skip
      if (foundInNew) return;

      // if not exist in new list, remove
      removeParticipantfromMentorshipGroup({
        groupid: groupId,
        userid: currentParticipant.id,
        reason: '',
      });
    });
  },
  async addMentorshipGroupParticipants(context, { companyId, groupId, participantsIds }) {
    // get current participants
    /** @type {Array} */
    const currentParticipants = await context.dispatch('getMentorshipGroupParticipants', {
      groupId,
      companyId,
    });

    participantsIds.forEach((participantId) => {
      const foundInCurrent = currentParticipants.find((participant) => Number(participant.id) === Number(participantId));

      // if already exist, skip
      if (foundInCurrent) return;

      // new, so add
      addParticipantToMentorshipGroup({
        groupid: groupId,
        userid: participantId,
      });
    });
  },
  async removeMentorshipGroupParticipants(context, { companyId, groupId, participantsIds }) {
    // get current participants
    /** @type {Array} */
    const currentParticipants = await context.dispatch('getMentorshipGroupParticipants', {
      groupId,
      companyId,
    });

    participantsIds.forEach((participantId) => {
      const foundInCurrent = currentParticipants.find((participant) => Number(participant.id) === Number(participantId));

      // if NOT found in current, no need to remove, skip
      if (!foundInCurrent) return;

      // if not exist in new list, remove
      removeParticipantfromMentorshipGroup({
        groupid: groupId,
        userid: participantId,
        reason: '',
      });
    });
  },
  async getMentorshipGroupParticipants(context, data) {
    const companyid = data.companyId || context.rootState.User.companyid;

    const formData = generateFormData({
      companyid,
      groupid: data.groupId,
    });

    const request = await apiPost('company_participants', formData, 61)
      .catch((err) => {
        console.warn('getMentorshipGroupParticipants::company_participants err', err);
        return false;
      })
      .then(async (res) => {
        if (!res) return false;
        if (toBoolean(res.data.error)) return false;

        /** @type {Array} */
        const participants = res.data.company_participants || [];

        // get users' data
        const promises = [];

        // cache data first
        await Promise.allSettled([
          apiGet('gender', 5),
          apiGet('countries', 5),
          apiGet('county', 5),
          apiGet('profession', 5),
          apiGet('experience', 5),
          apiGet('availability', 5),
          apiGet('group_goals', 5),
          context.dispatch('companyRegions', companyid),
        ]);

        participants.forEach((participant) => {
          const prom = context.dispatch('getUserData', participant.userid);

          promises.push(prom);
        });

        const users = await Promise.all(promises);
        // console.log('participants::', users);

        return users;
      });
    //

    return request;
  },
  async deactivateMentorshipGroup(context, data) {
    const request = await apiPost('dev_grp', generateFormData(data), 59)
      .catch((err) => {
        console.warn('deactivateMentorshipGroup::dev_grp err', err);
        return false;
      })
      .then(async (res) => {
        if (!res) return false;
        if (toBoolean(res.data.error)) return false;

        return true;
      });
    //

    return request;
  },
  async activateMentorshipGroup(context, data) {
    const request = await apiPost('act_grp', generateFormData(data), 59)
      .catch((err) => {
        console.warn('activateMentorshipGroup::act_grp err', err);
        return false;
      })
      .then(async (res) => {
        if (!res) return false;
        if (toBoolean(res.data.error)) return false;

        return true;
      });
    //

    return request;
  },
  async endMentorshipGroup(context, groupid) {
    const request = await apiPost('end_grp', generateFormData({ groupid }), 61)
      .catch((err) => {
        console.warn('endMentorshipGroup::end_grp err', err);
        return false;
      })
      .then(async (res) => {
        if (!res) return false;
        if (toBoolean(res.data.error)) return false;

        return true;
      });
    //

    return request;
  },
};
