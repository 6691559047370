import resolve from '../../../modules/api/resolve';
import { generateFormData, apiPost, apiStore } from '../../../modules/apiHelper';
import {
  invalideDate, isStaleData, objectIsValid, urlFriendly,
} from '../../../modules/dataHelper';

export default {
  async companyLogo(context, [CompanyId, refresh = false]) {
    const companyid = CompanyId || context.rootState.User.companyid;
    if (!companyid) return false;

    const company = await apiStore.getItem(`company/${companyid}`) || [invalideDate, []];

    if (!refresh && !isStaleData(company[0]) && objectIsValid(company[1])) return company[1].logo;

    // get company
    const response = (context.rootState.User.type === context.rootGetters.adminUserTypes.superAdmin)
      ? context.dispatch('getCompany', [this.id, true])
      : context.dispatch('myCompany', true);

    const result = await response;
    return result.logo;
  },

  async addCompanyLogo(context, [CompanyId, file1]) {
    const companyid = CompanyId || context.rootState.User.companyid;
    const userid = context.rootState.User.id;

    if (!file1 || !companyid || !userid) return false;

    const formData = generateFormData({
      userid,
      companyid,
      name: file1.name,
    });
    formData.append('file1', file1, urlFriendly(file1.name));

    const result = await resolve(apiPost('add_logo', formData, 3));

    return result;
  },
};
