/* eslint-disable max-len */
import localforage from 'localforage';
import {
  isStaleData, now, toBoolean,
} from './dataHelper';
import xhrRequest from './xhr';

/**
 *
 * @param {Boolean} isProduction ask (if url is a production url, parse true)
 * @returns {Boolean}
 */
export function whichBuild(isProduction = true) {
  const url = window.location.hostname;
  const regexTest = new RegExp(isProduction ? process.env.VUE_APP_SCOPE_URL : process.env.VUE_APP_DEV_APP_URL);

  return !!(regexTest.test(url));
}

export const apiBaseUrl = process.env.VUE_APP_API_BASE_URL;
export const apiUrl = `${apiBaseUrl}${whichBuild(true) ? '' : '/test'}`;

export const apiTsdUrl = `${apiUrl}/tsd/`;
export const apiTslUrl = `${apiUrl}/tsl/`;
export const apiTslAccUrl = `${apiUrl}/tsl_account/`;
export const apiTslMssgUrl = `${apiUrl}/tsl_mssg/`;
export const apiTslOptUrl = `${apiUrl}/tsl_opt/`;
export const apiTslStatsUrl = `${apiUrl}/tsl_stats/`;
export const apiTslSettingsUrl = `${apiUrl}/tsl_settings/`;
export const apiProcessUrl = `${apiUrl}/process/`;
export const apiTstUrl = `${apiUrl}/tst/`;
export const apiTsvUrl = `${apiUrl}/tsv/`;
export const apiTaskUrl = `${apiUrl}/task/`;
export const apiChatUrl = `${apiUrl}/chat/`;
export const apiCreateUrl = `${apiUrl}/create/`;
export const apiSchlUrl = `${apiUrl}/schl/`;
export const apiPackUrl = `${apiUrl}/pack/`;
export const apiTslKenPemUrl = `${apiUrl}/tsl_ken_pem/`;
export const apiTslKenPem1Url = `${apiUrl}/tsl_ken_pem1/`;
export const apiTslKops = `${apiUrl}/tsl_kops/`;
export const apiTslKenRsRc = `${apiUrl}/tsl_ken_rsrc/`;
export const apiTslKenRsq = `${apiUrl}/tsl_ken_rsq/`;
export const apiTslKenRs = `${apiUrl}/tsl_ken_rs/`;
export const apiTslKenSessionRange = `${apiUrl}/tsl_ken_session_range/`;
export const apiTslKenPemRange = `${apiUrl}/tsl_ken_pem_range/`;
export const apiTslKenPem1Range = `${apiUrl}/tsl_ken_pem1_range/`;
export const apiSms = `${apiUrl}/sms/`;
export const apiTslKenLinks = `${apiUrl}/tsl_ken_links/`;
export const apiTslKenLinksLogMentor = `${apiUrl}/tsl_ken_links_log_mentor/`;
export const apiTslKenLinksLogMentee = `${apiUrl}/tsl_ken_links_log_mentee/`;
export const apiTslKenLinksLog = `${apiUrl}/tsl_ken_links_log/`;
export const apiTslKenProd = `${apiUrl}/tsl_ken_prod/`;
export const apiTslKenSuvCrt = `${apiUrl}/tsl_ken_suv_crt/`;
export const apiTslKenSuvLog = `${apiUrl}/tsl_ken_suv_log/`;
export const apiTslKenSuv = `${apiUrl}/tsl_ken_suv/`;
export const apiTslKenSuvAct = `${apiUrl}/tsl_ken_suv_act/`;
export const apiTslKenPil = `${apiUrl}/tsl_ken_pil/`;
export const apiTslKop = `${apiUrl}/tsl_kop/`;
export const apiTslKenResourceADel = `${apiUrl}/tsl_ken_resource_a_del/`;
export const apiTslKenResourceCompLog = `${apiUrl}/tsl_ken_resource_comp_log/`;
export const apiTslKenMatch = `${apiUrl}/tsl_ken_match/`;
export const apiTslKenRegionD = `${apiUrl}/tsl_ken_region_d/`;
export const apiTslKenRegion = `${apiUrl}/tsl_ken_region/`;
export const apiTslKenRegionE = `${apiUrl}/tsl_ken_region_e/`;
export const apiTslKenRegionF = `${apiUrl}/tsl_ken_region_f/`;
export const apiSmsRegion = `${apiUrl}/sms_region/`;
export const apiTslKenRegionC = `${apiUrl}/tsl_ken_region_c/`;
export const apiTslKenRegionB = `${apiUrl}/tsl_ken_region_b/`;
export const apiTslKenLinksRegion = `${apiUrl}/tsl_ken_links_region/`;
export const apiTslKenRegionB1 = `${apiUrl}/tsl_ken_region_b_1/`;
export const apiTslKenRegionB2 = `${apiUrl}/tsl_ken_region_b_2/`;
export const apiTslKenRegionH = `${apiUrl}/tsl_ken_region_h/`;
export const apiTslKenStatsGoalLog = `${apiUrl}/tsl_ken_stats_goal_log/`;
export const apiTslKenRegionI = `${apiUrl}/tsl_ken_region_i/`;
export const apiTslKenRegionK = `${apiUrl}/tsl_ken_region_k/`;
export const apiTslKenMatchSelA = `${apiUrl}/tsl_ken_match_sel_a/`;
export const apiTslKenMatchSel = `${apiUrl}/tsl_ken_match_sel/`;
export const apiTslKenRegionJ = `${apiUrl}/tsl_ken_region_j/`;
export const apiTslKenResource = `${apiUrl}/tsl_ken_resource/`;
export const apiTslKenNilTskUser = `${apiUrl}/tsl_ken_nil_tsk_usr/`;
export const apiTslKenGrp = `${apiUrl}/tsl_ken_grp/`;
export const apiTslKenGrpA = `${apiUrl}/tsl_ken_grp_a/`;
export const apiTslKenGrpB = `${apiUrl}/tsl_ken_grp_b/`;
export const apiTslKenMt = `${apiUrl}/tsl_ken_mt/`;
export const apiTslKenMntA = `${apiUrl}/tsl_ken_mnt_a/`;
export const apiTslKenMntB = `${apiUrl}/tsl_ken_mnt_b/`;
export const apiTslKenMntC = `${apiUrl}/tsl_ken_mnt_c/`;
export const apiTslKenGrpC = `${apiUrl}/tsl_ken_grp_c/`;
export const apiTslKenGrpT = `${apiUrl}/tsl_ken_grp_t/`;
export const apiTslKenGrpD = `${apiUrl}/tsl_ken_grp_d/`;
export const apiTslKenGrpChatGroup = `${apiUrl}/tsl_ken_grp_chat_group/`;
export const apiTslKenGrpU = `${apiUrl}/tsl_ken_grp_u/`;
export const apiTslKenJan = `${apiUrl}/tsl_ken_jan/`;
export const apiTslKenJanB = `${apiUrl}/tsl_ken_jan_b/`;
export const apiTslKenJanA = `${apiUrl}/tsl_ken_jan_a/`;
export const apiTslKenGoal = `${apiUrl}/tsl_ken_goal/`;
export const apiTslFelCrt = `${apiUrl}/tsl_fel_crt/`;
export const apiTslFelQ = `${apiUrl}/tsl_fel_q/`;
export const apiTslFelMt = `${apiUrl}/tsl_fel_mt/`;
export const apiTslFelCrtSwtch = `${apiUrl}/tsl_fel_crt_swtch`;

export const apiStore = localforage.createInstance({
  driver: [
    localforage.INDEXEDDB,
    localforage.WEBSQL,
    localforage.LOCALSTORAGE,
  ],
  name: 'ema-app',
  storeName: 'highdata',
  version: 3,
});

export function getlocalStoreArrayItem(key) {
  return apiStore.getItem(key).then((value) => JSON.parse(value));
}

/**
 * generate api endpoint url
 * @param {String} endpoint [tsd, tst, task, _custom_, chat, process, tsl, tsl_account, tsl_mssg, tsl_opt, tsl_stats, tsv, create, tsl_settings, schl, pack, tsl_ken_pem,tsl_ken_pem1, tsl_kops, tsl_ken_rsrc, tsl_ken_rsq, tsl_ken_rs, tsl_ken_session_range, tsl_ken_pem_range, tsl_ken_pem1_range, sms, tsl_ken_links, tsl_ken_links_log_mentor, tsl_ken_links_log_mentee, tsl_ken_links_log, tsl_ken_prod, tsl_ken_suv_crt, tsl_ken_suv_log, tsl_ken_suv, tsl_ken_suv_act, tsl_ken_pil, tsl_kop, tsl_ken_resource_a_del, tsl_ken_resource_comp_log, tsl_ken_match, tsl_ken_region_d, tsl_ken_region, tsl_ken_region_e, tsl_ken_region_f, sms_region,tsl_ken_region_c, tsl_ken_region_b, tsl_ken_links_region,tsl_ken_region_b_1,tsl_ken_region_b_2, tsl_ken_region_h, tsl_ken_stats_goal_log, tsl_ken_region_i, tsl_ken_region_k, tsl_ken_match_sel_a, tsl_ken_match_sel, tsl_ken_region_j, tsl_ken_resource, tsl_ken_nil_tsk_usr, tsl_ken_grp, tsl_ken_grp_a, tsl_ken_grp_b, tsl_ken_mt, tsl_ken_mnt_a, tsl_ken_mnt_b, tsl_ken_mnt_c, tsl_ken_grp_c, tsl_ken_grp_t, tsl_ken_grp_d, tsl_ken_grp_chat_group, tsl_ken_grp_u, tsl_ken_jan, tsl_ken_jan_b, tsl_ken_jan_a, tsl_ken_goal] default: tsd
 * @param {String} action
 */
export function buildApiEndpoint(action, endpoint) {
  switch (endpoint) {
    case 0:
      return `${apiTsdUrl}?action=${action}`;
    case 1:
      return `${apiTstUrl}?action=${action}`;
    case 2:
      return `${apiTaskUrl}?action=${action}`;
    case 3:
      return `${apiUrl}/${action}/`;
    case 4:
      return `${apiChatUrl}?action=${action}`;
    case 5:
      return `${apiProcessUrl}?action=${action}`;
    case 6:
      return `${apiTslUrl}?action=${action}`;
    case 7:
      return `${apiTslAccUrl}?action=${action}`;
    case 8:
      return `${apiTslMssgUrl}?action=${action}`;
    case 9:
      return `${apiTslOptUrl}?action=${action}`;
    case 10:
      return `${apiTslStatsUrl}?action=${action}`;
    case 11:
      return `${apiTsvUrl}?action=${action}`;
    case 12:
      return `${apiCreateUrl}?action=${action}`;
    case 13:
      return `${apiTslSettingsUrl}?action=${action}`;
    case 14:
      return `${apiSchlUrl}?action=${action}`;
    case 15:
      return `${apiPackUrl}?action=${action}`;
    case 16:
      return `${apiTslKenPemUrl}?action=${action}`;
    case 17:
      return `${apiTslKenPem1Url}?action=${action}`;
    case 18:
      return `${apiTslKops}?action=${action}`;
    case 19:
      return `${apiTslKenRsRc}?action=${action}`;
    case 20:
      return `${apiTslKenRsq}?action=${action}`;
    case 21:
      return `${apiTslKenRs}?action=${action}`;
    case 22:
      return `${apiTslKenSessionRange}?action=${action}`;
    case 23:
      return `${apiTslKenPemRange}?action=${action}`;
    case 24:
      return `${apiTslKenPem1Range}?action=${action}`;
    case 25:
      return `${apiSms}?action=${action}`;
    case 26:
      return `${apiTslKenLinks}?action=${action}`;
    case 27:
      return `${apiTslKenLinksLogMentor}?action=${action}`;
    case 28:
      return `${apiTslKenLinksLogMentee}?action=${action}`;
    case 29:
      return `${apiTslKenLinksLog}?action=${action}`;
    case 30:
      return `${apiTslKenProd}?action=${action}`;
    case 31:
      return `${apiTslKenSuvCrt}?action=${action}`;
    case 32:
      return `${apiTslKenSuvLog}?action=${action}`;
    case 33:
      return `${apiTslKenSuv}?action=${action}`;
    case 34:
      return `${apiTslKenSuvAct}?action=${action}`;
    case 35:
      return `${apiTslKenPil}?action=${action}`;
    case 36:
      return `${apiTslKop}?action=${action}`;
    case 37:
      return `${apiTslKenResourceADel}?action=${action}`;
    case 38:
      return `${apiTslKenResourceCompLog}?action=${action}`;
    case 39:
      return `${apiTslKenMatch}?action=${action}`;
    case 40:
      return `${apiTslKenRegionD}?action=${action}`;
    case 41:
      return `${apiTslKenRegion}?action=${action}`;
    case 42:
      return `${apiTslKenRegionE}?action=${action}`;
    case 43:
      return `${apiTslKenRegionF}?action=${action}`;
    case 44:
      return `${apiSmsRegion}?action=${action}`;
    case 45:
      return `${apiTslKenRegionC}?action=${action}`;
    case 46:
      return `${apiTslKenRegionB}?action=${action}`;
    case 47:
      return `${apiTslKenLinksRegion}?action=${action}`;
    case 48:
      return `${apiTslKenRegionB1}?action=${action}`;
    case 49:
      return `${apiTslKenRegionB2}?action=${action}`;
    case 50:
      return `${apiTslKenRegionH}?action=${action}`;
    case 51:
      return `${apiTslKenStatsGoalLog}?action=${action}`;
    case 52:
      return `${apiTslKenRegionI}?action=${action}`;
    case 53:
      return `${apiTslKenRegionK}?action=${action}`;
    case 54:
      return `${apiTslKenMatchSelA}?action=${action}`;
    case 55:
      return `${apiTslKenMatchSel}?action=${action}`;
    case 56:
      return `${apiTslKenRegionJ}?action=${action}`;
    case 57:
      return `${apiTslKenResource}?action=${action}`;
    case 58:
      return `${apiTslKenNilTskUser}?action=${action}`;
    case 59:
      return `${apiTslKenGrp}?action=${action}`;
    case 60:
      return `${apiTslKenGrpA}?action=${action}`;
    case 61:
      return `${apiTslKenGrpB}?action=${action}`;
    case 62:
      return `${apiTslKenMt}?action=${action}`;
    case 63:
      return `${apiTslKenMntA}?action=${action}`;
    case 64:
      return `${apiTslKenMntB}?action=${action}`;
    case 65:
      return `${apiTslKenMntC}?action=${action}`;
    case 66:
      return `${apiTslKenGrpC}?action=${action}`;
    case 67:
      return `${apiTslKenGrpT}?action=${action}`;
    case 68:
      return `${apiTslKenGrpD}?action=${action}`;
    case 69:
      return `${apiTslKenGrpChatGroup}?action=${action}`;
    case 70:
      return `${apiTslKenGrpU}?action=${action}`;
    case 71:
      return `${apiTslKenJan}?action=${action}`;
    case 72:
      return `${apiTslKenJanB}?action=${action}`;
    case 73:
      return `${apiTslKenJanA}?action=${action}`;
    case 74:
      return `${apiTslKenGoal}?action=${action}`;
    case 75:
      return `${apiTslFelCrt}?action=${action}`;
    case 76:
      return `${apiTslFelQ}?action=${action}`;
    case 77:
      return `${apiTslFelMt}?action=${action}`;
    case 78:
      return `${apiTslFelCrtSwtch}?action=${action}`;
    default:
      return `${apiTsdUrl}?action=${action}`;
  }
}

/**
 * #### make an ajax GET request (uses xhr.js)
 * @param {String} actionParam api action name
 * @param {Number} endpoint [tsd, tst, task, _custom_, chat, process, tsl, tsl_account, tsl_mssg, tsl_opt, tsl_stats, tsv, create, tsl_settings, schl, pack, tsl_ken_pem, tsl_ken_pem1, tsl_kops, tsl_ken_rsrc, tsl_ken_rsq, tsl_ken_rs, tsl_ken_session_range, tsl_ken_pem_range, tsl_ken_pem1_range, sms, tsl_ken_links, tsl_ken_links_log_mentor, tsl_ken_links_log_mentee, tsl_ken_links_log, tsl_ken_prod, tsl_ken_suv_crt, tsl_ken_suv_log, tsl_ken_suv, tsl_ken_suv_act, tsl_ken_pil, tsl_kop, tsl_ken_resource_a_del, tsl_ken_resource_comp_log, tsl_ken_match, tsl_ken_region_d, tsl_ken_region, tsl_ken_region_e, tsl_ken_region_f, sms_region, tsl_ken_region_c, tsl_ken_region_b, tsl_ken_links_region,tsl_ken_region_b_1,tsl_ken_region_b_2, tsl_ken_region_h, tsl_ken_stats_goal_log, tsl_ken_region_i, tsl_ken_region_k, tsl_ken_match_sel_a, tsl_ken_match_sel, tsl_ken_region_j, tsl_ken_resource, tsl_ken_nil_tsk_usr, tsl_ken_grp, tsl_ken_grp_a, tsl_ken_grp_b, tsl_ken_mt, tsl_ken_mnt_a, tsl_ken_mnt_b, tsl_ken_mnt_c, tsl_ken_grp_c, tsl_ken_grp_t, tsl_ken_grp_d,tsl_ken_grp_chat_group, tsl_ken_grp_u, tsl_ken_jan, tsl_ken_jan_b, tsl_ken_jan_a, tsl_ken_goal] default: tsd
 * @param {Object} extraParam request queries {key: value} -> ?[key]=[value]&...
 * @param {Boolean} refresh whether to bypass cached results/fetch from server default: false
 * @param {Boolean} cacheData whether to cache the data default: true
 */
// eslint-disable-next-line consistent-return
export async function apiGet(
  actionParam = '',
  endpoint = 0,
  extraParam = {},
  refresh = false,
  cacheData = true,
) {
  try {
    const storeName = `${endpoint}/${actionParam}/${JSON.stringify(extraParam)
      .slice(0, 1)
      .slice(-1, -2)
      .replace('-', '_')
      .replace(' ', '_')}`;
    const storeData = (await apiStore.getItem(storeName) || undefined);

    if (Array.isArray(storeData) && !refresh) {
      if (!isStaleData(storeData[0])) return storeData[1];
    } else {
      // clean api cache
      await apiStore.removeItem(storeName);
    }

    const url = buildApiEndpoint(actionParam, endpoint);
    if (!url) throw new Error('BAD GET API URL');

    return new Promise((resolve, reject) => {
      xhrRequest({
        url,
        method: 'GET',
        params: {
          ...extraParam,
        },
      }).catch((err) => {
        console.warn('AJAX GET ERROR: ', actionParam);
        reject(err);
      }).then(async (res) => {
        // validate and save the data
        if (typeof res === 'object') {
          // eslint-disable-next-line no-param-reassign
          res.data.error = toBoolean(res.data.error);
          // eslint-disable-next-line no-param-reassign
          delete res.config;
          // eslint-disable-next-line no-param-reassign
          delete res.request;

          if (cacheData) {
            await apiStore.setItem(storeName, [now(), res]);
          }
        }

        resolve(res);
      });
    });
  } catch (err) {
    console.warn(err);
  }
}

/**
 * Make an ajax POST request. (uses .xhr.js)
 * @param {String} action api action name
 * @param {FormData} data
 * @param {Number} endpoint [tsd, tst, task, _custom_, chat, process, tsl, tsl_account, tsl_mssg, tsl_opt, tsl_stats, tsv, create, tsl_settings, schl, pack, tsl_ken_pem, tsl_ken_pem1, tsl_kops, tsl_ken_rsrc, tsl_ken_rsq, tsl_ken_rs, tsl_ken_session_range, tsl_ken_pem_range, tsl_ken_pem1_range, sms, tsl_ken_links, tsl_ken_links_log_mentor, tsl_ken_links_log_mentee, tsl_ken_links_log, tsl_ken_prod, tsl_ken_suv_crt, tsl_ken_suv_log, tsl_ken_suv,tsl_ken_suv_act, tsl_ken_pil, tsl_kop, tsl_ken_resource_a_del, tsl_ken_resource_comp_log, tsl_ken_match, tsl_ken_region_d, tsl_ken_region, tsl_ken_region_e, tsl_ken_region_f, sms_region, tsl_ken_region_c, tsl_ken_region_b, tsl_ken_links_region, tsl_ken_region_b_1,tsl_ken_region_b_2, tsl_ken_region_h, tsl_ken_stats_goal_log, tsl_ken_region_i, tsl_ken_region_k, tsl_ken_match_sel_a, tsl_ken_match_sel, tsl_ken_region_j, tsl_ken_resource, tsl_ken_nil_tsk_usr, tsl_ken_grp, tsl_ken_grp_a, tsl_ken_grp_b, tsl_ken_mt, tsl_ken_mnt_a, tsl_ken_mnt_b, tsl_ken_mnt_c, tsl_ken_grp_c, tsl_ken_grp_t, tsl_ken_grp_d, tsl_ken_grp_chat_group, tsl_ken_grp_u, tsl_ken_jan, tsl_ken_jan_b, tsl_ken_jan_a, tsl_ken_goal] default: tsd
 * @param {Object} headers
 * @param {Function} progressCallback xhr.onprogress event handler
 */
// eslint-disable-next-line consistent-return
export async function apiPost(
  action,
  data,
  endpoint = 0,
  headers = { 'Content-Type': 'multipart/form-data' },
  progressCallback = undefined,
) {
  if (!action || !data) {
    console.error('action or data not set');
    return false;
  }

  try {
    const url = buildApiEndpoint(action, endpoint);

    if (!url) throw new Error('BAD POST API URL', url, action, '\n');

    return xhrRequest({
      url,
      method: 'POST',
      data,
      headers,
      onUploadProgress: progressCallback,
    }).catch(() => {
      console.warn('AJAX POST ERROR: ', action);
    }).then((res) => {
      if (typeof res === 'object') {
        // make 'error' property a real boolean
        // eslint-disable-next-line no-param-reassign
        res.data.error = toBoolean(res.data.error);
      }

      return res;
    });
  } catch (err) {
    console.warn(err);
  }
}

/**
 * Does not support files YET
*/
export function generateFormData(obj = {}) {
  const formData = new FormData();

  Object.entries(obj).forEach(([index, value]) => {
    if (typeof value !== 'undefined' || value !== 'null') {
      formData.set(index, value);
    }
  });

  return formData;
}
