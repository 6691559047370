import userdata from './userdata';
import auth from './auth';
// import match from './match';
// import tasks from './tasks';
// import resources from './resources';

export default {
  ...auth,
  ...userdata,
  // ...match,
  // ...tasks,
  // ...resources,
};
