<template>
  <div
    :class="[
      'tw-inline-block tw-relative tw-cursor-pointer tw-flex-shrink-0',
      (photoSizeClass.length ? photoSizeClass : 'w-120 h-120')
    ]"
  >
    <!-- badge -->
    <div :class="['tw-rounded-full tw-absolute tw-p-1 tw-z-40', badgeClass]">
      <slot name="badge" />
    </div>

    <div
      :class="[
        'tw-h-full tw-w-full tw-relative tw-z-10',
        {'tw-bg-gray-500-- hover:tw-bg-gray-500' : isValidPhoto},
        {'tw-bg-app-black-11' : !isValidPhoto},
        {'opacity-11' : loadingPhoto},
        {'tw-overflow-hidden': isValidPhoto && !loadingPhoto},
      ]"
      @click="promptUpdatePhoto"
    >
      <!-- clickable element / input -->
      <input
        class="tw-hidden"
        type="file"
        ref="photo-input"
        accept="image/*"
        @change="addPhoto"
      />

      <!-- overlay -->
      <div class="tw-h-full tw-w-full tw-absolute tw-left-0 tw-top-0 tw-z-40 hover:tw-bg-transparent tw-bg-app-black-11" v-if="overlay">
        <slot name="overlay">
          <span
            style="transform: translateX(-50%) translateY(-50%); top: 50%;"
            v-if="!isValidPhoto"
            class="tw-z-10 tw-text-xs leading-17 tw-absolute tw-left-1/2 tw-text-center tw-w-full tw-px-4 tw-font-bold font-title tw-text-white tw-capitalize"
          >
            upload
          </span>
        </slot>
      </div>

      <!-- image -->
      <img
        v-if="isValidPhoto && !loadingPhoto"
        :src="form.photo || userPhoto(photo)"
        @onload="loadingPhoto = false"
        :class="['tw-w-full tw-h-full tw-absolute tw-left-0 tw-top-0 tw-object-contain tw-bg-transparent tw-rounded-3 tw-z-20', bgImageClass]"
      />
    </div>

  </div>
</template>

<script>

import { maxFileSize } from '@/modules/misc';
import { getPhoto } from '@/modules/dataHelper';

export default {
  name: 'PhotoInput',
  props: {
    photo: {
      type: String,
      default: '',
    },
    photoSizeClass: {
      type: String,
      default: '',
    },
    overlay: {
      type: Boolean,
      default: true,
    },
    badgeClass: {
      type: String,
      default: '',
    },
    bgImageClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loadingPhoto: true,
      edit: false,
      form: {
        photo: '',
        photoFile: undefined,
      },
    };
  },
  watch: {
    // eslint-disable-next-line func-names
    'form.photo': function (val = '') {
      if (val.length > 0) {
        this.loadingPhoto = false;
      }
    },
    // eslint-disable-next-line func-names
    'form.photoFile': async function (val) {
      if (!val) return;

      this.loadingPhoto = true;

      await getPhoto(val, async (event) => {
        this.form.photo = event.target.result;

        this.loadingPhoto = true;
        this.$emit('previewing', this.form.photo);
        // console.log(r);
        this.loadingPhoto = false;
      });
    },
  },
  computed: {
    isValidPhoto() {
      return this.photo.length || this.form.photo.length;
    },
  },
  methods: {
    addPhoto() {
      [this.form.photoFile] = this.$refs['photo-input'].files;

      if (!this.form.photoFile) return;

      if (this.form.photoFile.size > maxFileSize) {
        this.form.photoFile = undefined;

        this.$toasted.info('Please upload a photo 8MB maximum', {
          duration: 4000,
          position: 'top-right',
        });
      }

      this.$emit('change', this.form.photoFile);
    },
    promptUpdatePhoto() {
      this.$refs['photo-input'].click();
      this.$emit('click');
    },
  },
  async created() {
    if (this.isValidPhoto) {
      this.loadingPhoto = false;
    }

    // wait for valid photo to load
    setTimeout(() => {
      if (!this.isValidPhoto) {
        this.loadingPhoto = false;
      }
    }, 5000);
  },
};
</script>

<style>

</style>
