<template>
  <form
    id="journey-action-form"
    ref="journey-action-form"
    class="tw-max-w-full tw-flex-shrink-0 tw-mx-auto tw-mb-20 auth-card tw-flex tw-flex-wrap"
    method="post"
    action="/journey-action/edit"
    @submit.prevent="onSubmit"
  >

    <div class="tw-w-full tw-mb-4">
      <h1 class="tw-text-4xl font-title">{{ journeyActionId ? 'Edit Journey Action' : 'Create a Journey Action' }}</h1>
      <!-- <h6 class="tw-text-base opacity-78 tw-text-app-deep-blue">Please fill all inputs</h6> -->
    </div>

    <div class="tw-w-full tw-mb-4 tw-flex-shrink-0 tw-px-2 tw-bg-gray-200 tw-rounded-10 tw-py-3">
      <!-- <SelectGroup
        :class="{ '--loading-data': loadingForm }"
        label="Action"
        name="action"
        nameKey="title"
        placeholder="Action"
        input-classes="my-select--huge tw-bg-gray-200 tw-text-left"
        v-model="form.title"
        :options="actionsList"
        :reduce="action => action.action"
        :clearable="false"
        :error="errors.title"
      /> -->
      <p class="tw-text-xs tw-uppercase opacity-54">
        Selected Action
      </p>
      <p class="tw-font-bold">
        {{selectedActionTitle}}
      </p>
    </div>
    <!-- <div class="tw-w-full tw-mb-4 tw-flex-shrink-0 tw-px-1">
      <InputGroup
        :class="{ '--loading-data': loadingForm }"
        label="Name"
        name="name"
        type="text"
        placeholder="name"
        input-classes="tw-p-3"
        input-type=""
        v-model="form.title"
        :error="errors.title"
      />
    </div> -->
    <div class="tw-w-full tw-mb-4 tw-flex-shrink-0 tw-px-1">
      <!-- SELECT ACTION IF ACTIONID IS NOT SET -->
      <SelectGroup
        :class="{ '--loading-data': loadingForm }"
        label="User Type"
        name="userType"
        nameKey="label"
        placeholder="User Type"
        input-classes="my-select--huge tw-bg-gray-200 tw-text-left"
        v-model="form.type"
        :options="accountTypesOptions"
        :reduce="type => type.id"
        :clearable="false"
        :error="errors.type"
      />
    </div>
    <div class="tw-w-full tw-mb-4 tw-flex-shrink-0 tw-px-1">
      <div :class="['tw-mb-2 tw-block', { '--loading-data': loadingForm }]">
        <slot name="input-label">
          <label class="tw-block tw-text-sm tw-mb-2 opacity-78 tw-text-left" for="badge" aria-label="badge">
            Badge
          </label>
        </slot>
        <label class="tw-block tw-text-left tw-text-xs tw-mb-2 tw--mt-3 opacity-54">
          Search for a badge
        </label>

        <v-select
          :class="['my-select tw-w-full transition', 'my-select--huge tw-bg-gray-200 tw-text-left']"
          placeholder=""
          v-model="form.badgeId"
          label="badge_name"
          :options="badgesList"
          :reduce="badge => badge.id"
          :multiple="false"
          :clearable="true"
          :filterable="true"
          :searchable="true"
        >
          <template v-slot:option="option">
            <div class="tw-flex tw-justify-between tw-items-center">
              <span>{{option.badge_name}}</span>
              <span>
                <UserPhoto :photo="option.url" photo-size="tw-w-12 tw-h-12" />
              </span>
            </div>
          </template>
        </v-select>
        <slot name="error-message">
          <p class="tw-text-red-500 tw-text-left tw-text-xs tw-italic" v-if="errors.badgeId"> {{ errors.badgeId }} </p>
        </slot>
      </div>
    </div>

    <div class="tw-w-full tw-mb-4 tw-flex-shrink-0 tw-px-1">
      <InputGroup
        :class="{ '--loading-data': loadingForm }"
        label="Tip"
        instruct="Add a message if this action is a tip"
        name="tip"
        type="text"
        placeholder="tip"
        input-classes="tw-p-3"
        input-type=""
        v-model="form.tip"
        :error="errors.tip"
      />
    </div>

    <div class="tw-w-full tw-flex-shrink-0 tw-px-1 tw-mb-4 tw-pt-10 tw-flex tw-justify-between">
      <div>
        <BaseButton
          type="submit"
          :class="[
            'tw-block tw-w-full md:tw-inline-block md:tw-w-auto tw-mb-4 tw-py-3 tw-px-8',
            `${btnClass}`
          ]"
          :text="btnText"
          :disabled="!canSubmit || btnDisabled"
        />
      </div>

      <!-- <div v-if="journeyActionId">
        <BaseButton
          class="tw-bg-red-100 opacity-54 hover:tw-opacity-100 tw-border-red-600 tw-text-red-600 tw-inline-block tw-mb-4 tw-py-3 transition-fast"
          text="Delete"
          confirm
          @click="deleteGoal()"
        />
      </div> -->
    </div>

  </form>
</template>

<script>
import _debounce from 'lodash/debounce';

import InputGroup from '@/components/InputGroup.vue';
import SelectGroup from '@/components/SelectGroup.vue';
import UserPhoto from '@/components/UserPhoto.vue';

import form from '@/modules/formHelper';

export default {
  name: 'EditJourneyAction',
  components: {
    InputGroup,
    SelectGroup,
    UserPhoto,
  },
  props: {
    company: {
      type: Object,
      required: true,
    },
    position: {
      type: [String, Number],
      required: true,
    },
    action: {
      type: [String, Number],
    },
    journeyActionId: {
      type: [String, Number],
      default: undefined,
    },
    journeyId: {
      type: [String, Number],
      required: true,
    },
    btnClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loadingForm: true,
      errorForm: undefined,
      btnText: 'Save',
      btnDisabled: false,
      errors: {},
      form: {
        actionId: undefined,
        title: undefined,
        type: undefined,
        badgeId: undefined,
        tip: undefined,
      },
      badgesList: [],
    };
  },
  watch: {
    // eslint-disable-next-line func-names
    'company.id': function () {
      this.resetForm();
      this.loadForm();
    },
    journeyActionId() {
      this.resetForm();
      this.loadForm();
    },
  },
  computed: {
    actionsList() {
      return this.$store.state.CompanySettings.journeyActionsList;
    },
    canSubmit() {
      // return form.isValidTextInput(this.form.title)
      return !this.loadingForm
        // && this.form.actionId !== undefined
        && this.form.type !== undefined
        && (
          form.isValidTextInput(this.form.tip)
          || this.form.badgeId !== undefined
        );
    },
    accountTypesOptions() {
      return [
        {
          id: this.$store.state.User.accountTypes.mentor,
          label: 'Mentors',
        },
        {
          id: this.$store.state.User.accountTypes.mentee,
          label: 'Mentees',
        },
        // {
        //   id: 0,
        //   label: 'Both Mentors and Mentees',
        // },
      ];
    },
    selectedActionTitle() {
      return this.actionsList.find((action) => action.action === this.action)?.title || '';
    },
  },
  methods: {
    resetForm() {
      this.form = {
        actionId: undefined,
        title: undefined,
        type: undefined,
        badgeId: undefined,
        tip: undefined,
      };
    },
    // eslint-disable-next-line func-names
    loadForm: _debounce(async function () {
      this.loadingForm = true;
      this.errorForm = undefined;

      const badges = await this.$store.dispatch('getBadges', [this.company.id]);

      if (Array.isArray(badges)) {
        this.badgesList = badges;
      }

      // * get journey action details if id is defined
      if (this.journeyActionId !== undefined) {
        const action = (await this.$store.dispatch('getJourneyAction', [this.journeyActionId, this.company.id, false]) || []);

        this.form.title = action.title || '';
        this.form.type = action.type || undefined;
        this.form.actionId = action.action;
        this.form.badgeId = action.badgeid;
        this.form.tip = action.badge_type || '';
      }

      await this.$nextTick();
      await this.$nextTick();

      this.loadingForm = false;
    }, 300),
    async onSubmit() {
      if (!this.canSubmit) {
        this.$toasted.global.appError({
          errorMessage: 'You can\'t submit the form. Check for empty inputs',
        });

        console.warn('Cannot submit form... \n check for empty inputs');
        return false;
      }

      // signup!!
      this.btnDisabled = true;
      this.btnText = 'working...';

      const data = {
        ...(this.form),
        // action: this.form.actionId,
        badgeId: this.form.badgeId, // not touching because it works lol
        journeyId: this.journeyId,
        action: this.action,
        title: this.action,
        position: this.position,
        companyId: this.company.id,
      };

      const result = await this.$store.dispatch('saveJourneyAction', data);

      if (result) {
        this.$toasted.success('Saved!', { duration: 1000 });
        this.$emit('created-success');

        // TODO: LOG ACTION
        // if (this.journeyActionId) {
        //   this.$store.dispatch('logAction', this.$store.state.Logger.actionsIndexes.editGoal);
        // } else {
        //   this.$store.dispatch('logAction', this.$store.state.Logger.actionsIndexes.addGoal);
        // }
      } else {
        this.$toasted.global.appError({
          errorMessage: 'Saving journey action failed. Please try again later',
        });
      }

      this.btnDisabled = false;
      this.btnText = 'save';

      return result;
    },
  },
  async created() {
    this.errors = { ...this.form };

    await this.$nextTick();
    await this.loadForm();
    await this.$nextTick();

    this.loadingForm = false;
  },
};
</script>

<style>

</style>
