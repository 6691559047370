<template>
  <div>
    <h4 class="tw-text-2xl tw-mb-3 opacity-54">Documents and media Resources</h4>
    <!-- FILTER COMPONENT -->
    <BaseCard class="tw-flex tw-flex-no-wrap tw-py-2 tw-px-4 tw-items-center tw-border-t-2 tw-border-app-white-87 tw-transition-slow">
      <div class="tw-inline-flex tw-items-center">
        <!--  -->
        <svg version="1.1" class="tw-w-6 tw-h-6" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
          <path d="M32.2,72c-0.7,0-1.6-0.2-2.3-0.7c-1.1-0.7-1.8-2.1-1.8-3.4V43.7L7.8,5.7C7.2,4.3,7.4,3,8.1,1.8C8.7,0.7,9.9,0,11.5,0h49
            c1.4,0,2.7,0.7,3.4,1.8s0.9,2.5,0.5,3.9L45.7,43.7v17.8c0,1.6-0.9,3-2.3,3.6l-9.6,6.4C33.4,71.8,32.7,72,32.2,72z M12.2,4.6
            l20.1,37.4c0.2,0.7,0.5,1.1,0.5,1.8V67l8.4-5.7V43.5c0-0.5,0-1.1,0.2-1.6L59.6,4.3H12.2V4.6z"/>
        </svg>
        <p class="leading-19 tw-ml-2">Filter</p>
      </div>
      <BaseInput
        v-model="filterConfig.search"
        type="text"
        placeholder="Search by name of resource"
        input-class="tw-ml-8 tw-bg-app-deep-blue-11 tw-rounded-3 tw-text-sm leading-17 tw-p-2 tw-flex-grow tw-min-w-1/4"
      />
      <v-select
        placeholder="File Type"
        class="my-select tw-w-40 tw-ml-10 tw-bg-app-deep-blue-11 tw-border-b tw-border-black tw-text-black tw-rounded-t-3 tw-rounded-b-none tw-text-sm tw-flex-shrink-0"
        v-model="filterConfig.type"
        :options="filterConfig.options"
        clearable
      />
      <!-- <BaseButton
        text="Filter"
        class="tw-ml-8 tw-bg-app-black-11 hover:tw-bg-blue-200 hover:tw-shadow-app transition-fast tw-py-3 tw-px-10 tw-flex-shrink-0 tw-border-none tw-flex-grow-0"
        @click="doFilter()"
      /> -->
    </BaseCard>

    <!-- RESOURCES -->
    <transition name="fade" mode="out-in" appear>

      <div v-if="errorResources" class="tw-mt-12">
        <p class="tw-py-12 tw-text-base tw-text-center" v-html="errorResources"></p>
      </div>

      <ContentLoadingThreeGrid
        v-else-if="loadingResources"
        class="tw-mt-16 -tw-max-w-list tw-mx-auto"
      />

      <template v-else>

        <div v-if="resources.length === 0" class="tw-mt-12">
          <!-- Goodies -->
          <p class="tw-py-16 tw-text-base tw-text-center opacity-54">There are 0 document resources</p>
        </div>

        <div v-else-if="filteredResources.length === 0 && hasFiltered" class="tw-mt-12">
          <!-- Goodies -->
          <p class="tw-py-16 tw-text-base tw-text-center opacity-54">There are 0 {{ filterConfig.type }} resources</p>
        </div>

        <div v-else-if="filteredResources.length === 0" class="tw-mt-12">
          <!-- Goodies -->
          <p class="tw-py-16 tw-text-base tw-text-center opacity-54">Use the filter bar to search for resources!</p>
        </div>

        <ViewMore
          v-else
          class="tw-flex tw-flex-wrap stacked-grid lg:stacked-grid--stack-three stacked-grid--stack-three tw-mt-12"
          :list="filteredResources"
          :limit="6"
        >

          <template v-slot="{ item: resource, index }">
            <!-- RESOURCE CARD -->
            <ResourceCard
              :key="`res-${index}`"
              :resource="resourceCardData(resource)"
              @click-delete="(...args) => deleteResource(...args)"
            >
              <template v-slot:default v-if="resource.company && isSuperAdmin">
                <div v-if="resource.company" class="tw-my-1 tw-p-2 tw-bg-app-black-11">
                  <p class="tw-text-xs opacity-54 leading-15 tw-uppercase tw-font-bold">
                    Company
                  </p>
                  <p class="tw-text-sm leading-15">
                    {{ resource.company.company }}
                  </p>
                </div>

              </template>
            </ResourceCard>
          </template>

        </ViewMore>

      </template>

    </transition>
  </div>
</template>

<script>
import BaseInput from '@/components/BaseInput.vue';
import ResourceCard from '@/components/ResourceCard2.vue';
import ContentLoadingThreeGrid from '@/components/ContentLoadingThreeGrid.vue';

import filterMixin from '@/mixins/filter';

import { getReadableFileType, toBoolean } from '@/modules/dataHelper';
import {
  sortBy, reverse, uniqBy,
} from 'lodash';
import { apiPost } from '@/modules/apiHelper';

export default {
  name: 'DocumentsResources',
  mixins: [filterMixin],
  components: {
    BaseInput,
    ResourceCard,
    ContentLoadingThreeGrid,
  },
  props: {
    propToTriggerGetResources: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      resources: [],
      resourcesShares: {},
      resourcesCompanies: {},
      resourcesSenders: {},
      loadingResources: true,
      errorResources: undefined,
      filterConfig: {
        search: '',
        searchAt: [
          'path',
          'resource',
          'name',
        ],
        dataName: 'resources',
        type: '',
        options: [],
      },
    };
  },
  watch: {
    propToTriggerGetResources(newVal, oldVal) {
      if (newVal !== oldVal) {
        // get resources
        this.getResources();
      }
    },
  },
  computed: {
    filteredResources() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.filterConfig.type = this.filterConfig.type || '';

      if (!this.filterConfig.type.length) return this.filteredList;

      return this.filteredList.filter((item) => {
        const itemFileType = getReadableFileType(item.file_type || item.resource || item.path, true);
        return (itemFileType.toLowerCase() === this.filterConfig.type) ? item : null;
      });
    },
    hasFiltered() {
      return this.filterConfig.type.length > 0 || this.filterConfig.search.length > 0;
    },
  },
  methods: {
    async getResources() {
      this.loadingResources = true;
      this.errorResources = undefined;
      await this.$nextTick();
      //

      let resources = [];

      if (this.isRegionalAdmin) {
        resources = await this.$store.dispatch('getResourcesInRegion');
      } else {
        resources = await this.$store.dispatch('getResources');
      }

      if (!resources) {
        this.$toasted.global.appError();
        await this.$nextTick();
        this.loadingResources = false;
        this.errorResources = 'We could not get shared resources. Please try again later';

        return false;
      }

      await this.$nextTick();

      const sendersPromises = [];
      const companiesPromises = [];

      const currentCompanyId = this.$store.getters.resourcesContextId;

      resources.forEach((res) => {
        if (!this.resourcesShares[res.resourceid]) {
          // count
          // ? dk how to do this
          // const uniqResources = uniqBy(resources, 'data');
          // eslint-disable-next-line eqeqeq
          const shares = resources.filter((r) => r.resourceid == res.resourceid && r.companyid == currentCompanyId).length;
          this.resourcesShares[res.resourceid] = shares;
        }

        if (!this.resourcesSenders[res.senderid]) {
          sendersPromises.push(this.$store.dispatch('getUserData', res.senderid));
          this.resourcesSenders[res.senderid] = { id: res.senderid };
        }

        if (!this.resourcesCompanies[res.companyid] && this.isSuperAdmin) {
          companiesPromises.push(this.$store.dispatch('getCompany', [res.companyid]));
          this.resourcesCompanies[res.companyid] = { id: res.companyid };
        }
      });

      Promise.all(sendersPromises).then((resolvedSenders) => {
        resolvedSenders.forEach((sender) => {
          this.resourcesSenders[sender.id] = sender;
        });
      });

      if (this.isSuperAdmin) {
        Promise.all(companiesPromises).then((resolvedCompanies) => {
          resolvedCompanies.forEach((company) => {
            this.resourcesCompanies[company.id] = company;
          });
        });
      }

      const promises = [];
      const uniqResources = uniqBy(resources, 'resourceid');

      uniqResources.forEach((resource) => {
        promises.push(this.$store.dispatch('getResource', [resource.resourceid]));
      });

      Promise.all(promises).then((resolvedResources) => {
        const validResources = resolvedResources.filter((r) => !!r?.id);

        this.resources = reverse(sortBy(validResources, ['dateOfPost']));
      });

      await Promise.all([...promises, ...sendersPromises, ...companiesPromises]);
      await this.$nextTick();

      await this.generateFilterOptions();

      await this.$nextTick();
      this.errorResources = undefined;
      this.loadingResources = false;

      return true;
    },

    resourceCardData(resource = {}) {
      const fileType = getReadableFileType(resource.data, true);

      return {
        ...resource,
        ...{
          file_type: fileType,
          shares: this.resourcesShares[resource.id],
          sender: this.resourcesSenders[resource.userid],
          company: this.isSuperAdmin ? this.resourcesCompanies[resource.companyid] : {},
        },
      };
    },
    generateFilterOptions() {
      const types = [];

      this.resources.forEach((resource) => {
        const fileType = getReadableFileType(resource.file_type || resource.resource || resource.path, true);

        if (types.indexOf(fileType.toLowerCase()) < 0) {
          types.push(fileType.toLowerCase());
        }
      });

      this.filterConfig.options = types;
    },

    async deleteResource(resource) {
      // eslint-disable-next-line no-alert
      const confirm = window.confirm(`Are you sure you want to delete "${resource.name || resource.title || resource.data}"?`);
      if (!confirm) return;

      const formData = new FormData();
      formData.set('resourceid', resource.id);

      await apiPost('del_resource_log', formData, 38)
        .catch(() => {
          this.$toasted.appError();
          return false;
        })
        .then((res) => {
          if (!res) return;
          if (toBoolean(res.data.error)) {
            this.$toasted.appError({ errorMessage: 'Sorry we can\'t delete resource at the moment' });
          }

          this.$toasted.success('Resource deleted', { duration: 4000 });
          this.getResources();

          this.$store.dispatch('logAction', this.$store.state.Logger.actionsIndexes.deleteMediaResources);
        });
    },
  },
  async created() {
    // GET Resources
    await Promise.all([
      this.getResources(),
    ]);
    await this.generateFilterOptions();

    await this.$nextTick();
    await this.$nextTick();

    this.loadingResources = false;
  },
};
</script>

<style>

</style>
