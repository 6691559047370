var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'tw-text-center tw-bg-gray-200 tw-rounded-full tw-relative tw-inline-flex tw-items-center tw-overflow-hidden tw-justify-center',
    _vm.photoSize
  ]},[_c('img',{directives:[{name:"show",rawName:"v-show",value:(_vm.uPhoto.length && !_vm.loadingPhoto),expression:"uPhoto.length && !loadingPhoto"}],ref:"photo",class:[
      'tw-block tw-rounded-full tw-text-center tw-align-middle tw-uppercase tw-leading-relaxed tw-mx-auto',
      `${_vm.logo ? 'tw-object-contain tw-bg-app-light-blue': 'tw-object-cover tw-bg-gray-500'}`,
      _vm.photoSize,
    ],attrs:{"title":_vm.self ? 'me': _vm.userFirstName,"alt":_vm._f("firstLetter")(_vm.self ? 'me': _vm.userFirstName),"src":_vm.uPhoto,"decoding":"async","async":""},on:{"load":function($event){_vm.loadingPhoto = false},"error":function($event){_vm.loadingPhoto = true}}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.uPhoto.length === 0 || _vm.loadingPhoto),expression:"uPhoto.length === 0 || loadingPhoto"}],class:[
      'tw-block tw-text-black tw-rounded-full tw-text-center tw-uppercase tw-leading-relaxed opacity-31',
    ],staticStyle:{"font-size":"70%"}},[_vm._v(" "+_vm._s(_vm._f("firstLetter")(_vm.userFirstName))+_vm._s(_vm._f("firstLetter")(_vm.userLastName))+" ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }