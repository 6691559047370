<template>
  <form
    id="pillar-group-form"
    ref="pillar-group-form"
    class="tw-max-w-full tw-mx-auto tw-flex-shrink-0 tw-mb-20 auth-card tw-flex tw-flex-wrap"
    method="post"
    action="/survey/create"
    @submit.prevent="onSubmit"
  >

    <div class="tw-w-full tw-mb-4">
      <h1 class="tw-text-4xl font-title">{{ surveyId ? 'Edit Survey' : 'Create a Survey' }}</h1>
      <!-- <h6 class="tw-text-base opacity-78 tw-text-app-deep-blue">Please fill all inputs</h6> -->
    </div>

    <div class="tw-w-full tw-mb-4 tw-flex-shrink-0 tw-px-1">
      <InputGroup
        :class="{ '--loading-data': loadingForm }"
        label="Name"
        name="name"
        type="text"
        placeholder="name"
        input-classes="tw-p-3"
        v-model="form.name"
        :error="errors.name"
      />
    </div>

    <div class="tw-w-full tw-mb-4 tw-flex-shrink-0 tw-px-1">
      <SelectGroup
        label="When should this survey appear?"
        name="type"
        nameKey="type"
        placeholder="Type"
        input-classes="my-select--huge tw-font-sans tw-bg-gray-200 tw-text-left"
        v-model="form.type"
        :options="surveyTypes"
        :reduce="type => type.id"
        :selected="form.type"
        :clearable="false"
        :error="errors.type"
      />
    </div>

    <div class="tw-w-full tw-flex-shrink-0 tw-px-1 tw-mb-4 tw-pt-10">
      <BaseButton
        type="submit"
        :class="[
          'tw-block tw-w-full md:tw-inline-block md:tw-w-auto tw-mb-4 tw-py-3 tw-px-8',
          `${btnClass}`
        ]"
        :text="btnText"
        :disabled="!canSubmit || btnDisabled"
      />
    </div>

  </form>
</template>

<script>
import _debounce from 'lodash/debounce';

// import QuillTextareaGroup from '@/components/QuillTextareaGroup.vue';
import InputGroup from '@/components/InputGroup.vue';
import SelectGroup from '@/components/SelectGroup.vue';

import form from '@/modules/formHelper';

export default {
  name: 'EditSurvey',
  components: {
    // QuillTextareaGroup,
    InputGroup,
    SelectGroup,
  },
  props: {
    company: {
      type: Object,
      required: true,
    },
    surveyId: {
      type: [String, Number],
      default: undefined,
    },
    btnClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loadingForm: true,
      errorForm: undefined,
      btnText: 'Save',
      btnDisabled: false,
      form: {
        name: '',
        type: undefined,
      },
      errors: {},
    };
  },
  watch: {
    // eslint-disable-next-line func-names
    'company.id': function () {
      this.resetForm();
      this.loadForm();
    },
    surveyId() {
      this.resetForm();
      this.loadForm();
    },
  },
  computed: {
    canSubmit() {
      return form.isValidTextInput(this.form.name)
        && form.isValidSelectInput([this.form.type])
        && !this.loadingForm
        && this.company.id !== undefined;
    },
    surveyTypes() {
      return this.$store.state.Surveys.surveyTypes;
    },
  },
  methods: {
    resetForm() {
      this.form = {
        name: '',
        type: undefined,
      };
    },
    // eslint-disable-next-line func-names
    loadForm: _debounce(async function () {
      this.loadingForm = true;
      this.errorForm = undefined;

      // * get survey details if surveyId is defined
      if (this.surveyId) {
        const surveys = await this.$store.dispatch('getCompanySurveys', [this.company.id, true]);
        const curr = surveys.find((p) => Number(p.id) === Number(this.surveyId)) || {};

        this.form.name = curr.pillar;
        this.form.type = Number(curr.type);
      }

      await this.$nextTick();
      await this.$nextTick();

      this.loadingForm = false;
    }, 300),
    async onSubmit() {
      if (!this.canSubmit) {
        this.$toasted.global.appError({
          errorMessage: 'You can\'t submit the form. Check for empty inputs',
        });

        console.warn('Cannot submit form... \n check for empty inputs');
        return false;
      }

      // signup!!
      this.btnDisabled = true;
      this.btnText = 'working...';

      const data = {
        ...(this.form),
        companyId: this.company.id,
        id: this.surveyId,
      };

      const result = await this.$store.dispatch('saveSurvey', data);

      if (result) {
        this.$toasted.success('Saved!', { duration: 1000 });
        this.$emit('created-success');

        if (this.surveyId) {
          this.$store.dispatch('logAction', this.$store.state.Logger.actionsIndexes.editSurvey);
        } else {
          this.$store.dispatch('logAction', this.$store.state.Logger.actionsIndexes.addSurvey);
        }
      } else {
        this.$toasted.global.appError({
          errorMessage: 'Saving survey failed. Please try again later',
        });
      }

      this.btnDisabled = false;
      this.btnText = 'save';

      return true;
    },
  },
  async created() {
    this.errors = { ...this.form };

    await this.$nextTick();
    await this.loadForm();
    await this.$nextTick();

    this.loadingForm = false;
  },
};
</script>

<style>

</style>
