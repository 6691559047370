import store from '../../store';
import StatsView from '../../views/Stats/StatsView.vue';

const meta = {
  requiresLogin: true,
  requiresUserType: store.state.User.accountTypes.staff,
};

export default [
  {
    path: 'stats',
    // alias: 'default', // to be the default view loaded by route "/app"
    name: 'stats',
    component: StatsView,
    meta,
    children: [

      {
        path: 'accounts',
        alias: 'default',
        name: 'accStats',
        component() {
          return import(/* webpackChunkName: "stats" */ '../../views/Stats/AccountsStats.vue');
        },
      },
      {
        path: 'goals',
        name: 'goaStats',
        component() {
          return import(/* webpackChunkName: "stats" */ '../../views/Stats/GoalsStats.vue');
        },
      },
      {
        path: 'goals/users-goals',
        name: 'goaUsersStats',
        component() {
          return import(/* webpackChunkName: "stats" */ '../../views/Stats/SelectedGoalsUsersStats.vue');
        },
      },
      {
        path: 'goals/users-pillars',
        name: 'pilUsersStats',
        component() {
          return import(/* webpackChunkName: "stats" */ '../../views/Stats/SelectedPillarsUsersStats.vue');
        },
      },
      {
        path: 'goals/tasks-list',
        name: 'TasList',
        component() {
          return import(/* webpackChunkName: "stats" */ '../../views/Stats/TasksList.vue');
        },
      },
      {
        path: 'matches',
        name: 'matStats',
        component() {
          return import(/* webpackChunkName: "stats" */ '../../views/Stats/MatchesStats.vue');
        },
      },
      {
        path: 'matches/unmatched-users',
        name: 'matUnmatchedUsers',
        component() {
          return import(/* webpackChunkName: "stats" */ '../../views/Stats/UnmatchedUsers.vue');
        },
      },
      {
        path: 'activity-log',
        name: 'actLog',
        component() {
          return import(/* webpackChunkName: "stats" */ '../../views/Stats/ActivityLog.vue');
        },
      },
      // {
      //   path: 'resources',
      //   name: 'resStats',
      //   component() {
      //     return import(/* webpackChunkName: "stats" */ '../../views/Stats/ResourcesStats.vue');
      //   },
      // },

    ],
  },
];
