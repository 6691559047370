<template>
  <AppView
    padd-bottom
  >
    <template v-slot:top-app-view>
      <transition name="fade" mode="out-in" appear>
        <TopNav
          title="Communications"
          title-class="font-title pl-body tw-text-xl tw-tracking-wider tw-leading-6 opacity-54"
        />
      </transition>
    </template>

    <div class="app-view-grid-- tw-flex px-body tw-py-4">
      <div class="app-view-grid__col-74 tw-w-7/12--">
        <!-- form -->
        <div class="tw-max-w-lg tw-p-6 tw-bg-gray-200 tw-rounded-10">
          <h4 class="tw-text-2xl tw-mx-4 tw-mb-4">Send a message (SMS)</h4>
          <div
            v-if="isSuperAdmin"
            class="tw-m-4 tw-border-b tw-bg-gray-300 tw-rounded-3 tw-p-4"
          >
            <v-select
              placeholder="Select Company"
              :class="[
                { '--loading-data': loadingForm },
                'my-select tw-bg-app-deep-blue-11 tw-border-b tw-border-black tw-text-black tw-rounded-t-3 tw-rounded-b-none tw-text-sm tw-flex-shrink-0',
              ]"
              label="company"
              v-model="communication.companyId"
              :value="(companies[0] || {}).id"
              :options="companies"
              :reduce="option => option.id"
              :clearable="false"
              searchable
              filterable
            />
          </div>

          <div class="tw-m-4 tw-mb-0">
            <v-select
              placeholder="Specific person"
              :class="[
                { '--loading-data': loadingForm },
                'my-select tw-bg-app-deep-blue-11 tw-border-b tw-border-black tw-text-black tw-rounded-t-3 tw-rounded-b-none tw-text-sm tw-flex-shrink-0',
              ]"
              v-model="communication.userId"
              :value="(users[0] || {}).id"
              :options="users"
              :reduce="option => option.id"
              clearable
              searchable
              filterable
            />
            <div class="tw-py-4">
              <p class="tw-text-center opacity-54 tw-text-xs">
                or
              </p>
            </div>
          </div>
          <div
            :class="{ '--loading-data': loadingForm }"
          >
            <div class="tw-m-4 tw-inline-block">
              <BaseCheckBox
                @change="selectTo('all')"
                :checked="communication.to === 'all'"
                id="every"
                :label="`Everyone ${selectedCompany.label ? `on ${selectedCompany.label}` : ''} (${usersCount})`"
                value="all"
                class="tw-text-sm"
              />
            </div>
            <div class="tw-m-4 tw-inline-block">
              <BaseCheckBox
                @change="selectTo('mentors')"
                :checked="communication.to === 'mentors'"
                id="mentors"
                :label="`Mentors ${selectedCompany.label ? `on ${selectedCompany.label}` : ''} (${mentorsCount})`"
                value="mentors"
                class="tw-text-sm"
              />
            </div>
            <div class="tw-m-4 tw-inline-block">
              <BaseCheckBox
                @change="selectTo('mentees')"
                :checked="communication.to === 'mentees'"
                id="mentees"
                :label="`Mentees ${selectedCompany.label ? `on ${selectedCompany.label}` : ''} (${menteesCount})`"
                value="mentees"
                class="tw-text-sm"
              />
            </div>
            <div class="tw-m-4 tw-inline-block">
              <BaseCheckBox
                @change="selectTo('dormant')"
                :checked="communication.to === 'dormant'"
                id="dormant"
                :label="`Dormant accounts ${selectedCompany.label ? `on ${selectedCompany.label}` : ''} (${dormantUsersCount})`"
                value="dormant"
                class="tw-text-sm"
              />
            </div>
            <div class="tw-m-4 tw-inline-block">
              <BaseCheckBox
                @change="selectTo('deactive')"
                :checked="communication.to === 'deactive'"
                id="deactive"
                :label="`Deactivated accounts ${selectedCompany.label ? `on ${selectedCompany.label}` : ''} (${deactivatedUsersCount})`"
                value="deactive"
                class="tw-text-sm"
              />
            </div>
          </div>
          <div class="tw-m-4">
            <div class="tw-flex tw-items-center">
              <p class="tw-mb-2 tw-text-sm opacity-78">Message</p>
              <p class="tw-mb-2 tw-text-sm opacity-78 tw-ml-auto">
                <span :class="[{ 'tw-text-red-500': textCount > maxTextCount }]">{{textCount}}</span> out of {{maxTextCount}} chars
              </p>

            </div>
            <BaseTextarea
              placeholder="Type message here"
              v-model="communication.text"
              class="tw-border tw-border-black tw-p-4 tw-rounded-3 tw-shadow-app-lg tw-bg-white"
              rows="4"
            />
          </div>
          <div class="tw-border-t tw-mx-4 tw-py-4">
            <BaseButton
              class="tw-py-3 tw-bg-black tw-text-white tw-block tw-border-0 tw-w-full tw-shadow-xl tw-uppercase"
              :text="sendButtonText"
              :disabled="!canSend || !send"
              type="button"
              @click="onSubmit"
            />
          </div>
        </div>

        <div class="tw-border-b tw-p-4"></div>

        <!-- log of messages sent -->
        <div class="tw-max-w-lg">
          <h4 class="tw-text-2xl tw-p-4">Sent Messages</h4>
          <template v-if="errorCommsList">
            <p class="tw-text-red-500 tw-text-lg tw-text-center tw-py-8 tw-px-4" v-html="errorCommsList"></p>
          </template>
          <template v-else-if="loadingCommsList">
            <p class="tw-text-sm tw-text-center tw-py-8 tw-px-4 opacity-54">Loading...</p>
          </template>
          <template v-else>
            <div class="tw-p-4">
              <div
                v-if="isSuperAdmin"
                class="tw-mb-4 tw-border-b tw-bg-gray-300 tw-rounded-3 tw-p-4"
              >
                <v-select
                  placeholder="Select Company"
                  :class="[
                    { '--loading-data': loadingForm },
                    'my-select tw-bg-app-deep-blue-11 tw-border-b tw-border-black tw-text-black tw-rounded-t-3 tw-rounded-b-none tw-text-sm tw-flex-shrink-0',
                  ]"
                  label="company"
                  v-model="commsListCompanyId"
                  :value="(companies[0] || {}).id"
                  :options="companies"
                  :reduce="option => option.id"
                  :clearable="false"
                  searchable
                  filterable
                />
              </div>
              <table>
                <ViewMore
                  v-if="communicationsList.length > 0"
                  :limit="8"
                  :list="communicationsList"
                  v-slot="{ item: comm, index }"
                  span="3"
                >
                  <thead class="tw-bg-gray-200" v-if="index == 0">
                    <tr>
                      <td class="tw-p-3">Phone Number Sent</td>
                      <td class="tw-p-3">Message</td>
                      <td class="tw-p-3">Date Sent</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="tw-border-b tw-p-3">
                        <span>{{comm.user?.name}} {{comm.user?.last_name}}</span>
                        <br/>
                        <span>{{comm.phoneNumber}}</span>
                      </td>
                      <td class="tw-border-b tw-border-l tw-p-3">{{comm.sms}}</td>
                      <td class="tw-border-b tw-border-l tw-p-3">{{comm.dateofPost | toJsDate | moment('calendar') }}</td>
                    </tr>
                  </tbody>
                </ViewMore>
              </table>
            </div>
          </template>
        </div>
      </div>
    </div>
  </AppView>
</template>

<script>
import BaseCheckBox from '@/components/BaseCheckBox.vue';
import formHelper from '@/modules/formHelper';
import _sortBy from 'lodash/sortBy';
import _reverse from 'lodash/reverse';

const maxTextCount = 145;

export default {
  name: 'CommunicationsView',
  components: {
    BaseCheckBox,
  },
  data() {
    return {
      communicationsList: [],
      commsListCompanyId: undefined,
      communication: {
        companyId: undefined,
        userId: undefined,
        to: undefined,
        text: undefined,
      },
      journeys: [],
      badgesList: [],
      companies: [],
      communicationToOptions: [],
      users: [],
      dormantUsersCount: 0,
      deactivatedUsersCount: 0,
      unverifiedUsersCount: 0,
      loadingCommsList: true,
      loadingForm: true,
      errorCommsList: undefined,
      sendButtonText: 'send',
      send: true,
      maxTextCount,
    };
  },
  watch: {
    // eslint-disable-next-line func-names
    'communication.companyId': async function (val) {
      if (val) {
        this.communication.userId = undefined;
        this.communication.to = undefined;

        if (this.isRegionalAdmin) {
          const users = await this.getUsersInRegion([val]);

          this.users = users.map((c) => ({
            ...c,
            id: c.id,
            label: `${c.name} ${c.middle || c.last_name} (${c.working_num})`,
          }));

          this.countDormantAndDeactivatedAccountsInRegion();
        } else {
          const users = await this.getUsers([val]);

          this.users = users.map((c) => ({
            ...c,
            id: c.id,
            label: `${c.name} ${c.middle || c.last_name} (${c.working_num})`,
          }));

          this.countDormantAndDeactivatedAccounts();
        }
      }
    },
    // eslint-disable-next-line func-names
    'communication.to': function (val) {
      if (val) {
        // clear userId
        this.communication.userId = undefined;
      }
    },
    // eslint-disable-next-line func-names
    'communication.userId': function (val) {
      if (val) {
        // clear to
        this.communication.to = undefined;
      }
    },
    commsListCompanyId(val) {
      if (val) {
        this.getCommunicationsList();
      }
    },
  },
  computed: {
    canSend() {
      return (!!this.communication.to || !!this.communication.userId)
        && formHelper.isValidTextInput(this.communication.text)
        && (this.communication.text || '').length < this.maxTextCount;
    },
    selectedCompany() {
      return this.companies.filter((comp) => comp.id === this.communication.companyId);
    },
    usersCount() {
      return this.users.length;
    },
    mentorsCount() {
      return this.users.filter((u) => Number(u.type) === this.$store.state.User.accountTypes.mentor).length;
    },
    menteesCount() {
      return this.users.filter((u) => Number(u.type) === this.$store.state.User.accountTypes.mentee).length;
    },
    textCount() {
      return (this.communication.text || '').length;
    },
  },
  methods: {
    async getCommunicationsList() {
      this.loadingCommsList = true;
      this.errorCommsList = undefined;
      await this.$nextTick();

      // Store will handle Company scope for staff admins
      const communicationsList = await this.$store.dispatch('getCommunicationsList', this.commsListCompanyId);

      if (!communicationsList) {
        this.$toasted.global.appError();
        await this.$nextTick();
        this.loadingCommsList = false;
        this.errorCommsList = 'We could not get log of past communications. Please try again later';

        return;
      }

      this.communicationsList = _reverse(_sortBy(communicationsList, 'dateofPost'));

      await this.$nextTick();

      this.loadingCommsList = false;
      this.errorCommsList = undefined;
    },
    async getCommunicationsListInRegion() {
      this.loadingCommsList = true;
      this.errorCommsList = undefined;
      await this.$nextTick();

      // Store will handle Company scope for staff admins
      const communicationsList = await this.$store.dispatch('getCommunicationsListInRegion', [this.commsListCompanyId]);

      if (!communicationsList) {
        this.$toasted.global.appError();
        await this.$nextTick();
        this.loadingCommsList = false;
        this.errorCommsList = 'We could not get log of past communications. Please try again later';

        return false;
      }

      this.communicationsList = _reverse(_sortBy(communicationsList, 'dateofPost'));

      await this.$nextTick();

      this.loadingCommsList = false;
      this.errorCommsList = undefined;

      return true;
    },
    async selectTo(to) {
      this.communication.to = to;
    },
    async getUsers(companyIds = []) {
      this.loadingForm = true;

      const requests = [];

      companyIds.forEach((companyId) => {
        const mentorAccounts = this.$store.dispatch(
          'getAccounts',
          ['mentor', 'verified', companyId],
        );
        const menteeAccounts = this.$store.dispatch(
          'getAccounts',
          ['mentee', 'verified', companyId],
        );

        requests.push(mentorAccounts, menteeAccounts);
      });

      const results = await Promise.all(requests).then((res) => {
        // eslint-disable-next-line no-underscore-dangle
        const _res = [];
        res.forEach((__res) => {
          _res.push(...__res);
        });
        return _res;
      });

      this.loadingForm = false;

      return results;
    },
    async getUsersInRegion(companyIds = [], region) {
      this.loadingForm = true;

      const requests = [];

      companyIds.forEach((companyId) => {
        const mentorAccounts = this.$store.dispatch(
          'getAccountsInRegion',
          ['mentor', 'verified', companyId, region],
        );
        const menteeAccounts = this.$store.dispatch(
          'getAccountsInRegion',
          ['mentee', 'verified', companyId, region],
        );

        requests.push(mentorAccounts, menteeAccounts);
      });

      const results = await Promise.all(requests).then((res) => {
        // eslint-disable-next-line no-underscore-dangle
        const _res = [];
        res.forEach((__res) => {
          _res.push(...__res);
        });
        return _res;
      });

      this.loadingForm = false;

      return results;
    },
    async getCompanyJourneys(companyId, refresh = false) {
      this.loadingJourneys = true;
      await this.$nextTick();

      const journeys = await this.$store.dispatch('getJourneys', [companyId, refresh]);

      return journeys || [];
    },
    clearForm() {
      this.communication = {
        companyId: undefined,
        userId: undefined,
        to: undefined,
        text: undefined,
      };
    },
    async countDormantAndDeactivatedAccounts() {
      Promise.all([
        this.$store.dispatch('getAccounts', ['mentor', 'dormant', this.communication.companyId]),
        this.$store.dispatch('getAccounts', ['mentee', 'dormant', this.communication.companyId]),
        this.$store.dispatch('getAccounts', ['mentor', 'suspended', this.communication.companyId]),
        this.$store.dispatch('getAccounts', ['mentee', 'suspended', this.communication.companyId]),
      ]).then(([mentors, mentees, mentorsSuspended, menteesSuspended]) => {
        this.dormantUsersCount = mentors.length + mentees.length;
        this.deactivatedUsersCount = mentorsSuspended.length + menteesSuspended.length;
      });
    },
    async countDormantAndDeactivatedAccountsInRegion() {
      Promise.all([
        this.$store.dispatch('getAccountsInRegion', ['mentor', 'dormant', this.communication.companyId]),
        this.$store.dispatch('getAccountsInRegion', ['mentee', 'dormant', this.communication.companyId]),
        this.$store.dispatch('getAccountsInRegion', ['mentor', 'suspended', this.communication.companyId]),
        this.$store.dispatch('getAccountsInRegion', ['mentee', 'suspended', this.communication.companyId]),
      ]).then(([mentors, mentees, mentorsSuspended, menteesSuspended]) => {
        this.dormantUsersCount = mentors.length + mentees.length;
        this.deactivatedUsersCount = mentorsSuspended.length + menteesSuspended.length;
      });
    },
    async onSubmit() {
      if (!this.canSend) return false;

      await this.$nextTick();

      this.sendButtonText = 'sending...';
      this.send = false;

      if (this.isRegionalAdmin) {
        const result = await this.$store.dispatch('sendCommsMessageInRegion', this.communication);

        if (result) {
          this.$toasted.success('Messages sent!', {
            duration: 5000,
            position: 'top-right',
          });

          await this.getCommunicationsListInRegion();

          if (this.communication.userId) {
            this.$store.dispatch('logAction', this.$store.state.Logger.actionsIndexes.sendSmsToUserInRegion);
          } else {
            switch (this.communication.to) {
              case 'all':
                this.$store.dispatch('logAction', this.$store.state.Logger.actionsIndexes.sendSmsToAllInRegion);
                break;
              case 'mentors':
                this.$store.dispatch('logAction', this.$store.state.Logger.actionsIndexes.sendSmsToMentorsInRegion);
                break;
              case 'mentees':
                this.$store.dispatch('logAction', this.$store.state.Logger.actionsIndexes.sendSmsToMenteesInRegion);
                break;
              case 'dormant':
                this.$store.dispatch('logAction', this.$store.state.Logger.actionsIndexes.sendSmsToDormantAccountsInRegion);
                break;
              case 'deactive':
                this.$store.dispatch('logAction', this.$store.state.Logger.actionsIndexes.sendSmsToDeactivatedAccountsInRegion);
                break;
              default:
                console.warn('unkown ::to to log');
                break;
            }
          }
        } else {
          this.$toasted.global.appError();
        }
      } else {
        const result = await this.$store.dispatch('sendCommsMessage', this.communication);

        if (result) {
          this.$toasted.success('Messages sent!', {
            duration: 5000,
            position: 'top-right',
          });

          await this.getCommunicationsList();

          if (this.communication.userId) {
            this.$store.dispatch('logAction', this.$store.state.Logger.actionsIndexes.sendSmsToUser);
          } else {
            switch (this.communication.to) {
              case 'all':
                this.$store.dispatch('logAction', this.$store.state.Logger.actionsIndexes.sendSmsToAll);
                break;
              case 'mentors':
                this.$store.dispatch('logAction', this.$store.state.Logger.actionsIndexes.sendSmsToMentors);
                break;
              case 'mentees':
                this.$store.dispatch('logAction', this.$store.state.Logger.actionsIndexes.sendSmsToMentees);
                break;
              case 'dormant':
                this.$store.dispatch('logAction', this.$store.state.Logger.actionsIndexes.sendSmsToDormantAccounts);
                break;
              case 'deactive':
                this.$store.dispatch('logAction', this.$store.state.Logger.actionsIndexes.sendSmsToDeactivatedAccounts);
                break;
              default:
                console.warn('unkown ::to to log');
                break;
            }
          }
        } else {
          this.$toasted.global.appError();
        }
      }

      this.clearForm();

      this.sendButtonText = 'send';
      this.send = true;

      return true;
    },
  },
  async mounted() {
    this.loadingForm = true;

    if (this.isSuperAdmin) {
      const companies = await this.$store.dispatch('getCompanies');
      const myCompany = await this.$store.dispatch('myCompany');

      if (Array.isArray(companies)) {
        this.companies = companies;

        this.communication.companyId = myCompany.id;
        this.commsListCompanyId = myCompany.id;
      } else {
        this.$toasted.global.appError({
          errorMessage: 'Could not fetch companies',
        });
      }
    } else {
      const myCompany = await this.$store.dispatch('myCompany');
      this.companies = [myCompany];
      this.communication.companyId = myCompany.id;
    }

    if (this.isRegionalAdmin) {
      const users = await this.getUsersInRegion([this.communication.companyId]);

      this.users = users.map((c) => ({
        ...c,
        id: c.id,
        label: `${c.name} ${c.middle || c.last_name} (${c.email})`,
      }));

      await this.getCommunicationsListInRegion();

      this.countDormantAndDeactivatedAccountsInRegion();
    } else {
      const users = await this.getUsers([this.communication.companyId]);

      this.users = users.map((c) => ({
        ...c,
        id: c.id,
        label: `${c.name} ${c.middle || c.last_name} (${c.email})`,
      }));

      await this.getCommunicationsList();

      this.countDormantAndDeactivatedAccounts();
    }

    this.loadingForm = false;
  },
};
</script>

<style>

</style>
