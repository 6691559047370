<template>
  <TopNav
    type="back"
    :prev-route="{ name: (isSuperAdmin ? 'viewCompany' : 'myLicense'), params: { id: $route.params.id } }"
    :title="company.company || 'fetching company...'"
    :title-class="[
      'tw-ml-8',
      { 'opacity-78 font-title tw-text-xl tw-tracking-wider tw-leading-6': !loading },
      { 'opacity-31 tw-italic tw-lowercase': loading },
    ]"
  >
    <template v-slot:title-left v-if="!loading">
      <UserPhoto
        :photo="company.logo"
        :user-name="{
          name: company.company,
        }"
        logo
        photo-size="tw-h-12 tw-w-12"
        :class="[
          'tw-mr-6 tw-my-1 tw-rounded-10--force --force-children tw-bg-app-white-87',
          { 'tw-shadow-app-sm': !(company.logo || '').length }
        ]"
      />
    </template>
  </TopNav>
</template>

<script>
import UserPhoto from '@/components/UserPhoto.vue';

export default {
  name: 'CompanyHeader',
  components: {
    UserPhoto,
  },
  props: {
    company: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style>

</style>
