<template>
  <div class="tw-mb-2 tw-block">
    <slot name="input-label">
      <label class="tw-block tw-text-sm tw-mb-2 opacity-78" :for="name" :aria-label="name">
        {{ label }}
      </label>
    </slot>
    <label v-if="instruct.length > 0" class="tw-block tw-text-xs tw-mb-2 tw--mt-3 opacity-54">
      {{ instruct }}
    </label>

    <div class="tw-flex tw-items-stretch">
      <!-- <v-select
        class="my-select my-select--py-3 tw-w-full transition tw-flex-shrink tw-flex-grow-0 tw-border tw-border-gray-700"
        placeholder="Country code"
        v-model="dialCode"
        :options="dialCodes"
        :multiple="false"
        :clearable="false"
        :reduce="r => r.id"
        filterable
        searchable
        append-to-body
        :calculate-position="dropdownPosition"
        style="max-width: 150px"
      /> -->

      <!-- <BaseInput
        :id="id"
        :class="inputClasses"
        type="number"
        :name="name"
        :placeholder="placeholder"
        v-model="phoneNumber"
      /> -->
    </div>
    <div>
      <!-- tel-intl-input -->
      <input
        class="tw-w-full tw-rounded-sm tw-block tw-bg-gray-200 tw-outline-none transition hover:tw-bg-gray-200 focus:tw-bg-white tw-p-3"
        id="phone-number-input"
        ref="phone-number-input"
        v-model="phoneNumber"
      >
    </div>
      <!-- :name="name" -->
    <slot name="message" />
    <slot name="error-message">
      <p class="tw-text-red-500 tw-text-xs tw-italic" v-if="showError" slot> {{ error }} </p>
    </slot>
  </div>
</template>

<script>
import intlTelInput from 'intl-tel-input';
import utilsScript from 'intl-tel-input/build/js/utils';

let phoneInput;
const validationErrors = {
  0: 'okay',
  1: 'invalid country code',
  2: 'number is too short',
  3: 'number is too long',
  4: 'number is okay (local only)',
  5: 'number has an invalid length',
};

export default {
  name: 'PhoneNumberInput',
  props: {
    label: {
      type: String,
      default: '',
      required: false,
    },
    instruct: {
      type: String,
      default: '',
      required: false,
    },
    id: {
      type: String,
      required: false,
    },
    name: {
      type: String,
      required: false,
    },
    value: {
      default: undefined,
    },
    inputClasses: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      phoneNumber: undefined,
      error: '',
      dialCode: undefined,
      dialCodes: [],
    };
  },
  computed: {
    showError() {
      return this.error.length > 0;
    },
    inputValue() {
      if (!phoneInput) {
        return this.phoneNumber;
      }

      const { dialCode } = phoneInput.getSelectedCountryData();
      return `${dialCode}${this.phoneNumber}`;
    },
  },
  watch: {
    inputValue(val) {
      // VALIDATE
      const errorCode = phoneInput.getValidationError();
      if (this.phoneNumber && errorCode === 0) {
        this.$emit('input', val);
        this.error = '';
      } else {
        this.$emit('input', undefined);
      }

      if (errorCode) {
        this.error = validationErrors[errorCode] || '';
      }
    },
  },
  methods: {
    changedPhoneNumber(value) {
      console.log(value);
    },
  },
  async mounted() {
    phoneInput = intlTelInput(
      document.querySelector('#phone-number-input'),
      {
        // any initialisation options go here
        separateDialCode: true,
        // preferredCountries: ['ke', 'tz', 'ug'],
        preferredCountries: ['ke'],
        utilsScript,
      },
    );
  },
};
</script>

<style>

</style>
