<template>
  <AppView
    padd-bottom
  >
    <template v-slot:top-app-view>
      <transition name="fade" mode="out-in" appear>
        <CompanyHeader :company="company" :loading="loadingCompany" />
      </transition>
    </template>

    <transition name="fade" mode="out-in" appear>

      <div v-if="errorCompany" class="tw-mt-12" key="errmsg">
        <p class="tw-py-12 tw-text-base tw-text-center" v-html="errorCompany"></p>
      </div>

      <div v-else-if="loadingCompany" class="tw-mt-12" key="lodng">
        <p class="tw-py-12 tw-text-base tw-text-center">Loading...</p>
      </div>

      <div v-else key="bdy">
        <transition name="fade" mode="out-in" appear>

          <div class="app-view-grid px-body">
            <div class="app-view-grid__col-74">
              <portal to="modal-body" key="mdl">
                <div class="">
                  <EditExpert
                    :company="company"
                    @created-success="closeModal(); getCompanyExperts();"
                  />
                </div>
              </portal>

              <template>
                <div class="tw-flex tw-items-center tw-pt-10 tw-pb-4">
                  <img
                    data-src="/img/experts.png"
                    src="/img/experts.png"
                    style="width: 80px; height: 80px;"
                    class="tw-object-cover tw-block tw-mr-8"
                    async
                  >
                  <h3 class="font-title tw-text-xl tw-tracking-wider">
                    Experts
                  </h3>

                  <BaseButton
                    class="tw-ml-auto"
                    text="Add an Expert"
                    title="Add an Expert"
                    @click="createExpert"
                  />
                </div>
                <div class="tw-border-t tw-border-app-black-11 tw-mb-4"></div>
                <div></div>
              </template>

              <div class="tw-mb-4">
                <label class="tw-text-xs tw-text-black opacity-78">Question Guide for {{ company.company }} Mentees</label>
                <QuillTextareaGroup
                  :rows="3"
                  class="tw-bg-white"
                  v-model="form.instruction"
                  max-height="400px"
                />
                <BaseButton @click="createCompanyQuestionInstruction" text="Save Instruction" class="tw-bg-black tw-border-0 tw-text-white" />
              </div>

              <div class="tw-border-t tw-border-app-black-11 tw-mb-8"></div>

              <!-- Experts -->
              <template>
                <ViewMore
                  :list="experts"
                  :limit="8"
                  class="tw-flex tw-flex-wrap tw-h-auto"
                  v-slot="{ item: expert }"
                >
                  <Expert :expert="expert" class="tw-pr-4 tw-pb-4" show-view-more-button />
                </ViewMore>
              </template>
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </AppView>
</template>

<script>
import CompanyHeader from '@/views/Companies/Company/_partials/CompanyHeader.vue';
import EditExpert from '@/views/Companies/Company/_partials/EditExpert.vue';
import Expert from '@/components/Expert.vue';
import QuillTextareaGroup from '@/components/QuillTextareaGroup.vue';

import companyMixin from '@/mixins/company';

const defaultDeleteButtonText = 'Cancel Meeting';

export default {
  name: 'ExpertsView',
  mixins: [companyMixin],
  components: {
    CompanyHeader,
    EditExpert,
    Expert,
    QuillTextareaGroup,
  },
  props: ['id'],
  data() {
    return {
      error: undefined,
      loading: true,
      loadingParticipants: true,
      maxVisibleParticipants: 10,
      experts: [],
      newForm: false,
      form: {
        instruction: '',
      },
      deleteButtonDisabled: false,
      deleteButtonText: defaultDeleteButtonText,
    };
  },
  methods: {
    async getCompanyExperts() {
      this.loading = true;

      await this.$nextTick();

      const experts = await this.$store.dispatch('getCompanyExperts', this.company.id);

      if (Array.isArray(experts)) {
        this.experts = experts;
      }

      this.loading = false;
    },
    async createExpert() {
      this.newForm = true;

      await this.$nextTick();
      // open modal
      this.$store.commit('openModal', [true]);
    },
    async closeForm() {
      // close modal
      this.closeModal();

      await this.$nextTick();

      this.newForm = false;
    },
    async onSuccess() {
      this.getCompanyExperts();
    },
    async getCompanyQuestionInstructions() {
      const instruction = await this.$store.dispatch('getCompanyQuestionInstruction', this.company.id);

      if (instruction) {
        this.form.instruction = instruction;
      }
    },
    async createCompanyQuestionInstruction() {
      if (!this.form.instruction.length) {
        return;
      }

      const response = await this.$store.dispatch('createCompanyQuestionInstruction', {
        guide: this.form.instruction,
        companyid: this.company.id,
      });

      if (response) {
        this.getCompanyQuestionInstructions();
      }
    },
  },
  async created() {
    // get company
    await Promise.all([
      this.getCompany(),
    ]);

    await this.$nextTick();

    // assert user company permissions
    if (!this.hasPermission) {
      this.$router.push({
        name: 'app',
      });

      return;
    }

    this.getCompanyQuestionInstructions();
    await this.getCompanyExperts();

    await this.$nextTick();
    await this.$nextTick();

    this.loading = false;
  },
};
</script>

<style>

</style>
