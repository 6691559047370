// import _debounce from 'lodash/debounce';

import { apiPost, generateFormData } from '../../../modules/apiHelper';
import { toBoolean } from '../../../modules/dataHelper';

export default {
  // eslint-disable-next-line consistent-return
  async getGroupMessages(context, groupId) {
    // if (process.env.NODE_ENV === 'development') return false;
    await context.dispatch('fetchMessages', groupId);

    if (context.getters.isFetching(groupId)) return true;
    // This is set only once when this action is dispatched
    context.commit('updateFetchingChats', [groupId, true]);

    try {
      setTimeout(async function fetch() {
        // const n = now() + context.state.fetchingIntervals;
        if (context.getters.isFetching(groupId)) {
          await context.dispatch('fetchMessages', groupId)
            .catch((err) => {
              context.commit('updateFetchingChatError', true);
              throw err;
            });

          // await context.dispatch('totalNewMessages');

          setTimeout(fetch, (context.state.fetchingIntervals[groupId] || context.state.fetchingIntervals.default));
        }
      }, (context.state.fetchingIntervals[groupId] || context.state.fetchingIntervals.default));
    } catch (err) {
      console.error('ERROR IN FETCHING MESSAGES! (chat.js::fetchMessages)\n\n', err);
      context.commit('updateFetchingChatError', true);
    }
  },
  async fetchMessages(context, groupId) {
    const formData = new FormData();
    formData.set('groupid', groupId);

    await apiPost('chat_list', formData, 69)
      .then(async (res) => {
        if (!res) return false;
        const messages = context.state.messages[`group${groupId}`] || [];
        // add new messages
        // await context.commit('addGroupMessages', [groupId, res.data.chat_lists]);
        // if (messages.length !== res.data.chat_lists.length) {
        if (messages.length > 0) {
          // eslint-disable-next-line no-async-promise-executor
          await new Promise(async (resolve) => {
            await res.data.chat_lists.forEach(async (message) => {
              if (!messages.find((m) => m.id === message.id)) {
                await context.commit('addGroupMessage', [groupId, message]);
              }
              // update the chat message status // * no need for this for admin
              // const storeMessage = context.state.messages[`group${groupId}`].find((m) => m.id === message.id);
              // if (storeMessage.status !== message.status) {
              //   context.commit('updateGroupMessage', [message.id, groupId, 'status', message.status]);
              // }
            });

            resolve();
          });
        } else {
          await context.commit('addGroupMessages', [groupId, res.data.chat_lists]);
        }

        return true;
      });
  },
  // called when logging out
  stopFetchingGroupMessages(context, groupId = undefined) {
    if (!groupId) { // stop all if groupId was not given
      // eslint-disable-next-line no-unused-vars
      Object.entries(context.state.fetchingGroupMessages).forEach(([id, isFetching]) => {
        context.commit('updateFetchingChats', [id, false]);
      });
    } else {
      context.commit('updateFetchingChats', [groupId, false]);
    }
  },
  async sendGroupChatMessage(context, {
    groupId, text, attachmentId = undefined, attachmentType = undefined, chatReplyId = undefined, resourceType = 3, // resource
  }) {
    const formData = generateFormData({
      text,
      groupid: groupId,
      senderid: context.rootState.User.id,
      userid: context.rootState.User.id,
    });

    if (attachmentType && attachmentId) {
      formData.set(attachmentType, attachmentId);
      formData.set('resource_type', resourceType);
    }

    if (chatReplyId) {
      formData.set('chatrefid', chatReplyId);
    }

    const response = await apiPost('chat', formData, 69)
      .then((res) => {
        if (!res) return false;
        if (toBoolean(res.data.error)) return false;
        return true;
      });
    //

    return !!response;
  },
  markMessagesAsRead(context, linkid) {
    const formData = new FormData();
    formData.set('linkid', linkid);

    apiPost('chat_seen', formData, 4);
  },
};
