import actions from './actions';
import state from './state';
import updateProp from '../_helpers/updateProp';

export default {
  namespaced: false,
  ...state,
  mutations: {
    updateProp,
  },
  actions,
};
