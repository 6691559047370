<script>
import { Pie } from 'vue-chartjs';

export default {
  name: 'PieChart',
  mixins: [Pie],
  props: {
    data: {
      type: Array,
      required: true,
    },
    dataOptions: {
      type: Object,
      default() {
        return {};
      },
    },
    datasetOptions: {
      type: Object,
      default() {
        return {};
      },
    },
    dataLabels: {
      type: Array,
      required: true,
    },
    dataColors: {
      type: Array,
      default() {
        return [];
      },
    },
    /**
     * @default {String} 'pie'
     * pie
     * doughnut
     */
    type: {
      type: String,
      default: 'pie',
    },
  },
  data() {
    return {
      options: {},
    };
  },
  computed: {
    mergedOptions() {
      return { ...this.defaultOptions, ...this.dataOptions };
    },
    defaultOptions() {
      return {
        responsive: true,
        backgroundColor: '#000',
        // rotation: Math.round(Math.random() * -2),
        rotation: -2,
        layout: {
          padding: {
            // to avoid hiding the points at the top
            top: 16,
            left: 16,
            right: 16,
            bottom: 16,
          },
        },
        legend: {
          display: true,
          position: 'bottom',
          align: 'center',
          fullWidth: false,
          labels: {
            boxWidth: 10,
            // lineCap: '',
          },
        },
      };
    },
    mergedDatasetOptions() {
      return {
        ...this.defaultDatasetOptions,
        ...this.datasetOptions,
        ...{ backgroundColor: this.dataColors },
      };
    },
    defaultDatasetOptions() {
      const backgroundColor = [];
      // generate random colors
      for (let i = 0; i < this.data.length; i += 1) {
        backgroundColor.push(`#${Math.floor(Math.random() * 16777215).toString(16)}`);
      }

      return {
        backgroundColor,
        borderWidth: 0,
      };
    },
    chartData() {
      return {
        labels: this.dataLabels,
        datasets: [
          {
            data: this.data,
            ...this.mergedDatasetOptions,
          },
        ],
      };
    },
  },
  mounted() {
    this.options = { ...this.mergedOptions };

    this.renderChart(this.chartData, this.options);

    // rendered
  },
};
</script>

<style>

</style>
