
import _find from 'lodash/find';
import resolve from '../../../modules/api/resolve';
import {
  apiStore, apiPost, apiGet, generateFormData,
} from '../../../modules/apiHelper';
import {
  toArray,
  objectIsValid,
  isStaleData,
  toBoolean,
  urlFriendly,
  now,
} from '../../../modules/dataHelper';

export default {
  async getUserData(context, userid = undefined) {
    if (userid === undefined || userid === null) {
      const user = await context.dispatch('getMyData');
      return user;
    }

    const [userStoreExpiry, userStoreData] = await apiStore.getItem(`user/${userid}`) || [0, {}];

    if (objectIsValid(userStoreData) && !isStaleData(userStoreExpiry)) return userStoreData;

    const formData = new FormData();
    formData.set('userid', userid);

    const user = await Promise.all([
      apiPost('log_user', formData, 5), // 0
      apiGet('gender', 5), // 1
      apiGet('countries', 5), // 2
      apiGet('county', 5), // 3
      apiGet('profession', 5), // 4 // todo
      apiGet('experience', 5), // 5
      apiGet('availability', 5), // 6
      apiPost('profile_resources', formData, 1), // 7
      apiGet('group_goals', 5), // 8
    ]).then(async (res) => {
      if (!res || !res[0]) return {};
      if (!res[0].data.log_user.length) return {};

      const {
        id,
        name,
        middle,
        // eslint-disable-next-line camelcase
        last_name,
        // eslint-disable-next-line camelcase
        working_num,
        email,
        type,
        profession,
        experience,
        availability,
        city,
        country,
        gender,
        bio,
        status,
        code,
        course,
        companyid,
        packid,
        licenseAt,
        // eslint-disable-next-line camelcase
        license_end,
        py,
        bal,
        opt,
        u,
        createAt,
        // eslint-disable-next-line camelcase
        log_date,
        // eslint-disable-next-line camelcase
        regional_area,
        staff,
      } = res[0].data.log_user[0];

      const regions = await context.dispatch('companyRegions', companyid);

      return {
        id,
        name,
        middle,
        last_name,
        working_num,
        email,
        type,
        bio,
        status: Number(status),
        COC: toBoolean(code),
        course: toBoolean(course),
        packageid: packid,
        professionid: profession, // * for mentees in company scopes
        licensestartdate: licenseAt,
        licenseenddate: license_end,
        py,
        bal,
        opt,
        u,
        companyid,
        dateCreated: createAt,
        dateLastLogin: log_date,
        photo: res[7].data.profile_resources.length > 0
          ? res[7].data.profile_resources[0].data || ''
          : '',
        gender: _find(res[1].data.gender, {
          id: gender,
        }),
        country: _find(res[2].data.users, {
          id: country,
        }),
        city: _find(res[3].data.county, {
          id: city,
        }),
        profession: _find(res[4].data.profession, { // todo
          id: profession,
        }),
        menteeProfession: _find(res[8].data.group_goals, {
          id: profession,
        }) || {},
        experience: _find(res[5].data.experience, {
          id: experience,
        }),
        availability: toArray(availability),
        availabilities: toArray(availability).map((a) => _find(res[6].data.availability, {
          id: a,
        })),
        region: _find(regions, { id: regional_area }),
        staff,
      };
    });

    if (objectIsValid(user)) {
      if (this.userid === context.rootState.User.id) {
        apiStore.setItem('userbio', user.bio);
        apiStore.setItem('userphoto', user.photo);
      }

      apiStore.setItem(`user/${userid}`, [now(), {
        ...user,
      }]);
    }

    return user;
  },
  // ! adding this action here because idk where to add it to
  async companyRegions(context, companyId) {
    const [regionsStoreExpiry, regionsStoreData] = await apiStore.getItem(`regions/${companyId}`) || [0, []];

    if ((Array.isArray(regionsStoreData) && regionsStoreData.length) && !isStaleData(regionsStoreExpiry)) return regionsStoreData;

    const companyIdFormData = new FormData();
    companyIdFormData.set('companyid', companyId);

    const regions = await resolve(apiPost('regions', companyIdFormData, 30));

    if (regions) {
      await apiStore.setItem(`regions/${companyId}`, [now(), regions?.regions]);

      return regions?.regions || [];
    }

    return [];
  },

  async getUserGoals(context, [userid, userType]) {
    const formData = generateFormData({ userid });

    const apiCall = (Number(userType) === context.state.accountTypes.mentor)
      ? apiPost('goals_check', formData, 5)
      : apiPost('sub_goals_check', formData, 1);
    //

    const goals = await apiCall
      .catch((err) => {
        console.warn('getUserGoals\nCannot get user goals\n', err);
        return [];
      })
      .then(async (res) => {
        if (!res) return [];
        if (toBoolean(res.data.error)) return [];

        return res.data.goals_check || [];
      });

    return goals;
  },
  // todo: cache this
  async getUserAllGoalsSelected(context, [userid, type, companyid]) {
    const key = process.env.VUE_APP_KEY;

    const formData = generateFormData({ companyid, key });
    const apiEndpoint = Number(type) === context.rootState.User.accountTypes.mentor
      ? 'mentor_goal_log'
      : 'mentee_goal_log';

    const allUsersGoals = await apiPost(apiEndpoint, formData, 51)
      .catch((err) => {
        console.warn('getUserAllGoalsSelected\nCannot get user selected goals\n', err);
        return [];
      })
      .then(async (res) => {
        if (!res) return [];
        if (toBoolean(res.data.error)) return [];

        return (res.data[apiEndpoint] || []).filter((goal) => Number(goal.userid) === Number(userid));
      });
    //

    return allUsersGoals;
  },
  async updateUserPhoto(context, file) {
    if (!file) {
      console.warn('No file sent ', file);
      return false;
    }
    const userid = context.rootState.User.id;
    const formData = new FormData();
    const fileName = file.name.split('.')[0];
    formData.set('userid', userid);
    formData.set('name', urlFriendly(fileName, true));
    formData.append('file1', file, urlFriendly(file.name, true));

    const response = await apiPost('add_profile_pic', formData, 3)
      .catch((err) => {
        console.warn('AddProfilePic failed! ', err);
        return false;
      })
      .then(async (res) => {
        if (!res) return false;

        if (res.data.error) return false;

        await apiStore.removeItem(`user/${userid}`);
        await this.dispatch('getUserData', userid);

        context.commit('refreshMyPhoto');

        return true;
      });

    return response;
  },
  async updateUserBio(context, bio) {
    if (!bio) {
      console.warn('No bio => ', bio);
      return false;
    }
    const userid = context.rootState.User.id;
    const formData = new FormData();
    formData.set('userid', userid);
    formData.set('bio', bio);

    const response = await apiPost('bio', formData, 1)
      .catch((err) => {
        console.warn('SaveUserBio failed! ', err);
        return false;
      })
      .then(async (res) => {
        if (!res) return false;

        if (res.data.error) return false;

        await apiStore.removeItem(`user/${userid}`);
        await apiStore.setItem('userbio', bio);
        this.dispatch('getUserData', userid);

        return true;
      });

    return response;
  },
  async updateUserAvailability(context, availability) {
    if (!availability) {
      console.warn('No availability => ', availability);
      return false;
    }
    const userid = context.rootState.User.id;
    const formData = new FormData();
    formData.set('userid', userid);
    formData.set('availability', availability);

    const response = await apiPost('change_availability', formData, 1)
      .catch((err) => {
        console.warn('UpdateUserAvailability failed! ', err);
        return false;
      })
      .then(async (res) => {
        if (!res) return false;

        if (res.data.error) return false;

        await apiStore.removeItem(`user/${userid}`);
        await this.dispatch('getUserData', userid);

        return true;
      });

    return response;
  },


  // looks at a user's data to determine if he is new to the app
  async userIsNew({ dispatch }) {
    // first sets/gets
    await dispatch('getLoggedInUserData');
    // gets (sure bet)
    // const userData = await dispatch('getLoggedInUserData');

    return [
      await dispatch('logUser', true), // checks server data match
      true,
      // // userData[6].length > 0 && userData[7].length > 0, // User profile is set
      true, // TODO: User has accepted T&Cs
    ];
  },

  async postUserLastSeen(context) {
    if (context.state.id) {
      // console.log('logging');
      // await apiStore.setItem('session-expiry', now());
      const formData = new FormData();
      formData.set('userid', context.state.id);
      return apiPost('last_seen', formData, 1);
    }
    return false;
  },
};
