<template>
  <form
    id="pillar-group-form"
    ref="pillar-group-form"
    class="tw-max-w-full tw-mx-auto tw-flex-shrink-0 tw-mb-20 auth-card tw-flex tw-flex-wrap"
    method="post"
    action="/pillar-group/create"
    @submit.prevent="onSubmit"
  >

    <div class="tw-w-full tw-mb-4">
      <h1 class="tw-text-4xl font-title">{{ groupId ? 'Edit Pillar Group' : 'Create a Pillar Group' }}</h1>
      <!-- <h6 class="tw-text-base opacity-78 tw-text-app-deep-blue">Please fill all inputs</h6> -->
    </div>

    <div class="tw-w-full tw-mb-4 tw-flex-shrink-0 tw-px-1">
      <InputGroup
        :class="{ '--loading-data': loadingForm }"
        label="Name"
        name="name"
        type="text"
        placeholder="name"
        input-classes="tw-p-3"
        v-model="form.name"
        :error="errors.name"
      />
    </div>
    <!-- <div class="tw-w-full tw-mb-4 tw-flex-shrink-0 tw-px-1">
      <QuillTextareaGroup
        :class="{ '--loading-data': loadingForm }"
        label="Description"
        instruct="Give a small description about the"
        name="description"
        type="text"
        placeholder="name"
        input-classes="tw-p-3 focus:tw-bg-gray-100"
        v-model="form.description"
        :rows="4"
        :error="errors.description"
      />
    </div> -->

    <div class="tw-w-full tw-flex-shrink-0 tw-px-1 tw-mb-4 tw-pt-10">
      <BaseButton
        type="submit"
        :class="[
          'tw-block tw-w-full md:tw-inline-block md:tw-w-auto tw-mb-4 tw-py-3 tw-px-8',
          `${btnClass}`
        ]"
        :text="btnText"
        :disabled="!canSubmit || btnDisabled"
      />
    </div>

  </form>
</template>

<script>
import _debounce from 'lodash/debounce';

// import QuillTextareaGroup from '@/components/QuillTextareaGroup.vue';
import InputGroup from '@/components/InputGroup.vue';
import form from '@/modules/formHelper';

export default {
  name: 'EditPillarGroup',
  components: {
    // QuillTextareaGroup,
    InputGroup,
  },
  props: {
    company: {
      type: Object,
      required: true,
    },
    groupId: {
      type: [String, Number],
      default: undefined,
    },
    btnClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loadingForm: true,
      errorForm: undefined,
      btnText: 'Save',
      btnDisabled: false,
      form: {
        name: undefined,
        description: undefined,
      },
      errors: {},
    };
  },
  watch: {
    // eslint-disable-next-line func-names
    'company.id': function () {
      this.resetForm();
      this.loadForm();
    },
    groupId() {
      this.resetForm();
      this.loadForm();
    },
  },
  computed: {
    canSubmit() {
      return form.isValidTextInput(this.form.name)
        && !this.loadingForm
        // && form.isValidTextInput(this.form.description)
        && this.company.id !== undefined;
    },
  },
  methods: {
    resetForm() {
      this.form = {
        name: undefined,
        description: undefined,
      };
    },
    // eslint-disable-next-line func-names
    loadForm: _debounce(async function () {
      this.loadingForm = true;
      this.errorForm = undefined;

      // * get pillar group details if id is defined
      if (this.groupId) {
        const pillars = await this.$store.dispatch('getPillarGroups', [this.company.id, true]);
        const curr = pillars.find((p) => Number(p.id) === Number(this.groupId)) || {};

        this.form.name = curr.goal_group || '';
        this.form.description = curr.description || '';
      }

      await this.$nextTick();
      await this.$nextTick();

      this.loadingForm = false;
    }, 300),
    async onSubmit() {
      if (!this.canSubmit) {
        this.$toasted.global.appError({
          errorMessage: 'You can\'t submit the form. Check for empty inputs',
        });

        console.warn('Cannot submit form... \n check for empty inputs');
        return false;
      }

      this.btnDisabled = true;
      this.btnText = 'working...';

      const data = {
        ...(this.form),
        companyId: this.company.id,
        groupId: this.groupId,
      };
      const result = await this.$store.dispatch('savePillarGroup', data);

      if (result) {
        this.$toasted.success('Saved!', { duration: 1000 });
        this.$emit('created-success');

        if (this.groupId) {
          this.$store.dispatch('logAction', this.$store.state.Logger.actionsIndexes.editPillar);
        } else {
          this.$store.dispatch('logAction', this.$store.state.Logger.actionsIndexes.addPillar);
        }
      } else {
        this.$toasted.global.appError({
          errorMessage: 'Saving pillar failed. Please try again later',
        });
      }

      this.btnDisabled = false;
      this.btnText = 'save';

      return result;
    },
  },
  async created() {
    this.errors = { ...this.form };

    await this.$nextTick();
    await this.loadForm();
    await this.$nextTick();

    this.loadingForm = false;
  },
};
</script>

<style>

</style>
