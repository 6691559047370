<template>
  <AppView
    padd-bottom
  >
    <template v-slot:top-app-view>
      <transition name="fade" mode="out-in" appear>
        <CompanyHeader :company="company" :loading="loadingCompany" />
      </transition>
    </template>

    <transition name="fade" mode="out-in" appear>

      <div v-if="errorCompany" class="tw-mt-12" key="errmsg">
        <p class="tw-py-12 tw-text-base tw-text-center" v-html="errorCompany"></p>
      </div>

      <div v-else-if="loadingCompany" class="tw-mt-12" key="lodng">
        <p class="tw-py-12 tw-text-base tw-text-center">Loading...</p>
      </div>

      <div v-else key="bdy">

        <div class="app-view-grid px-body">
          <div class="app-view-grid__col-74">
            <transition name="fade" mode="out-in" appear>
              <div v-if="error" class="tw-bg-red-500 tw-p-4 tw-rounded-10">
                <p class="tw-text-white" v-html="error"></p>
              </div>
              <div v-else-if="loading" class="tw-p-4 tw-rounded-10">
                <p class="tw-italic tw-text-sm tw-text-center tw-text-white">Loading...</p>
              </div>
              <div v-else>
                <portal to="modal-body" key="mdl">
                  <div class="">
                    <EditExpert
                      :company="company"
                      @created-success="closeModal(); getExpert();"
                    />
                  </div>
                </portal>

                <!-- EXPERT -->
                <Expert :expert="expert" :show-view-more-button="false" />
              </div>
            </transition>

            <transition name="fade" mode="out-in" appear>
              <!-- Expert questions -->
              <div v-if="errorQuestions" class="tw-bg-red-500 tw-p-4 tw-rounded-10">
                <p class="tw-text-white" v-html="errorQuestions"></p>
              </div>
              <div v-else-if="loadingQuestions" class="tw-p-4 tw-rounded-10">
                <p class="tw-italic tw-text-sm tw-text-center">Loading questions...</p>
              </div>
              <div v-else>
                <div>
                  <div class="tw-py-4 tw-inline-flex tw-items-center">
                    <p class="tw-text-xs opacity-54">
                      Expert Question Corner
                    </p>
                    <template v-if="expertQuestionCornerStatus === QUESTION_CORNER_OFF || RESCHEDULE === true">
                      <div class="tw-border tw-rounded-10 tw-inline-flex tw-items-end tw-ml-3 tw-pr-2">
                        <InputGroup
                          label="Start date"
                          name="startDate"
                          type="date"
                          placeholder="Start Date"
                          input-classes="tw-p-1"
                          v-model="form.startDate"
                          :error="errors.startDate"
                          class="tw-mr-1 tw-ml-3"
                        />
                        <InputGroup
                          label="End date"
                          name="endDate"
                          type="date"
                          placeholder="End Date"
                          input-classes="tw-p-1"
                          v-model="form.endDate"
                          :error="errors.endDate"
                          class="tw-mr-3"
                        />

                        <BaseButton text="Schedule" class="tw-mb-2" @click="activateExpertQuestionCorner" />
                      </div>

                      <BaseButton v-if="RESCHEDULE" text="Cancel" class="tw-ml-2" @click="RESCHEDULE = false" />
                    </template>

                    <template v-if="expertQuestionCornerStatus === QUESTION_CORNER_ON">
                      <SwitchInput
                        :checked="expertQuestionCornerStatus === QUESTION_CORNER_ON"
                        @change="toggleActivateExpertQuestionCorner"
                        class="tw-ml-4 tw-mr-2"
                      />
                      <p class="tw-text-xs tw-font-bold tw-uppercase tw-text-app-primary-blue">
                        On
                      </p>
                    </template>

                    <template v-if="expertQuestionCornerStatus === QUESTION_CORNER_SCHEDULED && RESCHEDULE === false">
                      <div class="tw-inline-flex tw-ml-3 tw-items-center">
                        <p class="tw-text-xs tw-font-bold tw-uppercase">
                          Scheduled to start from {{ expert.q_start | moment('Do MMMM, YYYY') }} to {{ expert.q_end | moment('Do MMMM, YYYY') }}
                        </p>

                        <BaseButton text="Reschedule" class="tw-ml-4" @click="RESCHEDULE = true" />
                        <BaseButton text="Switch off Export Corner" class="tw-ml-2" @click="deactivateExpertQuestionCorner" />
                      </div>
                    </template>
                  </div>
                  <div class="tw-flex tw-items-start">
                    <div class="tw-w-1/3 tw-p-2">
                      <div class="tw-rounded-10 tw-bg-app-black-11 tw-p-4 tw-w-full tw-h-full tw-relative tw-overflow-auto" style="max-height: 70vh;">
                        <h5 class="tw-text-xs tw-mb-4 opacity-54">Unselected Questions</h5>
                        <div class="tw-flex tw-justify-around tw-flex-col">
                          <!-- loop pending questions -->
                          <div v-for="question in expertUnapprovedQuestions" :key="question.id">
                            <div class="tw-group">
                              <div class="tw-px-2">
                                <p class="tw-text-xs tw-mb-2">
                                  {{ question.question }}
                                </p>
                                <div class="tw-rounded-10 tw-flex-- tw-items-center tw-justify-end transition-fast tw-hidden group-hover:tw-flex">
                                  <BaseButton
                                    type="button"
                                    icon
                                    alt="Return"
                                    text="Decline"
                                    @click="declineQuestion(question.id)"
                                    class="tw-rounded-full tw-border-none tw-px-2 tw-inline-flex tw-items-center"
                                    style="line-height: 0; font-size: 0;"
                                  >
                                    <span slot="icon">
                                      <svg class="tw-w-5 tw-h-5--" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                        viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                                        <g>
                                        <path clip-rule="evenodd"
                                          d="m1.67,20.04c0.05,-0.04 0.08,-0.08 0.14,-0.13l19.38,-15.5c0.67,-0.55 1.45,-0.77 2.31,-0.67c0.88,0.1 1.58,0.49 2.11,
                                          1.18c0.55,0.68 0.77,1.45 0.69,2.32c-0.11,0.86 -0.5,1.57 -1.19,2.12l-12.2,9.77l33.69,0c6.81,0 12.63,2.41 17.44,
                                          7.22c4.81,4.83 7.22,10.65 7.22,17.46c0,6.82 -2.41,12.62 -7.22,17.44c-4.81,4.81 -10.63,7.22 -17.44,7.22l-29.2,
                                          0c-0.88,0 -1.63,-0.31 -2.23,-0.92c-0.61,-0.62 -0.92,-1.36 -0.92,-2.23c0,-0.87 0.31,-1.62 0.92,-2.23c0.61,-0.62
                                          1.36,-0.92 2.23,-0.92l29.2,0c5.06,0 9.39,-1.8 12.97,-5.37c3.59,-3.58 5.38,-7.91 5.38,-12.98c0,-5.07 -1.78,-9.4
                                          -5.38,-12.99c-3.58,-3.58 -7.91,-5.37 -12.97,-5.37l-33.73,0l12.92,10.45c0.69,0.55 1.06,1.26 1.16,2.12c0.09,0.86
                                          -0.14,1.64 -0.69,2.31c-0.55,0.68 -1.25,1.07 -2.13,1.17c-0.84,0.09 -1.63,-0.13 -2.31,-0.68l-19.89,-16.06c-0.19,-0.15
                                          -0.33,-0.3 -0.47,-0.47c-0.2,-0.25 -0.38,-0.52 -0.48,-0.81c-0.02,-0.03 -0.02,-0.07 -0.03,-0.09c0,-0.02 -0.02,-0.03
                                          -0.02,-0.05c-0.02,-0.05 -0.03,-0.1 -0.05,-0.15c-0.09,-0.29 -0.14,-0.58 -0.13,-0.89l0.02,-0.27c0,-0.03 0,-0.06 0,
                                          -0.09l0.09,-0.5c0.05,-0.14 0.08,-0.28 0.14,-0.41c0.03,-0.05 0.05,-0.11 0.08,-0.16l0.17,-0.3c0.13,-0.18 0.27,-0.35
                                          0.42,-0.51z" fill-rule="evenodd" id="svg_1"/>
                                        </g>
                                      </svg>
                                    </span>
                                  </BaseButton>
                                  <BaseButton
                                    type="button"
                                    icon
                                    alt="Select"
                                    text="Select"
                                    @click="approveQuestion(question.id)"
                                    class="tw-rounded-full tw-border-none tw-px-2 tw-bg-app-black-11 hover:tw-bg-gray-100 tw-inline-flex tw-items-center"
                                  >
                                    <span slot="icon">
                                      <svg class="tw-w-5 tw-h-5--" version="1.1" id="icon-right" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                                        y="0px" viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                                        <path d="M16,67.8c0-1.2,0.4-2,1.2-2.9l29-29l-29-29c-1.6-1.6-1.6-4.1,0-5.7c1.6-1.6,4.1-1.6,5.7,0l31.9,31.9c1.6,1.6,1.6,4.1,0,5.7
                                        L22.9,70.8c-1.6,1.6-4.1,1.6-5.7,0C16.4,69.9,16,69,16,67.8z" />
                                      </svg>
                                    </span>
                                  </BaseButton>
                                </div>
                              </div>
                              <div class="tw-border-b tw-border-app-black-11 tw-my-2"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tw-w-1/3 tw-p-2">
                      <div class="tw-rounded-10 tw-bg-app-black-11 tw-p-4 tw-w-full tw-h-full tw-relative tw-overflow-auto tw-mb-2" style="max-height: 70vh;">
                        <h5 class="tw-text-xs tw-mb-4 opacity-54">Selected Unanswered Questions</h5>
                        <div class="tw-flex tw-justify-around tw-flex-col">
                          <!-- loop pending questions -->
                          <div v-for="question in expertApprovedQuestions" :key="question.id">
                            <div class="">
                              <div class="tw-px-2">
                                <p class="tw-text-xs tw-mb-2">
                                  {{ question.question }}
                                </p>
                              </div>
                              <div class="tw-border-b tw-border-app-black-11 tw-my-2"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="tw-rounded-10 tw-bg-app-black-11 tw-p-4 tw-w-full tw-h-full tw-relative tw-overflow-auto" style="max-height: 70vh;">
                        <h5 class="tw-text-xs tw-mb-4 opacity-54">Declined Questions</h5>
                        <div class="tw-flex tw-justify-around tw-flex-col">
                          <!-- loop declined questions -->
                          <div v-for="question in expertDeclinedQuestions" :key="question.id">
                            <div class="">
                              <div class="tw-px-2">
                                <p class="tw-text-xs tw-mb-2">
                                  {{ question.question }}
                                </p>
                              </div>
                              <div class="tw-border-b tw-border-app-black-11 tw-my-2"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tw-w-1/3 tw-p-2">
                      <div class="tw-rounded-10 tw-bg-app-black-11 tw-p-4 tw-w-full tw-h-full tw-relative tw-overflow-auto" style="max-height: 70vh;">
                        <h5 class="tw-text-xs tw-mb-4 opacity-54">Answered Questions</h5>
                        <div class="tw-flex tw-justify-around tw-flex-col">
                          <!-- loop pending questions -->
                          <div v-for="question in expertAnsweredQuestions" :key="question.id">
                            <div class="">
                              <div class="tw-px-2">
                                <p class="tw-text-xs tw-mb-2">
                                  {{ question.question }}
                                </p>
                              </div>
                              <div class="tw-border-b tw-border-app-black-11 tw-my-2"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </transition>
          </div>
        </div>
      </div>
    </transition>
  </AppView>
</template>

<script>
import CompanyHeader from '@/views/Companies/Company/_partials/CompanyHeader.vue';
import EditExpert from '@/views/Companies/Company/_partials/EditExpert.vue';
import Expert from '@/components/Expert.vue';
import SwitchInput from '@/components/SwitchInput.vue';
import InputGroup from '@/components/InputGroup.vue';

import companyMixin from '@/mixins/company';
import { toJsDate } from '@/modules/dataHelper';

const defaultDeleteButtonText = 'Cancel Meeting';

export default {
  name: 'ExpertView',
  mixins: [companyMixin],
  components: {
    CompanyHeader,
    EditExpert,
    Expert,
    SwitchInput,
    InputGroup,
  },
  props: ['id', 'expertId'],
  data() {
    return {
      error: undefined,
      loading: true,
      errorQuestions: undefined,
      loadingQuestions: true,
      maxVisibleParticipants: 10,
      expert: {},
      expertUnapprovedQuestions: [],
      expertApprovedQuestions: [],
      expertDeclinedQuestions: [],
      expertAnsweredQuestions: [],
      newForm: false,
      form: {
        startDate: '',
        endDate: '',
        meeting: {},
      },
      errors: {
        startDate: '',
        endDate: '',
      },
      deleteButtonDisabled: false,
      deleteButtonText: defaultDeleteButtonText,
      QUESTION_CORNER_OFF: 0,
      QUESTION_CORNER_ON: 1,
      QUESTION_CORNER_SCHEDULED: 2,
      RESCHEDULE: false,
    };
  },
  computed: {
    expertQuestionCornerStatus() {
      return Number(this.expert?.swtch);
    },
  },
  methods: {
    async getExpert() {
      this.loading = true;

      await this.$nextTick();

      const expert = await this.$store.dispatch('getExpert', this.expertId);

      if (expert) {
        this.expert = expert;
      }

      this.loading = false;
    },
    async getExpertQuestions() {
      this.loadingQuestions = true;

      const formData = {
        expertid: this.expert.id,
        companyid: this.company.id,
      };

      this.$store.dispatch('getExpertUnapprovedQuestions', formData).then((questions) => {
        if (Array.isArray(questions)) {
          this.expertUnapprovedQuestions = questions;
        } else {
          // error
          console.warn('getExpertUnapprovedQuestions::error');
        }
      });

      this.$store.dispatch('getExpertApprovedQuestions', formData).then((questions) => {
        if (Array.isArray(questions)) {
          this.expertApprovedQuestions = questions;
        } else {
          // error
          console.warn('getExpertApprovedQuestions::error');
        }
      });

      this.$store.dispatch('getExpertDeclinedQuestions', formData).then((questions) => {
        if (Array.isArray(questions)) {
          this.expertDeclinedQuestions = questions;
        } else {
          // error
          console.warn('getExpertDeclinedQuestions::error');
        }
      });

      this.$store.dispatch('getExpertAnsweredQuestions', formData).then((questions) => {
        if (Array.isArray(questions)) {
          this.expertAnsweredQuestions = questions;
        } else {
          // error
          console.warn('getExpertApprovedQuestions::error');
        }
      });

      this.loadingQuestions = false;
    },
    async approveQuestion(questionId) {
      this.$toasted.info('Approving question...', { duration: 4000 });

      const response = await this.$store.dispatch('approveQuestion', questionId);

      if (response) {
        // get all questions
        await this.getExpertQuestions();
      }
    },
    async declineQuestion(questionId) {
      this.$toasted.info('Declining question...', { duration: 4000 });

      const response = await this.$store.dispatch('declineQuestion', questionId);

      if (response) {
        // get all questions
        await this.getExpertQuestions();
      }
    },
    toggleActivateExpertQuestionCorner() {
      if (this.expertQuestionCornerStatus === this.QUESTION_CORNER_ON) {
        // deactivate
        this.deactivateExpertQuestionCorner();
      } else {
        // activate
        // this.activateExpertQuestionCorner();
      }
    },
    async deactivateExpertQuestionCorner() {
      this.$toasted.info('Switching off the question corner...', { duration: 4500 });

      const result = await this.$store.dispatch('deactivateExpertQuestionCorner', {
        companyid: this.expert.companyid,
        expertid: this.expert.id,
      });

      if (result) {
        await this.getExpert();
      } else {
        // toast error
        this.$toasted.global.appError();
      }
    },
    async activateExpertQuestionCorner() {
      const { startDate, endDate } = this.form;

      if (!startDate?.length || !endDate?.length) {
        this.$toasted.info('Please select the timeframe for the question corner.', { duration: 6000 });
        return;
      }

      const result = await this.$store.dispatch('activateExpertQuestionCorner', {
        companyid: this.expert.companyid,
        expertid: this.expert.id,
        start: this.$moment(toJsDate(startDate)).format('YYYY-MM-DD'),
        end: this.$moment(toJsDate(endDate)).format('YYYY-MM-DD'),
      });

      if (result) {
        await this.getExpert();
        this.RESCHEDULE = false;
      } else {
        // toast error
        this.$toasted.global.appError();
      }
    },
  },
  async created() {
    // get company
    await Promise.all([
      this.getCompany(),
    ]);

    await this.$nextTick();

    // assert user company permissions
    if (!this.hasPermission) {
      this.$router.push({
        name: 'app',
      });

      return;
    }

    await this.getExpert();
    await this.getExpertQuestions();

    await this.$nextTick();
    await this.$nextTick();

    this.loading = false;
  },
};
</script>

<style>

</style>
