<template>
  <div class="tw-inline-flex tw-flex-col tw-items-center tw-rounded-10 tw-p-2 hover:tw-bg-gray-300 tw-transition-all tw-group">
    <div class="tw-relative">
      <UserPhoto
        logo
        class="tw-mb-2 tw-inline-block"
        photo-size="tw-h-20 tw-w-20 tw-rounded-10"
        :photo="badge.url"
      />

      <a @click.prevent="deleteBadge" href="#!" class="tw-p-1 tw-rounded-full tw-bg-red-100 tw-text-red-500 tw-absolute tw-top-0 tw-opacity-0 group-hover:tw-opacity-100 position-badge-pill">
        <svg class="tw-fill-current tw-h-6 tw-w-6" version="1.1" id="icon-delete" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
          y="0px" viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
          <path d="M63.1,15.2V64c0,4.4-3.6,8-8,8h-38c-4.4,0-8-3.6-8-8V15.2c0-1.1,0.8-1.9,1.9-1.9s1.9,0.8,1.9,1.9V64c0,2.3,1.9,4.2,4.2,4.2
            h38c2.3,0,4.2-1.9,4.2-4.2V15.2c0-1.1,0.8-1.9,1.9-1.9S63.1,14.2,63.1,15.2z M66.3,4.9H38v-3C38,0.8,37.2,0,36.1,0
            c-1.1,0-1.9,0.8-1.9,1.9v3H5.9C4.7,4.9,4,5.7,4,6.8s0.8,1.9,1.9,1.9h60.2C67.3,8.7,68,8,68,6.8S67.3,4.9,66.3,4.9z M24.7,58.9V17.1
            c0-1.1-0.8-1.9-1.9-1.9s-1.9,0.8-1.9,1.9v41.8c0,1.1,0.8,1.9,1.9,1.9S24.7,60,24.7,58.9z M38,58.9V17.1c0-1.1-0.8-1.9-1.9-1.9
            c-1.1,0-1.9,0.8-1.9,1.9v41.8c0,1.1,0.8,1.9,1.9,1.9C37.2,60.8,38,60,38,58.9z M51.3,58.9V17.1c0-1.1-0.8-1.9-1.9-1.9
            s-1.9,0.8-1.9,1.9v41.8c0,1.1,0.8,1.9,1.9,1.9S51.3,60,51.3,58.9z"/>
        </svg>
      </a>
    </div>

    <p class="tw-text-center tw-max-w-xs tw-text-sm">
      {{badge.badge_name}}
    </p>
  </div>
</template>

<script>
import { apiUrl } from '@/modules/apiHelper';
import UserPhoto from '@/components/UserPhoto.vue';

export default {
  name: 'JourneyBadge',
  components: {
    UserPhoto,
  },
  props: {
    badge: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      resource: {},
    };
  },
  computed: {
    badgeUrl() {
      return `${apiUrl}/${this.badge.url}`;
    },
  },
  methods: {
    deleteBadge() {
      this.$emit('delete-badge', this.badge);
    },
  },
  created() {
    // ? fetch the resource?
  },
};
</script>

<style>
.position-badge-pill {
  right: -12px;
}
</style>
