import resolve from '../../../modules/api/resolve';
import { apiPost, generateFormData } from '../../../modules/apiHelper';

export default {
  async getCompanyGoalsBadges(context, companyId) {
    const companyGoals = await context.dispatch('getGoals', [companyId]);

    const result = await resolve(apiPost('goal_badge_list', generateFormData({ companyid: companyId }), 74))
      .then((res) => {
        if (!res) return false;

        const companyGoalsBadges = res.goal_badge_list.map((goalBadge) => {
          // eslint-disable-next-line no-param-reassign
          goalBadge.goal = companyGoals.find((goal) => Number(goal.id) === Number(goalBadge.goalid)) || {};

          return goalBadge;
        });

        return companyGoalsBadges;
      });
    //

    return result;
  },

  async createGoalBadge(context, data) {
    const formData = generateFormData({
      goalid: data.goalId,
      name: data.name,
      description: data.description,
      companyid: data.companyId,
      userid: context.rootState.User.id,
    });
    formData.append('file1', data.badge);

    const result = await resolve(apiPost('create_goal_badge', formData, 74));

    return result;
  },
};
