export default {
  state: {
    openModal: false,
    selfCloseModal: true,
    modalType: 'modal',
    modalAcceptFn: () => {},
    dialogMessage: 'Are You Sure?',
  },
  getters: {},
  mutations: {
    openModal(
      state,
      [
        open = true,
        type = 'modal', // full-screen | modal
        selfClose = true,
        onAcceptFn = () => {},
        dialogMessage = 'Are you sure?',
      ],
    ) {
      state.dialogMessage = dialogMessage;
      state.modalAcceptFn = onAcceptFn;
      state.openModal = open;

      if (open) {
        state.modalType = type;
        state.selfCloseModal = selfClose;
      } else {
        setTimeout(() => {
          state.selfCloseModal = selfClose;
          state.modalType = type;
        }, 500);
      }
    },
  },
  actions: {},
};
