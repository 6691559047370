import Login from '../views/Auths/Login.vue';
import ForgotPassword from '../views/Auths/ForgotPassword.vue';

const meta = {
  requiresLogin: false,
  requiresUserType: false,
};

export default [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta,
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword,
    meta,
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    meta,
    component() {
      return import(/* webpackChunkName: "authsviews" */'../views/Auths/ResetPassword.vue');
    },
  },
  {
    path: '/verify-account',
    name: 'verify-account',
    meta,
    component() {
      return import(/* webpackChunkName: "authsviews" */'../views/Auths/EmailVerify.vue');
    },
  },
  {
    path: '/verify',
    name: 'do-verify-account',
    meta,
    component() {
      return import(/* webpackChunkName: "authsviews" */'../views/Auths/EmailVerify.vue');
    },
  },
];
