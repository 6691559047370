import store from '../../store';
import CommunicationsView from '../../views/Communications/CommunicationsView.vue';

const meta = {
  requiresLogin: true,
  requiresUserType: store.state.User.accountTypes.staff,
};

export default [
  {
    path: 'communications',
    name: 'communications',
    component: CommunicationsView,
    meta,
  },
];
