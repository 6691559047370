<template>
  <AppView
    nav="left-fixed"
    nav-class="tw-bg-app-white-32 tw-shadow-app"
    padd-bottom
    :show-bottom-bar="isChatView"
  >

    <template v-slot:nav>
      <h4 class="tw-text-xl tw-leading-6 tw-font-bold tw-uppercase tw-pl-5 tw-pt-6">
        {{mentorshipGroup?.group_name}}
      </h4>

      <transition-group name="new" mode="out-in" appear>
        <template v-if="!loading && !loadingCompany">
          <div key="menu" class="tw-w-full tw-h-full tw-relative tw-pl-5">
            <div style="margin-top: 86px;"></div>

            <ul
              class="app-view-menu-list transition-fast tw-mt-10"
            >
              <router-link
                tag="li"
                class="app-view-menu-list__item tw-rounded-l-full tw-py-4 tw-pl-8 tw-w-full tw-cursor-pointer hover:tw-bg-gray-300 transition-fast"
                :to="{ name: 'companyMentorshipGroupChat' }"
                v-html="'Chat'"
              />
              <router-link
                tag="li"
                class="app-view-menu-list__item tw-rounded-l-full tw-py-4 tw-pl-8 tw-w-full tw-cursor-pointer hover:tw-bg-gray-300 transition-fast"
                :to="{ name: 'companyMentorshipGroupTasks' }"
                v-html="'Tasks'"
              />
              <router-link
                tag="li"
                class="app-view-menu-list__item tw-rounded-l-full tw-py-4 tw-pl-8 tw-w-full tw-cursor-pointer hover:tw-bg-gray-300 transition-fast"
                :to="{ name: 'companyMentorshipGroupSettings' }"
                v-html="'Settings'"
              />
            </ul>
          </div>
        </template>
      </transition-group>
    </template>

    <template v-slot:default="{ recalculate }">
      <template v-if="errorCompany || error">
        <p v-html="errorCompany || error" class="tw-p-4 tw-text-center tw-text-red-500"></p>
      </template>
      <template v-else-if="loadingCompany || loading">
        <p class="tw-p-5 tw-text-center tw-italic opacity-78">Loading...</p>
      </template>
      <router-view
        v-else
        @mounted="recalculate();"
        @get-mentorship-group="getCompanyMentorshipGroup()"
        ref="mentorship-group"
        :company="company"
        :mentorship-group="mentorshipGroup"
      ></router-view>
    </template>
  </AppView>
</template>

<script>
import companyMixin from '@/mixins/company';

export default {
  name: 'MentorshipGroupView',
  mixins: [companyMixin],
  props: ['id', 'groupId'],
  data() {
    return {
      error: undefined,
      loading: true,
      mentorshipGroup: {},
    };
  },
  computed: {
    isChatView() {
      return this.$route.name === 'companyMentorshipGroupChat';
    },
  },
  methods: {
    async getCompanyMentorshipGroup() {
      this.error = undefined;

      const mentorshipGroup = await this.$store.dispatch('getCompanyMentorshipGroup', this.groupId);

      if (!mentorshipGroup?.id) {
        this.error = 'Error finding mentorship group';
        return;
      }

      this.mentorshipGroup = { ...mentorshipGroup };
    },
  },
  async created() {
    // get company
    await Promise.all([
      this.getCompany(),
    ]);

    await this.$nextTick();

    // assert user company permissions
    if (!this.hasPermission) {
      this.$router.push({
        name: 'app',
      });

      return;
    }

    await this.getCompanyMentorshipGroup();

    await this.$nextTick();
    await this.$nextTick();

    this.loading = false;

    // route to default route
    if (this.$route.name === 'companyMentorshipGroup') {
      this.$router.push({
        name: 'companyMentorshipGroupChat',
      });
    }
  },
};
</script>

<style>

</style>
