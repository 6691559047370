import goals from './goals';
import messages from './messages';
// import links from './links';
import logo from './logo';
import theme from './theme';
import journeys from './journeys';
import goalsBadges from './goals-badges';

export default {
  ...goals,
  ...messages,
  // ...links,
  ...logo,
  ...theme,
  ...journeys,
  ...goalsBadges,
};
