import { apiPost, apiStore, generateFormData } from '../../../modules/apiHelper';
import { isStaleData, invalideDate, now } from '../../../modules/dataHelper';
import resolve from '../../../modules/api/resolve';

export default {
  async getPillarGroups(context, [CompanyId, refresh = false]) {
    const companyid = CompanyId || context.rootState.User.companyid;
    if (!companyid) return false;

    const pillars = await apiStore.getItem(`pillars/${companyid}`) || [invalideDate, []];

    if (!refresh && !isStaleData(pillars[0]) && pillars[1].length) return pillars[1];

    const result = await resolve(apiPost('company-pillars', generateFormData({ companyid }), 7)).then((data) => {
      if (!data) return false;
      // console.log(data);

      const companyPillars = data['company-pillars'] || [];

      if (companyPillars.length > 0) {
        apiStore.setItem(`pillars/${companyid}`, [now(), companyPillars]);
      }

      return companyPillars;
    });

    return result;
  },

  async getGoals(context, [CompanyId, refresh = false]) {
    const companyid = CompanyId || context.rootState.User.companyid;
    if (!companyid) return false;

    const goals = await apiStore.getItem(`goals/${companyid}`) || [invalideDate, []];

    if (!refresh && !isStaleData(goals[0]) && goals[1].length) return goals[1];

    const result = await resolve(apiPost('company-goals', generateFormData({ companyid }), 7)).then((data) => {
      if (!data) return false;
      // console.log(data);

      const companyGoals = data['company-goals'] || [];

      if (companyGoals.length > 0) {
        apiStore.setItem(`goals/${companyid}`, [now(), companyGoals]);
      }

      return companyGoals;
    });

    return result;
  },

  async getSubGoals(context, [CompanyId, refresh = false]) {
    const companyid = CompanyId || context.rootState.User.companyid;
    if (!companyid) return false;

    const subGoals = await apiStore.getItem(`subgoals/${companyid}`) || [invalideDate, []];

    if (!refresh && !isStaleData(subGoals[0]) && subGoals[1].length) return subGoals[1];

    const result = await resolve(apiPost('company-subgoals', generateFormData({ companyid }), 7)).then((data) => {
      if (!data) return false;
      // console.log(data);

      const companySubGoals = data['company-subgoals'] || [];

      if (companySubGoals.length > 0) {
        apiStore.setItem(`subgoals/${companyid}`, [now(), companySubGoals]);
      }

      return companySubGoals;
    });

    return result;
  },

  async savePillarGroup(context, data) {
    const createPillar = async () => {
      const pillarReq = await resolve(apiPost(
        'add_pillar',
        generateFormData({
          pillar_name: data.name,
          companyid: data.companyId,
        }),
        7,
      ));

      return pillarReq;
    };

    const updatePillar = async () => {
      const pillarrNameReq = resolve(apiPost(
        'edit_pillar_name',
        generateFormData({
          pillar_name: data.name,
          pillarid: data.groupId,
        }),
        7,
      ));

      // const pillarGroupReq = resolve(apiPost(
      //   'edit_pillar_company',
      //   generateFormData({
      //     pillarid: data.groupId,
      //     companyid: data.companyId,
      //   }),
      //   7,
      // ));

      const result = await Promise.all([
        pillarrNameReq,
        // pillarGroupReq,
      ]);

      return result;
    };

    let result;

    if (data.groupId) {
      // updating
      result = await updatePillar();
    } else {
      // creating
      result = await createPillar();
    }

    // console.log(result);

    return result;
  },

  async saveGoal(context, data) {
    const createGoal = async () => {
      const goalReq = await resolve(apiPost(
        'add_goal',
        generateFormData({
          goal_name: data.name,
          groupid: data.groupId,
          companyid: data.companyId,
          desc: data.description,
        }),
      ));

      return goalReq;
    };

    const updateGoal = async () => {
      const goalGroupReq = resolve(apiPost(
        'edit_goal_pillar',
        generateFormData({
          goalid: data.goalId,
          pillarid: data.groupId,
        }),
        7,
      ));

      const goalNameReq = resolve(apiPost(
        'edit_goal_name',
        generateFormData({
          goalid: data.goalId,
          goal: data.name,
        }),
      ));

      const goalDescReq = resolve(apiPost(
        'edit_goal_desc',
        generateFormData({
          goalid: data.goalId,
          desc: data.description,
        }),
      ));

      const result = await Promise.all([
        goalGroupReq,
        goalNameReq,
        goalDescReq,
      ]);

      console.log(result);

      return result.every((r) => !!r);
    };

    let result;

    if (data.goalId) {
      // updating
      result = await updateGoal();
    } else {
      // creating
      result = await createGoal();
    }

    // console.log(result);

    return result;
  },

  async saveSubGoal(context, data) {
    const createSubGoal = async () => {
      const goalReq = await resolve(apiPost(
        'add_subgoal',
        generateFormData({
          goalid: data.goalId,
          companyid: data.companyId,
          sub_goal_name: data.name,
        }),
      ));

      return goalReq;
    };

    // TODO: edit subgoal [goalid]
    const updateSubGoal = async () => {
      const goalNameReq = resolve(apiPost(
        'edit_subgoal_name',
        generateFormData({
          subgoalid: data.subGoalId,
          subgoal_name: data.name,
        }),
      ));

      const goalIdReq = resolve(apiPost(
        'edit_goalid',
        generateFormData({
          subgoal_id: data.subGoalId,
          goalid: data.goalId,
        }),
        7,
      ));

      const result = await Promise.all([
        goalNameReq,
        goalIdReq,
      ]);

      console.log(result);

      return result.every((r) => !!r);
    };

    let result;

    if (data.subGoalId) {
      // updating
      result = await updateSubGoal();
    } else {
      // creating
      result = await createSubGoal();
    }

    // console.log(result);

    return result;
  },

  async deletePillarGroup(context, pillarid) {
    const result = (await resolve(apiPost('del_pillar_company', generateFormData({ pillarid }), 7)));

    if (result) {
      context.dispatch('clearGoalsTypeCache', 'pillars/');
    }

    return result;
  },

  async deleteGoal(context, goalid) {
    const result = (await resolve(apiPost('del_goal', generateFormData({ goalid }))));

    if (result) {
      context.dispatch('clearGoalsTypeCache', 'goals/');
    }

    return result;
  },

  async deleteSubGoal(context, subgoalid) {
    const result = (await resolve(apiPost('del_subgoal', generateFormData({ subgoalid }))));

    if (result) {
      context.dispatch('clearGoalsTypeCache', 'subgoals/');
    }

    return result;
  },

  async clearGoalsTypeCache(context, goalTypeCacheName) {
    if (!goalTypeCacheName) return;

    const regex = new RegExp(`(${goalTypeCacheName})`, 'gi');

    const cacheKeys = await apiStore.keys();
    const goalTypeCacheKeys = cacheKeys.filter((key) => regex.test(key));

    goalTypeCacheKeys.forEach((key) => apiStore.removeItem(key));
  },
};
