var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-relative"},[_c('div',{class:[
    'tw-flex tw-items-center tw-justify-between tw-cursor-pointer tw-rounded-r-full',
    { 'tw-bg-gray-200': _vm.isOpen }
    ],on:{"click":_vm.toggleBody}},[_c('div',{staticClass:"tw-pr-4 tw-flex-grow"},[_vm._t("header")],2),_c('button',{staticClass:"tw-rounded-full tw-border tw-bg-gray-200 tw-p-3 transition-fast focus:tw-bg-gray-400 active:tw-bg-gray-400"},[_c('svg',{staticClass:"tw-w-5 tw-h-5 transition-fast",style:(`transform: rotate(${_vm.isOpen ? '180deg' : '0deg'}); transform-origin: center;`),attrs:{"data-name":"Icon/Down","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","width":"24","height":"24","viewBox":"0 0 24 24"}},[_c('defs',[_c('clipPath',{attrs:{"id":"clip-path"}},[_c('rect',{attrs:{"id":"Rectangle_21","data-name":"Rectangle 21","width":"24","height":"24","fill":"none"}})])]),_c('g',{attrs:{"id":"Mask_Group_23","data-name":"Mask Group 23","transform":"translate(24) rotate(90)","clip-path":"url(#clip-path)"}},[_c('path',{attrs:{"id":"noun_right_1701594","d":"M0,16.95a1,1,0,0,1,.3-.725l7.25-7.25L.3,1.725A1,1,0,0,1,.3.3,1,1,0,0,1,1.725.3L9.7,8.275A1,1,0,0,1,9.7,9.7l-7.975,8A1,1,0,0,1,.3,17.7,1.059,1.059,0,0,1,0,16.95Z","transform":"translate(7 3)"}})])])])]),_c('div',{ref:"body",class:[
      'transition-slow tw-pt-2',
      {'tw-overflow-hidden': _vm.bodyHeight < _vm.defaultMaxHeight }, // how to make 'overflow: visible' after it has opened
      {'tw--ml-5 tw-pl-8': _vm.offsetBody },
      {'tw--mx-5 tw-px-5': !_vm.offsetBody },
      {'tw-overflow-auto': _vm.bodyHeight > _vm.defaultMaxHeight },
      _vm.bodyClass
    ],style:({ maxHeight: `${_vm.bodyHeight}px` })},[_vm._t("body",null,{"update":_vm.updateAccordionBody})],2),_c('div',{staticClass:"tw-border tw-w-full opacity-54 tw-mb-2"})])
}
var staticRenderFns = []

export { render, staticRenderFns }