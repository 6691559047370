<template>
  <div class="tw-mb-2 tw-block">
    <slot name="input-label">
      <label class="tw-block tw-text-sm tw-mb-2 opacity-78 tw-text-left" :for="name" :aria-label="name">
        {{ label }}
      </label>
    </slot>
    <label
      v-if="instruct.length > 0"
      class="tw-block tw-text-left tw-text-xs tw-mb-2 tw--mt-3 opacity-54"
      v-html="instruct"
    >
    </label>

    <BaseInput
      :id="id"
      :class="inputClasses"
      :type="type"
      :name="name"
      :accepts="accepts"
      :placeholder="placeholder"
      v-model="inputValue"
      :value="value"
    />
      <!-- :name="name" -->
    <slot name="error-message">
      <p class="tw-text-red-500 tw-text-xs tw-italic tw-text-left" v-if="showError" slot> {{ error }} </p>
    </slot>
  </div>
</template>

<script>
import BaseInput from '@/components/BaseInput.vue';


export default {
  name: 'InputGroup',
  components: {
    BaseInput,
  },
  props: {
    label: {
      type: String,
      default: '',
      required: false,
    },
    instruct: {
      type: String,
      default: '',
      required: false,
    },
    id: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    name: {
      type: String,
      required: false,
    },
    value: {
      default: undefined,
    },
    inputClasses: {
      type: String,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    accepts: {
      type: String,
      required: false,
    },
    error: {
      type: String,
      default: '',
      required: false,
    },
  },
  data() {
    return {
      inputValue: this.value,
    };
  },
  computed: {
    showError() {
      return this.error.length > 0;
    },
  },
  watch: {
    inputValue(val) {
      // console.log('inputValue');
      this.$emit('input', val);
    },
    value(val) {
      // console.log(val, 'val');
      this.inputValue = val;
    },
  },
};
</script>

<style>

</style>
