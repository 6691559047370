import resolve from '../../modules/api/resolve';
import { apiPost, generateFormData } from '../../modules/apiHelper';

export default {
  async logAction(context, actionId) {
    const formData = {
      pageid: actionId,
      companyid: context.rootState.User.companyid, // ? where to get companyid at login action?
      userid: context.rootState.User.id, // ? where to get userid at login action?
    };

    await apiPost('activity_log_ad', JSON.stringify(formData), 3, { 'Content-Type': 'application/json' });
  },

  async getCompanyLogs(context, companyId) {
    const companyid = companyId || context.state.User.companyid;

    return resolve(apiPost('activity_pages_ad_log', generateFormData({ companyid }), 52));
  },
};
