export default {
  userLogIn(state, userData) {
    state.id = userData.userid;
    state.url = userData.url;
    state.type = Number(userData.level) || Number(userData.type) || undefined;
    state.licensetype = userData.licensetype;
    state.companyid = userData.companyid;
  },
  updateUser(state, [index, data]) {
    state[index] = data;
  },
  authError(state, err) {
    state.authError = err;
  },
  clearUserSession(state) {
    state.id = undefined;
    state.url = undefined;
    state.name = undefined;
    state.type = undefined;
    state.licensetype = undefined;
    state.companyid = undefined;
  },
};
