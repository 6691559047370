<template>
  <div class="tw-rounded-3 tw-shadow-app" @click="clicked">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'BaseCard',
  methods: {
    clicked($ev) {
      this.$emit('click', $ev);
    },
  },
};
</script>

<style>

</style>
