<template>
  <AppView
    padd-bottom
  >

    <template v-slot:top-app-view>
      <transition name="fade" mode="out-in" appear>
        <CompanyHeader :company="company" :loading="loadingCompany" />
      </transition>
    </template>

    <transition name="fade" mode="out-in" appear>

      <div v-if="errorCompany || error" class="tw-mt-12" key="errmsg">
        <p class="tw-py-12 tw-text-base tw-text-center" v-html="errorCompany"></p>
      </div>

      <div v-else-if="loadingCompany || loading" class="tw-mt-12" key="lodng">
        <p class="tw-py-12 tw-text-base tw-text-center">Loading...</p>
      </div>

      <div v-else key="bdy">
        <transition name="fade" mode="out-in" appear>
          <div class="app-view-grid px-body">
            <div class="app-view-grid__col-74">
              <div class="tw-flex tw-justify-between">
                <div>
                  <h3 class="tw-text-2xl">Survey Responses</h3>
                  <h5 class="tw-text-base">Survey name: <span class="tw-font-bold">{{survey.pillar}}</span></h5>
                  <h5 class="tw-text-sm tw-mb-0">
                    Appears when -> <span class="tw-underline">{{getSurveyTypeName(survey.type)}}</span>
                  </h5>
                </div>
                <div>
                  <BaseButton
                    :text="reportBtnText"
                    class="tw-border-none tw-font-bold hover:tw-shadow-app transition-fast tw-bg-app-deep-blue-11 tw-block tw-py-3 tw-px-6 tw-mr-4"
                    :disabled="disabledReportBtn"
                    @click="generateReport()"
                  />
                </div>
              </div>
              <div class="tw-border-t tw-border-app-black-11 tw-my-6"></div>

              <template v-for="(questionWithResponses, index) in questionsWithResponses">
                <div :key="index" class="tw-rounded-10 tw-bg-gray-200 tw-mb-3 tw-p-4">
                  <p class="tw-text-lg tw-mb-4">{{questionWithResponses.criteria}}</p>
                  <template v-if="!questionWithResponses.responses.length">
                    <p class="tw-text-center tw-p-5 opacity-54 tw-bg-gray-300 tw-rounded-10">0 responses yet</p>
                  </template>
                  <template v-else>
                    <p>Responses</p>
                    <template v-if="questionWithResponses.questionType == 'ratings'">
                      <div style="height: 250px; width: 250px;" class="tw-relative">
                        <PieChart
                          :data="[
                            ...Object.values(questionWithResponses.data).map(d => d.counts || 0),
                          ]"
                          :data-labels="[
                            ...Object.values(questionWithResponses.data).map(d => `${d.label} (${d.countPercentage})`),
                          ]"
                          :data-colors="[
                            '#000000',
                            '#EDA310',
                            '#E96B6B',
                            'rgba(237, 163, 16, 0.11)',
                            '#B2967D',
                            '#98C9A3'
                          ]"
                          :height="250"
                          :width="250"
                        />
                      </div>
                    </template>
                    <template v-else>
                      <ol class="tw-bg-gray-300 tw-p-3 tw-rounded-10">
                        <ViewMore
                          :limit="6"
                          :list="questionWithResponses.responses"
                          v-slot="{ item: response }"
                        >
                          <li
                            v-if="response.ratesid.length"
                            :key="response.id"
                            class="tw-border-b tw-border-gray-400 tw-py-2 tw-text-sm"
                          >
                            <span>{{response.user.name}}: </span> {{response.ratesid}}
                          </li>
                        </ViewMore>
                      </ol>
                    </template>
                  </template>
                </div>
              </template>

            </div>
          </div>
        </transition>
      </div>
    </transition>
  </AppView>
</template>

<script>
import CompanyHeader from '@/views/Companies/Company/_partials/CompanyHeader.vue';
import PieChart from '@/components/PieChart.vue';

import companyMixin from '@/mixins/company';
import reportMixin from '@/mixins/report';
import xlsx from 'json-as-xlsx';

export default {
  name: 'CompanySurveyResponses',
  components: {
    CompanyHeader,
    PieChart,
  },
  mixins: [companyMixin, reportMixin],
  props: ['id', 'surveyId'],
  data() {
    return {
      error: undefined,
      loading: true,
      survey: {},
      questionsWithResponses: [],
    };
  },
  computed: {
    surveyTypes() {
      return this.$store.state.Surveys.surveyTypes;
    },
  },
  methods: {
    getSurveyTypeName(id) {
      return (this.surveyTypes.find((type) => Number(type.id) === Number(id)) || {}).type || 'any';
    },
    async getSurvey() {
      const surveys = await this.$store.dispatch('getCompanySurveys', [this.id, true]);

      if (!Array.isArray(surveys)) {
        this.error = 'Could not get Survey';
        return false;
      }

      const survey = surveys.find((suv) => Number(suv.id) === Number(this.surveyId));

      this.survey = survey;

      return true;
    },
    // gets the questions and the responses and the users
    async getSurveyResponses() {
      const questionsWithResponses = await this.$store.dispatch('getCompanySurveyResponses', [this.id, this.surveyId]);

      if (!Array.isArray(questionsWithResponses)) {
        this.error = 'could not get survey questions with responses';
        return false;
      }

      // transform to piechart data
      const labels = [];
      const transformedData = questionsWithResponses.map((questionWithResponses) => {
        const data = {};
        const dataWithPercentageCounts = {};
        let totalCounts = 0;

        questionWithResponses.responses.forEach((response) => {
          if (Number(response.ratesid)) {
            // find the rate id
            const rate = questionWithResponses.ratings.find((rating) => Number(rating.id) === Number(response.ratesid));

            // console.log(rate, response);
            if (!labels.includes(rate.rates)) {
              labels.push(rate.rates);
            }
            const dataKey = `${rate.rates}/${rate.id}`;

            data[dataKey] = {
              label: rate.rates,
              counts: (data[dataKey]?.counts || 0) + 1,
            };

            totalCounts += 1;
          }
        });

        // calculate percentage
        Object.entries(data).forEach(([key, d]) => {
          const percentage = ((d.counts * 100) / totalCounts).toFixed(1);

          dataWithPercentageCounts[key] = {
            ...d,
            label: d.label,
            countPercentage: `${percentage}%`,
          };
        });

        return {
          ...questionWithResponses,
          data: dataWithPercentageCounts,
        };
      });

      // console.log(transformedData);

      this.questionsWithResponses = transformedData;

      return true;
    },
    async getPageData() {
      this.loading = true;
      this.error = undefined;

      await Promise.all([
        this.getSurvey(),
        this.getSurveyResponses(),
      ]);

      this.loading = false;
    },
    async generateReport() {
      this.disabledReportBtn = true;
      this.reportBtnText = 'Exporting...';

      this.$toasted.info('Exporting survey responses', {
        duration: 4000,
        position: 'top-right',
      });

      const questionsWithResponses = new Array(...this.questionsWithResponses);
      const data = [];

      /**
       * a sheet = a survey question
       */
      questionsWithResponses.forEach((questionWithResponses) => {
        // eslint-disable-next-line no-underscore-dangle
        const _data = {
          sheet: `${questionWithResponses.criteria.substring(0, 25)}${questionWithResponses.criteria.length > 25 ? '...' : ''}`,
          content: questionWithResponses.questionType === 'ratings' ? [{}] : questionWithResponses.responses,
        };

        if (questionWithResponses.questionType === 'ratings') {
          _data.columns = questionWithResponses.ratings.map((rating) => ({
            label: rating.rates,
            value: (row) => `${questionWithResponses.data[`${rating.rates}/${rating.id}`]?.counts || 0} (${questionWithResponses.data[`${rating.rates}/${rating.id}`]?.countPercentage || '0%'})`,
          }));
        } else {
          _data.columns = [
            { label: 'User', value: (row) => `${row.user.name} ${row.user.middle}` },
            { label: 'User email', value: (row) => row.user.email },
            // eslint-disable-next-line eqeqeq
            { label: 'User type', value: (row) => (row.user.type == this.$store.state.User.accountTypes.mentor ? 'mentor' : 'mentee') },
            { label: 'Response', value: 'ratesid' },
          ];
        }

        data.push(_data);
      });

      const today = new Date().toLocaleDateString('en-us', {
        weekday: 'long', year: 'numeric', month: 'short', day: 'numeric',
      });

      const settings = {
        fileName: `${this.company.company} Survey responses [${today}]`,
        extraLength: 3, // A bigger number means that columns will be wider
        writeMode: 'writeFile', // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
      };

      // eslint-disable-next-line no-unused-vars
      xlsx(data, settings, (e) => {
        // console.log('complete!', e);
      });

      this.$store.dispatch('logAction', this.$store.state.Logger.actionsIndexes.exportSurveyResponses);

      this.disabledReportBtn = false;
      this.reportBtnText = 'Export';
    },
  },
  async created() {
    await this.getCompany();
    await this.$nextTick();

    // assert user company permissions
    if (!this.hasPermission) {
      this.$router.push({
        name: 'app',
      });

      return false;
    }

    await this.getPageData();

    await this.$nextTick();
    await this.$nextTick();

    return true;
  },
};
</script>

<style>

</style>
