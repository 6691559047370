/* eslint-disable no-unused-vars */
export default {
  state: () => ({
    theme: undefined,
    ui: {},
    logo: undefined, // ! maybe this will be part of a company's data
  }),
  actions: {
    companyUiSettings(context, [CompanyId, refresh = false]) {
      const companyid = CompanyId || context.rootState.User.companyid;
      if (!companyid) return false;

      // console.log('loading ui settings');
      return true;
    },
    saveUiSettings(context, [CompanyId, settings]) {},
  },
};
