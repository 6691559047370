// import _uniqBy from 'lodash/uniqBy';
import { apiPost, apiStore, generateFormData } from '../../../modules/apiHelper';
import {
  isStaleData, now, objectIsValid, toBoolean, urlFriendly,
} from '../../../modules/dataHelper';

export default {
  async createGroupTask(context, [formData, resources = []]) {
    const task = await apiPost('create_tasks', formData, 67)
      .catch((err) => {
        console.warn('Error in creating group task \n', err);
        return false;
      })
      .then(async (res) => {
        if (!res) return false;
        if (toBoolean(res.data.error)) return false;

        return res.data;
      });
    //

    const taskResources = [[], []];

    if (task) {
      const taskName = formData.get('task_name');
      const groupId = formData.get('groupid');
      // get the uploaded task
      // const theTaskIds = await apiPost('taskd', generateFormData({ task_name: taskName, groupid: groupId }), 68)
      const theTaskId = await apiPost('taskd', generateFormData({ task_name: taskName, groupid: groupId }), 68)
        .catch((err) => {
          console.warn('Error in getting the created group task \n', err);
          return false;
        })
        .then(async (res) => {
          if (!res) return false;
          if (toBoolean(res.data.error)) return false;

          // return res.data.taskd.map((t) => t.id);
          return res.data.taskd[0]?.id || false;
        });
      //

      // theTaskIds.forEach(async (taskId) => {
      //   const [response, percentagesComplete] = await context.dispatch('uploadGroupTaskResource', [taskId, resources]);
      //   taskResources[0].push(response);
      //   taskResources[1].push(percentagesComplete);
      // });

      if (theTaskId) {
        const [response, percentagesComplete] = await context.dispatch('uploadGroupTaskResource', [theTaskId, resources]);
        taskResources[0].push(response);
        taskResources[1].push(percentagesComplete);
      }

      // console.log([task, ...taskResources]);

      return [task, ...taskResources];
    }

    return [task, ...taskResources];
  },
  async uploadGroupTaskResource(context, [taskId, resources]) {
    const requests = [];
    const percentagesComplete = [];

    Array.from(resources).forEach((resource, index) => {
      // console.log(resource);
      const formData = new FormData();
      formData.set('taskid', taskId);
      formData.set('senderid', context.rootState.User.id);

      // if (resource.id) {
      //   formData.set('resourceid', resource.id);
      //   formData.set('resourcetype', resource.title ? 2 : 1); // 1 = resourceid, 2 = link resourceid
      // } else {
      // }
      formData.set('name', resource.name);
      formData.append('file1', resource, urlFriendly(resource.name));
      formData.set('resourcetype', 0); // 0 = file

      requests.push(apiPost('add_task_grp_resource', formData, 3,
        {
          'Content-Type': 'multipart/form-data',
        },
        (progressEvent) => {
          percentagesComplete[index] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        }));
    });

    const response = Promise.all(requests)
      .catch((err) => {
        console.warn('uploadGroupTaskResource failed!', err);
      })
      .then((res) => {
        if (!res) return false;
        const positive = res.every((r) => !!r);
        if (!positive) return false;
        const haveErrors = res.every((r) => toBoolean(r.data.error));
        if (haveErrors) return false;

        return true;
      });
    //

    return [response, percentagesComplete];
  },
  async revertGroupTask(context, [taskId, taskResources, reportText]) {
    const requests = [];
    const percentagesComplete = [];

    const arrayedResources = Array.from(taskResources);

    // eslint-disable-next-line no-unused-vars
    const uploadResource = async (resource, text = '', index = 0) => {
      const formData = new FormData();
      formData.set('taskid', taskId);
      formData.set('name', resource.name);
      formData.append('file1', resource, urlFriendly(resource.name));
      formData.set('text', 0);

      // if (index === 0 && text.length) {
      //   formData.set('text', 2);
      //   formData.set('review', text);
      // } else {
      // formData.set('text', 0);
      // }

      requests.push(apiPost('reject_task_report', formData, 70,
        {
          'Content-Type': 'multipart/form-data',
        },
        (progressEvent) => {
          percentagesComplete[index] = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        }));
      //
    };

    const uploadedText = async () => {
      const formData = new FormData();
      formData.set('taskid', taskId);
      formData.set('review', reportText);
      formData.set('text', 1);

      requests.push(apiPost('reject_task_report', formData, 70));
    };

    if (arrayedResources.length && reportText.length) {
      arrayedResources.forEach((resource, index) => {
        uploadResource(resource, reportText, index);
      });

      uploadedText();
    } else if (arrayedResources.length && !reportText.length) {
      arrayedResources.forEach((resource, index) => {
        uploadResource(resource, '', index);
      });
    } else if (reportText.length && !arrayedResources.length) {
      uploadedText();
    }

    const response = Promise.all(requests)
      .catch((err) => {
        console.warn('revertTask failed!', err);
      })
      .then((res) => {
        if (!res) return false;
        const positive = res.every((r) => !!r);
        if (!positive) return false;
        const haveErrors = res.every((r) => toBoolean(r.data.error));
        if (haveErrors) return false;

        return true;
      });
    //

    return [response, percentagesComplete];
  },
  async approveGroupTaskReport(context, taskId) {
    const formData = new FormData();
    formData.set('taskid', taskId);

    const response = await apiPost('task_end', formData, 67)
      .catch((err) => {
        console.warn('approveGroupTaskReport::task_end err', err);
        return false;
      })
      .then(async (res) => {
        if (!res) return false;
        if (toBoolean(res.data.error)) return false;

        // get the updated task Details
        context.dispatch('getGroupTask', [taskId, true]);

        return true;
      });

    return response;
  },
  async getGroupTask(context, [taskid, refresh = false]) {
    const localDb = await apiStore.getItem(`task/${taskid}`) || [0, undefined];

    if (localDb[1] && !isStaleData(localDb[0]) && !refresh) return localDb[1];

    const formData = new FormData();
    formData.set('taskid', taskid);

    const task = await apiPost('task_list', formData, 1)
      .then(async (res) => {
        // console.log(res);
        if (!res) return {};
        if (toBoolean(res.data.error)) return {};

        const tsk = res.data.task_list[0];

        if (tsk) {
          const goals = await context.dispatch('getGoals', [tsk.companyid]);
          // get tasks' goals
          // eslint-disable-next-line eqeqeq
          const taskGoal = goals.find((goal) => goal.id == tsk.subgoalid);

          tsk.goal = taskGoal || {};

          apiStore.setItem(`task/${taskid}`, [now(), tsk]);
        }

        return tsk || {};
      });

    //
    return task;
  },

  async getGroupTasks(context, { groupId, companyId }) {
    const request = await apiPost('task_lists_group', generateFormData({ groupid: groupId }), 66)
      .catch((err) => {
        console.warn('getGroupTasks::task_lists_group err', err);
        return false;
      })
      .then(async (res) => {
        if (!res) return false;
        if (toBoolean(res.data.error)) return false;

        // const tasksList = _uniqBy(res.data.task_lists_group || [], 'task_name');
        const tasksList = res.data.task_lists_group || [];

        const goals = await context.dispatch('getGoals', [companyId]);

        tasksList.map((task) => {
          // eslint-disable-next-line no-param-reassign
          task.goal = goals.find((goal) => Number(goal.id) === Number(task.subgoalid));

          return task;
        });

        return tasksList;
      });
    //

    return request;
  },
  async getEndedGroupTasks(context, { groupId, companyId }) {
    const request = await apiPost('task_lists_end_group', generateFormData({ groupid: groupId }), 66)
      .catch((err) => {
        console.warn('getCompanyMentorshipGroups::task_lists_end_group err', err);
        return false;
      })
      .then(async (res) => {
        if (!res) return false;
        if (toBoolean(res.data.error)) return false;

        const tasksList = res.data.task_lists_end_group || [];

        const goals = await context.dispatch('getGoals', [companyId]);

        tasksList.map((task) => {
          // eslint-disable-next-line no-param-reassign
          task.goal = goals.find((goal) => Number(goal.id) === Number(task.subgoalid));

          return task;
        });

        return tasksList;
      });
    //

    return request;
  },
  async getGroupTasksOnReview(context, { groupId, companyId }) {
    const request = await apiPost('task_list_review', generateFormData({ groupid: groupId }), 66)
      .catch((err) => {
        console.warn('getGroupTasksOnReview::task_list_review err', err);
        return false;
      })
      .then(async (res) => {
        if (!res) return false;
        if (toBoolean(res.data.error)) return false;

        const tasksList = res.data.task_list_review || [];

        const goals = await context.dispatch('getGoals', [companyId]);

        tasksList.map((task) => {
          // eslint-disable-next-line no-param-reassign
          task.goal = goals.find((goal) => Number(goal.id) === Number(task.subgoalid));

          return task;
        });

        return tasksList;
      });
    //

    return request;
  },
  async getGroupTaskResources(context, { groupId, taskId, userId }) {
    const formData = new FormData();
    formData.set('groupid', groupId);

    const response = await apiPost('task_report_resources', formData, 66)
      .catch((err) => {
        console.warn('getGroupTaskResources::task_report_resources failed: ', err);
        return [];
      })
      .then(async (res) => {
        if (!res) return [];
        if (toBoolean(res.data.error)) return [];
        const resources = [];
        const resourcesProm = [];

        (res.data.task_report_resources || []).forEach(({
          // eslint-disable-next-line camelcase
          resourceid, resource_type, taskid, menteeid,
        }) => {
          // eslint-disable-next-line eqeqeq
          if (taskid != taskId || menteeid != userId) return;

          // eslint-disable-next-line camelcase
          const r = context.dispatch('getResource', [resourceid, resource_type]);

          resourcesProm.push(r);

          r.then((resource) => {
            // console.log(resource);
            if (objectIsValid(resource)) {
              resources.push(resource);
            }
          });
        });

        await Promise.all(resourcesProm);
        return resources;
      });

    return response;
  },
  async getGroupTaskReportResources(context, { groupId, taskId }) {
    const formData = new FormData();
    formData.set('groupid', groupId);

    const response = await apiPost('task_report_resource', formData, 66)
      .catch((err) => {
        console.warn('getGroupTaskReportResources::task_report_resource failed: ', err);
        return [];
      })
      .then(async (res) => {
        if (!res) return [];
        if (toBoolean(res.data.error)) return [];
        const resources = [];
        const resourcesProm = [];

        (res.data.task_report_resource || []).forEach(({
          // eslint-disable-next-line camelcase
          resourceid, resource_type, taskid,
        }) => {
          // eslint-disable-next-line eqeqeq
          if (taskid != taskId) return;

          // eslint-disable-next-line camelcase
          const r = context.dispatch('getResource', [resourceid, resource_type]);

          resourcesProm.push(r);

          r.then((resource) => {
            // console.log(resource);
            if (objectIsValid(resource)) {
              resources.push(resource);
            }
          });
        });

        await Promise.all(resourcesProm);
        return resources;
      });

    return response;
  },
  async getGroupTaskReportTexts(context, { groupId, taskId }) {
    const formData = new FormData();
    formData.set('groupid', groupId);

    const response = await apiPost('task_list_review_text', formData, 66)
      .catch((err) => {
        console.warn('getGroupTaskReportTexts::task_list_review_text failed: ', err);
        return [];
      })
      .then(async (res) => {
        if (!res) return [];
        if (toBoolean(res.data.error)) return [];

        // eslint-disable-next-line eqeqeq
        return (res.data.task_list_review_text || []).filter((text) => text.taskid == taskId);
      });

    return response;
  },
};
