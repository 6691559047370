<template>
  <form
    id="mentorship-group-form"
    ref="mentorship-group-form"
    class="tw-max-w-full tw-mx-auto tw-flex-shrink-0 tw-mb-20 auth-card tw-flex tw-flex-wrap"
    method="post"
    action="/mentorship-group/create"
    @submit.prevent="onSubmit"
  >

    <div class="tw-w-full tw-mb-4">
      <h1 class="tw-text-4xl font-title">{{ mentorshipGroupId ? 'Edit Mentorship Group' : 'Create a Mentorship Group' }}</h1>
      <!-- <h6 class="tw-text-base opacity-78 tw-text-app-deep-blue">Please fill all inputs</h6> -->
    </div>

    <div class="tw-w-full tw-mb-4 tw-flex-shrink-0 tw-px-1">
      <InputGroup
        :class="{ '--loading-data': loadingForm }"
        label="Name"
        name="name"
        type="text"
        placeholder="name"
        input-classes="tw-p-3"
        v-model="form.name"
        :error="errors.name"
      />
    </div>
    <div class="tw-w-full tw-mb-4 tw-flex-shrink-0 tw-px-1">
      <QuillTextareaGroup
        :class="{ '--loading-data': loadingForm }"
        label="Description"
        instruct="Give a small description about the mentorship group"
        name="description"
        type="text"
        placeholder="name"
        input-classes="tw-p-3 focus:tw-bg-gray-100"
        v-model="form.description"
        :rows="4"
        :error="errors.description"
      />
    </div>
    <div class="tw-w-full tw-mb-4 tw-flex-shrink-0 tw-px-1" v-if="!mentorshipGroupId">
      <SelectGroup
        :class="{ '--loading-data': loadingForm }"
        label="Goal"
        name="goal"
        nameKey="goal"
        placeholder="Goal"
        input-classes="my-select--huge tw-bg-gray-200 tw-text-left"
        v-model="form.goal"
        :options="companyGoals"
        :reduce="goal => goal.id"
        :clearable="false"
        :error="errors.goal"
      />
    </div>
    <div v-else class="tw-w-full tw-mb-4 tw-py-2 tw-px-4 tw-flex-shrink-0 tw-rounded-10 tw-bg-gray-200">
      <span class="tw-text-xs opacity-54">SELECTED GOAL</span>
      <br />
      <span class="tw-font-bold">{{selectedGoal?.goal}}</span>
    </div>

    <div class="tw-w-full tw-flex-shrink-0 tw-px-1 tw-mb-4 tw-pt-10">
      <BaseButton
        type="submit"
        :class="[
          'tw-block tw-w-full md:tw-inline-block md:tw-w-auto tw-mb-4 tw-py-3 tw-px-8',
          `${btnClass}`
        ]"
        :text="btnText"
        :disabled="!canSubmit || btnDisabled"
      />
    </div>

  </form>
</template>

<script>
import _debounce from 'lodash/debounce';

import QuillTextareaGroup from '@/components/QuillTextareaGroup.vue';
import InputGroup from '@/components/InputGroup.vue';
import SelectGroup from '@/components/SelectGroup.vue';

import form from '@/modules/formHelper';

export default {
  name: 'EditMentorshipGroup',
  components: {
    QuillTextareaGroup,
    InputGroup,
    SelectGroup,
  },
  props: {
    company: {
      type: Object,
      required: true,
    },
    mentorshipGroupId: {
      type: [String, Number],
      default: undefined,
    },
    btnClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loadingForm: true,
      errorForm: undefined,
      btnText: 'Save',
      btnDisabled: false,
      form: {
        name: undefined,
        description: undefined,
        goal: undefined,
      },
      companyGoals: [],
      errors: {},
    };
  },
  watch: {
    // eslint-disable-next-line func-names
    'company.id': function () {
      this.resetForm();
      this.loadForm();
    },
    mentorshipGroupId() {
      this.resetForm();
      this.loadForm();
    },
  },
  computed: {
    canSubmit() {
      return form.isValidTextInput(this.form.name)
        && !this.loadingForm
        && form.isValidSelectInput([this.form.goal])
        && this.company.id !== undefined;
    },
    selectedGoal() {
      return this.companyGoals.find((goal) => Number(goal.id) === Number(this.form.goal));
    },
  },
  methods: {
    resetForm() {
      this.form = {
        name: undefined,
        description: undefined,
        goal: undefined,
      };
    },
    // eslint-disable-next-line func-names
    loadForm: _debounce(async function () {
      this.loadingForm = true;
      this.errorForm = undefined;

      const companyGoals = await this.$store.dispatch('getGoals', [this.company.id, true]);

      if (Array.isArray(companyGoals)) {
        this.companyGoals = companyGoals;
      }

      // * get mentorship group details if id is defined
      if (this.mentorshipGroupId) {
        const mentorshipGroup = await this.$store.dispatch('getCompanyMentorshipGroup', this.mentorshipGroupId);

        if (mentorshipGroup) {
          this.form.name = mentorshipGroup.group_name;
          this.form.description = mentorshipGroup.description;
          this.form.goal = mentorshipGroup.goalid;
        }
      }

      await this.$nextTick();
      await this.$nextTick();

      this.loadingForm = false;
    }, 300),
    async onSubmit() {
      if (!this.canSubmit) {
        this.$toasted.global.appError({
          errorMessage: 'You can\'t submit the form. Check for empty inputs',
        });

        console.warn('Cannot submit form... \n check for empty inputs');
        return;
      }

      this.btnDisabled = true;
      this.btnText = 'working...';

      const data = {
        title: this.form.name,
        description: this.form.description,
        goalid: this.form.goal,
        companyid: this.company.id,
      };

      let result;

      if (this.mentorshipGroupId) {
        data.id = this.mentorshipGroupId;
        result = await this.$store.dispatch('updateCompanyMentorshipGroup', data);
      } else {
        result = await this.$store.dispatch('createCompanyMentorshipGroup', data);
      }


      if (result) {
        this.$toasted.success('Saved!', { duration: 1000 });
        this.$emit('created-success');

        // todo: log action
        // if (this.groupId) {
        //   this.$store.dispatch('logAction', this.$store.state.Logger.actionsIndexes.editPillar);
        // } else {
        //   this.$store.dispatch('logAction', this.$store.state.Logger.actionsIndexes.addPillar);
        // }
      } else {
        this.$toasted.global.appError({
          errorMessage: 'Creating a mentorship group failed. Please try again later',
        });
      }

      this.btnDisabled = false;
      this.btnText = 'save';
    },
  },
  async created() {
    this.errors = { ...this.form };

    await this.$nextTick();
    await this.loadForm();
    await this.$nextTick();

    this.loadingForm = false;
  },
};
</script>

<style>

</style>
