/**
 * requires a filterConfig object
 * it at least filters a list by the filterConfig.search value
 * required
 */
export default {
  name: 'filterMixin',
  data() {
    return {
      defaultDataName: 'list',
    };
  },
  watch: {
    // filterConfig: {
    //   async handler() {
    //     // this.loadingAccounts = true;
    //     // await this.doFilter();
    //     // this.loadingAccounts = false;
    //   },
    //   deep: true,
    // },
  },
  computed: {
    dataName() {
      return this.filterConfig.dataName || this.defaultDataName;
    },
    canFilter() {
      if (typeof this.filterConfig === 'object') {
        return (
          typeof this.filterConfig.search === 'string'
          && Array.isArray(this.filterConfig.searchAt)
          && (this.filterConfig.searchAt || []).length > 0
        );
      }

      return false;
    },
    filteredList() {
      if (!this.canFilter) {
        console.warn(`component not set for filtering a list. Maybe you don't need this mixin.
        
          Required filter configs;
          ##

            filterConfig: {
              search: '', -> input's v-model
              searchAt: [] -> props to search at (String:'' || Array:['', ''])
              dataName: '' -> the list's data name
            }

          ##
        `);

        return this[this.dataName];
      }

      const searchValue = this.filterConfig.search.toLowerCase();
      const searchAtLength = this.filterConfig.searchAt.length;

      if (!searchValue.length) return this[this.dataName];

      return this[this.dataName].filter((dataItem) => {
        const valuesFound = Array(searchAtLength);

        this.filterConfig.searchAt.forEach((prop, index) => {
          const data = Array.isArray(prop) ? (dataItem[prop[0] || ''] || {}) : dataItem;
          const dataProp = Array.isArray(prop) ? (prop[1] || '') : prop;
          //
          valuesFound[index] = String(data[dataProp] || '').toLowerCase().indexOf(searchValue) >= 0;
        });

        return valuesFound.some((value) => value === true) ? dataItem : false;
      });
    },
  },
};
