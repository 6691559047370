import store from '../../store';

const meta = {
  requiresLogin: true,
  requiresUserType: store.state.User.accountTypes.superAdmin,
};

export default [
  {
    path: 'sessions',
    name: 'sessions',
    component() {
      return import(/* webpackChunkName: "sadminviews" */ '../../views/Classes/ClassesView.vue');
    },
    meta,
  },
  {
    path: 'sessions/:id',
    name: 'viewClass',
    component() {
      return import(/* webpackChunkName: "sadminviews" */ '../../views/Classes/Class/ClassView.vue');
    },
    props: true,
    meta,
  },
];
