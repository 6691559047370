import _sortBy from 'lodash/sortBy';

import actions from './actions';

const INTERVAL_SLOW = 20000;
const INTERVAL_NORMAL = 10000;
const INTERVAL_FAST = 3000; // ? make this slower for none-chromium browsers

export default {
  state: {
    messages: {},
    fetchingGroupMessages: {},
    fetchingIntervals: {
      default: INTERVAL_NORMAL,
    }, // INTERVAL_FAST for fastFetching
    fetchingChatError: false,
    totalNewMessages: 0,
  },
  getters: {
    groupMessages: (state) => (id) => _sortBy(state.messages[`group${id}`], 'tym_unix'),
    // eslint-disable-next-line eqeqeq
    groupChatMessage: (state) => (id, messageId) => state.messages[`group${id}`].find((message) => message.id == messageId),
    isFetching: (state) => (id) => !!state.fetchingGroupMessages[`group${id}`] || false,
    fetchingError: (state) => !!state.fetchingChatError,
  },
  mutations: {
    addGroupMessages(state, [id, messages]) {
      // console.log('MESSAGES...');
      state.messages = { ...state.messages, [`group${id}`]: [...messages] };
      // console.log(state.messages);
    },
    addGroupMessage(state, [id, message]) {
      if (!state.messages[`group${id}`]) {
        // console.log('setting');
        state.messages[`group${id}`] = [...message]; // ! why am I destructuring? [todo]
      } else {
        // console.log('pushing.....');
        state.messages[`group${id}`].push(message);
      }
    },
    updateGroupMessage(state, [id, groupId, prop, value]) {
      if (!state.messages[`group${groupId}`]) return false;
      // eslint-disable-next-line eqeqeq
      const messageIndex = state.messages[`group${groupId}`].findIndex((m) => m.id == id);
      if (messageIndex < 0) return false;

      state.messages[`group${groupId}`][messageIndex][prop] = value;
      return true;
    },
    updateFetchingChats(state, [groupId, isFetching]) {
      state.fetchingGroupMessages = { ...state.fetchingGroupMessages, [`group${groupId}`]: isFetching };
      state.fetchingIntervals = { ...state.fetchingIntervals, [groupId]: isFetching ? INTERVAL_NORMAL : INTERVAL_SLOW };
    },
    isOnMentorshipGroupChat(state, groupId) {
      state.fetchingIntervals = { ...state.fetchingIntervals, [groupId]: INTERVAL_FAST };
      // state.fetchingIntervals[groupId] = INTERVAL_FAST;

      // slow the fetch speed for other groups
      Object.entries(state.fetchingIntervals).forEach(([id]) => {
        if (id !== 'default' && id !== groupId) {
          state.fetchingIntervals = { ...state.fetchingIntervals, [id]: INTERVAL_SLOW };
          // state.fetchingIntervals[id] = INTERVAL_SLOW;
        }
      });
    },
    resetGroupChatFetchSpeed(state) {
      Object.entries(state.fetchingIntervals).forEach(([id]) => {
        if (id !== 'default') {
          state.fetchingIntervals[id] = INTERVAL_NORMAL;
        }
      });
    },
    updateFetchingChatError(state, error) {
      state.fetchingChatError = error;
    },
    updateTotalNewMessages(state, total) {
      state.totalNewMessages = total;
    },
  },
  actions,
};
