import { generateFormData, apiPost, apiStore } from '../../../modules/apiHelper';
import {
  invalideDate, isStaleData, now, objectIsValid, urlFriendly,
} from '../../../modules/dataHelper';
import resolve from '../../../modules/api/resolve';

/* eslint-disable no-unused-vars */

/**
 * landingpage bg image
 * landingpage text
 * UI settings
 *   - color palette
 */

export default {
  async companyLandingpageSettings(context, params = []) {
    const results = await context.dispatch('companyUISettings', params);
    return results;
  },

  async companyUISettings(context, [companyid, refresh = false]) {
    if (!companyid) return false;

    const settings = await apiStore.getItem(`companysettings/${companyid}`) || [invalideDate, {}];

    if (!refresh && !isStaleData(settings[0]) && objectIsValid(settings[1])) return settings[1];

    const result = await resolve(apiPost('UI_lists', generateFormData({ companyid }), 13))
      .then((res) => {
        if (!res) return false;
        const uisettings = res.UI_lists || [];
        // eslint-disable-next-line no-var, no-underscore-dangle, vars-on-top
        var __settings;

        if (uisettings.length) {
          // save to store
          apiStore.setItem(`companysettings/${companyid}`, [now(), uisettings[uisettings.length - 1]]);

          // eslint-disable-next-line no-underscore-dangle
          if (objectIsValid((uisettings[uisettings.length - 1].settings))) {
            __settings = JSON.parse((uisettings[uisettings.length - 1]).settings);
          }

          return {
            ...(__settings || {}),
            ...{
              landingpageBg: uisettings[uisettings.length - 1].background_url || '',
            },
          };
        }

        return false;
      });
    //

    if (result) {
      context.commit('updateProp', ['landingpage', result.landingpageText, 'text']);
      context.commit('updateProp', ['landingpage', result.landingpageBg, 'bgImage']);
      // context.commit('updateProp', ['uiSettings', result]);
      // context.commit('updateProp', ['uid', result.id]); // TODO: update uid
    }

    return result;
  },

  async addCompanyUISettings(context, [companyid, settings = {}, Id]) {
    // eslint-disable-next-line no-var
    var backgroundUrl = settings.bgImageUrl;

    // settings id
    const id = Id || settings.uid || context.state.uid;

    if (!companyid) return false;

    const settingsData = {
      landingpageText: settings.text,
    };

    if (id) {
      console.log('updating');
      // do update // ? or just add as a new UI settings ?
      const result = await Promise.all([
        context.dispatch('updateCompanyUISettingSetting', [companyid, settingsData, id]),
        // context.dispatch('updateCompanyUISettingFrozen', [companyid, settingsData, id]),
        // context.dispatch('updateCompanyUISettingUserType', [companyid, settingsData, id]),
        // context.dispatch('updateCompanyUISettingCompany', [companyid, settingsData, id]),
      ]);

      return result;
    }

    if (typeof settings.bgImage === 'object') {
      // save bg image
      // settings.bgImage
      backgroundUrl = await context.dispatch('addCompanyLandingpageBg', [companyid, settings.bgImage]);
      console.log(backgroundUrl);
    }

    const formData = generateFormData({
      companyid,
      user_type: context.rootState.User.accountTypes.all, // ? why am I doing this?
      settings: JSON.stringify(settingsData).replace(/'/g, "\\'").replace(/"/g, '\\"'),
      frozen: 0,
      background_url: backgroundUrl,
    });

    const result = await resolve(apiPost('add_UI', formData, 13));

    if (result) {
      // reload data
      context.dispatch('companyUISettings', [companyid, true]);
    }

    return result;
  },

  async addCompanyLandingpageBg(context, [companyid, file1]) {
    const userid = context.rootState.User.id;

    if (!file1 || !companyid || !userid) return false;

    const formData = generateFormData({
      userid,
      companyid,
      name: file1.name,
    });
    formData.append('file1', file1, urlFriendly(file1.name));

    const result = await resolve(apiPost('add_background', formData, 3));


    // ! get url then return url
    if (result) {
      return result.url || result.background_url;
    }

    return false;
  },

  async updateCompanyUISettingSetting(context, [companyid, settings, Id]) {
    if (!companyid) return false;

    const uid = Id || settings.uid || context.state.uid;

    const formData = generateFormData({
      uid,
      settings: JSON.stringify(settings),
    });

    const result = await resolve(apiPost('update_UI_settings', formData, 13));

    return result;
  },
};
