<template>
  <transition name="fade" mode="out-in" appear>
    <div>
      <portal to="modal-body" key="mdl">
        <!--  -->
        <EditJourneyAction
          :position="journeyActionPosition"
          :action="journeyActionAction"
          v-if="activeModal === 'EditJourney'"
          :company="company"
          :journey-id="journeyId"
          @created-success="closeModal(); getJourneyActions();"
        />
      </portal>

      <!-- JOURNEY Hierarchy -->
      <div class="journey-kanban tw-flex tw-flex-col tw-relative">
        <div class="tw-p-2">
          <p class="tw-text-center tw-text-xs tw-uppercase">Journey Hierarchy</p>
        </div>

        <div class="tw-z-0 tw-mx-auto tw-absolute tw-left-1/2 journey-kanban__hierarchy-line">
          <div class="tw-border-l tw-border-gray-700 tw-h-full"></div>
        </div>

        <div class="tw-z-10 journey-kanban__hierarchy-list tw-mx-auto tw-pt-5">
          <!--  -->
          <template v-for="({ position, journeyActions, title, action }, index) in formattedJourneyActions">
            <div class="tw-mb-4" :key="`${position}-${index}`">
              <div>
                <div :class="[
                  'tw-rounded-10 tw-border journey-kanban__hierarchy-list__item tw-p-3 tw-mb-4 tw-flex tw-flex-col tw-justify-center',
                  ]"
                >
                  <div class="tw-h-full tw-flex tw-items-center">
                    <div class="grab here tw-text-xs">
                      {{position}}
                    </div>

                    <div class="tw-flex-grow tw-text-center">
                      <p class="tw-font-sm tw-mb-1">{{title}}</p>

                      <div v-for="journeyAction in journeyActions" :key="journeyAction.id">
                        <JourneyKanbanActionPill
                          :class="[{ 'tw-mb-2' : journeyActions.length > 1 }]"
                          :journey-action="journeyAction"
                          @delete-journey-action="deleteJourneyAction"
                        />
                      </div>
                    </div>
                  </div>

                  <BaseButton
                    text="Add Action"
                    class="tw-bg-black tw-border-0 tw-text-white tw-mt-3"
                    @click="openJourneyActionForm({ position, action })"
                    v-if="canAddActionInThisPosition(position)"
                  />
                </div>

              </div>

            </div>
          </template>

          <!-- <div>
            <div :class="[
              'tw-rounded-10 tw-border journey-kanban__hierarchy-list__item tw-p-3 tw-flex tw-flex-col tw-justify-center',
              'journey-kanban__hierarchy-list__item--dropzone--'
              ]">

              <BaseButton
                text="Add Action"
                class="tw-bg-black tw-border-0 tw-text-white tw-inline-block"
                @click="openJourneyActionForm(Object.entries(formattedJourneyActions).length + 1)"
              />

            </div>
          </div> -->

          <!-- <div dropzone="true">
            <div :class="[
              'tw-rounded-10 tw-border journey-kanban__hierarchy-list__item tw-flex tw-flex-col tw-justify-center',
              'journey-kanban__hierarchy-list__item--dropzone'
              ]">

              <span class="tw-text-xs tw-italic opacity-54 tw-text-center tw-block">Drag an action here</span>

            </div>
          </div> -->
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import EditJourneyAction from '@/views/Companies/Company/_partials/EditJourneyAction.vue';
import JourneyKanbanActionPill from '@/components/JourneyKanbanActionPill.vue';
import _orderBy from 'lodash/orderBy';

export default {
  name: 'CompanyMainJourney',
  components: {
    EditJourneyAction,
    JourneyKanbanActionPill,
  },
  props: {
    company: {
      type: Object,
      required: true,
    },
    journeyId: {
      type: [String, Number],
      required: true,
    },
    activeModal: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      journeyActions: [],
      journeyActionPosition: 1,
      journeyActionAction: undefined,
    };
  },
  computed: {
    accountTypes() {
      return this.$store.state.User.accountTypes;
    },
    actionsList() {
      return _orderBy(this.$store.state.CompanySettings.journeyActionsList, 'position');
    },
    formattedJourneyActions() {
      // group the journey actions by post, then order the post
      // const journeyActions = {};

      this.actionsList.map((actionList) => {
        // eslint-disable-next-line no-param-reassign
        actionList.journeyActions = this.journeyActions.filter((action) => action.title === actionList.action);

        return actionList;
      });

      return this.actionsList;
    },
  },
  methods: {
    async getJourneyActions() {
      const actions = await this.$store.dispatch('getJourneyActions', [this.journeyId, this.company.id, true]);

      if (Array.isArray(actions)) {
        this.journeyActions = actions;
      }
    },
    async openJourneyActionForm({ position, action }) {
      this.$emit('open-modal');
      await this.$nextTick();

      this.journeyActionPosition = Number(position) === 0 ? 1 : Number(position);
      this.journeyActionAction = action;
      await this.$nextTick();

      this.$store.commit('openModal', [true, 'modal']);
    },
    async deleteJourneyAction(journeyActionId) {
      const result = await this.$store.dispatch('deleteJourneyAction', journeyActionId);

      if (result) {
        this.$toasted.success('Action Deleted!', { duration: 1000 });
        this.getJourneyActions();
      }
    },
    canAddActionInThisPosition(position) {
      const { journeyActions } = (this.formattedJourneyActions.find((action) => Number(action.position) === Number(position)) || {});

      const hasActionForBoth = journeyActions.find((action) => Number(action.type) === 0);

      if (hasActionForBoth) return false;

      const hasActionsForMentor = journeyActions.find((action) => Number(action.type) === this.$store.state.User.accountTypes.mentor);
      const hasActionsForMentee = journeyActions.find((action) => Number(action.type) === this.$store.state.User.accountTypes.mentee);

      if (hasActionsForMentor && hasActionsForMentee) return false;

      return true;
    },
  },
  async created() {
    await Promise.all([
      this.getJourneyActions(),
    ]);
  },
};
</script>
