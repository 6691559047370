export default {
  state: {
    minimalNav: false,
  },
  getters: {},
  mutations: {
    minimizeMenu(state, minimize = true) {
      state.minimalNav = minimize;
    },
  },
  actions: {},
};
