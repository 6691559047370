var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'tw-inline-block tw-relative tw-cursor-pointer tw-flex-shrink-0',
    (_vm.photoSizeClass.length ? _vm.photoSizeClass : 'w-120 h-120')
  ]},[_c('div',{class:['tw-rounded-full tw-absolute tw-p-1 tw-z-40', _vm.badgeClass]},[_vm._t("badge")],2),_c('div',{class:[
      'tw-h-full tw-w-full tw-relative tw-z-10',
      {'tw-bg-gray-500-- hover:tw-bg-gray-500' : _vm.isValidPhoto},
      {'tw-bg-app-black-11' : !_vm.isValidPhoto},
      {'opacity-11' : _vm.loadingPhoto},
      {'tw-overflow-hidden': _vm.isValidPhoto && !_vm.loadingPhoto},
    ],on:{"click":_vm.promptUpdatePhoto}},[_c('input',{ref:"photo-input",staticClass:"tw-hidden",attrs:{"type":"file","accept":"image/*"},on:{"change":_vm.addPhoto}}),(_vm.overlay)?_c('div',{staticClass:"tw-h-full tw-w-full tw-absolute tw-left-0 tw-top-0 tw-z-40 hover:tw-bg-transparent tw-bg-app-black-11"},[_vm._t("overlay",function(){return [(!_vm.isValidPhoto)?_c('span',{staticClass:"tw-z-10 tw-text-xs leading-17 tw-absolute tw-left-1/2 tw-text-center tw-w-full tw-px-4 tw-font-bold font-title tw-text-white tw-capitalize",staticStyle:{"transform":"translateX(-50%) translateY(-50%)","top":"50%"}},[_vm._v(" upload ")]):_vm._e()]})],2):_vm._e(),(_vm.isValidPhoto && !_vm.loadingPhoto)?_c('img',{class:['tw-w-full tw-h-full tw-absolute tw-left-0 tw-top-0 tw-object-contain tw-bg-transparent tw-rounded-3 tw-z-20', _vm.bgImageClass],attrs:{"src":_vm.form.photo || _vm.userPhoto(_vm.photo)},on:{"onload":function($event){_vm.loadingPhoto = false}}}):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }