import resolve from '../../modules/api/resolve';
import { apiPost, generateFormData } from '../../modules/apiHelper';

export default {
  async createExpert(context, formData) {
    formData.set('adminid', context.rootState.User.id);

    const result = await resolve(apiPost('add_experts', formData, 75)).then(async (data) => {
      if (!data) return false;

      return data;
    });

    return result;
  },

  async getCompanyExperts(context, companyid) {
    const formData = generateFormData({ companyid });

    const result = await resolve(apiPost('experts_log', formData, 75)).then(async (data) => {
      if (!data) return false;

      return data.experts_log;
    });

    return result;
  },

  async getExpert(context, expertid) {
    const formData = generateFormData({ expertid });

    const result = await resolve(apiPost('expert_d', formData, 76)).then(async (data) => {
      if (!data) return false;

      return data.expert_d[0];
    });

    return result;
  },

  async activateExpert(context, data) {
    const formData = generateFormData(data);

    const result = await resolve(apiPost('activate_experts', formData, 75)).then(async (response) => {
      if (!response) return false;

      return true;
    });

    return result;
  },

  async deactivateExpert(context, data) {
    const formData = generateFormData(data);

    const result = await resolve(apiPost('deactive_experts', formData, 75)).then(async (response) => {
      if (!response) return false;

      return true;
    });

    return result;
  },

  async activateExpertQuestionCorner(context, data) {
    const formData = generateFormData(data);

    const result = await resolve(apiPost('swtch_ind', formData, 78)).then(async (response) => {
      if (!response) return false;

      return true;
    });

    return result;
  },

  async deactivateExpertQuestionCorner(context, data) {
    const formData = generateFormData(data);

    const result = await resolve(apiPost('swtch_ind_off', formData, 78)).then(async (response) => {
      if (!response) return false;

      return true;
    });

    return result;
  },

  async getCompanyQuestionInstruction(context, companyid) {
    const formData = generateFormData({ companyid });

    const result = await resolve(apiPost('guide_log', formData, 76)).then(async (response) => {
      if (!response) return false;

      if (response.guide_log.length) {
        return response.guide_log[response.guide_log.length - 1]?.guide;
      }

      // return empty string
      return '';
    });

    return result;
  },

  async createCompanyQuestionInstruction(context, data) {
    const formData = generateFormData({
      adminid: context.rootState.User.id,
      ...data,
    });

    const result = await resolve(apiPost('ad_guide', formData, 76)).then(async (response) => {
      if (!response) return false;

      return true;
    });

    return result;
  },

  async getExpertUnapprovedQuestions(context, data) {
    const formData = generateFormData(data);

    const result = await resolve(apiPost('un_app_question_log', formData, 76)).then(async (res) => {
      if (!res) return false;

      return res.un_app_question_log;
    });

    return result;
  },

  async getExpertApprovedQuestions(context, data) {
    const formData = generateFormData(data);

    const result = await resolve(apiPost('app_question_log', formData, 76)).then(async (res) => {
      if (!res) return false;

      return res.app_question_log;
    });

    return result;
  },

  async getExpertDeclinedQuestions(context, data) {
    const formData = generateFormData(data);

    const result = await resolve(apiPost('decl_app_question_log', formData, 76)).then(async (res) => {
      if (!res) return false;

      return res.decl_app_question_log;
    });

    return result;
  },

  async getExpertAnsweredQuestions(context, data) {
    const formData = generateFormData(data);

    const result = await resolve(apiPost('experts_log_questions', formData, 76)).then(async (res) => {
      if (!res) return false;

      return (res.experts_log_questions || []).filter((question) => Number(question.status) === 1);
    });

    return result;
  },

  async approveQuestion(context, questionid) {
    const formData = generateFormData({
      adminid: context.rootState.User.id,
      questionid,
    });

    const result = await resolve(apiPost('app_q', formData, 76)).then(async (response) => {
      if (!response) return false;

      return true;
    });

    return result;
  },

  async declineQuestion(context, questionid) {
    const formData = generateFormData({
      adminid: context.rootState.User.id,
      questionid,
      reason: 'Does not meet requirements',
    });

    const result = await resolve(apiPost('app_d', formData, 76)).then(async (response) => {
      if (!response) return false;

      return true;
    });

    return result;
  },
};
