import store from '../../store';
import ResourcesView from '../../views/Resources/ResourcesView.vue';

const meta = {
  requiresLogin: true,
  requiresUserType: store.state.User.accountTypes.staff,
};

export default [
  {
    path: 'resources',
    name: 'resources',
    component: ResourcesView,
    meta,
  },
  {
    path: 'resources/users-uploaded-resources',
    name: 'usersUploadedResources',
    meta,
    component() {
      return import(/* webpackChunkName: "resources" */ '../../views/Resources/UploadedResourcesByUsers.vue');
    },
  },
  {
    path: 'resources/view',
    name: 'ResourceView',
    props: true,
    meta,
    component() {
      return import(/* webpackChunkName: "resources" */ '../../views/Resources/ResourceView.vue');
    },
  },
];
