export default {
  name: 'reportMixin',
  // ? watch for completed reports
  // ? needs a dynamic watch
  data() {
    return {
      reportType: undefined,
      reportName: undefined,
      reportData: [],
      disabledReportBtn: false,
      reportBtnText: 'Export',
    };
  },
};
