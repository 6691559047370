<template>
  <div>
    <div>
      <slot name="nav-prepend" />
    </div>

    <div
      class="tw-flex tw-flex-no-wrap tw-items-center tw-py-6 min-h-56 top-nav"
    >
      <div ref="topnav-left-action">
        <slot name="left-action">
          <BackButton
            v-if="isBack && showBackButton"
            class="top-nav__action tw-mr-4 pl-body"
            :prev-route="prevRoute"
          />
        </slot>
      </div>
      <p
        ref="topnav-title"
        :style="`
          transform: translateX(${(centerTitle && isMobileView) || alwaysCenter ? '-50%': '0'});
          top: ${(centerTitle && isMobileView) || alwaysCenter ? '24px': 'auto'};
          `"
        :class="[
          'tw-font-thin leading-24 tw-text-base tw-text-black tw-uppercase tw-truncate',
          titleClass,
          {
            'tw-mx-auto tw-text-center tw-font-normal tw-absolute tw-left-1/2': (centerTitle && isMobileView) || alwaysCenter
          },
          {
            'tw-flex tw-items-center': !logo
          },
        ]"
      >
        <img v-if="logo" src="/img/app-logo.png" alt="title" class="tw-h-5">
        <template v-else>
          <slot name="title-left" />
          {{ transformedTitle }}
          <slot name="title-right" />
        </template>
      </p>
      <div
        ref="topnav-right-action"
        class="tw-ml-auto tw-flex tw-items-center"
      >
        <slot name="right-actions" />
        <NotificationIcon
          v-if="showNotificationIcon"
          :class="[
            'tw-h-6 tw-w-6',
            { 'tw-mr-6': showUserPhoto },
            { 'mr-body': !showUserPhoto },
          ]"
        />
        <router-link
          v-if="showUserPhoto"
          :to="{ name: 'profile' }"
          tag="div"
          class="mr-body tw-cursor-pointer tw-rounded-full"
        >
          <UserPhoto photo-size="tw-h-6 tw-w-6" self />
        </router-link>
      </div>
    </div>

    <div>
      <slot name="nav-append" />
    </div>
  </div>
</template>

<script>
import BackButton from '@/components/BackButton.vue';
import NotificationIcon from '@/components/NotificationIcon.vue';
import UserPhoto from '@/components/UserPhoto.vue';

export default {
  name: 'TopNav',
  components: {
    BackButton,
    NotificationIcon,
    UserPhoto,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    centerTitle: {
      type: Boolean,
      default: false,
    },
    logo: {
      type: Boolean,
      default: false,
    },
    alwaysCenter: {
      type: Boolean,
      default: false,
    },
    titleClass: {
      type: [String, Object, Array],
      default: '',
    },
    shrinkTitle: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'menu',
    },
    showNotificationIcon: {
      type: Boolean,
      default: false,
    },
    showUserPhoto: {
      type: Boolean,
      default: false,
    },
    showBackButton: {
      type: Boolean,
      default: true,
    },
    prevRoute: {
      type: [String, Object],
      default: undefined,
    },
  },
  watch: {
    title(val, oldVal) {
      this.titleSize = val.length;
      this.calculateTitleSize();
      if (oldVal.length < 1) {
        this.addOnResizeFn(this.calculateTitleSize);
      }
    },
  },
  data() {
    return {
      titleSize: this.title.length,
    };
  },
  computed: {
    isBack() {
      return this.type === 'back' && window.history.length > 1;
    },
    transformedTitle() {
      if (this.isMobileView && this.shrinkTitle) {
        return `${this.title.substring(0, ((this.titleSize > 4) ? this.titleSize : 4))}${(this.titleSize > 0 && this.title.length > this.titleSize) ? '...' : ''}`;
      }
      return this.title;
    },
  },
  methods: {
    clickLeftAction() {
      this.$emit('input');
    },
    /**
     * Truncates the title text by the space available for the title
     */
    async calculateTitleSize() {
      const minTitleWidth = 40; // assumes profile photo width 24px + a margin of 16px

      await this.$nextTick();
      await this.$nextTick();
      await this.$nextTick();
      await this.$nextTick();
      await this.$nextTick();
      if (this.$refs['topnav-title']) {
        const $parentElWidth = this.$refs['topnav-title'].parentElement.clientWidth;
        const $titleElWidth = this.$refs['topnav-title'].clientWidth;
        const $siblingsWidth = (this.$refs['topnav-left-action'].clientWidth || 0) + (this.$refs['topnav-right-action'].clientWidth || 0);
        const spaceLeft = $parentElWidth - ($siblingsWidth + $titleElWidth + (24 * 3)); // 24 as the margin between siblings
        const letterWidth = Math.ceil(($titleElWidth - minTitleWidth) / this.transformedTitle.length);
        // resize the title length
        if (spaceLeft <= 0) {
          // reduce the title length
          const removedLettersCount = Math.abs(spaceLeft) / letterWidth;
          // console.log(Math.ceil(removedLettersCount), spaceLeft, letterWidth, this.titleSize, this.titleSize - Math.ceil(removedLettersCount));

          this.titleSize -= Math.ceil(removedLettersCount);
        } else if (spaceLeft > 10) {
          // grow the title length to the maximum size possible
          const addLettersCount = Math.ceil(Math.abs(spaceLeft) / letterWidth);
          this.titleSize = addLettersCount > this.title.length ? this.title.length : addLettersCount;
        }
      }
    },
  },
  async mounted() {
    this.titleSize = this.title.length || 0;
    if (this.title.length > 0) {
      this.calculateTitleSize();
      this.addOnResizeFn(this.calculateTitleSize);
    }

    await this.$nextTick();

    this.$emit('mounted');
  },
};
</script>

<style>
</style>
