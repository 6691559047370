<template>
  <AppView
    nav="left-fixed"
    nav-class="tw-bg-app-white-32 tw-shadow-app"
    padd-bottom
  >

    <template v-slot:nav>
      <h4 class="tw-text-xl tw-leading-6 tw-font-bold tw-uppercase tw-pl-5 tw-pt-6">Stats Menu</h4>

      <transition-group name="new" mode="out-in" appear>
        <template v-if="!loading">
          <div key="menu" class="tw-w-full tw-h-full tw-relative tw-pl-5">
            <!-- select dropdown for superadmin to change the company context -->
            <div
              v-if="isSuperAdmin"
              class="tw-border-b tw--ml-5 tw-pl-5 tw-py-3 tw-mt-8"
            >
              <SelectGroup
                :class="[
                  'tw-mr-5',
                  { '--loading-data': loading }
                ]"
                label="Company scope"
                name="company"
                nameKey="company"
                placeholder="Company Scope"
                input-classes="tw-text-app-deep-blue tw-bg-app-white-32 tw-text-left"
                @input="updateContext"
                :options="companies"
                :reduce="company => company.id"
                :selected="companyScope"
                :clearable="false"
              />
            </div>
            <div v-else style="margin-top: 86px;"></div>

            <ul
              class="app-view-menu-list transition-fast tw-mt-10"
            >
              <router-link
                tag="li"
                class="app-view-menu-list__item tw-rounded-l-full tw-py-4 tw-pl-8 tw-w-full tw-cursor-pointer hover:tw-bg-gray-300 transition-fast"
                :to="{ name: 'accStats' }"
                v-html="'Accounts'"
              />
              <router-link
                tag="li"
                class="app-view-menu-list__item tw-rounded-l-full tw-py-4 tw-pl-8 tw-w-full tw-cursor-pointer hover:tw-bg-gray-300 transition-fast"
                :to="{ name: 'matStats' }"
                v-html="'Matches'"
              />
              <router-link
                tag="li"
                class="app-view-menu-list__item tw-rounded-l-full tw-py-4 tw-pl-8 tw-w-full tw-cursor-pointer hover:tw-bg-gray-300 transition-fast"
                :to="{ name: 'goaStats' }"
                v-html="'Goals'"
              />
              <router-link
                tag="li"
                class="app-view-menu-list__item tw-rounded-l-full tw-py-4 tw-pl-8 tw-w-full tw-cursor-pointer hover:tw-bg-gray-300 transition-fast"
                :to="{ name: 'actLog' }"
                v-html="'Activity Log'"
              />
              <!-- <router-link
                tag="li"
                class="app-view-menu-list__item tw-rounded-l-full tw-py-4 tw-pl-8 tw-w-full tw-cursor-pointer hover:tw-bg-gray-300 transition-fast"
                :to="{ name: 'resStats' }"
                v-html="'Resources'"
              /> -->
            </ul>

          </div>

          <div key="report" class="tw-absolute tw-bottom-0 tw-py-6 tw-w-full">

            <!-- <div class="tw-flex tw-w-full tw-items-center tw-flex-no-wrap tw-p-6 tw--mb-6 tw-bg-gray-100 tw-pr-2">
              <div class="tw-flex-grow">
                <BaseButton
                  :text="btnText"
                  :disabled="btnDisabled"
                  class="tw-border-none tw-font-bold hover:tw-shadow-app transition-fast tw-bg-app-deep-blue-11 tw-block tw-w-full tw-py-3 tw-px-6"
                  confirm
                  @click="createHTMLReport()"
                />
              </div>

              <div>
                <p class="tw-uppercase tw-text-center tw-text-sm tw-text-gray-500 tw-p-3">PDF</p>
              </div>

            </div> -->
          </div>
        </template>
      </transition-group>
    </template>
    <!-- <canvas id="canvas-pdf"></canvas> -->

    <template v-slot:default="{ recalculate }">
      <router-view
        @mounted="recalculate(); btnDisabled = false"
        ref="stat-html"
        :key="statsContext.companyContext"
      ></router-view>
    </template>

  </AppView>
</template>

<script>
import SelectGroup from '@/components/SelectGroup.vue';

export default {
  name: 'StatsView',
  components: {
    SelectGroup,
  },
  data() {
    return {
      loading: true,
      btnText: 'export all stats',
      btnDisabled: true,
      companyScope: undefined,
      companies: [],
    };
  },
  computed: {
    statsContext() {
      return {
        companyContext: this.$store.getters.statsContextId,
        regionContext: this.$store.getters.statsRegionContextId,
      };
    },
  },
  methods: {
    async getCompanies() {
      this.companies = (await this.$store.dispatch('getCompanies') || []);
    },
    async updateContext(value) {
      if (!value) return;
      this.$store.commit('updateStatsContext', value);
    },
  },
  async mounted() {
    await this.$nextTick();
    await this.$nextTick();

    await this.$nextTick();
  },
  async created() {
    await this.$nextTick();
    await this.$nextTick();
    await this.$nextTick();

    this.companyScope = this.statsContext.companyContext;

    if (this.isSuperAdmin) {
      await this.getCompanies();
    }
    await this.$nextTick();
    await this.$nextTick();

    this.loading = false;

    // route to default route
    if (this.$route.name === 'stats') {
      this.$router.push({
        name: 'accStats',
      });
    }
  },
};
</script>

<style>

</style>
