var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"pop-over-menu",class:[
  'popover tw-fixed tw-z-50 transition-fast',
  { 'popover--open': _vm.openMenu && _vm.isMounted },
  { 'popover--bottombar': _vm.isMobileView },
],style:({
  top: _vm.mouseY,
  left: _vm.mouseX,
}),on:{"click":function($event){$event.stopPropagation();return _vm.closePopOver()}}},[_c('portal-target',{attrs:{"is-mounted":_vm.isMounted,"name":"popover-menu"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }