<template>
  <AppView
    padd-bottom
  >

    <template v-slot:top-app-view>
      <transition name="fade" mode="out-in" appear>
        <!-- // * not using CompanyHeader because of companyLogo data -->
        <TopNav
          type="back"
          :prev-route="{ name: (isSuperAdmin ? 'viewCompany' : 'myLicense'), params: { id: $route.params.id } }"
          :title="company.company || 'fetching company...'"
          :title-class="[
            'tw-ml-8',
            { 'opacity-78 font-title tw-text-xl tw-tracking-wider tw-leading-6': !loadingCompany },
            { 'opacity-31 tw-italic tw-lowercase': loadingCompany },
          ]"
        >
          <template v-slot:title-left v-if="!loadingCompany">
            <UserPhoto
              :photo="companyLogo"
              :user-name="{
                name: company.company,
              }"
              logo
              photo-size="tw-h-12 tw-w-12"
              :class="[
                'tw-mr-6 tw-my-1 tw-rounded-10--force --force-children tw-bg-app-white-87',
                { 'tw-shadow-app-sm': !(company.logo || '').length }
              ]"
            />
          </template>
        </TopNav>
      </transition>
    </template>

    <transition name="fade" mode="out-in" appear>

      <div v-if="errorCompany" class="tw-mt-12" key="errmsg">
        <p class="tw-py-12 tw-text-base tw-text-center" v-html="errorCompany"></p>
      </div>

      <div v-else-if="loadingCompany" class="tw-mt-12" key="lodng">
        <p class="tw-py-12 tw-text-base tw-text-center">Loading...</p>
      </div>

      <div v-else key="bdy">
        <transition name="fade" mode="out-in" appear>

          <div class="app-view-grid px-body">
            <div class="app-view-grid__col-74">

              <!-- LOOK & FEEL (logo, welcome message) -->
              <template>
                <div class="tw-flex tw-items-center tw-pt-10 tw-pb-3">
                  <img
                    data-src="/img/customize-experience.png"
                    src="/img/customize-experience.png"
                    style="width: 80px; height: 80px;"
                    class="tw-object-cover tw-block tw-mr-8"
                    async
                  >
                  <h3 class="font-title tw-text-xl tw-tracking-wider">
                    Appearance
                  </h3>
                </div>
                <div class="tw-border-t tw-border-app-black-11 tw-mb-6"></div>
                <div></div>

                <div class="tw-flex tw-py-6 tw-flex-no-wrap">
                  <!-- logo -->
                  <div class="tw-px-8 tw-pb-8">
                    <!-- company logo -->
                    <p class="tw-font-bold opacity-78 leading-19 tw-text-base tw-mb-4">Company Logo</p>

                    <CompanyCustomLogo
                      :company-id="company.id"
                      :company-logo="companyLogo"
                      @save="getCompanySettings(company, true)"
                    />
                  </div>

                  <!-- landing page -->
                  <div class="tw-px-8 tw-flex-grow tw-flex-shrink-0">
                    <p class="tw-font-bold opacity-78 leading-19 tw-text-base tw-mb-4">
                      Landing Page content
                    </p>
                    <CompanyCustomLandingPage
                      :company-id="company.id"
                      :company-landingpage-text="landingpageSettings.text"
                      :company-landingpage-bg-image="landingpageSettings.bgImage"
                      @save="getCompanySettings(company, true)"
                    />
                  </div>

                </div>

                <div class="tw-mt-8">
                  <!-- welcome message -->
                  <div class="tw-px-8 tw-box-content">
                    <!-- welcome message -->
                    <p class="tw-font-bold opacity-78 leading-19 tw-text-base tw-mb-4">Welcome message</p>

                    <div class="tw-inline-flex tw-flex-no-wrap">
                      <div>
                        <CompanyCustomUserWelcomeMessage
                          style="max-width: 220px;"
                          :hint="`write a welcome message for ${isSuperAdmin ? '' : 'your'} mentees`"
                          :company-id="company.id"
                          :message-id="companyMessages.mentee.id"
                          :user-type-id="accountTypes.mentee"
                          :company-message="companyMessages.mentee.message"
                          @save="getCompanySettings(company, true)"
                        />
                      </div>

                      <div class="tw-ml-8">
                        <CompanyCustomUserWelcomeMessage
                          style="max-width: 220px;"
                          :hint="`write a welcome message for ${isSuperAdmin ? '' : 'your'} mentors`"
                          :company-id="company.id"
                          :message-id="companyMessages.mentor.id"
                          :user-type-id="accountTypes.mentor"
                          :company-message="companyMessages.mentor.message"
                          @save="getCompanySettings(company, true)"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div class="tw-flex tw-mt-8">
                  <!-- theme -->
                  <div class="tw-px-8 tw-pb-8" style="max-width: 220px;">
                    <!-- theme -->
                    <p class="tw-font-bold opacity-78 leading-19 tw-text-base">
                      Your Theme <span class="tw-text-xs opacity-54" v-if="!isKengen">(default)</span>
                    </p>

                    <div :class="['tw-mt-4', { 'opacity-54 hover:opacity-87': !isKengen }]">
                      <div v-if="!isKengen">
                        <span class="tw-inline-block tw-border-2 tw-border-white tw-rounded-full w-56 h-56 tw-bg-app-deep-blue"></span>
                        <span class="tw-inline-block tw-border-2 tw-border-white tw-rounded-full w-56 h-56 tw-bg-black"></span>
                      </div>
                      <div v-else>
                        <span class="tw-inline-block tw-border-2 tw-border-white tw-rounded-full w-56 h-56 tw-bg-app-primary-orange"></span>
                        <span class="tw-inline-block tw-border-2 tw-border-white tw-rounded-full w-56 h-56 tw-bg-app-primary-blue"></span>
                      </div>

                      <div class="tw-mt-1" v-if="!isKengen">
                        <span class=" tw-inline-block tw-border-2 tw-border-white tw-rounded-full tw-w-6 tw-h-6 tw-bg-app-dark-yellow"></span>
                        <span class=" tw-inline-block tw-border-2 tw-border-white tw-rounded-full tw-w-6 tw-h-6 tw-bg-app-orange"></span>
                      </div>
                      <div class="tw-mt-1" v-else>
                        <span class=" tw-inline-block tw-border-2 tw-border-white tw-rounded-full tw-w-6 tw-h-6 tw-bg-app-deep-blue"></span>
                        <span class=" tw-inline-block tw-border-2 tw-border-white tw-rounded-full tw-w-6 tw-h-6 tw-bg-black"></span>
                      </div>
                    </div>
                  </div>
                </div>

              </template>

              <!-- MENTORSHIP JOURNEYS -->
              <template>
                <portal to="modal-body" key="mdl">
                  <EditJourney
                    :company="company"
                    @created-success="closeModal(); getCompanyJourneys(undefined, true);"
                  />
                </portal>

                <div class="tw-flex tw-items-center tw-pt-12 tw-pb-4">
                  <img
                    data-src="/img/edit-goals.png"
                    src="/img/mentorship-settings.png"
                    style="width: 80px; height: 80px;"
                    class="tw-object-cover tw-block tw-mr-8"
                    async
                  >
                  <h3 class="font-title tw-text-xl tw-tracking-wider">
                    Mentorship Journeys
                  </h3>

                  <BaseButton
                    text="Create A Journey"
                    class="tw-ml-auto"
                    @click="promptCreateJourney"
                  />
                </div>
                <div class="tw-border-t tw-border-app-black-11 tw-mb-8"></div>
                <div class="tw-flex tw-flex-wrap stacked-grid stacked-grid--stack-four lg:stacked-grid--stack-four">
                  <!-- journeys list -->
                  <template v-for="(journey, index) in companyJourneys">
                    <BaseCard :key="index" class="tw-border-- --tw-border-app-primary-blue info-card">
                      <h4 class="tw-text-xl font-title tw-px-2 tw-py-4">{{journey.title}}</h4>

                      <div class="tw-text-right tw-mt-3 tw-w-full tw-px-2 tw-pb-4">
                        <BaseButton
                          text="View Journey"
                          :to="{ name: 'customizeCompanyJourney', params: { id: company.id, journeyId: journey.id } }"
                          class="tw-shadow-app transition-fast tw-border-0 info-card__action tw-rounded-3 tw-fill-current tw-px-2 tw-py-1 tw-mr-2 tw-mb-2 tw-inline-block transition-fast tw-bg-app-deep-blue-11"
                        />
                      </div>
                    </BaseCard>
                  </template>
                </div>


                <div>
                  <!-- some options -->
                </div>
              </template>

            </div>
          </div>

        </transition>
      </div>

    </transition>

  </AppView>
</template>

<script>
import UserPhoto from '@/components/UserPhoto.vue';

import CompanyCustomUserWelcomeMessage from '@/views/Companies/Company/_partials/CompanyCustomUserWelcomeMessage.vue';
import CompanyCustomLandingPage from '@/views/Companies/Company/_partials/CompanyCustomLandingPage.vue';
import CompanyCustomLogo from '@/views/Companies/Company/_partials/CompanyCustomLogo.vue';
import EditJourney from '@/views/Companies/Company/_partials/EditJourney.vue';

import companyMixin from '@/mixins/company';
import { mapState } from 'vuex';

export default {
  name: 'CustomizeCompanyView',
  components: {
    UserPhoto,
    CompanyCustomLandingPage,
    CompanyCustomUserWelcomeMessage,
    CompanyCustomLogo,
    EditJourney,
  },
  mixins: [companyMixin],
  props: ['id'],
  data() {
    return {
      companyMessages: {
        mentor: {
          message: '',
        },
        mentee: {
          message: '',
        },
      },
      companyLogo: '',
      companyJourneys: [],
    };
  },
  computed: {
    accountTypes() {
      return this.$store.state.User.accountTypes;
    },
    ...mapState({
      landingpageSettings: (state) => state.CompanySettings.landingpage,
      companySettingsId: (state) => state.CompanySettings.id,
    }),
  },
  methods: {
    /**
     * - Logo
     * - welcome message
     * - landing page text
     * - landing page bg image
     *
     *
     * - ? ICEBOX: UI preferences (color)
     *
     */
    // eslint-disable-next-line no-unused-vars
    async getCompanySettings(company = this.company, refresh = false) {
      this.loadingPhoto = true;
      this.loadingCompany = true;
      this.errorCompany = undefined;

      const settleMessages = (messages) => {
        const userTypeMessage = (userType) => messages
          .find(({ id, message, level }) => (Number(level) === userType ? { id, message } : null)) || {};

        //
        this.companyMessages.mentor = {
          ...this.companyMessages.mentor,
          ...userTypeMessage(this.accountTypes.mentor),
        };
        this.companyMessages.mentee = {
          ...this.companyMessages.mentee,
          ...userTypeMessage(this.accountTypes.mentee),
        };
      };

      const settleLogo = (logo) => {
        this.companyLogo = logo;
      };

      // theme, messages & logo
      await Promise.all([
        this.$store.dispatch('companyMessages', [this.company.id, refresh]),
        this.$store.dispatch('companyLogo', [this.company.id, refresh]),
        this.$store.dispatch('companyLandingpageSettings', [this.company.id, refresh]), // uses vuex store
      ]).then(([messages, logo]) => {
        if (messages) {
          settleMessages(messages);
        }

        settleLogo(logo || this.company.logo);
      });

      await this.$nextTick();

      this.loadingPhoto = false;
      this.loadingCompany = false;
      this.errorCompany = undefined;
    },
    async getCompanyJourneys(company = this.company, refresh = false) {
      this.loadingJourneys = true;
      await this.$nextTick();

      const journeys = await this.$store.dispatch('getJourneys', [company.id, refresh]);

      if (journeys) {
        this.companyJourneys = journeys;
      }

      await this.$nextTick();
      this.loadingJourneys = false;
    },
    promptCreateJourney() {
      // open modal
      this.$store.commit('openModal', [true, 'modal']);
    },
  },
  async created() {
    // get company
    await this.getCompany();
    await this.$nextTick();

    // assert user company permissions
    if (!this.hasPermission) {
      this.$router.push({
        name: 'app',
      });

      return false;
    }

    await Promise.all([
      this.getCompanySettings(this.company, true),
      this.getCompanyJourneys(this.company, true),
      this.kengenIsLoggedIn(),
    ]);

    await this.$nextTick();

    this.loadingCompany = false;
    return true;
  },
};
</script>

<style>

</style>
