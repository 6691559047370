<template>
  <div
    :class="[
      'app-view tw-flex tw-flex-wrap tw-relative transition',

      {
        'app-view--max': minimalNav,
        'app-view--full': fullViewApp
      },

      { 'app-view--with-menu': hasNavFixed },
    ]"
  >

    <div
      v-if="nav === 'left-sticky' || nav === 'left-fixed'"
      :class="[
        'app-view-nav tw-min-h-screen transition tw-z-20',
        `${navClass}`,
        { 'app-view-nav--menu-minimal' : minimalNav },
        {
          'tw-fixed tw-left-0' : nav === 'left-fixed',
          'tw-sticky' : nav === 'left-sticky'
        },
      ]"
    >
      <slot name="nav" />
    </div>


    <div class="tw-fixed tw-top-0 tw-z-10 tw-w-full tw-bg-app-light-blue" ref="top-app-view">
      <transition name="fade" mode="out-in" appear>
        <portal-target name="top-app-view">
          <slot name="top-app-view" :recalculate="calculatePaddings" />
        </portal-target>
      </transition>
    </div>


    <div
      :class="['tw-z-0 tw-relative tw-min-h-screen tw-flex-grow', bodyClass]"
      :style="{
        paddingTop: `${topViewHeight}px`,
        paddingBottom: paddBottom ? `${bottomViewHeight}px` : 'auto',
      }"
    >
      <slot name="default" :recalculate="calculatePaddings" />
    </div>


    <div
      class="tw-fixed tw-bottom-0 tw-z-10 tw-w-full bg-bottom-app transition-fast border-box"
      ref="bottom-app-view"
      :style="`transform: translateY(${showBottomBar ? '0%' : '100%'})`"
    >
      <slot name="bottom-app-view" />
        <div :class="{ 'tw-absolute tw-bottom-0 tw-w-full': floatBottomAppView }">
          <transition name="new" mode="out-in" appear>
            <portal-target name="bottom-app-view" />
          </transition>
        </div>
    </div>


  </div>
</template>

<script>
import { mapState } from 'vuex';
import { resizeObservable } from '@/modules/misc';

export default {
  name: 'AppView',
  props: {
    showBottomBar: {
      type: Boolean,
      default: true,
    },
    floatBottomAppView: {
      type: Boolean,
      default: false,
    },
    bodyClass: {
      type: [Array, String],
      default: '',
    },
    paddBottom: {
      type: Boolean,
      default: false,
    },
    nav: {
      type: String, // [left|right]-[fixed|sticky]
      required: false,
    },
    navClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      topViewHeight: 0,
      bottomViewHeight: 0,
      fullViewApp: false,
    };
  },
  computed: {
    ...mapState({
      minimalNav: (state) => state.Ui.nav.minimalNav,
    }),
    hasNav() {
      return !!this.nav;
    },
    hasNavFixed() {
      return /(\w+-fixed)/.test((this.nav || ''));
    },
  },
  methods: {
    calculatePaddings() {
      this.topViewHeight = this.$refs['top-app-view'] ? this.$refs['top-app-view'].offsetHeight : 0;
      this.bottomViewHeight = (this.$refs['bottom-app-view'] ? this.$refs['bottom-app-view'].offsetHeight === 0 : true)
        ? this.topViewHeight * 1.35
        : this.$refs['bottom-app-view'].offsetHeight * 1.35;
    },
  },
  async mounted() {
    await this.$nextTick();
    await this.$nextTick();

    if (this.hasNav) this.minimizeMenu();

    await this.$nextTick();
    await this.$nextTick();

    // watch for top-app-view and bottom-app-view height to recalculate paddings on resize
    if (
      window.ResizeObserver
      && this.$refs['top-app-view']
      && this.$refs['bottom-app-view']
    ) {
      resizeObservable(
        [
          this.$refs['top-app-view'],
          this.$refs['bottom-app-view'],
        ],
        (entry) => {
          if (entry.contentRect.height !== 0) {
            // console.log(entry);
            this.calculatePaddings();
          }
        },
      );
    } else {
      setTimeout(() => {
        this.calculatePaddings();
      }, 200);
    }
  },
};
</script>

<style lang="postcss">
.app-view-nav {
  /* width: 25.5%; */
  will-change: width, margin-left, margin-right;
  width: var(--app-view-nav-width);
  max-width: var(--app-view-nav-width) !important;
  margin-left: var(--menu-nav-width);
}

.app-view-nav.app-view-nav--menu-minimal {
  margin-left: var(--menu-nav-min-width);
}


.bg-bottom-app {
  background: rgba(248,249,255,0);
  background: -moz-linear-gradient(top, rgba(248,249,255,0) 0%, rgba(248,249,255,0.87) 78%, rgba(248,249,255,0.87) 100%);
  background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(248,249,255,0)), color-stop(78%, rgba(248,249,255,0.87)), color-stop(100%, rgba(248,249,255,0.87)));
  background: -webkit-linear-gradient(top, rgba(248,249,255,0) 0%, rgba(248,249,255,0.87) 78%, rgba(248,249,255,0.87) 100%);
  background: -o-linear-gradient(top, rgba(248,249,255,0) 0%, rgba(248,249,255,0.87) 78%, rgba(248,249,255,0.87) 100%);
  background: -ms-linear-gradient(top, rgba(248,249,255,0) 0%, rgba(248,249,255,0.87) 78%, rgba(248,249,255,0.87) 100%);
  background: linear-gradient(to bottom, rgba(248,249,255,0) 0%, rgba(248,249,255,0.87) 78%, rgba(248,249,255,0.87) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f8f9ff', endColorstr='#f8f9ff', GradientType=0 );
}
</style>
