import { mapGetters } from 'vuex';

import navUiMixin from './nav';
import popoverUiMixin from './popover';
import modalUiMixin from './modal';
import resizeUiMixin from './resize';

import resourceUiMixin from './resource';

import userUiMixin from './user';

/**
 * !! LOAD ALL STORE GETTERS USED BY THE MICRO MIXINS HERE!
 */
export default {
  name: 'uiMixins',
  mixins: [
    navUiMixin,
    popoverUiMixin,
    modalUiMixin,
    resizeUiMixin,

    resourceUiMixin,

    userUiMixin,
  ],
  computed: {
    ...mapGetters([
      // Ui
      'isMobileView',
      'isDesktopView',
      'viewWidthIsLargerThan',
      'findViewState',
      'findViewData',
      // User
      'userTypeName',
    ]),
  },
};
