<template>
  <div class="tw-w-full tw-flex tw-group">
    <div>
      <UserPhoto
        :photo="expert.pic"
        :user-name="{
          name: expert.name,
        }"
        photo-size="tw-h-32 tw-w-32"
        :class="[
          'tw-mr-6 tw-my-1 tw-rounded-10--force --force-children tw-bg-app-white-87',
        ]"
      />
    </div>

    <div class="tw-w-3/5 tw-pr-2">
      <div class="tw-flex tw-items-center">
        <p class="tw-text-xl tw-font-bold tw-mb-1">{{expert.name}} {{expert.last_name}}</p>
        <div class="tw-ml-2 tw-pl-2 tw-border-l">
          <p class="tw-text-xs opacity-54">
            Activated Status
          </p>
          <SwitchInput
            :checked="activationStatus === ACTIVE_STATUS"
            @change="toggleActivateExpert"
            size="sm"
            class=""
          />
        </div>
      </div>
      <p class="tw-text-sm" v-html="expert.bio"></p>
    </div>

    <div class="tw-self-center" v-if="showViewMoreButton">
      <BaseButton
        text="View More"
        :to="{ name: 'expert', params: { id: expert.companyid, expertId: expert.id } }"
        class="tw-opacity-25 tw-transition-opacity transition-fast group-hover:tw-opacity-100"
      />
    </div>
  </div>
</template>

<script>
import UserPhoto from '@/components/UserPhoto.vue';
import SwitchInput from '@/components/SwitchInput.vue';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Expert',
  components: {
    UserPhoto,
    SwitchInput,
  },
  props: {
    showViewMoreButton: {
      type: Boolean,
      default: true,
    },
    expert: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      ACTIVE_STATUS: 0,
      INACTIVE_STATUS: 1,
    };
  },
  computed: {
    activationStatus() {
      return Number(this.expert.status);
    },
  },
  methods: {
    async getExpertActivationStatus() {
      await this.$store.dispatch('getExpert', this.expert.id);
      // this.expert = expert;
    },
    toggleActivateExpert() {
      if (this.activationStatus === this.ACTIVE_STATUS) {
        // deactivate
        this.deactivateExpert();
      } else {
        // activate
        this.activateExpert();
      }
    },
    async activateExpert() {
      const result = await this.$store.dispatch('activateExpert', {
        companyid: this.expert.companyid,
        email: this.expert.email,
        reason: '[Admin]',
      });

      if (result) {
        await this.getExpertActivationStatus();
      } else {
        // toast error
        this.$toasted.global.appError();
      }
    },
    async deactivateExpert() {
      const result = await this.$store.dispatch('deactivateExpert', {
        companyid: this.expert.companyid,
        email: this.expert.email,
        reason: '[Admin]',
      });

      if (result) {
        await this.getExpertActivationStatus();
      } else {
        // toast error
        this.$toasted.global.appError();
      }
    },
  },
};
</script>

<style>

</style>
