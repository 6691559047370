<template>
  <div>
    <transition mode="out-in" name="fade">
      <div v-if="!meeting?.id">
        <div class="tw-w-full tw-p-8 tw-bg-gray-300 tw-rounded-10">
          <p class="tw-text-sm tw-text-center opacity-78">
            Create a meeting first before adding participants
          </p>
        </div>
      </div>

      <div v-else>
        <template v-if="meeting?.id && !!participants === false">
          <div class="tw-flex tw-bg-app-primary-blue tw-px-4 tw-py-2 tw-rounded-10 tw-mb-4">
            <div class="tw-pl-5">
              <p class="tw-text-white opacity-78 tw-text-sm">
                Now select a mentorship group to add its users to the meeting
              </p>
            </div>
          </div>
        </template>

        <div class="tw-mb-2">
          <div class="tw-inline-block tw-py-1 tw-px-3 tw-rounded-full tw-bg-gray-200">
            {{participantsFromDb}} participants added
          </div>
        </div>

        <!-- participant form -->
        <form
          ref="add-meeting-participants-form"
          id="add-meeting-participants-form"
          method="post"
          action="/add_meeting_participants"
          class="tw-pb-16 md:tw-max-w-md"
          @submit.prevent="onSubmit"
        >
          <div class="tw-mb-4">
            <SelectGroup
              label="mentorship group"
              name="participants"
              nameKey="group_name"
              input-classes="tw-font-sans tw-bg-gray-200 my-select--huge"
              v-model="participants"
              :reduce="participant => participant.id"
              :options="listOptions"
              :selected="participants"
              :multiple="false"
              :clearable="true"
              :error="formError"
            />
          </div>
          <div class="tw-mb-4">
            <SelectGroup
              label="User Types"
              name="userTypes"
              nameKey="name"
              input-classes="tw-font-sans tw-bg-gray-200 my-select--huge"
              v-model="userType"
              :reduce="type => type"
              :options="userTypesOptions"
              :multiple="false"
              :clearable="true"
              :error="formError"
            />
          </div>
          <div class="tw-mb-4">
            <label class="tw-block tw-text-sm tw-mb-2 opacity-78 tw-text-left">
              Experts
            </label>
            <v-select
              :class="['my-select tw-w-full transition']"
              placeholder="Search Experts"
              v-model="experts"
              :options="expertsOptions"
              :clearable="true"
              :searchable="true"
              :multiple="true"
              :reduce="type => type.id"
            >
              <template v-slot:option="option">
                <div class="tw-flex tw-items-center">
                  <UserPhoto :photo="option.pic" photo-size="tw-h-8 tw-w-8" class="tw-mr-1" /> {{ option.name }} {{ option.last_name }}
                </div>
              </template>
            </v-select>
          </div>

          <div>
            <BaseButton
              :text="saveButtonText"
              type="submit"
              style="margin-top: 40px;"
              class="tw-shadow-app tw-block tw-w-full tw-bg-black tw-border-transparent tw-text-white tw-py-3"
              :disabled="!canSubmit || saveButtonDisabled"
              @click="onSubmit"
            />
          </div>
        </form>
      </div>
    </transition>
  </div>
</template>

<script>
import SelectGroup from '@/components/SelectGroup.vue';
import UserPhoto from '@/components/UserPhoto.vue';

const defaultButtonText = 'SAVE MEETING PARTICIPANTS';

export default {
  name: 'AddMeetingParticipants',
  components: {
    SelectGroup,
    UserPhoto,
  },
  props: {
    meeting: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      listOptions: [],
      userTypesOptions: [
        'both mentors and mentees',
        'mentors',
        'mentees',
      ],
      userType: undefined,
      participants: undefined,
      participantsFromDb: undefined,
      experts: [],
      expertsOptions: [],
      formError: undefined,
      saveButtonText: defaultButtonText,
      saveButtonDisabled: false,
    };
  },
  watch: {
    'meeting.id': {
      async handler(value) {
        if (value) {
          this.setFormData();
        }
      },
      immediate: true,
    },
  },
  computed: {
    canSubmit() {
      return this.participants?.length && this.userType?.length;
    },
  },
  methods: {
    async getMeetingParticipantsOptions() {
      if (!this.meeting?.id) return;

      const list = await this.$store.dispatch('getCompanyMentorshipGroups', this.meeting.companyid);
      // display only active mentorship groups
      this.listOptions = list.filter((item) => Number(item.status) === 0);
    },
    async getCompanyExpertsOptions() {
      const list = await this.$store.dispatch('getCompanyExperts', this.meeting.companyid);
      const listArray = Array.isArray(list) ? list : [];

      this.expertsOptions = listArray.map((expert) => ({
        label: `${expert.name} ${expert.last_name}`,
        ...expert,
      }));
    },
    async onSubmit() {
      if (!this.canSubmit) return;

      this.saveButtonDisabled = true;
      this.saveButtonText = 'SAVING...';

      let userTypeId = '';

      switch (this.userType) {
        case this.userTypesOptions[0]:
          userTypeId = 0;
          break;
        case this.userTypesOptions[1]:
          userTypeId = 3;
          break;
        case this.userTypesOptions[2]:
          userTypeId = 4;
          break;
        default:
          userTypeId = 0;
          break;
      }

      await this.$store.dispatch('saveParticipantsInMentorshipGroup', {
        meetingId: this.meeting.id,
        companyId: this.meeting.companyid,
        mentorshipGroupId: this.participants,
        userType: userTypeId,
        // participants: [...this.participants]
      });

      await this.$store.dispatch('saveExpertsParticipants', {
        meetingId: this.meeting.id,
        companyId: this.meeting.companyid,
        participants: [...this.experts],
      });

      this.$toasted.success('Saved!', { duration: 4000 });

      await this.$emit('on-success', this.meeting.linkid);

      this.saveButtonDisabled = false;
      this.saveButtonText = defaultButtonText;

      const data = {
        id: this.meeting.id,
        companyId: this.meeting.companyid,
      };

      this.participantsFromDb = ((await this.$store.dispatch('getParticipants', data)) || [])?.length || 0;
    },
    async setFormData() {
      this.getMeetingParticipantsOptions();
      this.getCompanyExpertsOptions();

      const group = this.$route.query['mentorship-group'];
      this.participants = group;

      const data = {
        id: this.meeting.id,
        companyId: this.meeting.companyid,
      };

      this.participantsFromDb = ((await this.$store.dispatch('getParticipants', data)) || [])?.length || 0;

      this.experts = ((await this.$store.dispatch('getExpertsParticipants', data)) || []).map((p) => p.id);
    },
  },
};
</script>

<style>

</style>
