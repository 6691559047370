<template>
  <AppView
    padd-bottom
  >
    <template v-slot:top-app-view>
      <transition name="fade" mode="out-in" appear>
        <CompanyHeader :company="company" :loading="loadingCompany" />
      </transition>
    </template>

    <transition name="fade" mode="out-in" appear>

      <div v-if="errorCompany" class="tw-mt-12" key="errmsg">
        <p class="tw-py-12 tw-text-base tw-text-center" v-html="errorCompany"></p>
      </div>

      <div v-else-if="loadingCompany" class="tw-mt-12" key="lodng">
        <p class="tw-py-12 tw-text-base tw-text-center">Loading...</p>
      </div>

      <div v-else key="bdy">
        <transition name="fade" mode="out-in" appear>

          <div class="app-view-grid px-body">
            <div class="app-view-grid__col-74">
              <portal to="modal-body" key="mdl">
                <EditMentorshipGroup
                  :company="company"
                  @created-success="closeModal(); getCompanyMentorshipGroups();"
                  class="tw-p-6"
                />
              </portal>

              <template>
                <div class="tw-flex tw-items-center tw-pt-10 tw-pb-4">
                  <img
                    data-src="/img/mentorship-groups.png"
                    src="/img/mentorship-groups.png"
                    style="width: 80px; height: 80px;"
                    class="tw-object-cover tw-block tw-mr-8"
                    async
                  >
                  <h3 class="font-title tw-text-xl tw-tracking-wider">
                    Mentorship Groups
                  </h3>

                  <BaseButton
                    class="tw-ml-auto"
                    text="Create A Mentorship Group"
                    title="Create A Mentorship Group"
                    @click="createMentorshipGroup"
                  />
                </div>
                <div class="tw-border-t tw-border-app-black-11 tw-mb-8"></div>
                <div></div>
              </template>

              <!-- MENTORSHIP GROUPS -->
              <template>
                <ViewMore
                  :list="mentorshipGroups"
                  :limit="4"
                  class=""
                  v-slot="{ item: mentorshipGroup }"
                >
                  <div class="tw-border-b tw-group">
                    <!-- TITLE AND STATUS -->
                    <div class="tw-py-4">
                      <p class="tw-text-xl tw-mb-4">
                        <span class="tw-font-bold">
                          {{mentorshipGroup.group_name}}
                        </span>

                        <span class="tw-ml-2"></span>

                        <span v-if="Number(mentorshipGroup.status) === 0" class="tw-text-xs opacity-78 tw-leading-none tw-py-2 tw-px-3 tw-bg-green-200 tw-rounded-3 tw-inline-block">
                          ACTIVE
                        </span>
                        <span v-if="Number(mentorshipGroup.status) === 1" class="tw-text-xs opacity-78 tw-leading-none tw-py-2 tw-px-3 tw-bg-gray-200 tw-rounded-3 tw-inline-block">
                          ENDED
                        </span>
                        <span v-if="Number(mentorshipGroup.status) === 2" class="tw-text-xs opacity-78 tw-leading-none tw-py-2 tw-px-3 tw-bg-red-200 tw-rounded-3 tw-inline-block">
                          DEACTIVATED
                        </span>
                      </p>
                      <p class="tw-text-sm tw-py-1 tw-px-3 tw-bg-gray-200 tw-rounded-10 tw-inline-block">
                        Goal: {{mentorshipGroup?.goal?.goal}}
                      </p>
                    </div>

                    <!-- MENTORS -->
                    <div class="tw-mb-4" v-if="mentorshipGroup?.mentors?.length">
                      <p class="tw-text-sm opacity-54">MENTORS ({{mentorshipGroup?.mentors?.length}})</p>
                      <div class="tw-flex tw-flex-wrap">
                        <template v-for="(participant, index) in mentorshipGroup?.mentors.slice(0, maxVisibleParticipants)">
                          <UserPhoto
                            :key="participant.id"
                            :user-name="participant"
                            :photo="participant.photo"
                            photo-size="tw-w-6 tw-h-6"
                            :class="{ 'tw--ml-3': index > 0 }"
                          />
                        </template>
                        <span v-if="mentorshipGroup?.mentors?.length > maxVisibleParticipants" class="opacity-54 tw-ml-1">...</span>
                      </div>
                    </div>
                    <p class="tw-text-sm opacity-54 tw-mb-4" v-else-if="!loadingParticipants && mentorshipGroup?.mentors?.length === 0">
                      MENTORS ({{mentorshipGroup?.mentors?.length}})
                    </p>

                    <!-- MENTEES -->
                    <div class="tw-mb-4" v-if="mentorshipGroup?.mentees?.length">
                      <p class="tw-text-sm opacity-54">MENTEES ({{mentorshipGroup?.mentees?.length}})</p>
                      <div class="tw-flex tw-flex-wrap">
                        <template v-for="(participant, index) in mentorshipGroup?.mentees.slice(0, maxVisibleParticipants)">
                          <UserPhoto
                            :key="participant.id"
                            :user-name="participant"
                            :photo="participant.photo"
                            photo-size="tw-w-6 tw-h-6"
                            :class="{ 'tw--ml-3': index > 0 }"
                          />
                        </template>
                        <span v-if="mentorshipGroup?.mentees?.length > maxVisibleParticipants" class="opacity-54 tw-ml-1">...</span>
                      </div>
                    </div>
                    <p class="tw-text-sm opacity-54 tw-mb-4" v-else-if="!loadingParticipants && mentorshipGroup?.mentees?.length === 0">
                      MENTEES ({{mentorshipGroup?.mentees?.length}})
                    </p>

                    <!-- ACTIONS -->
                    <div class="tw-mb-4 opacity-54-- transition-fast group-hover:tw-opacity-100">
                      <BaseButton
                        text="Chat"
                        class="tw-mr-4 tw-border-0 tw-bg-gray-300 hover:tw-bg-gray-400"
                        :to="{ name: 'companyMentorshipGroupChat', params: { id, groupId: mentorshipGroup.id }}"
                      />
                      <BaseButton
                        text="Tasks"
                        class="tw-mr-4 tw-border-0 tw-bg-gray-300 hover:tw-bg-gray-400"
                        :to="{ name: 'companyMentorshipGroupTasks', params: { id, groupId: mentorshipGroup.id }}"
                      />
                      <BaseButton
                        text="Settings"
                        class="tw-mr-4 tw-border-0 tw-bg-gray-300 hover:tw-bg-gray-400"
                        :to="{ name: 'companyMentorshipGroupSettings', params: { id, groupId: mentorshipGroup.id }}"
                      />
                    </div>
                  </div>

                </ViewMore>
              </template>
            </div>
          </div>

        </transition>
      </div>
    </transition>
  </AppView>
</template>

<script>
import CompanyHeader from '@/views/Companies/Company/_partials/CompanyHeader.vue';
import EditMentorshipGroup from '@/views/Companies/Company/MentorshipGroup/_partials/EditMentorshipGroup.vue';
import ViewMore from '@/components/ViewMore.vue';
import UserPhoto from '@/components/UserPhoto.vue';

import companyMixin from '@/mixins/company';
import _orderBy from 'lodash/orderBy';
import _reverse from 'lodash/reverse';

export default {
  name: 'MentorshipGroupsView',
  components: {
    CompanyHeader,
    EditMentorshipGroup,
    ViewMore,
    UserPhoto,
  },
  mixins: [companyMixin],
  props: ['id'],
  data() {
    return {
      error: undefined,
      loading: true,
      loadingParticipants: true,
      maxVisibleParticipants: 10,
      mentorshipGroups: [],
    };
  },
  methods: {
    async getCompanyMentorshipGroups() {
      this.loading = true;
      this.error = undefined;

      await this.$nextTick();

      const mentorshipGroups = await this.$store.dispatch('getCompanyMentorshipGroups', this.id);

      if (!mentorshipGroups) {
        this.error = 'Error getting mentorship groups';
        return;
      }

      this.mentorshipGroups = _reverse(_orderBy(mentorshipGroups, (grp) => Number(grp.id)));
      await this.$nextTick();

      // get participants separetely in order to not slow down getting mentorship groups
      this.getMentorshipGroupsParticipants();

      await this.$nextTick();

      this.loading = false;
    },
    async getMentorshipGroupsParticipants() {
      this.loadingParticipants = true;

      const mentorshipGroups = this.mentorshipGroups.map(async (mentorshipGroup) => {
        const participants = await this.$store.dispatch('getMentorshipGroupParticipants', {
          companyId: mentorshipGroup.companyid,
          groupId: mentorshipGroup.id,
        });

        // eslint-disable-next-line no-param-reassign
        mentorshipGroup.mentors = (participants || []).filter((participant) => Number(participant.type) === this.$store.state.User.accountTypes.mentor);
        // eslint-disable-next-line no-param-reassign
        mentorshipGroup.mentees = (participants || []).filter((participant) => Number(participant.type) === this.$store.state.User.accountTypes.mentee);

        return mentorshipGroup;
      });

      this.mentorshipGroups = await Promise.all(mentorshipGroups);

      this.loadingParticipants = false;
    },
    async createMentorshipGroup() {
      await this.$nextTick();

      // open modal
      this.$store.commit('openModal', [true, 'modal']);
    },
  },
  async created() {
    // get company
    await Promise.all([
      this.getCompany(),
    ]);

    await this.$nextTick();

    // assert user company permissions
    if (!this.hasPermission) {
      this.$router.push({
        name: 'app',
      });

      return;
    }

    await this.getCompanyMentorshipGroups();

    await this.$nextTick();
    await this.$nextTick();

    this.loading = false;
  },
};
</script>

<style>

</style>
