/**
 *
 * @param {String} url
 * @param {Object} params
 */
function buildURL(url, params) {
  if (!params) {
    return url;
  }

  const isAddingMoreParams = url.indexOf('?') >= 0;
  const paramsArray = Object.entries(params);
  const paramsLength = paramsArray.length;
  let queryStr = '';
  if (isAddingMoreParams) {
    queryStr = paramsLength > 0 ? '&' : '';
  } else {
    queryStr = '?';
  }

  paramsArray.forEach(([key, value], index) => {
    queryStr += `${key}=${value}`;

    if (index !== paramsLength - 1) {
      queryStr += '&';
    }
  });

  return url + queryStr;
}

function createError(message, config, request, response) {
  const error = new Error(message);

  console.error(message, {
    error,
    config,
    request,
    response,
  });

  return error;
}

/**
 * Resolve or reject a Promise based on response status.
 *
 * @param {Function} resolve A function that resolves the promise.
 * @param {Function} reject A function that rejects the promise.
 * @param {object} response The response.
 */
function settle(resolve, reject, response) {
  if (response.status === 200) {
    resolve(response);
  } else {
    reject(createError(
      `Request failed with status code ${response.status}`,
      response.config,
      response.request,
      response,
    ));
  }
}

const defaultConfig = {
  params: {},
  data: undefined,
  headers: {
    // 'Access-Control-Max-Age': 432000000,
  },
  // responseType: 'json',
  timeout: 0,
};

/**
 * api GET | POST
 * @param {Object} requestConfig
 * {
 *    url:String,
 *    method:String,
 *    params:Object,
 *    data:Object,
 *    headers:Object,
 *    onUploadProgress:Function
 *    onDownloadProgress:Function
 * }
 */
export default function xhrRequest(requestConfig) {
  const config = { ...defaultConfig, ...requestConfig };

  return new Promise(((resolve, reject) => {
    let requestData = config.data;

    let request = new XMLHttpRequest();

    request.open(
      config.method.toUpperCase(),
      buildURL(config.url, config.params),
      true,
    );

    // Set the request timeout in MS
    request.timeout = config.timeout;

    // Listen for ready state
    request.onreadystatechange = function handleLoad() {
      if (!request || request.readyState !== 4) {
        return;
      }

      // The request errored out and we didn't get a response, this will be
      // handled by onerror instead
      // With one exception: request that using file: protocol, most browsers
      // will return status as 0 even though it's a successful request
      if (request.status === 0 && !request.responseURL) {
        return;
      }

      // Prepare the response
      const response = {
        data: JSON.parse(request.response),
        status: request.status,
        statusText: request.statusText,
        config,
        request,
      };

      settle(resolve, reject, response);

      // Clean up request
      request = null;
    };

    // Handle browser request cancellation (as opposed to a manual cancellation)
    request.onabort = function handleAbort() {
      if (!request) {
        return;
      }

      reject(createError('Request aborted', config, 'ECONNABORTED', request));

      // Clean up request
      request = null;
    };

    // Handle low level network errors
    request.onerror = function handleError() {
      // Real errors are hidden from us by the browser
      // onerror should only fire if it's a network error
      reject(createError('Network Error', config, null, request));

      // Clean up request
      request = null;
    };

    // Handle timeout
    request.ontimeout = function handleTimeout() {
      const timeoutErrorMessage = `timeout of ${config.timeout}ms exceeded`;

      reject(createError(timeoutErrorMessage, config, 'ECONNABORTED', request));

      // Clean up request
      request = null;
    };

    // ! THIS DOES NOT WOOOORK!!!
    // ! CORS ISSUES EVERYWHERE
    // ! & BROWSER SENDING OPTIONS METHOD INSTEAD OF POST|GET
    // const requestHeaders = config.headers;
    // // console.log(requestHeaders);
    // // Add headers to the request
    // if ('setRequestHeader' in request) {
    //   Object.entries(requestHeaders).forEach(([key, val]) => {
    //     // if (typeof requestData === 'undefined' && key.toLowerCase() === 'content-type') {
    //     if (key.toLowerCase() === 'content-type') {
    //       // Remove Content-Type if data is undefined
    //       delete requestHeaders[key];
    //     } else {
    //       // Otherwise add header to the request
    //       request.setRequestHeader(key, val);
    //     }
    //   });
    // }

    // Add responseType to request if needed
    if (config.responseType) {
      try {
        request.responseType = config.responseType;
      } catch (e) {
        // Expected DOMException thrown by browsers not compatible XMLHttpRequest Level 2.
        // But, this can be suppressed for 'json' type as it can be parsed by default 'transformResponse' function.
        if (config.responseType !== 'json') {
          throw e;
        }
      }
    }

    // Handle progress if needed
    if (typeof config.onDownloadProgress === 'function') {
      request.addEventListener('progress', config.onDownloadProgress);
    }

    // Not all browsers support upload events
    if (typeof config.onUploadProgress === 'function' && request.upload) {
      request.upload.addEventListener('progress', config.onUploadProgress);
    }

    if (requestData === undefined) {
      requestData = null;
    }

    // Send the request
    request.send(requestData);
  }));
}
