<template>
  <div>
    <transition name="fade" appear>
      <div
        v-show="openModal"
        class="modal-container tw-z-70 tw-fixed tw-flex tw-justify-center tw-items-center tw-h-screen tw-w-screen tw-left-0 tw-top-0"
      >
        <div :class="
          [
            'modal tw-fixed tw-z-70 tw-flex tw-flex-col tw-flex-grow-0 tw-flex-shrink-0',
            { 'modal--open tw-bg-white': openModal && !isFullScreenModal },
            { 'modal--open modal--fullscreen': openModal && isFullScreenModal },
            { 'modal--dialog': isDialogModal },
          ]"
        >
          <div class="modal__header">
            <button
              class="tw-absolute tw-right-0 tw-top-0 tw-m-2 tw-inline-block"
              @click="close('button')"
              v-if="selfCloseModal && !isFullScreenModal"
            >
              <svg class="tw-w-6 tw-h-6" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                  viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
                <path d="M41.2,36L70.9,6.4c1.5-1.5,1.5-3.8,0-5.2c-1.5-1.5-3.8-1.5-5.2,0L36,30.8L6.4,1.1c-1.5-1.5-3.8-1.5-5.2,0s-1.5,3.8,0,5.2
                  L30.7,36L1.1,65.6c-1.5,1.5-1.5,3.8,0,5.3C1.9,71.6,3,72,3.7,72s1.9-0.4,2.6-1.1L36,41.3l29.6,29.6c0.7,0.8,1.9,1.1,2.6,1.1
                  s1.9-0.4,2.6-1.1c1.5-1.5,1.5-3.8,0-5.3L41.2,36z"/>
              </svg>
            </button>
            <portal-target name="modal-header">
              <slot name="header" />
            </portal-target>
          </div>
          <div class="modal__body tw-flex-grow">
            <template v-if="isDialogModal">
              <ConfirmCard
                @confirmed:true="close()"
                @confirmed:false="close()"
                :on-accept="modalAcceptFn"
                :message="dialogMessage"
              />
            </template>
            <template v-else>
              <portal-target name="modal-body">
                <slot name="body" />
              </portal-target>
            </template>
          </div>
          <div class="modal__footer">
            <template v-if="!isDialogModal">
              <portal-target name="modal-footer">
                <slot name="footer" />
              </portal-target>
            </template>
          </div>
        </div>

        <button
          class="tw-absolute tw-right-0 tw-top-0 tw-z-70 tw-m-6 tw-inline-block"
          @click="close('button')"
          v-if="selfCloseModal && isFullScreenModal"
        >
          <svg class="tw-w-8 tw-h-8" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
              viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
            <path d="M41.2,36L70.9,6.4c1.5-1.5,1.5-3.8,0-5.2c-1.5-1.5-3.8-1.5-5.2,0L36,30.8L6.4,1.1c-1.5-1.5-3.8-1.5-5.2,0s-1.5,3.8,0,5.2
              L30.7,36L1.1,65.6c-1.5,1.5-1.5,3.8,0,5.3C1.9,71.6,3,72,3.7,72s1.9-0.4,2.6-1.1L36,41.3l29.6,29.6c0.7,0.8,1.9,1.1,2.6,1.1
              s1.9-0.4,2.6-1.1c1.5-1.5,1.5-3.8,0-5.3L41.2,36z"/>
          </svg>
        </button>

        <transition name="fade" mode="in-out" appear>
          <div
            v-show="openModal"
            @click="close('bg')"
            :class="[
              'modal-bg tw-fixed tw-w-screen tw-h-screen tw-z-60 tw-left-0 tw-top-0',
              { 'tw-bg-black opacity-31': openModal && !isFullScreenModal },
              { 'tw-bg-white opacity-87': openModal && isFullScreenModal },
            ]"
          ></div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ConfirmCard from '@/components/ConfirmCard.vue';

export default {
  name: 'BaseModal',
  components: {
    ConfirmCard,
  },
  props: {
    type: {
      type: String,
      default: 'dialog', // in future, can be bottom-modal,
    },
  },
  computed: {
    ...mapState({
      openModal: (state) => state.Ui.modal.openModal,
      modalType: (state) => state.Ui.modal.modalType,
      selfCloseModal: (state) => state.Ui.modal.selfCloseModal,
      dialogMessage: (state) => state.Ui.modal.dialogMessage,
      modalAcceptFn: (state) => state.Ui.modal.modalAcceptFn,
    }),
    isDialogModal() {
      return this.modalType === 'dialog';
    },
    isFullScreenModal() {
      return this.modalType === 'full-screen' || this.modalType === 'fullscreen';
    },
  },
  methods: {
    close(clickedEl = 'button') {
      if ((this.isFullScreenModal || this.isDialogModal) && clickedEl === 'bg') return false;
      this.$store.commit('openModal', [false]);
      return true;
    },
  },
};
</script>

<style>

</style>
