import journeyActionsList from './journeyActionsList.json';
import announcementsActionsList from './announcementsActionsList.json';

const badgeTypes = {
  tip: 1,
  badge: 2,
  badgeAndTip: 3,
  announcement: 4,
};

export default {
  state: {
    uid: undefined,
    landingpage: {
      text: '',
      bgImage: undefined,
      // colorTheme: undefined, // * ICEBOX
    },
    uiSettings: {},
    journeyActionsList,
    announcementsActionsList,
    badgeTypes,
  },
};
