<template>
  <div class="tw-relative">
    <div class="tw-shrink-1 tw-pt-2 tw-pl-2">
      <h4 style="max-height: 41px;" class="leading-19 tw-text-base tw-pb-2 tw-break-all tw-overflow-hidden">
        {{ resource.title.length ? resource.title : resource.link }}
      </h4>
      <h6 class="opacity-31 tw-text-xs leading-15 tw-overflow-hidden tw-my-2 tw-mt-0" style="max-height: 30px" >
        Uploaded {{ resource['createAt'] || (resource['Date of Post'] || resource['dateOfPost'] || resource['dateofPost']) | moment('calendar') }}
      </h6>

      <div class="tw-self-stretch tw-flex-grow tw-w-full">
        <slot name="default" />
      </div>

      <div class="tw-flex tw-items-center tw-mb-2">
        <button
          class="tw-text-red-500 tw-p-1 tw-bg-red-100 tw-mr-2 tw-fill-current"
          @click.stop="deleteBtnClick(resource)"
        >
          <svg class="tw-w-6 tw-h-6" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
              y="0px" viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
            <path d="M63.1,15.2V64c0,4.4-3.6,8-8,8h-38c-4.4,0-8-3.6-8-8V15.2c0-1.1,0.8-1.9,1.9-1.9s1.9,0.8,1.9,1.9V64c0,2.3,1.9,4.2,4.2,4.2
              h38c2.3,0,4.2-1.9,4.2-4.2V15.2c0-1.1,0.8-1.9,1.9-1.9S63.1,14.2,63.1,15.2z M66.3,4.9H38v-3C38,0.8,37.2,0,36.1,0
              c-1.1,0-1.9,0.8-1.9,1.9v3H5.9C4.7,4.9,4,5.7,4,6.8s0.8,1.9,1.9,1.9h60.2C67.3,8.7,68,8,68,6.8S67.3,4.9,66.3,4.9z M24.7,58.9V17.1
              c0-1.1-0.8-1.9-1.9-1.9s-1.9,0.8-1.9,1.9v41.8c0,1.1,0.8,1.9,1.9,1.9S24.7,60,24.7,58.9z M38,58.9V17.1c0-1.1-0.8-1.9-1.9-1.9
              c-1.1,0-1.9,0.8-1.9,1.9v41.8c0,1.1,0.8,1.9,1.9,1.9C37.2,60.8,38,60,38,58.9z M51.3,58.9V17.1c0-1.1-0.8-1.9-1.9-1.9
              s-1.9,0.8-1.9,1.9v41.8c0,1.1,0.8,1.9,1.9,1.9S51.3,60,51.3,58.9z"/>
          </svg>
        </button>
        <a
          class="leading-14 tw-p-1 tw-border-box tw-inline-block active:tw-bg-gray-300 focus:tw-bg-gray-300 tw-rounded-sm hover:tw-bg-gray-300 tw-fill-current"
          :href="resource.link"
          target="_blank"
        >
          <svg class="tw-w-6 tw-h-6" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
            <path d="M70.5,32C59.8,19.7,48.2,13.3,36.3,13.3c0,0,0,0-0.2,0c-16.9,0-29.8,12.9-34.6,18.5c-2,2.2-2,5.8,0,8.2
              c10.7,12.3,22.3,18.7,34.2,18.7c0,0,0,0,0.2,0c16.9,0,29.8-12.9,34.6-18.5C72.5,37.8,72.5,34.2,70.5,32z M67.5,37.6
              c-4.4,5.2-16.3,17.1-31.6,17.1c0,0,0,0-0.2,0c-10.7,0-21.3-5.8-31.2-17.3c-0.6-0.8-0.6-2.2,0-3c4.4-5.2,16.3-17.1,31.6-17.1
              c0,0,0,0,0.2,0c10.7,0,21.3,5.8,31.2,17.3C68.1,35.4,68.1,36.8,67.5,37.6z M35.9,25.9c-5.6,0-10.1,4.6-10.1,10.1s4.6,10.1,10.1,10.1
              S46,41.6,46,36S41.5,25.9,35.9,25.9z M35.9,42.2c-3.4,0-6.2-2.8-6.2-6.2c0-3.4,2.8-6.2,6.2-6.2s6.2,2.8,6.2,6.2
              C42.1,39.4,39.3,42.2,35.9,42.2z"/>
          </svg>
        </a>
      </div>

      <div class="tw-flex tw-items-center tw-justify-between tw-mb-2">
        <div class="tw-inline-flex tw-items-center tw-bg-app-black-11-- tw-rounded-r-full tw-py-1" v-if="resource.sender">
          <UserPhoto
            :photo="resource.sender.photo"
            :user-name="resource.sender"
            photo-size="tw-h-6 tw-w-6"
            :title="resource.sender.name"
          />
        </div>
        <p
          :title="`${resource.shares} users received this resource`"
          class="opacity-54 tw-text-xs leading-15 tw-overflow-hidden tw-my-2 tw-mt-0 tw-bg-gray-300 tw-rounded-full tw-text-gray-900 tw-px-2" style="max-height: 30px"
          v-if="resource.shares"
        >
          {{ resource.shares }} users
        </p>

        <!-- <button
          v-if="canShare"
          class="leading-14 tw-p-1 tw-border-box tw-inline-block active:tw-bg-gray-300 focus:tw-bg-gray-300 tw-fill-current tw-relative"
          @click.stop="shareBtnClick($event)"
        >
          <span class="tw-absolute tw-left-0 tw-top-0 tw-w-full tw-h-full tw-z-10"></span>
          <span class="tw-inline-block tw-uppercase tw-font-bold tw-text-xs tw-align-middle">SHARE</span>
          <svg class="tw-w-6 tw-h-6 tw-ml-1 tw-inline-block" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" xml:space="preserve">
            <path d="M71.1,25.6L56.6,10.8c-1-1-2.4-1-3.3,0c-1,1-1,2.4,0,3.3l11.2,11.5H25.3C11.5,25.6,0,36.8,0,50.9v8.6c0,1.4,1,2.4,2.4,2.4
              s2.4-1,2.4-2.4v-8.6c0-11.2,9.1-20.5,20.5-20.5h39.1L53.2,41.8c-1,1-1,2.4,0,3.3c0.5,0.5,1,0.7,1.7,0.7c0.7,0,1.2-0.2,1.7-0.7
              l14.6-14.8C72.3,29.2,72.3,27.1,71.1,25.6z"/>
          </svg>
        </button> -->
      </div>
    </div>
  </div>
</template>

<script>
import UserPhoto from '@/components/UserPhoto.vue';
// import ResourceMixin from '@/mixins/resources';

export default {
  name: 'LinkResourceCard',
  components: {
    UserPhoto,
  },
  props: {
    resource: {
      type: Object,
      required: true,
    },
    canShare: {
      type: Boolean,
      default: false,
    },
  },
  // mixins: [ResourceMixin],
  methods: {
    cardClick($event) {
      this.$emit('click-card', $event, this.resource);
    },
    shareBtnClick($event) {
      this.$emit('click-share', $event, this.resource);
    },
    deleteBtnClick($event) {
      this.$emit('click-delete', $event, this.resource);
    },
  },
};
</script>

<style>

</style>
