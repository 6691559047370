<template>
  <div class="tw-relative">
    <div :class="[
      'tw-flex tw-items-center tw-justify-between tw-cursor-pointer tw-rounded-r-full',
      { 'tw-bg-gray-200': isOpen }
      ]"
      @click="toggleBody"
    >
      <div class="tw-pr-4 tw-flex-grow">
        <slot name="header" />
      </div>
      <!-- ARROW UP/DOWN -->
      <button class="tw-rounded-full tw-border tw-bg-gray-200 tw-p-3  transition-fast focus:tw-bg-gray-400 active:tw-bg-gray-400">
        <svg
          class="tw-w-5 tw-h-5 transition-fast"
          :style="`transform: rotate(${isOpen ? '180deg' : '0deg'}); transform-origin: center;`"
          data-name="Icon/Down" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24" viewBox="0 0 24 24">
          <defs>
            <clipPath id="clip-path">
              <rect id="Rectangle_21" data-name="Rectangle 21" width="24" height="24" fill="none"/>
            </clipPath>
          </defs>
          <g id="Mask_Group_23" data-name="Mask Group 23" transform="translate(24) rotate(90)" clip-path="url(#clip-path)">
            <path id="noun_right_1701594" d="M0,16.95a1,1,0,0,1,.3-.725l7.25-7.25L.3,1.725A1,1,0,0,1,.3.3,1,1,0,0,1,1.725.3L9.7,8.275A1,1,0,0,1,9.7,9.7l-7.975,8A1,1,0,0,1,.3,17.7,1.059,1.059,0,0,1,0,16.95Z" transform="translate(7 3)"/>
          </g>
        </svg>
      </button>

    </div>
    <div
      ref="body"
      :style="{ maxHeight: `${bodyHeight}px` }"
      :class="[
        'transition-slow tw-pt-2',
        {'tw-overflow-hidden': bodyHeight < defaultMaxHeight }, // how to make 'overflow: visible' after it has opened
        {'tw--ml-5 tw-pl-8': offsetBody },
        {'tw--mx-5 tw-px-5': !offsetBody },
        {'tw-overflow-auto': bodyHeight > defaultMaxHeight },
        bodyClass
      ]"
    >
      <slot name="body" ref="body" :update="updateAccordionBody"/>
    </div>
    <div class="tw-border tw-w-full opacity-54 tw-mb-2"></div>
  </div>
</template>

<script>
export default {
  name: 'Accordion',
  props: {
    bodyClass: {
      type: String,
      default: '',
    },
    offsetBody: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      bodyHeight: 0,
      isOpen: false,
      defaultMaxHeight: 6000,
    };
  },
  watch: {
    isOpen(newVal) {
      if (newVal) {
        this.bodyHeight = this.$refs ? this.$refs.body.scrollHeight : this.defaultMaxHeight;
        setTimeout(() => {
          this.updateAccordionBody(true);
        }, 400);
      } else {
        this.bodyHeight = 0;
      }
    },
  },
  methods: {
    toggleBody() {
      this.isOpen = !this.isOpen;
    },
    updateAccordionBody(auto = false) {
      // console.log('updating');
      this.bodyHeight = this.$refs && !auto ? this.$refs.body.scrollHeight : this.defaultMaxHeight;
    },
  },
};
</script>

<style>

</style>
