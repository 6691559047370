import resolve from '../../../modules/api/resolve';
import { apiPost, apiStore, generateFormData } from '../../../modules/apiHelper';
import { invalideDate, isStaleData, now } from '../../../modules/dataHelper';

export default {
  async saveSurvey(context, form) {
    const createSurvey = async () => {
      const surveyReq = await resolve(apiPost(
        'create_survey_pillar',
        generateFormData({
          companyid: form.companyId,
          pillar_name: form.name,
          type: form.type,
        }),
        31,
      ));

      return surveyReq;
    };

    const updateSurvey = async () => {
      const surveyReq = await resolve(apiPost(
        'update_survey_pillar',
        generateFormData({
          pillarid: form.id,
          pillar_name: form.name,
        }),
        35,
      ));

      return surveyReq;
    };

    let result;

    if (form.id) {
      // updating
      result = await updateSurvey();
    } else {
      result = await createSurvey();
    }

    return result;
  },

  async saveSurveyQuestion(context, form) {
    const createSurveyQuestion = async () => {
      const questionReq = await resolve(apiPost(
        'create_survey',
        generateFormData({
          companyid: form.companyId,
          pillarid: form.surveyId,
          criteria: form.question,
        }),
        33,
      ));

      return questionReq;
    };

    const updateSurveyQuestion = async () => {
      const surveyReq = await resolve(apiPost(
        'update_survey_question',
        generateFormData({
          questionid: form.id,
          question: form.question,
        }),
        35,
      ));

      return surveyReq;
    };

    let result;

    if (form.id) {
      // updating
      result = await updateSurveyQuestion();
    } else {
      result = await createSurveyQuestion();
    }

    return result;
  },

  async saveQuestionRating(context, form) {
    const createQuestionRating = async () => {
      const ratingReq = await resolve(apiPost(
        'create_survey_ratings',
        generateFormData({
          companyid: form.companyId,
          suvid: form.questionId,
          rates: form.rate,
        }),
        33,
      ));

      return ratingReq;
    };

    const updateQuestionRating = async () => {
      const surveyReq = await resolve(apiPost(
        'update_question_rating',
        generateFormData({
          ratesid: form.id,
          rates: form.rate,
        }),
        35,
      ));

      return surveyReq;
    };

    let result;

    if (form.id) {
      // updating
      result = await updateQuestionRating();
    } else {
      result = await createQuestionRating();
    }

    return result;
  },

  async getCompanySurveys(context, [CompanyId, refresh = false]) {
    const companyid = CompanyId || context.rootState.User.companyid;
    if (!companyid) return false;

    const surveys = await apiStore.getItem(`surveys/${companyid}`) || [invalideDate, []];

    if (!refresh && !isStaleData(surveys[0]) && surveys[1].length) return surveys[1];

    const result = await resolve(apiPost('survey_pillar_log', generateFormData({ companyid }), 31))
      .then((data) => {
        if (!data) return false;

        const companySurveys = data.survey_pillar_log || [];

        if (companySurveys.length) {
          apiStore.setItem(`surveys/${companyid}`, [now(), companySurveys]);
        }

        return companySurveys;
      });
    //

    return result;
  },

  async getSurveyQuestions(context, [CompanyId, surveyId, refresh = false]) {
    const companyid = CompanyId || context.rootState.User.companyid;
    if (!companyid) return false;

    const surveyQuestions = await apiStore.getItem(`surveyQuestions/${companyid}/${surveyId}`) || [invalideDate, []];

    if (!refresh && !isStaleData(surveyQuestions[0]) && surveyQuestions[1].length) return surveyQuestions[1];

    const result = await resolve(apiPost('survey_log', generateFormData({ companyid }), 32))
      .then((data) => {
        if (!data) return false;

        const companySurveyQuestions = (data.survey_log || []).filter((question) => Number(question.pillarid) === Number(surveyId));

        if (companySurveyQuestions.length) {
          apiStore.setItem(`surveyQuestions/${companyid}/${surveyId}`, [now(), companySurveyQuestions]);
        }

        return companySurveyQuestions;
      });
    //

    return result;
  },

  async getSurveyQuestionRatings(context, [CompanyId, questionId, refresh = false]) {
    const companyid = CompanyId || context.rootState.User.companyid;
    if (!companyid) return false;

    const questionRatings = await apiStore.getItem(`questionRatings/${companyid}/${questionId}`) || [invalideDate, []];

    if (!refresh && !isStaleData(questionRatings[0]) && questionRatings[1].length) return questionRatings[1];

    const result = await resolve(apiPost('ratings_log', generateFormData({ companyid }), 32))
      .then((data) => {
        if (!data) return false;

        const companySurveyQuestionRatings = (data.ratings_log || []).filter((rating) => Number(rating.suvid) === Number(questionId));

        if (companySurveyQuestionRatings.length) {
          apiStore.setItem(`questionRatings/${companyid}/${questionId}`, [now(), companySurveyQuestionRatings]);
        }

        return companySurveyQuestionRatings;
      });
    //

    return result;
  },

  async getCompanySurveyResponses(context, [CompanyId, surveyId]) {
    const companyid = CompanyId || context.rootState.User.companyid;
    if (!companyid) return false;

    const surveyQuestions = await context.dispatch('getSurveyQuestions', [companyid, surveyId, true]);

    const surveyResponses = await resolve(apiPost('submit_survey_log', generateFormData({ companyid }), 32))
      .then((data) => {
        if (!data) return false;

        return data.submit_survey_log || [];
      });
    //

    if (!surveyResponses || !surveyQuestions) {
      return false;
    }

    const promises = [];

    const mappedQuestions = await Promise.all(
      surveyQuestions.map(async (question) => {
        // filter responses that are only in surveyQuestions only
        const responses = surveyResponses.filter((response) => Number(question.id) === Number(response.suvid));

        // console.log(responses);

        // get user of each response
        const mappedResponses = await Promise.all(
          responses.map(async (response) => {
            const userPromise = context.dispatch('getUserData', response.userid);
            promises.push(userPromise);

            const userResponded = await userPromise;

            return {
              ...response,
              user: userResponded,
            };
          }),
        );

        // console.log(mappedResponses);

        // get question ratings
        const questionRatings = context.dispatch('getSurveyQuestionRatings', [companyid, question.id, true]);
        promises.push(questionRatings);

        const ratings = await questionRatings;

        return {
          ...question,
          questionType: ratings.length ? 'ratings' : 'text',
          ratings,
          responses: mappedResponses,
          // responses,
        };
      }),
    );

    await Promise.all(promises);

    // console.log(mappedQuestions);


    return mappedQuestions;
  },

  async activateSurvey(context, surveyId) {
    const result = await resolve(apiPost(
      'activate_survey_pillar',
      generateFormData({
        pillarid: surveyId,
        start: '11-12-2024',
        end: '15-12-2024',
      }),
      34,
    ));

    return result;
  },

  async deactivateSurvey(context, surveyId) {
    const result = await resolve(apiPost(
      'dev_suv_pillar',
      generateFormData({
        pillarid: surveyId,
        reason: 'not relevant',
      }),
      33,
    ));

    return result;
  },

  async activateSurveyQuestion(context, surveyQuestionId) {
    const result = await resolve(apiPost(
      'activate_survey',
      generateFormData({
        suvid: surveyQuestionId,
        start: '11-12-2024',
        end: '15-12-2024',
      }),
      33,
    ));

    return result;
  },

  async deactivateSurveyQuestion(context, surveyQuestionId) {
    const result = await resolve(apiPost(
      'dev_suv',
      generateFormData({
        suvid: surveyQuestionId,
        reason: 'not relevant',
      }),
      33,
    ));

    return result;
  },
};
