import { getReadableFileType } from '../../modules/dataHelper';
import { apiUrl } from '../../modules/apiHelper';

export default {
  name: 'resourceUiMixin',
  methods: {
    resourceType(type = 'FILE', isFileName = false) {
      return getReadableFileType(type, isFileName) || type.split('/')[0];
    },

    // * JUST LEARNT OF THE DOWNLOAD ATTRIBUTE IN LINK TAGS
    // * [UPDATE] USING LINK[download] ATTRIBUTE TO TRIGGER DOWNLOAD
    // ? [UPDATE 2] USING LINK[download] DOES NOT WORK DUE TO cross-site ISSUES,
    //        REVERTED BACK TO OPENING IN NEW WINDOW
    // TODO: MOVE SOME CODES TO A MODULE
    async downloadResource(resourceData, resourceName = '') {
      if (!resourceData) {
        console.warn('resource data is falsey. ', resourceData);
        return;
      }
      // if (!/(resource\/)/.test(resourceData)) return false;

      function getFileName(_url, _resourceName) {
        let name = (!_resourceName)
          ? _url.split('\\').pop().split('/').pop()
          : _resourceName;
        //
        name = name.split('.');
        const type = name.pop();
        name = name.join('.');

        return [`${name}.${type}`, name];
      }

      // ? attempt to use <a href="{url}" download />
      // eslint-disable-next-line no-unused-vars
      function forceDownloadWithAnchor(blob, filename) {
        const a = document.createElement('a');
        a.download = filename;
        a.href = blob;
        document.body.appendChild(a);
        a.click();
        a.remove();
      }

      // ? attempt to use <iframe src="{url}" />
      // eslint-disable-next-line no-unused-vars
      function forceDownloadWithIframe(url, filename) {
        const iframe = document.createElement('iframe');
        iframe.style.visibility = 'none';
        // iframe.src = url;
        iframe.src = 'about:blank';
        document.body.appendChild(iframe);
        const a = document.createElement('a');
        a.download = filename;
        a.href = url;

        iframe.contentDocument.body.appendChild(a);

        console.log(iframe.contentDocument);

        a.click();
        // * how to delete the iframe after download??
      }

      // const url = urlFriendly(`${apiUrl}/${resourceData}`, false);
      const url = `${apiUrl}/${resourceData}`;
      const [fileName, noExt] = getFileName(url, resourceName);
      this.$toasted.info(`Downloading ${fileName} ...`, {
        duration: 5000,
      });

      console.log(noExt);

      // forceDownloadWithIframe(url, fileName);
      // forceDownloadWithAnchor(url, fileName);


      // #region fetch resource code

      // ? attempt to use resource's blob data
      // ? fetch api returns content-type 'text/html'
      // await fetch(url, {
      //   method: 'GET',
      //   headers: new Headers({
      //     Origin: window.location.origin,
      //     'Content-Disposition': `attachment; filename=${fileName}`,
      //   }),
      //   mode: 'no-cors',
      // })
      //   .then((res, req) => {
      //     console.log(req);
      //     return res.blob();
      //   })
      //   .then((blob) => {
      //     console.log(blob);
      //     const blobUrl = window.URL.createObjectURL(blob);
      //     forceDownload(blobUrl, noExt);
      //   })
      //   .catch(e => console.error(e));

      // #endregion

      //

      window.open(url, '_blank');
    },

    downloadAllResources(resources = []) {
      resources.forEach((resource) => {
        this.downloadResource(resource.data, resource.name);
      });
    },
  },
};
