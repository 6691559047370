<template>
  <div>
    <div class="tw-flex tw-h-screen tw-w-full">
      <div class="tw-hidden md:tw-block md:tw-w-1/6 lg:tw-w-1/3 tw-bg-gray-100 my-bg-pattern"></div>
      <div class="tw-w-full md:tw-w-5/6 lg:tw-w-2/3 px-body md:tw-px-0 tw-bg-white tw-h-full tw-flex tw-flex- tw-flex-col tw-justify-center tw-items-center">
        <BaseCard class="tw-p-0 md:tw-p-8 tw-shadow-none md:tw-shadow-lg tw-max-w-full md:tw-w-2/3 tw-flex-shrink-0 tw-mb-20 auth-card">
          <div>
            <div v-if="loading">
              <p class="opacity-54 tw-text-xs tw-py-4 tw-block tw-text-center">Loading...</p>
            </div>
            <div v-else-if="!passwordSent">
              <h1 class="tw-text-4xl tw-text-center">Forgot password</h1>
              <div class="tw-h-4 tw-block"></div>
              <form
                ref="forgot-password-form"
                id="forgot-password-form"
                method="post"
                action="/forgot-password"
                @submit.prevent="onSubmit"
                >
                <div class="tw-block tw-my-4 tw-shadow-md tw-bg-red-600 tw-text-white tw-px-4 tw-py-2 tw-rounded-sm" v-if="errors.request">
                  {{ errors.request }}
                </div>
                <div class="tw-mb-4">
                  <InputGroup
                    label="Type your email address"
                    name="email"
                    type="email"
                    placeholder="email"
                    v-model.lazy="email"
                    :error="errors.email"
                  />
                </div>
                <div class="flex items-center justify-between">
                  <BaseButton
                    type="submit"
                    :text="btnText"
                    :disabled="!canSubmit || btnDisabled"
                  />
                </div>
              </form>

            </div>
            <div v-else class="tw-text-center">
              <h2 class="tw-text-4xl ">Password sent to your email!</h2>
              <h6 class="opacity-54 tw-italic tw-font-thin">Try again in 10 minutes</h6>
            </div>
          </div>
        </BaseCard>
      </div>
    </div>
  </div>
</template>

<script>
import InputGroup from '@/components/InputGroup.vue';
import form from '@/modules/formHelper';
import { apiStore, generateFormData, apiPost } from '@/modules/apiHelper';
import { now, isStaleData, invalideDate } from '@/modules/dataHelper';
import authMixin from '@/mixins/auth';

export default {
  name: 'ForgotPassword',
  components: {
    InputGroup,
  },
  mixins: [authMixin],
  data() {
    return {
      btnText: 'Recover password',
      btnDisabled: false,
      email: undefined,
      loading: true,
      passwordSent: false,
      errors: {
        email: undefined,
        request: undefined,
      },
    };
  },
  computed: {
    canSubmit() {
      return form.isValidEmail(this.email);
    },
    formData() {
      return generateFormData({
        email: this.email,
      });
    },
  },
  watch: {
    email() {
      if (!form.isValidEmail(this.email)) {
        this.errors.email = 'Invalid email address';
      } else {
        this.errors.email = undefined;
      }
    },
  },
  methods: {
    onSubmit() {
      if (!this.canSubmit) {
        this.errors.request = 'Please type a valid email address';
        return false;
      }
      this.btnText = 'working...';
      this.btnDisabled = true;
      this.errors.request = undefined;

      return apiPost('reset-password', this.formData, 5)
        .catch((err) => {
          console.warn('password verification error', err);
          this.$toasted.global.appError();
        })
        .then(async (res) => {
          this.btnText = 'Recover password';
          this.btnDisabled = false;
          if (!res) return false;

          const { data } = res;
          if (data.error) {
            console.error(data.message);
            this.$toasted.global.appError({ errorMessage: data.message, duration: 4000, position: 'top-right' });
            return false;
          }

          this.$toasted.success(data.message, {
            duration: 7000,
            position: 'bottom-right',
          });

          this.passwordSent = true;
          await this.$store.dispatch('clearUserSession');
          await apiStore.setItem('forgotpasswordsent', [true, now()]);

          return true;
        });
    },
  },
  async mounted() {
    await apiStore.getItem('forgotpasswordsent')
      .then((res) => {
        const time = res ? res[1] : invalideDate;
        // password sent is true if data exists and is not stale date
        this.passwordSent = res ? (!!res[0] && !isStaleData(time, now(), 600000)) : false;

        if (this.passwordSent) {
          setTimeout(() => {
            this.passwordSent = false;
            apiStore.removeItem('forgotpasswordsent');
          }, now() - time);
        }
      });

    await this.$nextTick();
    this.loading = false;
  },
};
</script>

<style>

</style>
