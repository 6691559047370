var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{class:[
      'tw-fixed tw-z-30 tw-h-screen tw-left-0 tw-top-0 transition menu-nav',
      'tw-bg-app-light-blue- tw-bg-app-deep-blue- tw-bg-gray-700 tw-text-white tw-text-app-deep-blue-',
      { 'side-nav--open tw-shadow-xl': _vm.openNav },
      { 'menu-nav--minimal': _vm.minimalNav },
      { 'tw-bg-app-light-blue- tw-bg-app-deep-blue- tw-bg-gray-700 tw-text-white tw-text-app-deep-blue-': !_vm.isKengen },
      { 'tw-bg-app-primary-orange': _vm.isKengen },
      { 'tw-pt-0': _vm.isKengen },
    ],staticStyle:{"width":"calc(100vw - 36px)"}},[_c('button',{staticClass:"menu-nav__btn-controller tw-z-40",attrs:{"type":"button"},domProps:{"innerHTML":_vm._s(_vm.leftNav)},on:{"click":function($event){$event.stopPropagation();return _vm.menuBtnClick()}}}),(_vm.isKengen && !_vm.loadingKengen)?_c('div',{staticClass:"tw-bg-white tw-py-4 tw-mb-12"},[(!_vm.minimalNav)?_c('img',{staticClass:"tw-h-12 tw-ml-10",attrs:{"src":"/img/app-logo-kengen.png","alt":"Kengen admin"}}):_vm._e(),(_vm.minimalNav)?_c('img',{staticClass:"tw-h-12 tw-ml-5",attrs:{"src":"/img/app-logo-kengen@square.jpg","alt":"Kengen admin"}}):_vm._e(),(_vm.isRegionalAdmin && !_vm.minimalNav)?_c('p',{staticClass:"tw-text-app-primary-blue tw-mx-10"},[_vm._v(" "+_vm._s(_vm.region?.region || '')+" Region ")]):_vm._e()]):_vm._e(),_c('ul',{class:[
        'tw-block tw-px-4 menu-list tw-max-w-full transition-fast',
        { 'menu-list--minimal': _vm.minimalNav }
      ]},[_c('router-link',{class:[
          'menu-list__item hover:tw-bg-gray-500 tw-cursor-pointer leading-24 tw-flex tw-items-center tw-rounded-full -tw-font-bold tw-text-base tw-uppercase tw-fill-current align-middle',
        ],attrs:{"tag":"li","to":"/app/stats","title":"Stats"}},[_c('svg',{staticClass:"menu-list__item__icon tw-inline-block tw-flex-shrink-0 -tw-text-blue-700",staticStyle:{"enable-background":"new 0 0 72 72"},attrs:{"version":"1.1","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","x":"0px","y":"0px","viewBox":"0 0 72 72","xml:space":"preserve"}},[_c('path',{attrs:{"d":"M13.6,30.9H6.9c-3.9,0-6.9,3.1-6.9,6.9v27.3C0,68.9,3.1,72,6.9,72h6.7c3.9,0,6.9-3.1,6.9-6.9V37.8\n            C20.6,33.9,17.5,30.9,13.6,30.9z M15.4,65.1c0,1-0.8,1.8-1.8,1.8H6.9c-1,0-1.8-0.8-1.8-1.8V37.8c0-1,0.8-1.8,1.8-1.8h6.7\n            c1,0,1.8,0.8,1.8,1.8C15.4,37.8,15.4,65.1,15.4,65.1z M39.3,0h-6.7c-3.9,0-6.9,3.1-6.9,6.9v58.1c0,3.9,3.1,6.9,6.9,6.9h6.7\n            c3.9,0,6.9-3.1,6.9-6.9V6.9C46.3,3.1,43.2,0,39.3,0z M41.1,65.1c0,1-0.8,1.8-1.8,1.8h-6.7c-1,0-1.8-0.8-1.8-1.8V6.9\n            c0-1,0.8-1.8,1.8-1.8h6.7c1,0,1.8,0.8,1.8,1.8V65.1z M65.1,10.3h-6.7c-3.9,0-6.9,3.1-6.9,6.9v47.8c0,3.9,3.1,6.9,6.9,6.9h6.7\n            c3.9,0,6.9-3.1,6.9-6.9V17.2C72,13.4,68.9,10.3,65.1,10.3z M66.9,65.1c0,1-0.8,1.8-1.8,1.8h-6.7c-1,0-1.8-0.8-1.8-1.8V17.2\n            c0-1,0.8-1.8,1.8-1.8h6.7c1,0,1.8,0.8,1.8,1.8V65.1z"}})]),_c('span',{staticClass:"menu-list__item__name tw-inline-block tw-text-base tw-uppercase leading-24"},[_vm._v(" Stats ")])]),_c('router-link',{class:[
          'menu-list__item hover:tw-bg-gray-500 tw-cursor-pointer leading-24 tw-flex tw-items-center tw-rounded-full -tw-font-bold tw-text-base tw-uppercase tw-fill-current align-middle',
        ],attrs:{"tag":"li","to":"/app/accounts","title":"Accounts"}},[_c('svg',{staticClass:"menu-list__item__icon tw-inline-block tw-flex-shrink-0 -tw-text-blue-700",staticStyle:{"enable-background":"new 0 0 72 72"},attrs:{"version":"1.1","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","x":"0px","y":"0px","viewBox":"0 0 72 72","xml:space":"preserve"}},[_c('path',{attrs:{"d":"M46.9,39.5C53,35.6,57.1,29,57.1,21.3C57.1,9.5,47.7,0,35.9,0S14.6,9.5,14.6,21.3c0,7.7,4.1,14.3,10.2,18.2\n            C12.6,43.8,3.3,55.3,2.3,69.2c0,0.8,0.3,1.3,0.8,2C3.6,72,4.4,72,4.9,72h62.3c0.8,0,1.3-0.3,1.8-0.8s0.8-1.3,0.8-2\n            C68.7,55.3,59.4,43.8,46.9,39.5z M19.7,21.3c0-9,7.2-16.1,16.1-16.1S52,12.3,52,21.3s-7.2,16.1-16.1,16.1S19.7,30.2,19.7,21.3z\n            M7.7,66.6C10,53,21.8,42.5,35.9,42.5S62,52.8,64.1,66.6H7.7z"}})]),_c('span',{staticClass:"menu-list__item__name tw-inline-block tw-text-base tw-uppercase leading-24"},[_vm._v(" Accounts ")])]),_c('router-link',{class:[
          'menu-list__item hover:tw-bg-gray-500 tw-cursor-pointer leading-24 tw-flex tw-items-center tw-rounded-full -tw-font-bold tw-text-base tw-uppercase tw-fill-current align-middle',
        ],attrs:{"tag":"li","to":"/app/matches","title":"Mentor-Mentee Matches"}},[_c('svg',{staticClass:"menu-list__item__icon tw-inline-block tw-flex-shrink-0 -tw-text-blue-700",staticStyle:{"enable-background":"new 0 0 72 72"},attrs:{"version":"1.1","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","x":"0px","y":"0px","viewBox":"0 0 72 72","xml:space":"preserve"}},[_c('path',{attrs:{"d":"M65.6,25.5h-20c-3.2,0-6,2.6-6,6V34H32v-2.5c0-3.2-2.6-6-6-6H6c-3.2,0-6,2.6-6,6v8.9c0,3.2,2.6,6,6,6h20.2c3.2,0,6-2.6,6-6\n            v-2.6h7.5v2.5c0,3.2,2.6,6,6,6H66c3.2,0,6-2.6,6-6v-8.9C71.6,28.2,69,25.5,65.6,25.5z M28.3,40.2c0,1.1-0.9,2.3-2.3,2.3H6\n            c-1.3,0-2.3-0.9-2.3-2.3v-8.9c0-1.1,0.9-2.3,2.3-2.3h20.2c1.1,0,2.3,0.9,2.3,2.3V34h-6.2c-1.1,0-1.9,0.8-1.9,1.9s0.8,1.9,1.9,1.9h6\n            V40.2z M67.9,40.2c0,1.1-0.9,2.3-2.3,2.3h-20c-1.1,0-2.3-0.9-2.3-2.3v-2.5h6.2c1.1,0,1.9-0.8,1.9-1.9S50.7,34,49.6,34h-6.2v-2.5\n            c0-1.1,0.9-2.3,2.3-2.3h20.2c1.1,0,2.3,0.9,2.3,2.3v8.7H67.9z"}})]),_c('span',{staticClass:"menu-list__item__name tw-inline-block tw-text-base tw-uppercase leading-24"},[_vm._v(" Matches ")])]),(!_vm.isRegionalAdmin)?_c('router-link',{class:[
          'menu-list__item hover:tw-bg-gray-500 tw-cursor-pointer leading-24 tw-flex tw-items-center tw-rounded-full -tw-font-bold tw-text-base tw-uppercase tw-fill-current align-middle',
        ],attrs:{"tag":"li","to":"/app/resources","title":"Resources"}},[_c('svg',{staticClass:"menu-list__item__icon tw-inline-block tw-flex-shrink-0 -tw-text-blue-700",staticStyle:{"enable-background":"new 0 0 72 72"},attrs:{"version":"1.1","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","x":"0px","y":"0px","viewBox":"0 0 72 72","xml:space":"preserve"}},[_c('path',{attrs:{"d":"M62.1,14h-8.8c-0.8,0-1.6-0.6-1.6-1.6V2.6C52,1.2,50.8,0,49.4,0H12.1c-2.8,0-5,2.2-5,5v62c0,2.8,2.2,5,5,5h47.9\n            c2.8,0,5-2.2,5-5V16.6C64.9,15.2,63.7,14,62.1,14z M60.9,66.8c0,0.6-0.4,1-1,1H12.1c-0.6,0-1-0.4-1-1V5c0-0.6,0.6-1,1-1H48v8.4\n            c0,3,2.4,5.6,5.6,5.6h7.4V66.8z M51,29.9c0,1.2-0.8,2-2,2H23c-1.2,0-2-0.8-2-2s0.8-2,2-2H49C50.2,27.9,51,28.7,51,29.9z M51,41.9\n            c0,1.2-0.8,2-2,2H23c-1.2,0-2-0.8-2-2c0-1.2,0.8-2,2-2H49C50.2,39.9,51,40.7,51,41.9z M51,53.9c0,1.2-0.8,2-2,2H23c-1.2,0-2-0.8-2-2\n            s0.8-2,2-2H49C50.2,51.9,51,52.7,51,53.9z"}})]),_c('span',{staticClass:"menu-list__item__name tw-inline-block tw-text-base tw-uppercase leading-24"},[_vm._v(" Resources ")])]):_vm._e(),(!_vm.isRegionalAdmin)?_c('router-link',{class:[
          'menu-list__item hover:tw-bg-gray-500 tw-cursor-pointer leading-24 tw-flex tw-items-center tw-rounded-full -tw-font-bold tw-text-base tw-uppercase tw-fill-current align-middle',
        ],attrs:{"tag":"li","to":"/app/communications","title":"Communications"}},[_c('svg',{staticClass:"menu-list__item__icon tw-inline-block tw-flex-shrink-0 -tw-text-blue-700",staticStyle:{"enable-background":"new 0 0 72 72"},attrs:{"version":"1.1","id":"Layer_1","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","x":"0px","y":"0px","viewBox":"0 0 72 72","xml:space":"preserve"}},[_c('path',{attrs:{"d":"M7.2,72c-0.8,0-1.7-0.2-2.3-0.8c-1-0.8-1.5-2.1-1.3-3.4l2.1-13.2c-3.6-5.9-5.4-12.6-5.4-19.3c0-16.5,11.9-31,28.1-34.6l0,0\n            c10.9-2.3,22,0.4,30.6,7.5c8.4,7.1,13.2,17.6,12.8,28.7C71.1,52.9,59.3,67,43.6,70.3c-7.7,1.7-15.7,0.8-23-2.7L8.4,71.8\n            C8,72,7.6,72,7.2,72z M29.2,4.8C14.9,7.9,4.5,20.7,4.5,35.4c0,6.3,1.7,12.4,5.2,17.6c0.2,0.4,0.4,1,0.4,1.5L8,67.6l12.1-4.2\n            c0.6-0.2,1-0.2,1.7,0.2c6.5,3.4,13.8,4.2,20.9,2.7c13.6-3.1,24.1-15.5,24.5-29.5C67.7,27,63.5,17.8,56,11.5\n            C48.7,5.2,38.8,2.9,29.2,4.8L29.2,4.8z M28.8,2.9L28.8,2.9L28.8,2.9z"}})]),_c('span',{staticClass:"menu-list__item__name tw-inline-block tw-text-base tw-uppercase leading-24"},[_vm._v(" Communications ")])]):_vm._e(),(_vm.isSuperAdmin)?_c('router-link',{class:[
          'menu-list__item hover:tw-bg-gray-500 tw-cursor-pointer leading-24 tw-flex tw-items-center tw-rounded-full -tw-font-bold tw-text-base tw-uppercase tw-fill-current align-middle',
        ],attrs:{"tag":"li","to":"/app/companies","title":"Companies"}},[_c('svg',{staticClass:"menu-list__item__icon tw-inline-block tw-flex-shrink-0 -tw-text-blue-700",staticStyle:{"enable-background":"new 0 0 72 72"},attrs:{"version":"1.1","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","x":"0px","y":"0px","viewBox":"0 0 72 72","xml:space":"preserve"}},[_c('path',{attrs:{"d":"M65.9,12.7H48.7v-0.6C48.7,5.3,43.4,0,36.6,0H36h-0.6c-6.8,0-12.1,5.3-12.1,12.1v0.6H6.1c-3.4,0-6.1,2.8-6.1,6.1v47\n            C0,69.2,2.8,72,6.1,72h59.7c3.4,0,6.1-2.8,6.1-6.1v-47C72,15.5,69.2,12.7,65.9,12.7z M27.5,12.1c0-4.2,3.4-7.8,7.8-7.8H36h0.6\n            c4.2,0,7.8,3.4,7.8,7.8v0.6H27.5V12.1z M6.1,16.9h59.7c1.1,0,1.9,0.8,1.9,1.9v19.3H55.1v-4.2c0-1.3-0.8-2.1-2.1-2.1\n            c-1.3,0-2.1,0.8-2.1,2.1v4.2H21.2v-4.2c0-1.3-0.8-2.1-2.1-2.1c-1.3,0-2.1,0.8-2.1,2.1v4.2H4.2V18.8C4.2,17.8,5.1,16.9,6.1,16.9z\n            M67.8,65.9c0,1.1-0.8,1.9-1.9,1.9H6.1c-1.1,0-1.9-0.8-1.9-1.9V42.4h12.7v4.2c0,1.3,0.8,2.1,2.1,2.1c1.3,0,2.1-0.8,2.1-2.1v-4.2\n            h29.6v4.2c0,1.3,0.8,2.1,2.1,2.1c1.3,0,2.1-0.8,2.1-2.1v-4.2h12.7V65.9z"}})]),_c('span',{staticClass:"menu-list__item__name tw-inline-block tw-text-base tw-uppercase leading-24"},[_vm._v(" Companies ")])]):_vm._e(),(_vm.isSuperAdmin)?_c('router-link',{class:[
          'menu-list__item hover:tw-bg-gray-500 tw-cursor-pointer leading-24 tw-flex tw-items-center tw-rounded-full -tw-font-bold tw-text-base tw-uppercase tw-fill-current align-middle',
        ],attrs:{"tag":"li","to":"/app/sessions","title":"Mentors In Practice Sessions"}},[_c('svg',{staticClass:"menu-list__item__icon tw-inline-block tw-flex-shrink-0 -tw-text-blue-700",staticStyle:{"enable-background":"new 0 0 72 72"},attrs:{"version":"1.1","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","x":"0px","y":"0px","viewBox":"0 0 72 72","xml:space":"preserve"}},[_c('path',{attrs:{"d":"M22.2,34.9v13.8c0,1.2-0.9,2.1-2.1,2.1h-18c-1.2,0-2.1-0.9-2.1-2.1V31.8c0-4.7,3.8-8.5,8.5-8.5h32.3c3.2,0,5.8,2.6,5.8,5.8\n            s-2.6,5.8-5.8,5.8H22.2z M9.5,18c-4.7,0-8.5-3.8-8.5-8.5s3.8-8.5,8.5-8.5c4.7,0,8.5,3.8,8.5,8.5S14.2,18,9.5,18z M9.5,70.9\n            c-4.7,0-8.5-3.8-8.5-8.5S4.9,54,9.5,54c4.7,0,8.5,3.8,8.5,8.5S14.2,70.9,9.5,70.9z M36,70.9c-4.7,0-8.5-3.8-8.5-8.5S31.3,54,36,54\n            s8.5,3.8,8.5,8.5S40.7,70.9,36,70.9z M62.5,70.9c-4.7,0-8.5-3.8-8.5-8.5s3.8-8.5,8.5-8.5s8.5,3.8,8.5,8.5S67.1,70.9,62.5,70.9z\n            M25.4,6.4c-1.2,0-2.1-0.9-2.1-2.1s0.9-2.1,2.1-2.1h40.2C69.2,2.1,72,5,72,8.5V36c0,3.5-2.8,6.4-6.4,6.4H55.1\n            c-1.2,0-2.1-0.9-2.1-2.1s0.9-2.1,2.1-2.1h10.6c1.2,0,2.1-0.9,2.1-2.1V8.5c0-1.2-0.9-2.1-2.1-2.1H25.4z"}})]),_c('span',{staticClass:"menu-list__item__name tw-inline-block tw-text-base tw-uppercase leading-24"},[_vm._v(" Sessions ")])]):_vm._e(),(_vm.isSuperAdmin)?_c('router-link',{class:[
          'menu-list__item hover:tw-bg-gray-500 tw-cursor-pointer leading-24 tw-flex tw-items-center tw-rounded-full -tw-font-bold tw-text-base tw-uppercase tw-fill-current align-middle',
        ],attrs:{"tag":"li","to":"/app/packages","title":"Packages"}},[_c('svg',{staticClass:"menu-list__item__icon tw-inline-block tw-flex-shrink-0 -tw-text-blue-700",staticStyle:{"enable-background":"new 0 0 72 72"},attrs:{"version":"1.1","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","x":"0px","y":"0px","viewBox":"0 0 72 72","xml:space":"preserve"}},[_c('path',{staticClass:"st0",attrs:{"d":"M6.8,3.5C3.1,3.5,0,6.6,0,10.4v51.3c0,3.8,3.1,6.8,6.8,6.8h58.3c3.8,0,6.9-3.1,6.9-6.8V10.4\n            c0-3.8-3.1-6.9-6.9-6.9H6.8z M6.8,7.7H26v19.7c0,1.2,0.9,2.1,2.1,2.1h15.8c1.2,0,2.1-0.9,2.1-2.1V7.7h19.1c1.5,0,2.6,1.1,2.6,2.6\n            v51.3c0,1.5-1.1,2.6-2.6,2.6H6.8c-1.5,0-2.6-1.1-2.6-2.6V10.4C4.2,8.9,5.4,7.7,6.8,7.7z M30.2,7.7h11.6v17.6H30.2V7.7z M10.2,11.6\n            c-1.2,0-2.1,0.9-2.1,2.1c0,1.2,0.9,2.1,2.1,2.1h9.4c1.2,0,2.1-0.9,2.1-2.1c0,0,0,0,0,0c0-1.2-0.9-2.1-2.1-2.1c0,0,0,0,0,0H10.2z\n            M55.1,41.8c-0.1,0-0.2,0-0.3,0c0,0,0,0-0.1,0c0,0,0,0,0,0c-0.2,0-0.3,0.1-0.5,0.1c-0.2,0.1-0.4,0.2-0.6,0.4c0,0,0,0,0,0\n            c0,0,0,0-0.1,0.1L47.1,49c-0.8,0.8-0.8,2.2,0,3c0,0,0,0,0,0c0.8,0.8,2.2,0.8,3,0L53,49v8.8c0,1.2,0.9,2.1,2.1,2.1c0,0,0,0,0,0\n            c1.2,0,2.1-0.9,2.1-2.1c0,0,0,0,0,0V49l2.9,2.9c0.8,0.8,2.2,0.8,3,0c0.8-0.8,0.8-2.2,0-3c0,0,0,0,0,0l-6.5-6.5\n            c-0.1-0.1-0.1-0.1-0.2-0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1-0.1\n            c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0\n            c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0C55.3,41.8,55.2,41.8,55.1,41.8C55.2,41.8,55.1,41.8,55.1,41.8L55.1,41.8z"}})]),_c('span',{staticClass:"menu-list__item__name tw-inline-block tw-text-base tw-uppercase leading-24"},[_vm._v(" Packages ")])]):_vm._e(),(_vm.isOtherAdmin)?_c('router-link',{class:[
          'menu-list__item hover:tw-bg-gray-500 tw-cursor-pointer leading-24 tw-flex tw-items-center tw-rounded-full -tw-font-bold tw-text-base tw-uppercase tw-fill-current align-middle',
        ],attrs:{"tag":"li","to":"/app/my-license","title":"My License"}},[_c('svg',{staticClass:"menu-list__item__icon tw-inline-block tw-flex-shrink-0 -tw-text-blue-700",staticStyle:{"enable-background":"new 0 0 72 72"},attrs:{"version":"1.1","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","x":"0px","y":"0px","viewBox":"0 0 72 72","xml:space":"preserve"}},[_c('path',{attrs:{"d":"M59.9,23.9C59.9,10.7,49.3,0,36,0S12.1,10.7,12.1,23.9c0,8.5,4.4,15.7,10.9,20v24.8c0,1.3,0.9,2.4,2,3\n            c1.1,0.4,2.6,0.2,3.5-0.7l7.6-7l7.6,7c0.7,0.7,1.3,0.9,2.2,0.9c0.4,0,0.9,0,1.3-0.2c1.1-0.4,2-1.7,2-3V43.9\n            C55.6,39.6,59.9,32.4,59.9,23.9z M44.7,66.1L38.2,60c-1.3-1.1-3-1.1-4.4,0l-6.5,6.1v-20c2.6,1.1,5.7,1.7,8.7,1.7s6.1-0.7,8.7-1.7\n            V66.1z M36,43.5c-10.9,0-19.6-8.7-19.6-19.6S25.1,4.4,36,4.4s19.6,8.7,19.6,19.6S46.9,43.5,36,43.5z"}})]),_c('span',{staticClass:"menu-list__item__name tw-inline-block tw-text-base tw-uppercase leading-24 tw-truncate"},[_vm._v(" My Account ")])]):_vm._e(),_c('li',{class:[
          'menu-list__item hover:tw-bg-gray-500 tw-cursor-pointer leading-24 tw-flex tw-items-center tw-rounded-full -tw-font-bold tw-text-base tw-uppercase tw-fill-current align-middle',
        ],attrs:{"title":"logout"},on:{"click":function($event){return _vm.logout();}}},[_c('svg',{staticClass:"menu-list__item__icon tw-inline-block tw-flex-shrink-0",staticStyle:{"enable-background":"new 0 0 72 72"},attrs:{"version":"1.1","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","x":"0px","y":"0px","viewBox":"0 0 72 72","xml:space":"preserve"}},[_c('g',[_c('path',{attrs:{"d":"M47.4,7.7c-1.2-0.5-2.6,0.2-3,1.4c-0.5,1.2,0.2,2.6,1.4,3C57.1,16,64.6,26.9,64.6,39c0,15.8-12.8,28.6-28.6,28.6\n              S7.7,54.6,7.7,38.8c0-12.1,7.7-22.8,18.8-26.9c1.2-0.5,1.9-1.9,1.4-3c-0.5-1.2-1.9-1.9-3-1.4C11.6,12.1,2.8,24.6,2.8,38.8\n              C2.8,57.1,17.6,72,36,72s33.2-14.9,33.2-33.2C69.4,24.9,60.6,12.3,47.4,7.7z"}}),_c('path',{attrs:{"d":"M36.2,34.6c1.4,0,2.3-0.9,2.3-2.3v-30c0-1.4-0.9-2.3-2.3-2.3s-2.3,0.9-2.3,2.3v30C33.9,33.4,34.8,34.6,36.2,34.6z"}})])]),_c('span',{staticClass:"menu-list__item__name tw-inline-block tw-text-base tw-uppercase leading-24",staticStyle:{"line-height":"24px"}},[_vm._v(" Logout ")])])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }