<template>
  <div>
    <div class="tw-flex tw-h-screen tw-w-full tw-bg-white">
      <div class="tw-hidden md:tw-block md:tw-w-1/6 tw-my-3 my-bg-pattern tw-rounded-r-55 tw-overflow-hidden"></div>
      <div class="tw-w-full md:tw-w-5/6 px-body md:tw-px-0 tw-bg-white tw-h-full tw-flex tw-flex-col tw-justify-center tw-items-center">
        <BaseCard
          :class="[
            'tw-p-0 md:tw-p-8 tw-border-t-2 transition-slow tw-overflow-hidden tw-shadow-none md:tw-shadow-app tw-max-w-md tw-w-2/3 tw-flex-shrink-0 tw-mb-20 auth-card',
            { 'tw-border-app-black-11': loginStep !== 2 },
            { 'tw-border-app-deep-blue-11': loginStep === 2 },
            ]"
        >
          <transition name="slide-right" mode="out-in" appear>
            <div v-if="loginStep !== 2" key="password">
              <h1 class="tw-text-4xl tw-text-center">Login</h1>
              <div class="tw-h-4 tw-block"></div>
              <form
                ref="login-form"
                id="login-form"
                method="post"
                action="/login"
                @submit.prevent="onPasswordSubmit"
                >
                <div class="tw-block tw-my-4 tw-shadow-md tw-bg-red-600 tw-text-white tw-px-4 tw-py-2 tw-rounded-sm" v-if="errors.auth">
                  {{ errors.auth }}
                </div>
                <div class="tw-mb-5">
                  <InputGroup
                    label="email address"
                    name="email"
                    type="email"
                    placeholder="email"
                    input-classes="tw-py-2"
                    v-model="email"
                    :error="errors.email"
                  />
                </div>
                <div class="tw-mb-8">
                  <InputGroup
                    label="Password"
                    name="password"
                    type="password"
                    placeholder="password"
                    input-classes="tw-py-2"
                    v-model="password"
                    :error="errors.password"
                  />
                </div>
                <div class="flex items-center justify-between">
                  <BaseButton
                    type="submit"
                    :text="btnText"
                    :disabled="!canPasswordSubmit || btnDisabled"
                  />
                  <router-link
                    class="tw-inline-block tw-ml-2 tw-align-baseline tw-font-bold tw-text-sm tw-text-blue-500
                    my-btn hover:tw-text-blue-800"
                    to="forgot-password"
                  >Forgot Password?</router-link>
                </div>
              </form>
            </div>

            <div v-else key="otp">
              <h1 class="tw-text-4xl tw-text-center">Confirm user</h1>
              <h4 class="tw-text-sm">
                We have sent you an sms and email to confirm you really are the admin.
              </h4>
              <div class="tw-h-4 tw-block"></div>
              <form
                ref="login-form"
                id="login-form-2"
                method="post"
                action="/auth-login"
                @submit.prevent="onOTPSubmit"
                >
                <div class="tw-block tw-my-4 tw-shadow-md tw-bg-red-600 tw-text-white tw-px-4 tw-py-2 tw-rounded-sm" v-if="errors.auth">
                  {{ errors.auth }}
                </div>
                <div class="tw-mb-8">
                  <InputGroup
                    label="OTP"
                    name="otp"
                    type="number"
                    placeholder="OTP number"
                    input-classes="tw-py-4"
                    v-model="otp"
                    :error="errors.otp"
                  />
                </div>
                <div class="flex items-center justify-between">
                  <BaseButton
                    type="submit"
                    :text="btnText"
                    :disabled="!canOTPSubmit || btnDisabled"
                    class="tw-bg-app-light-blue tw-border-app-deep-blue tw-text-app-deep-blue"
                  />
                </div>
              </form>
            </div>
          </transition>
        </BaseCard>
      </div>
    </div>
  </div>
</template>

<script>
import _debounce from 'lodash/debounce';
import InputGroup from '@/components/InputGroup.vue';
import form from '@/modules/formHelper';
import { apiStore, apiPost, generateFormData } from '@/modules/apiHelper';
import authMixin from '@/mixins/auth';

export default {
  name: 'LoginView',
  components: {
    InputGroup,
  },
  mixins: [authMixin],
  data() {
    return {
      btnText: 'Log in',
      btnDisabled: false,
      loginStep: 1,
      // form inputs
      email: undefined,
      password: undefined,
      otp: undefined,
      errors: {
        email: undefined,
        otp: undefined,
        auth: this.$store.state.User.authError,
      },
    };
  },
  computed: {
    canPasswordSubmit() {
      return form.isValidEmail(this.email)
        && form.isValidTextInput(this.password, true, form.passwordRegex);
    },
    canOTPSubmit() {
      return form.isValidNumber(this.otp, false);
    },
    formData() {
      return generateFormData({
        email: this.email,
        password: this.password,
      });
    },
  },
  watch: {
    // eslint-disable-next-line func-names
    email: _debounce(function (val) {
      if (!form.isValidEmail(val)) {
        this.errors.email = 'Invalid email address';
      } else {
        this.errors.email = undefined;
      }
    }, 300),
    // eslint-disable-next-line func-names
    password: _debounce(function (val) {
      if (!form.isValidTextInput(val, true, form.passwordRegex)) {
        this.errors.password = 'Tip: Your password is atleast 8 characters long';
      } else {
        console.log('cleared!');
        this.errors.password = undefined;
      }
    }, 150),
  },
  methods: {
    onPasswordSubmit() {
      if (!this.canPasswordSubmit) {
        this.$store.commit('authError', 'Cannot submit the form. Please check for empty fields');
        // this.$toasted.global.appError({ errorMessage: 'Cannot submit the form. Please check for empty fields', duration: 4000, position: 'top-right' });
        return false;
      }

      this.btnDisabled = true;
      this.btnText = 'working...';
      this.$store.commit('authError', undefined);

      return apiPost('login', this.formData, 6)
        .catch((err) => {
          console.warn('login error', err);
          this.$toasted.global.appError();
        })
        .then(async (res) => {
          this.btnDisabled = false;
          this.btnText = 'Log in';
          // console.log(res.data);
          if (!res) return false;

          const { data } = res;
          if (data.error) {
            this.$store.commit('authError', data.message);
            this.$toasted.global.appError({ errorMessage: data.message, duration: 4000, position: 'top-right' });
            return false;
          }
          const status = (data.status || '').toLowerCase();

          if (status === 'Verify') {
            // redirect to verify
            this.$router.push({
              name: 'verify-account',
              query: {
                email: this.email,
                s: 'ev',
              },
            });

            return true;
          }

          await this.$nextTick();

          // * step two
          this.loginStep = 2;
          await this.$nextTick();

          return status === 'success';
        });
    },
    onOTPSubmit() {
      if (!this.canOTPSubmit) {
        this.$store.commit('authError', 'Cannot submit the form. Please check for empty fields');
        // this.$toasted.global.appError({ errorMessage: 'Cannot submit the form. Please check for empty fields', duration: 4000, position: 'top-right' });
        return false;
      }

      this.btnDisabled = true;
      this.btnText = 'working...';
      this.$store.commit('authError', undefined);

      return apiPost('login_opt', generateFormData({ OTP: this.otp }), 9)
        .catch((err) => {
          console.warn('login otp error', err);
          this.$toasted.global.appError();
        })
        .then(async (res) => {
          this.btnDisabled = false;
          this.btnText = 'Log in';

          if (!res) return false;

          const { data } = res;

          await this.$store.dispatch('clearUserSession');
          this.$store.commit('authError', false);

          // TODO: Dynamic license
          await this.$store.dispatch('userLogIn', { ...data, licensetype: 1 });

          await this.$nextTick();

          this.$store.dispatch('logAction', this.$store.state.Logger.actionsIndexes.login);

          this.$toasted.success(data.message, {
            duration: 4000,
            theme: 'outline',
            position: 'bottom-right',
          });

          await apiStore.removeItem('forgotpasswordsent');
          await this.$nextTick();

          // ! reload window for app routes to register afresh
          window.location.reload();

          // const userStoreStates = this.$store.state.User;
          // if (userStoreStates.accountTypes.superAdmin === userStoreStates.id) {
          // }
          // await this.$router.push({ name: 'app' })
          //   .catch((err) => {
          //     console.warn('error in routing', err);
          //     // ? Redirect by old js to an 'Oops' page
          //     window.location.reload();
          //   });

          return true;
        });
    },
  },
  async created() {
    await this.unregisterAuthStoreModule();
    // if user is logged in, redirect to app
    const to = sessionStorage.getItem('after-login-redirector') || '/app';
    if (this.$store.getters.isLoggedIn) {
      sessionStorage.removeItem('after-login-redirector');
      this.$router.push(to);
    }
  },

};
</script>

<style>

</style>
